import { Component, OnInit, ViewChild } from "@angular/core";
import { FileService } from "../../../services/file.service";
import { Router } from "@angular/router";
import { getImportProjectKegaUrl, getImportProjectVegaUrl } from "../../../app.urls";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { AppCommunicationService } from "../../../services/app-communication.service";
import { ProjectsImportInfoModalComponent } from "../projects-import-info-modal/projects-import-info-modal.component";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-import-project-page",
  templateUrl: "./import-project-page.component.html",
  styleUrls: ["./import-project-page.component.scss"]
})
export class ImportProjectPageComponent implements OnInit {

  agencies = [
    {
      name: "KEGA"
    },
    {
      name: "VEGA"
    }
  ];

  selectedAgency = this.agencies[0];

  selectedFile: File[] = [];

  isValid = false;

  @ViewChild(ProjectsImportInfoModalComponent, {static: false}) projectImport: ProjectsImportInfoModalComponent;

  constructor(
    private fileService: FileService,
    private router: Router,
    private toastr: ToastrService,
    private appCommunicationService: AppCommunicationService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
  }

  checkValidity() {
    this.isValid = !!(this.selectedFile.length > 0 && this.selectedFile[0].size && this.selectedAgency);
  }

  addFile(event) {
    if (event && event.currentFiles) {
      this.selectedFile = [...event.currentFiles];
      this.checkValidity();
    }
  }

  submitAction() {
    if (this.isValid) {
      let url;
      if (this.selectedAgency.name === "KEGA") {
        url = getImportProjectKegaUrl();
      } else {
        url = getImportProjectVegaUrl();
      }

      this.appCommunicationService.loadingOn();

      this.fileService
        .upload(url, this.selectedFile[0])
        .pipe(first())
        .subscribe({
          next: value => {
            this.appCommunicationService.loadingOff();
            this.toastr.success(this.i18n.getTranslation('Súbor úspešne importovaný.'));

            if (value && value.body && value.body.result) {
              this.toastr.success(`${this.i18n.getTranslation('Úspešne importovaných')} ${value.body.result} ${this.i18n.getTranslation('záznamov')}.`);
            }

            this.reset();
          },
          error: err => {
            this.toastr.error(this.i18n.getTranslation('Súbor sa nepodarilo importovať'));

            if (err && err.error && err.error.errors && err.error.errors.length > 0) {
              if (err.error.errors.indexOf(1006) !== -1) {
                this.toastr.error(this.i18n.getTranslation('Súbor má zlú štruktúru / postupnosť stĺpcov.'));
              }
            }
            this.appCommunicationService.loadingOff();
          }
        });
    }
  }

  reset() {
    this.selectedFile = [];
  }

  async closeAction() {
    await this.router.navigate(["/projects-management"]);
  }

  clickedInfoButton() {
    this.projectImport.openModal();
  }

}
