import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { UserService } from "../../../../services/user.service";
import { AppCommunicationService } from "../../../../services/app-communication.service";
import { ConfirmationService } from "primeng";
import { ToastrService } from "ngx-toastr";
import { TicketService } from "../../../../services/ticket.service";
import { TicketFullModel } from "../../../../models/ticket/ticket-full.model";
import { FormChangeAuthorParticipantRequestModel } from "../../../../models/form-change-author-participant-request.model";
import { TukeAuthorRequestModel } from "../../../../models/tuke-author-request.model";
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-author-tuke-employment-dialog",
  templateUrl: "./author-tuke-employment-dialog.component.html",
  styleUrls: ["./author-tuke-employment-dialog.component.scss"]
})
export class AuthorTukeEmploymentDialogComponent implements OnInit {

  data: any[] = [];

  title = "";

  ticket: TicketFullModel;

  authorLogin = "";

  doAfterClose: () => void;

  @ViewChild("staticModal", { static: false }) modal: ModalDirective;

  constructor(
    private userService: UserService,
    private appCommunication: AppCommunicationService,
    private confirmDialog: ConfirmationService,
    private toastr: ToastrService,
    private ticketService: TicketService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
  }

  async openModal(authorLogin: string, ticket: TicketFullModel, doAfterClose: () => void = null) {
    if (!authorLogin || !ticket) {
      return;
    }

    this.ticket = ticket;

    this.authorLogin = authorLogin;

    this.doAfterClose = doAfterClose;

    this.appCommunication.loadingOn(this.i18n.getTranslation("Načítavam dáta."));

    const res = await this.userService
      .getTukeAuthorEmploymentsByLogin(authorLogin)
      .toPromise();

    if (res) {
      this.data = res
        .sort((a, b) => {
          const dateA = new Date(a.startEmploymentDate).getTime();
          const dateB = new Date(b.startEmploymentDate).getTime();
          return dateB - dateA;
        })
        .sort((a, b) => {
          return a.isHistorical - b.isHistorical;
        });

      if (this.data.length > 0) {
        this.title = `${this.data[0].name} ${this.data[0].surname}`;
      }
      this.modal.show();
    }

    setTimeout(() => {
      this.appCommunication.loadingOff();
    }, 70);
  }

  close() {
    this.modal.hide();

    if (this.doAfterClose) {
      this.doAfterClose();
    }
  }

  changeAuthorsEmployment(employment: any) {
    this.confirmDialog
      .confirm({
        message: this.i18n.getTranslation("Skutočne si prajete zmeniť autorov pomer na TUKE?"),
        acceptLabel: this.i18n.getTranslation('Áno'),
        rejectLabel: this.i18n.getTranslation('Nie'),
        accept: () => {
          this.appCommunication.loadingOn(this.i18n.getTranslation("Mením autorov pomer na TUKE."));

          const changedParticipantRequest = this.replaceSearchedParticipant(employment);

          this.userService
            .changeSearchedParticipant(changedParticipantRequest)
            .subscribe(v => {
              this.toastr.success(this.i18n.getTranslation("Zmena prebehla úspešne"));
              this.appCommunication.loadingOff();
              this.close();
            }, e => {
              this.toastr.error(this.i18n.getTranslation("Zmena pomeru zlyhala."));
              this.appCommunication.loadingOff();
            });
        }
      });
  }

  replaceSearchedParticipant(participant: any): FormChangeAuthorParticipantRequestModel {
    if (!this.ticket) {
      return;
    }

    const authorChangeRequest = new FormChangeAuthorParticipantRequestModel();
    const tukeAuthorRequest = new TukeAuthorRequestModel();
    Object.assign(tukeAuthorRequest, participant);
    authorChangeRequest.ticketId = this.ticket.id;
    authorChangeRequest.tukeAuthor = tukeAuthorRequest;

    // check if is author
    if (this.ticket.formBiblio) {
      const isAuthor = this.ticket.formBiblio.author.login === this.authorLogin;

      if (isAuthor) {
        authorChangeRequest.formAuthorId = this.ticket.formBiblio.author.id;
      } else {
        const participantIndex = this.ticket.formBiblio.participants.findIndex(p => p.login === this.authorLogin);

        if (participantIndex !== -1) {
          authorChangeRequest.formParticipantId = this.ticket.formBiblio.participants[participantIndex].id;
        }
      }
    }

    return authorChangeRequest;
  }

}
