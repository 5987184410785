import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-meeting-list',
  templateUrl: './meeting-list.component.html',
  styleUrls: ['./meeting-list.component.scss']
})
export class MeetingListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
