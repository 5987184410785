import { AfterViewInit, Component, EventEmitter, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { FilterService, FilterToShow } from "./filter.service";
import { Store } from "@ngrx/store";
import { CODEBOOK_ITEMS, CodebooksService } from "../../../../services/codebooks.service";

export const YEARS_INIT_YEAR = 2004;

export const FILTER_TYPES = {
  search: "search",
  year: "year",
  biblioCategory: "biblioCategory",
  faculty: "faculty",
  department: "department"
};

@Component({
  selector: "smart-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"]
})
export class FilterComponent implements OnInit, AfterViewInit {
  pageSizeData = [
    {
      title: "5",
      value: 5
    },
    {
      title: "10",
      value: 10
    },
    {
      title: "20",
      value: 20
    },
    {
      title: "30",
      value: 30
    }
  ];

  years = {
    data: [],
    settings: {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Označiť všetko",
      unSelectAllText: "Odznačiť všetko",
      itemsShowLimit: 1,
      allowSearchFilter: false
    }
  };

  biblioCategory = {
    data: [],
    settings: {
      singleSelection: false,
      idField: "id",
      textField: "code",
      selectAllText: "Označiť všetko",
      unSelectAllText: "Odznačiť všetko",
      itemsShowLimit: 2,
      allowSearchFilter: true
    }
  };

  faculty = {
    data: [],
    settings: {
      singleSelection: true,
      idField: "facultyId",
      textField: "facultyCode",
      selectAllText: "Označiť všetko",
      unSelectAllText: "Odznačiť všetko",
      itemsShowLimit: 1,
      allowSearchFilter: true
    }
  };

  department = {
    data: [],
    settings: {
      singleSelection: true,
      idField: "departmentId",
      textField: "departmentCode",
      selectAllText: "Označiť všetko",
      unSelectAllText: "Odznačiť všetko",
      itemsShowLimit: 1,
      allowSearchFilter: true
    }
  };

  filterToShow = new FilterToShow();

  filter = {
    text: "",
    years: [],
    biblioCategories: [],
    faculty: [],
    department: [],
    pageSize: this.pageSizeData[1]
  };

  allowedAutoSaveFilter = false;

  @Output() filterChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected filterService: FilterService,
    protected codebooksService: CodebooksService,
    private store: Store<any>
  ) {
  }

  ngOnInit() {
    this.filterService
      .filterToShow
      .subscribe(f => this.filterToShow = f);

    this.generateYearsFilter();

    this.generateBiblioCategory();

    this.generateFacultyFilter();

    const restoredFilter = this.filterService.loadFromLocalStorage();
    if (restoredFilter) {
      this.filter = restoredFilter.filter;
      this.filterToShow = restoredFilter.filterToShow;
    }

    this.store.subscribe(v => {
      this.allowedAutoSaveFilter = v.settings.autoSaveFilter;
    });

    setTimeout(() => {
      this.filterData();
    }, 200);
  }

  ngAfterViewInit(): void {
    this.onInputFilter();
  }

  generateYearsFilter() {
    let initYear = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
    const endYear = moment(`${YEARS_INIT_YEAR}-01-01 00:00:00`);

    while (moment(initYear).format("YYYY") > moment(endYear).format("YYYY")) {
      this.years.data.push({
        item_id: moment(initYear).format("YYYY"),
        item_text: moment(initYear).format("YYYY")
      });
      if (moment(initYear).format("YYYY") === moment().format("YYYY")) {
        this.filter.years.push(
          moment(initYear).format("YYYY")
        );
      }
      initYear = moment(initYear).subtract(1, "years").format("YYYY-MM-DD HH:mm:ss");
    }
  }

  async generateBiblioCategory() {
    this.biblioCategory.data = await this.codebooksService
      .get(CODEBOOK_ITEMS.biblioCategories);
  }

  async generateFacultyFilter() {
    const data = await this.codebooksService.get(CODEBOOK_ITEMS.faculties);

    if (data && data.items) {
      this.faculty.data = data.items;
    }
  }

  generateDepartmentFilter() {
    let facultyId;
    console.log(this.filter.faculty)
    if (this.filter && this.filter.faculty && this.filter.faculty.length > 0) {
      facultyId = this.filter.faculty[0].facultyId;
    } else {
      this.department.data = [];
      return;
    }

    this.codebooksService
      .getDepartments(facultyId)
      .subscribe(d => {
        const data = d;

        if (data && data['items']) {
          this.department.data = data['items'];
        }

      });
  }

  filterData() {
    const pageSize = this.filter.pageSize;
    const filter = Object.assign(this.filter);
    filter.pageSize = pageSize;
    this.filterService.filter.next(filter);
    if (this.allowedAutoSaveFilter) {
      this.filterService.saveToLocalStorage(filter, this.filterToShow);
    }
    this.filterService.currentFilter = filter;
    this.filterChanged.emit(this.filter);
  }

  resetFilter() {
    this.filter = {
      text: "",
      years: [moment(Date.now()).format("YYYY")],
      biblioCategories: [],
      pageSize: this.pageSizeData[1],
      faculty: [],
      department: []
    };
    if (this.allowedAutoSaveFilter) {
      this.filterService.saveToLocalStorage(this.filter, this.filterToShow);
    }
    this.filterData();
  }

  onPageSizeChange(event) {
    if (event) {
      this.filter.pageSize = event.value;
      this.filterData();
    }
  }

  onInputFilter() {
    let timeout = null;
    const textInput = document.getElementById("search") as HTMLInputElement;
    textInput.onkeyup = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        this.filter.text = textInput.value;
        this.filterData();
      }, 500);
    };
  }

  changeFilter(filter) {
    if (filter) {
      Object.keys(filter)
        .forEach(k => this.filter[k] = filter[k]);
    }
  }

  public getCurrentFilter() {
    return this.filter;
  }
}
