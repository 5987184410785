import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormAttachmentTypes, FormAttachmentTypesArray, handOverTypes, TicketStatuses } from "src/app/+app-custom/constants";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TicketSimpleModel } from "../../../../models/ticket/ticket-simple.model";
import { TicketAttachmentPhysicalDocument } from "../../misc/ticket-attachment-physical-document";
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-ticket-modal-subform",
  templateUrl: "./ticket-modal-subform.component.html",
  styleUrls: ["./ticket-modal-subform.component.scss"]
})
export class TicketModalSubformComponent implements OnInit, OnChanges {
  attachmentTypes = [];

  handOverTypes = [];

  processingTypes = [ ];

  ticketStatusEnum = TicketStatuses;

  subform: FormGroup;

  forceShowedAttachmentTypeSubform = false;

  @Input() ticket: TicketSimpleModel;

  @Input() showOnlyComment = false;

  constructor(
    private formBuilder: FormBuilder,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.regenerateValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['ticket']) {
      this.regenerateValues();
    }
  }

  regenerateValues() {
    this.generateDropdownValues();

    if (this.showOnlyComment) {
      this.subform = this.formBuilder.group({
        comment: ["", [Validators.required]]
      });
    } else {
      this.subform = this.formBuilder.group({
        attachmentType: [""],
        handOverType: [""],
        processingType: [""]
      });
    }

    this.prefillInputs();
  }

  generateDropdownValues() {
    this.handOverTypes = [
      { label: this.i18n.getTranslation('Osobne'), value: handOverTypes.picking_up_in_uk },
      { label: this.i18n.getTranslation("Internou poštou"), value: handOverTypes.sending_by_post },
      { label: this.i18n.getTranslation("Bez vrátenia"), value: handOverTypes.nothing }
    ];

    this.processingTypes = [
      { label: this.i18n.getTranslation('Sken'), value: "scan" },
      { label: this.i18n.getTranslation('Konverzia'), value: "conversion" }
    ];


    const formAttachmentTypes = FormAttachmentTypesArray;
    this.attachmentTypes = [];

    formAttachmentTypes.forEach(t => {
      this.attachmentTypes.push({
        label: this.i18n.getTranslation(t.description),
        value: t.value
      });
    });
  }

  changedAttachmentType() {
    const isPhysicDocument = this.showAttachmentTypesDropdown();

    if (!isPhysicDocument) {
      this.subform.controls['handOverType'].patchValue(handOverTypes.nothing);
      this.subform.controls['processingType'].patchValue('conversion');
    } else {
      this.subform.controls['handOverType'].patchValue(this.ticket.handOverType || handOverTypes.picking_up_in_uk);
      this.subform.controls['processingType'].patchValue('scan');
    }
  }

  changedProcessingType() {
    // if (this.subform.get('processingType').value === 'scan') {
    //   this.subform.controls['handOverType'].patchValue(handOverTypes.nothing);
    // }
  }

  prefillInputs() {
    this.subform.controls["attachmentType"].patchValue(this.ticket.attachmentType);

    if (!TicketAttachmentPhysicalDocument(this.ticket.attachmentType, FormAttachmentTypes)) {

      this.forceShowedAttachmentTypeSubform = false;

      this.subform.controls["handOverType"].patchValue(handOverTypes.sending_by_post);

      this.subform.controls["processingType"].patchValue("conversion");

    } else {

      this.forceShowedAttachmentTypeSubform = true;

      this.subform.controls["handOverType"].patchValue(handOverTypes.picking_up_in_uk);

      this.subform.controls["processingType"].patchValue("scan");

    }

  }

  getSubformValue(): any {
    return this.subform.value;
  }

  getSubform(): FormGroup {
    return this.subform;
  }

  showAttachmentTypesDropdown(): boolean {
    return (TicketAttachmentPhysicalDocument(this.subform.get('attachmentType').value, FormAttachmentTypes));
  }

}
