import { RoleCodebookModel } from '../codebook/role-codebook.model';
import { UserModel } from "../user.model";

export class BaseFormPersonModel {
  id?: number;
  participationPercentage?: number;
  roleCodeId: number;
  roleCode?: RoleCodebookModel;
  personId?: number;

  userId?: number;
  isExternal?: boolean;
  login: string;
  facultyId?: number;
  facultyCode?: string;
  facultyName?: string;
  departmentId?: number;
  departmentCode?: string;
  departmentName?: string;
  fullName: string;
  name: string;
  surname: string;
  title: string;
  titleAfter: string;
  fromDate?: Date;
  toDate?: Date;
  user?: UserModel;

  gender: string;

  orcid?: string;

  biblioFieldOfStudyId: number;
  researchAreaId: number;

  workCenterName?: string;
  workCenterId?: number;
  workCenterCode?: string;
}
