import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { BiblioService } from "../../services/biblio.service";
import { BiblioModel } from "../../models/biblio/biblio.model";
import { PublishingModel, PublishingType } from "../../models/biblio/publishing.model";
import { CitationComponent } from "../../views/catalog/components/citation/citation.component";
import { CitationModel } from "../../models/biblio/citation.model";

@Component({
  selector: "smart-biblio-detail",
  templateUrl: "./biblio-detail.component.html",
  styleUrls: ["./biblio-detail.component.scss"]
})
export class BiblioDetailComponent implements OnInit {
  biblio: BiblioModel;

  afterCloseFn: () => void;

  citationId = null;

  filteredCitation: CitationModel = new CitationModel();

  @ViewChild("biblioDetail", { static: true }) public lgModal: ModalDirective;

  @Output() clickCitation = new EventEmitter();

  @Output() clickDownload = new EventEmitter();

  @ViewChild(CitationComponent, { static: false }) citation: CitationComponent;

  constructor(
    protected biblioService: BiblioService
  ) {
  }

  ngOnInit() {
  }

  show(
    afterClose: () => void = null,
    id: number,
    biblio = null,
    citationId: number = null
  ) {
    if (biblio) {
      this.biblio = biblio;
    } else {
      this.biblioService.getById(id)
        .subscribe(val => this.biblio = val);
    }
    this.citationId = citationId;
    this.afterCloseFn = afterClose;
    if (this.citationId) {
      this.findCitation();
    }
    this.lgModal.show();
  }

  findCitation() {
    if (this.biblio && this.biblio.citations) {
      this.filteredCitation = this.biblio.citations.find(c => c.id === this.citationId);
    }
  }

  close() {
    this.lgModal.hide();
  }

  clickedCitation() {
    setTimeout(() => {
      this.citation.show(this.biblio, () => {
        this.show(null, this.biblio.id);
      });
    }, 50);
    this.close();
  }

  clickedDownload() {
    this.clickDownload.emit(true);
  }

  getSortedPersons(persons: PublishingModel[]): PublishingModel[] {
    return persons.filter(p => p.type !== PublishingType.reviewer)
      .sort((a, b) => {
        return a.type - b.type;
      })
      .sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
  }

}
