import { NgModule } from "@angular/core";
import { UserPublicationDataDialogComponent } from "./user-publication-data-dialog.component";
import { ModalModule } from "ngx-bootstrap";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";
import { AutoCompleteModule, CheckboxModule, DropdownModule, InputMaskModule, InputTextModule } from "primeng";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { PdfViewerModule } from "../../../core-frontend-common/components/pdf-viewer/pdf-viewer.module";

@NgModule({
  imports: [
    ModalModule,
    I18nModule,
    InputMaskModule,
    FormsModule,
    InputTextModule,
    CheckboxModule,
    CommonModule,
    PdfViewerModule,
    AutoCompleteModule,
    DropdownModule
  ],
  exports: [
    UserPublicationDataDialogComponent
  ],
  declarations: [
    UserPublicationDataDialogComponent
  ]
})

export class UserPublicationDataDialogModule {}
