import { ContactEmailModel } from "./contact-email.model";

export const EmailTemplates: ContactEmailModel[] = [
  {
    title: "Oprava formulára",
    value: {
      subject: "Oprava formulára",
      body: "Dobrý deň,<br>" +
        "<br>" +
        "<br>" +
        "<br>" +
        "<br>" +
        "S pozdravom"
    }
  },
  {
    title: "Dodanie podkladov",
    value: {
      subject: "Dodanie podkladov",
      body: "Dobrý deň,<br>" +
        "<br>" +
        "chceli by sme Vás požiadať o dodanie podkladov k danému archívnemu číslu za účelom spracovania do databázy EPC TUKE.<br>" +
        "<br>" +
        "V prípade otázok nás kontaktujte.<br>" +
        "<br>" +
        "S pozdravom"
    }
  },

  {
    title: "Doplnenie podkladov",
    value: {
      subject: "Doplnenie podkladov",
      body: "Dobrý deň,<br>" +
        "<br>" +
        "Váš záznam bol vrátený na registráciu (Moje publikácie). Žiadame Vás o kontrolu formulára a doplnenie požadovaných údajov.<br>" +
        "<br>" +
        "V prípade otázok nás kontaktujte.<br>" +
        "<br>" +
        "S pozdravom"
    }
  },
  {
    title: "Autocitácia",
    value: {
      subject: "Autocitácia",
      body: "Dobrý deň,<br>" +
        "<br>" +
        "Oznamujeme Vám, že Váš ohlas nebol spracovaný do databázy EPC, keďže ide o autocitáciu, ktorá podľa vyhlášky EPC nie je predmetom vykazovania publikačnej činnosti.<br>" +
        "<br>" +
        "V prípade otázok nás kontaktujte.<br>" +
        "<br>" +
        "S pozdravom"
    }
  },
  {
    title: "Thesis citácia",
    value: {
      subject: "Thesis citácia",
      body: "Dobrý deň,<br>" +
        "<br>" +
        "Oznamujeme Vám, že Váš ohlas nebol spracovaný do databázy EPC, keďže ide o citáciu v záverečnej práci, ktorá podľa vyhlášky EPC nie je predmetom vykazovania publikačnej činnosti.<br>" +
        "<br>" +
        "V prípade otázok nás kontaktujte.<br>" +
        "<br>" +
        "S pozdravom"
    }
  },
  {
    title: "Nevykazuje sa",
    value: {
      subject: "Nevykazuje sa",
      body: "Dobrý deň,<br>" +
        "<br>" +
        "Oznamujeme Vám, že Váš záznam nebol spracovaný do databázy EPC, keďže podľa vyhlášky EPC nie je predmetom vykazovania publikačnej činnosti.<br>" +
        "<br>" +
        "V prípade otázok nás kontaktujte.<br>" +
        "<br>" +
        "S pozdravom"
    }
  },
  {
    title: "Duplicita",
    value: {
      subject: "Duplicita",
      body: "Dobrý deň,<br>" +
        "<br>" +
        "Oznamujeme Vám, že Váš záznam/ohlas nebol spracovaný do databázy EPC, keďže ide o duplicitu.<br>" +
        "<br>" +
        "V prípade otázok nás kontaktujte.<br>" +
        "<br>" +
        "S pozdravom"
    }
  },
  {
    title: "Zmena kategórie",
    value: {
      subject: "Zmena kategórie",
      body: "Dobrý deň,<br>" +
        "<br>" +
        "Vo Vašom formulári máte uvedený nesprávny kód kategórie. Žiadame Vás o opravu na kategóriu ... . <br><br>Vrátený formulár na opravu nájdete v systéme EPC, záložka Moje žiadosti, koncept. Po oprave formulár odošlite. <br><br>V prípade ďalších otázok kontaktujte Ing. Čopjaka, marek.copjak@tuke.sk.<br>" +
        "<br>" +
        "S pozdravom"
    }
  }
];
