import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { PublishingModel } from "../../../models/biblio/publishing.model";
import { BiblioCatalogSharedService } from "../biblio-catalog-shared.service";
import { BiblioService } from "../../../services/biblio.service";
import { FilterItem, getQueryDesignerFilter } from "../misc/query-designer";
import { BiblioModel } from "../../../models/biblio/biblio.model";
import { Subject } from "rxjs";
import { FacultyModel } from "../../../models/faculty.model";
import { LanguageCodebookModel } from "../../../models/codebook/language-codebook.model";
import { lang } from "moment";
import { queryFilterTypes } from "../../../../core-frontend-common/misc/query-designer/query-designer";

@Component({
  selector: "smart-author-detail",
  templateUrl: "./author-detail.component.html",
  styleUrls: ["./author-detail.component.scss"]
})
export class AuthorDetailComponent implements OnInit {
  biblioData: BiblioModel[] = [];

  author: PublishingModel;

  queryDesigner: any;

  citedPublicationsCount = 0;

  mostUsedLanguages: { language: LanguageCodebookModel, count: number }[] = [];

  minPublishedYear = 0;
  maxPublishedYear = 0;

  publishedYears: { year: number, count: number }[] = [];

  @Input() dataChanged: Subject<any> = new Subject<any>();

  @Input() faculties: FacultyModel[] = [];

  constructor(
    protected catalogDataService: BiblioCatalogSharedService,
    protected biblioService: BiblioService
  ) {
    this.queryDesigner = this.getQueryDesigner();
  }

  ngOnInit() {
    this.dataChanged.subscribe(() => {
      this.queryDesigner = this.getQueryDesigner();
    });
  }

  getQueryDesigner(): any {
    this.author = this.catalogDataService.data.author;

    const filterItem: FilterItem = {
      queryParam: "participants",
      queryParamValue: "publishings.publishing.surname",
      queryParamData: [this.author.surname],
      filterType: queryFilterTypes.Contains
    };

    return getQueryDesignerFilter(
      [filterItem],
      null,
      1,
      10,
      null
    );
  }

  biblioListChanged(biblios: BiblioModel[]) {
    this.biblioData = biblios.sort((a, b) => {
      return b.citations.length - a.citations.length;
    });
    this.getCalculations();
  }

  getCalculations() {
    this.citedPublicationsCount = 0;

    this.mostUsedLanguages = [];

    this.publishedYears = [];

    this.biblioData.forEach(biblio => {
      this.citedPublicationsCount += biblio.citations.length;

      const existsLanguage = this.mostUsedLanguages.find(l => l.language.code === biblio.language.code);

      if (existsLanguage) {
        existsLanguage.count++;
      } else {
        this.mostUsedLanguages.push({ language: biblio.language, count: 1 });
      }

      const existsYear = this.publishedYears.find(y => y.year === biblio.yearOfPublishing);

      if (existsYear) {
        existsYear.count++;
      } else {
        this.publishedYears.push({ year: biblio.yearOfPublishing, count: 1 });
      }
    });

    this.mostUsedLanguages.sort((a, b) => {
      return b.count - a.count;
    });

    this.publishedYears.sort((a, b) => {
      return b.count - a.count;
    });

    if (this.publishedYears.length >= 2) {
      const yearsSorted = this.publishedYears.sort((a, b) => {
        return a.year - b.year;
      });

      this.minPublishedYear = yearsSorted[0].year;
      this.maxPublishedYear = yearsSorted[yearsSorted.length - 1].year;
    }
  }

  getFaculty(facultyId: number): FacultyModel {
    if (this.faculties && this.faculties.length > 0) {
      return this.faculties.find(f => f.facultyId === facultyId);
    }
  }

}
