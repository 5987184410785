import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from "../../core-frontend-common/template/shared/i18n";
import { isArray } from "lodash";

@Pipe({
  name: 'addRequiredText'
})
export class AddRequiredTextPipe implements PipeTransform {

  constructor(
    protected translate: I18nService
  ) {
  }

  transform(value: any, isRequired = true, validate = false, elValue = null): string {
    const translateRequired = this.translate.getTranslation(isRequired ? 'required' : 'not-required');
    const fillIn = this.translate.getTranslation('vyplniť');
    const filledIn = this.translate.getTranslation('vyplnené');

    if (!isRequired) {
      return `${value} <span class="required">(${translateRequired})</span>`;
    } else {
      if (!validate) {
        return `${value} <span class="required">(${translateRequired})</span>`;
      }
      if (validate && isArray(elValue)) {
        if (!elValue || elValue.length === 0) {
          return `${value} <span class="required">(${translateRequired})</span> <span class="badge bg-color-error txt-color-white"><i class="fal fa-exclamation-triangle"></i> ${fillIn}</span>`;
        } else {
          return `${value} <span class="required">(${translateRequired})</span> <span class="badge bg-color-success txt-color-white"><i class="fal fa-check"></i> ${filledIn}</span>`;
        }
      }

      const isNumber = typeof elValue === 'number';
      if (validate && (elValue || (isNumber && elValue >= 0) )) {
        return `${value} <span class="required">(${translateRequired})</span> <span class="badge bg-color-success txt-color-white"><i class="fal fa-check"></i> ${filledIn}</span>`;
      } else {
        return `${value} <span class="required">(${translateRequired})</span> <span class="badge bg-color-error txt-color-white"><i class="fal fa-exclamation-triangle"></i> ${fillIn}</span>`;
      }
    }
  }

}
