import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./core-frontend-common/template/shared/layout/main/main.component";
import { UserProfileViewComponent } from "./+app-custom/views/user-profile-view/user-profile-view.component";
import { AuthGuard } from "./core-frontend-common/auth/guards/auth.guard";
import { DevComponent } from "./+app-custom/components/dev/dev.component";
import { DashboardComponent } from "./+app-custom/views/dashboard/dashboard.component";
import { CatalogComponent } from "./+app-custom/views/catalog/catalog.component";
import { ExportsViewComponent } from "./+app-custom/views/exports-view/exports-view.component";
import { UserRoles } from "./+app-custom/constants";
import { RoleGuard } from "./core-frontend-common/auth/guards/role.guard";
import { QuotesPageComponent } from "./+app-custom/views/quotes-page/quotes-page.component";
import { DevTestComponent } from "./+app-custom/views/dev-test/dev-test.component";
import { MainBasicComponent } from "./core-frontend-common/template/shared/layout/main-basic/main-basic.component";
import { CustomPreloadingStrategy } from "./custom-preloading-strategy";
import { TicketRegistrationComponent } from "./+app-custom/views/ticket-registration/ticket-registration.component";
import { BiblioCatalogViewComponent } from "./+app-custom/views/biblio-catalog-view/biblio-catalog-view.component";
import { ProjetsManagementViewComponent } from "./+app-custom/views/projets-management-view/projets-management-view.component";
import { ImportProjectPageComponent } from "./+app-custom/views/projets-management-view/import-project-page/import-project-page.component";
import { MyPublicationsComponent } from "./+app-custom/views/my-publications/my-publications.component";
import { TicketCatalogComponent } from "./+app-custom/views/ticket-catalog/ticket-catalog.component";
import { DwToolsViewComponent } from "./+app-custom/views/dw-tools-view/dw-tools-view.component";

const routes: Routes = [
  { path: "", redirectTo: "dashboard", pathMatch: "full", canActivate: [AuthGuard] },
  {
    path: "",
    component: MainComponent,
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dev", component: DevComponent, pathMatch: "full" },
      { path: "dev-test", component: DevTestComponent, pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent, data: { pageTitle: "dashboard" }, pathMatch: "full" },
      {
        path: "catalog/:id",
        component: CatalogComponent,
        data: { pageTitle: "catalog", roles: [UserRoles.Superadmin, UserRoles.EpcWorkflowAdmin, UserRoles.EpcWorkflow] },
        canActivate: [RoleGuard]
      },
      {
        path: "catalog",
        component: CatalogComponent,
        data: { pageTitle: "catalog", roles: [UserRoles.Superadmin] },
        canActivate: [RoleGuard]
      },
      {
        path: "biblio-catalog",
        component: BiblioCatalogViewComponent,
        data: { pageTitle: "biblio-catalog", roles: [UserRoles.Superadmin] },
        canActivate: [RoleGuard]
      },
      {
        path: "projects-management",
        component: ProjetsManagementViewComponent,
        data: { pageTitle: "projectsManagement", roles: UserRoles.All },
        canActivate: [RoleGuard]
      },
      {
        path: "projects-management/import",
        component: ImportProjectPageComponent,
        data: { pageTitle: "importProject", roles: [UserRoles.Superadmin, UserRoles.EpcProjectAdmin] },
        canActivate: [RoleGuard]
      },
      {
        path: "my-publications",
        data: { preload: true, preloadCodebooks: true },
        component: MyPublicationsComponent
      },
      {
        path: "form",
        component: TicketRegistrationComponent,
        data: { pageTitle: "form" }
      },
      {
        path: "form-edit/:id",
        component: TicketRegistrationComponent,
        data: { pageTitle: "form" }
      },
      { path: "profile", component: UserProfileViewComponent, data: { pageTitle: "profile" }, pathMatch: "full" },
      {
        path: "ticket",
        data: {
          pageTitle: "ticket",
          roles: [UserRoles.Superadmin, UserRoles.EpcWorkflowAdmin, UserRoles.EpcWorkflow],
          preload: true, preloadCodebooks: true
        },
        component: TicketCatalogComponent,
        canActivate: [RoleGuard]
      },
      {
        path: "quote-edit/:id",
        component: QuotesPageComponent,
        data: { pageTitle: "quotes" }
      },
      {
        path: "exports",
        component: ExportsViewComponent,
        data: { pageTitle: "exports" },
        pathMatch: "full"
      },
      {
        path: "quotes",
        component: QuotesPageComponent,
        data: { pageTitle: "quotes" },
        pathMatch: "full"
      },
      {
        path: "quotes/:archiveId",
        component: QuotesPageComponent,
        data: { pageTitle: "quotes" },
        pathMatch: "full"
      },
      {
        path: "dwTools",
        data: {
          pageTitle: "dwTools",
          roles: [UserRoles.Superadmin, UserRoles.EpcWorkflowAdmin, UserRoles.EpcWorkflow],
          preload: true, preloadCodebooks: true
        },
        component: DwToolsViewComponent,
        canActivate: [RoleGuard]
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "vystupy",
    component: MainBasicComponent,
    children: [
      {
        path: "",
        component: ExportsViewComponent,
        data: { pageTitle: "exports" },
        pathMatch: "full"
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: CustomPreloadingStrategy })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
