import { FormParticipantModel } from "../models/registration-form/form-participant.model";
import { capitalizeFirstLetterInString } from "../helper-functions";

export const AcademicTitlesBeforeName = [
  "Bc.",
  "Mgr.",
  "Ing.",
  "arch.",
  "MUDr.",
  "MDDr.",
  "PhD.",
  "RNDr.",
  "PharmDr.",
  "PhDr.",
  "JUDr.",
  "PaeDr.",
  "ThDr.",
  "doc.",
  "prof.",
  "MBA",
  "MSc",
  "BSBA",
  "LL.M",
  "CIMA",
  "CFA",
  "ACCA"
];

export const AcademicTitlesAfterName = [
  "CSc.",
  "DrSc."
];

export function parseTitlesToUser(user: FormParticipantModel): FormParticipantModel {
  let fullName = user.fullName;

  let titlesBefore = [];
  let titlesAfter = [];
  let name = "";
  let surname = "";

  const titlesBeforeRegex = new RegExp(/(\w{1,}\.( ){1,})/g);
  const titlesAfterRegex = new RegExp(/(, \w+.)/g);

  titlesBefore = fullName.match(titlesBeforeRegex);
  fullName = fullName.replace(titlesBeforeRegex, "");
  titlesAfter = fullName.match(titlesAfterRegex);
  fullName = fullName.replace(titlesAfterRegex, "");

  fullName = fullName.replace(/,|\./g, "");

  const names = fullName.split(" ");

  if (names.length > 1) {
    name = capitalizeFirstLetterInString(names[0]);
    names.splice(0, 1);
    surname = capitalizeFirstLetterInString(names.join(" "));
  } else {
    name = capitalizeFirstLetterInString(fullName);
  }

  let title = "";
  let titleAfter = "";

  if (titlesBefore && titlesBefore.length > 0) {
    title = titlesBefore.join("");
  }

  if (titlesAfter && titlesAfter.length > 0) {
    titleAfter = titlesAfter.join("").replace(",", "");
  }

  user.name = name;
  user.surname = surname;
  user.title = title;
  user.titleAfter = titleAfter;
  user.fullName = `${title} ${name} ${surname} ${titleAfter}`;

  return user;
}
