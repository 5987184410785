import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { BiblioModel } from "../../../models/biblio/biblio.model";
import { FilterItem, getQueryDesignerFilter, SearchItem } from "../misc/query-designer";
import { BiblioService } from "../../../services/biblio.service";
import { AppCommunicationService } from "../../../services/app-communication.service";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";
import { queryFilterTypes } from "../../../../core-frontend-common/misc/query-designer/query-designer";

@Component({
  selector: "smart-biblio-list",
  templateUrl: "./biblio-list.component.html",
  styleUrls: ["./biblio-list.component.scss"]
})
export class BiblioListComponent implements OnInit, OnChanges {

  filter: any;

  paginator = {
    page: 1,
    totalRecords: 0,
    pageSize: 10,
    show: true
  };

  loading = false;

  biblioData: BiblioModel[] = [];

  @Input() queryDesigner: any = null;

  @Output() biblioList: EventEmitter<BiblioModel[]> = new EventEmitter<BiblioModel[]>();

  constructor(
    protected biblioService: BiblioService,
    protected appCommunicationService: AppCommunicationService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    if (this.biblioData.length === 0 && !this.loading) {
      this.loadData();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["queryDesigner"]) {
      this.loadData();
    }
  }

  async loadData() {
    let filter;
    this.loading = true;

    setTimeout(() => {
      this.appCommunicationService.loadingOn(this.i18n.getTranslation('Načítavam údaje, počkajte prosím.'));
    }, 20);


    if (this.queryDesigner) {
      filter = this.queryDesigner;

      filter.page = 1;
      filter.pageSize = 500;
      this.paginator.show = false;

    } else {
      this.paginator.show = true;

      if (this.filter && this.filter.pageSize) {
        this.paginator.pageSize = this.filter.pageSize.value;
      }

      const filterItems: FilterItem[] = [];

      const search: SearchItem = new SearchItem();

      console.log(this.filter);

      if (this.filter) {
        Object.keys(this.filter)
          .forEach(key => {

            if (key !== "text" && key !== "pageSize" && this.filter[key] && Object.keys(this.filter[key]).length > 0) {

              if ((key === "years" ||
                key === "biblioCategories")) {

                const item = new FilterItem();
                item.queryParamData = this.filter[key];
                if (key === "years") {
                  item.queryParam = "yearOfPublishing";
                  item.queryParamValue = "yearOfPublishing";
                } else if (key === "biblioCategories") {
                  item.queryParam = "categoryCode";
                  item.queryParamValue = "biblioCategory.code";
                  item.queryParamData = this.filter[key];
                  item.itemParameter = 'code';
                } else {
                  item.queryParam = key;
                  item.queryParamValue = key;
                }
                item.filterType = queryFilterTypes.EQUAL;
                filterItems.push(item);
              }

            }
          });

        if (this.filter.text) {
          search.searchValue = this.filter.text;
          search.searchParams = ["title"];
        }
      }

      console.log(search);

      filter = getQueryDesignerFilter(
        filterItems,
        (this.filter && this.filter.text) ? search : null,
        this.paginator.page,
        this.paginator.pageSize,
        null
      );
    }

    this.biblioService
      .getAllByFilter(filter)
      .subscribe(v => {
        this.paginator.totalRecords = v.totalItems;
        this.biblioData = v.items;
        this.biblioList.emit(this.biblioData);
        this.loading = false;
        setTimeout(() => {
          this.appCommunicationService.loadingOff();
        }, 30);
      }, e => {
        console.log(e);
        setTimeout(() => {
          this.appCommunicationService.loadingOff();
          this.loading = false;
        }, 30);
      });
  }

  onPageChange(event) {
    this.paginator.page = (event.first + event.rows) / this.paginator.pageSize;
    if (!this.loading) {
      this.loadData();
    }
  }

  filterChanged(filter) {
    this.filter = filter;
    this.loadData();
  }

  trackByFn(index: number, item: BiblioModel) {
    return item.id;
  }

}
