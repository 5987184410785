import { Directive, ElementRef, HostBinding, Inject, Input, OnChanges, Renderer2, SimpleChanges } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Directive({
  selector: '[formElementValueValid]'
})
export class FormElementValueValidDirective implements OnChanges {

  @Input() validityData: {
    isRequired: boolean,
    value: any,
    classes: string
  } = {
    isRequired: false,
    value: '',
    classes: ''
  };

  @HostBinding('class')
  className;

  constructor(
    @Inject(ElementRef) private element: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    console.log(element)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {

      if (changes.className) {
        console.log(this.className)
      }

      if (changes.validityData) {
        console.log(this.element)
        this.checkValidity();
      }

    }
  }

  checkValidity() {
    if (this.validityData.isRequired) {

      if (this.validityData.value) {
        this.setValid();
      } else {
        this.setInvalid();
      }

    } else {
      this.setValid();
    }
  }

  setInvalid() {
    this.className = this.validityData.classes + ' validation-invalid';

    // this.element.nativeElement.innerHTML += this.createElement();

    // this.element.nativeElement.

    console.log(this.element)

    if (this.renderer) {
      this.renderer.appendChild(this.element.nativeElement, this.createElement());

      console.log(this.element)
    }

    // this.element.nativeElement.innerHTML += '<span class="pull-right"><i class="fal fa-check"></i></span>'
  }

  setValid() {
    this.className = this.validityData.classes + ' validation-valid';

    if (this.renderer) {
      this.renderer.appendChild(this.element.nativeElement, this.createElement());

      console.log(this.element)
    }

    // this.element.nativeElement.innerHTML += this.createElement();

    // this.element.nativeElement.innerHTML += '<span class="pull-right"><i class="fal fa-check"></i></span>'

  }

  createElement(): any {
    // return '<span class="pull-right badge"><i class="fal fa-check"></i></span>';
    const el = this.document.createElement('span');
    el.className = 'badge pull-right';
    const i = this.document.createElement('i');
    i.className = 'fal fa-check';
    el.appendChild(i);
    console.log(el)
    return el;
  }

}
