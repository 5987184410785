import { NgModule } from "@angular/core";
import { CitationComponent } from "./citation.component";
import { ModalModule } from "ngx-bootstrap";
import { I18nModule } from "../../../../../core-frontend-common/template/shared/i18n/i18n.module";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    ModalModule,
    I18nModule,
    CommonModule
  ],
  exports: [
    CitationComponent
  ],
  declarations: [
    CitationComponent
  ]
})

export class CitationModule {}
