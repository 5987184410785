import { IFormElements } from '../../ticket-catalog/misc/form-elements';

export class CitationFormElements {
  isbn: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  issn: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  doi: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  eIssn: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  title: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  yearOfPublishing: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  pageCount: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  hostItemTitle: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  hostItemPageRange: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  hostItemIssue: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  hostItemVolume: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  publisherName: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  publisherAddress: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  meetingName: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  meetingCountry: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  meetingCity: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  meetingStartDatetime: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  meetingEndDatetime: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  wos: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
}
