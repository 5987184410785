import { FormHostItemMetadataModel } from "../registration-form/form-host-item-metadata.model";
import { HostItemModel } from "../biblio/host-item.model";

export class OpenAccessHostItemModel {
  id: number;
  formHostItemId: number;
  formHostItem: FormHostItemMetadataModel;
  hostItemId?: number;
  hostItem: HostItemModel;
  policy: string;
  startYear?: number | Date;
  endYear?: number | Date;
  acpFees?: number;
  otherFees?: number;
  feeSourceInfo: string;
}
