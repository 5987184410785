import { NgModule } from "@angular/core";
import { TicketRowexpansionComponent } from "./ticket-rowexpansion.component";
import { CommonModule } from "@angular/common";
import { RowexpansionBiblioComponent } from "./rowexpansion-biblio/rowexpansion-biblio.component";
import { RowexpansionCitationComponent } from "./rowexpansion-citation/rowexpansion-citation.component";
import { I18nModule } from "../../../../../core-frontend-common/template/shared/i18n/i18n.module";
import { CommonSharedPipesModule } from "../../../../../core-frontend-common/pipes/common-shared-pipes.module";
import { InputTextModule } from "primeng";
import { CustomPipesModule } from "../../../../pipes/customPipes.module";
import { TooltipModule } from "ngx-bootstrap";
import { RowexpansionBaseComponent } from "./rowexpansion-base.component";
import { AuthorTukeEmploymentDialogModule } from "../author-tuke-employment-dialog/author-tuke-employment-dialog.module";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    CommonSharedPipesModule,
    InputTextModule,
    CustomPipesModule,
    TooltipModule,
    AuthorTukeEmploymentDialogModule
  ],
  exports: [
    TicketRowexpansionComponent
  ],
  declarations: [
    TicketRowexpansionComponent,
    RowexpansionBiblioComponent,
    RowexpansionCitationComponent,
    RowexpansionBaseComponent
  ]
})

export class TicketRowexpansionModule {}
