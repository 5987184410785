import { CitationCategoryModel } from "../codebook/citation-category.model";

export class CitationModel {
  id: number;
  orderNumber: number;
  wos: string;
  isbn: string;
  issn: string;
  eissn: string;
  doi: string;
  title: string;
  pageCount?: number;
  yearOfPublishing: number;
  crepcId: number;
  archiveId?: number;
  hostItemTitle: string;
  hostItemLocalization: string;
  responsiblePersonsString: string;
  publisherInformation: string;
  sourceId?: number;
  biblioId?: number;
  category: CitationCategoryModel;
}
