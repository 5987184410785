import { NgModule } from "@angular/core";
import { InlineLoaderModule } from "./components/inline-loader/inline-loader.module";

@NgModule({
  imports: [
    InlineLoaderModule
  ],
  exports: [
  ],
  declarations: []
})

export class CommonModule {}
