import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { UserRoles } from 'src/app/+app-custom/constants';
import { UserByRoleModel } from 'src/app/+app-custom/models/user-by-role.model';
import { UserService } from 'src/app/+app-custom/services/user.service';

@Component({
  selector: 'smart-project-administrators-list-dialog',
  templateUrl: './project-administrators-list-dialog.component.html',
  styleUrls: ['./project-administrators-list-dialog.component.scss']
})
export class ProjectAdministratorsListDialogComponent implements OnInit, OnDestroy {

  administrators: UserByRoleModel[] = []; 
  loadedUsers?: Subscription;
  

  @ViewChild("administratorsListDialog", { static: true }) administratorsListDialog: ModalDirective;

  constructor(
    private userService: UserService
  ) { }
  
  
  ngOnInit() {
  }
  
  ngOnDestroy(): void {
    if(this.loadedUsers) {
      this.loadedUsers.unsubscribe();
    }
  }
  
  show() {
    this.loadUsers();
    this.administratorsListDialog.show();
  }
  
  closeClicked() {
    this.administratorsListDialog.hide();
  }
  
  loadUsers() {
    this.loadedUsers = this.userService.getPersonsByRole(UserRoles.EpcProjectAdmin).subscribe(users => { 
        this.administrators = users;
     })
  }
  
}
