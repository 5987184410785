import { NgModule } from "@angular/core";
import { RoundPipe } from "./round.pipe";
import { RequiredElementPipe } from "./required-element.pipe";
import { AddRequiredTextPipe } from "./add-required-text.pipe";
import { BiblioCategoryPipe } from "./biblio-category.pipe";
import { TicketLifecycleStatusPipe } from "./ticket-lifecycle-status.pipe";
import { TableShowColumnPipe } from "./table-show-column.pipe";
import { CitationCategoryPipe } from "./citation-category.pipe";
import { TrustUrlPipe } from './trust-url.pipe';
import { TrustHtmlPipe } from './trust-html.pipe';
import { AuthorAccordionHeaderPipe } from "./author-accordion-header.pipe";
import { DatePipe } from "@angular/common";

@NgModule({
  imports: [],
  declarations: [
    RoundPipe,
    RequiredElementPipe,
    AddRequiredTextPipe,
    BiblioCategoryPipe,
    CitationCategoryPipe,
    TicketLifecycleStatusPipe,
    TableShowColumnPipe,
    TrustUrlPipe,
    TrustHtmlPipe,
    AuthorAccordionHeaderPipe
  ],
  exports: [
    RoundPipe,
    RequiredElementPipe,
    AddRequiredTextPipe,
    TicketLifecycleStatusPipe,
    TableShowColumnPipe,
    CitationCategoryPipe,
    TrustUrlPipe,
    TrustHtmlPipe,
    BiblioCategoryPipe,
    AuthorAccordionHeaderPipe,
    AuthorAccordionHeaderPipe
  ],
  providers: [
    RoundPipe,
    TrustUrlPipe,
    TrustHtmlPipe,
    AuthorAccordionHeaderPipe,
    DatePipe
  ]
})
export class CustomPipesModule {
}
