import {
  QueryDesigner,
  QueryDesignerOperand, QueryDesignerSubWhere,
  QueryDesignerWhere,
  queryFilterTypes,
  queryOperators,
} from './query-designer';

export function getEmptyQueryDesigner(): QueryDesigner {
  return QueryDesigner.createEmpty();
}

export function createSubQuery(operator: queryOperators, data: any[], field: string, filterType: queryFilterTypes, itemParameter: string | null = null): QueryDesignerSubWhere {
  const operands: any[] = [];
  data.forEach(item => {
    operands.push({
      field,
      filterType,
      value: itemParameter ? item[itemParameter] : item,
    });
  });
  return {
    operatorType: operator,
    operands,
  };
}

export function createSubQueryFromObject(operator: string, data: any, field: string, filterType: queryFilterTypes, withoutValue = false): QueryDesignerOperand {
  if (withoutValue) {
    return {
      field,
      filterType,
    };
  }
  return {
    field,
    filterType,
    value: data,
  };
}

export function createSubQueryFromMultipleObjects(operator: queryOperators, data: { obj: any, filterType: queryFilterTypes }[], field: string, itemParameter = null): QueryDesignerWhere {
  const operands: any[] = [];
  data.forEach(item => {
    operands.push({
      field,
      filterType: item.filterType,
      value: itemParameter ? item.obj[itemParameter] : item.obj,
    });
  });
  return {
    operatorType: operator,
    operands,
  };
}

export function createSubQueryForMoreObjectsAndFields(operator: queryOperators, data: { obj: any, filterType: queryFilterTypes, field: string }[], itemParameter = null): QueryDesignerWhere {
  const operands: any[] = [];
  data.forEach(item => {
    operands.push({
      field: item.field,
      filterType: item.filterType,
      value: itemParameter ? item.obj[itemParameter] : item.obj,
    });
  });
  return {
    operatorType: operator,
    operands,
  };
}
