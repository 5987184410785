import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { SelectItem } from "primeng";

@Component({
  selector: "smart-multi-val-chooser",
  templateUrl: "./multi-val-chooser.component.html",
  styleUrls: ["./multi-val-chooser.component.scss"]
})
export class MultiValChooserComponent implements OnChanges {
  selectedOption: any;

  displayedOptions: SelectItem[] = [];

  @Input() options: string[];

  @Input() currentValue: any;

  @Output() onSelectOption: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["options"]) {
      this.generateOptions();
    }
  }

  generateOptions() {
    if (this.options) {
      this.options.forEach(o => this.displayedOptions.push({ title: o, value: o }));
      console.log(this.displayedOptions)
    }
  }

  changeOption(e) {
    if (e) {
      this.onSelectOption.emit(e.value.title);
    }
  }

}
