import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { CODEBOOK_ITEMS, CodebooksService } from "../../../../services/codebooks.service";
import { FacultyModel } from "../../../../models/faculty.model";
import { BiblioCategoryCodebookModel } from "../../../../models/codebook/biblio-category-codebook.model";
import { TicketTypeEnum } from "../../../../constants";
import { TicketSection, ticketSectionsEnum } from "../../misc/ticket-sections";
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-items-filters",
  templateUrl: "./items-filters.component.html",
  styleUrls: ["./items-filters.component.scss"]
})
export class ItemsFiltersComponent implements OnInit, OnChanges {
  selectedElement: any;

  wasModified = false;

  faculties: FacultyModel[] = [];

  categories: BiblioCategoryCodebookModel[] = [];

  allCategories: BiblioCategoryCodebookModel[] = [];

  years = [];

  ticketTypes = [];

  tabSections = ticketSectionsEnum;

  currentFilter = {
    search: "",
    faculty: null,
    category: [],
    type: {
      value: TicketTypeEnum.biblio,
      label: TicketTypeEnum.biblio
    },
    priority: false,
    priorityWaiting: false,
    yearOfPublishing: null,
    isDeleted: false,
    financedOnly: false,
    unfinishedTicketsOnly: false,
    handOverType: false,
    pageSize: { code: 5 }
  };

  defaultFilter = {
    search: "",
    faculty: null,
    category: [],
    type: null,
    priority: false,
    priorityWaiting: false,
    yearOfPublishing: null,
    isDeleted: false,
    financedOnly: false,
    unfinishedTicketsOnly: false,
    handOverType: false,
    pageSize: { code: 5 }
  };

  pageSizes = [
    { code: 5 },
    { code: 10 },
    { code: 15 },
    { code: 20 }
  ];

  @Output() onFilterChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() currentTab: TicketSection;

  @Input() forceSearchValue = '';

  constructor(
    protected codebookService: CodebooksService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.initCodebooks();

    this.generateTicketTypes();

    this.generateYearsFilter();

    if (this.forceSearchValue) {
      this.currentFilter.search = this.forceSearchValue;
      this.submitFilter();
    }

    this.resetFilter();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['currentTab']) {
      if (this.currentTab.section !== ticketSectionsEnum.allRecordsAdmin) {
        this.currentFilter.isDeleted = false;
      }
    }
  }

  onFocus(event) {
    if (event) {
      this.selectedElement = event.target;
    } else {
      this.selectedElement = null;
    }
  }

  onFocusOut() {
    this.selectedElement = null;
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'enter') {
      if (this.selectedElement) {
        this.submitFilter();
      }
    }
    if (event.key.toLowerCase() === 'escape') {
      if (this.selectedElement) {

        if (this.currentFilter.search === this.defaultFilter.search) {
          this.resetFilter();
        } else {
          this.currentFilter.search = '';
        }
      }
    }
  }

  async initCodebooks() {
    const facultiesRes = await this.codebookService.get(CODEBOOK_ITEMS.faculties);
    this.faculties = facultiesRes.items;

    this.categories = await this.codebookService.get(CODEBOOK_ITEMS.biblioCategories);

    this.allCategories = [...this.categories];
  }

  generateYearsFilter() {
    const startYear = 2010;
    const endYear = new Date().getFullYear();
    const yearsBetweenCount = endYear - startYear;

    for (let i = 0; i <= yearsBetweenCount; i++) {
      this.years.push({
        code: endYear - i,
        value: endYear - i
      });
    }
  }

  generateTicketTypes() {
    this.ticketTypes = [
      {
        value: TicketTypeEnum.biblio,
        label: TicketTypeEnum.biblio
      },
      {
        value: TicketTypeEnum.citation,
        label: TicketTypeEnum.citation
      }
    ];
  }

  filterChanged() {
    if (this.currentFilter.search !== this.defaultFilter.search) {
      this.wasModified = true;
    } else {
      this.wasModified = false;
    }
  }

  facultyFilterChanged() {
    this.submitFilter();
  }

  submitFilter() {
    this.onFilterChangeEvent.emit(this.currentFilter);
    this.savePageSizeToLocalStorage();
    this.filterChanged();
  }

  resetFilter() {
    this.defaultFilter.type = this.ticketTypes[0];
    Object.assign(this.currentFilter, this.defaultFilter);
    this.restorePageSize();
    this.wasModified = false;
    this.submitFilter();
  }

  savePageSizeToLocalStorage() {
    localStorage.setItem('pageSize', JSON.stringify(this.currentFilter.pageSize));
  }

  restorePageSize() {
    const size = localStorage.getItem('pageSize');

    if (size) {
      this.currentFilter.pageSize = JSON.parse(size);
    }
  }

  changedWaitingPriority(value: boolean) {
    this.currentFilter.priority = value;
  }

  changedFinancedOnly(value: boolean) {
    this.currentFilter.financedOnly = value;

    const financedCategories = this.categories.filter(c => c.isFinanced);

    if (value) {
      this.currentFilter.category = financedCategories;
    } else {
      this.currentFilter.category = [];
    }

    this.submitFilter();
  }

  changedHandOverType(value: boolean) {
    this.currentFilter.handOverType = value;

    this.submitFilter();
  }

}
