import { Component, OnInit } from "@angular/core";
import { BaseEpc2Component } from "../base-epc2.component";
import { first } from "rxjs/operators";
import { checkDwToolsErrorResponse } from "../dw-tools-errors";
import { AppCommunicationService } from "../../../services/app-communication.service";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";
import { DwToolsService } from "../../../services/dw-tools.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "smart-crepc-update-section",
  templateUrl: "./crepc-update-section.component.html",
  styleUrls: ["./crepc-update-section.component.scss"]
})
export class CrepcUpdateSectionComponent extends BaseEpc2Component implements OnInit {

  constructor(
    private appCommunication: AppCommunicationService,
    private dwTools: DwToolsService,
    private i18n: I18nService,
    private toastr: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
  }

  async pasteValue() {
    const text = await navigator.clipboard.readText();
    this.ac = Number.parseInt(text, 0);
  }

  getBiblioFromDw(ac: number) {
    this.ac = ac;

    if (!this.ac) {
      return;
    }

    this.appCommunication.loadingOn(this.i18n.getTranslation("Načítavam záznam."));

    this.dwTools
      .getFromDw(this.ac)
      .pipe(first())
      .subscribe({
        next: (value) => {
          this.dwRecord = value;

          this.loadedDwRecord.emit(this.dwRecord);

          this.appCommunication.loadingOff();
        },
        error: (err) => {
          const message = checkDwToolsErrorResponse(err);

          if (message) {
            this.showingErrorMessage(message);
          }

          this.appCommunication.loadingOff();
        }
      });
  }

  callCrepcMetadataUpdate() {
    this.appCommunication.loadingOn();

    this.dwTools
      .biblioCrepcMetadata(this.ac)
      .pipe(first())
      .subscribe({
        next: (value) => {
          this.appCommunication.loadingOff();
          this.toastr.success(this.i18n.getTranslation('Operácia bola úspešná'), this.i18n.getTranslation('Úspech'));
        },
        error: (err) => {
          console.log(err);
          this.toastr.error(this.i18n.getTranslation('Operácia sa nepodarila'), this.i18n.getTranslation('Chyba'));
          this.appCommunication.loadingOff();
        }
      });
  }

}
