import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DwDatabaseModel } from "../../../models/dwtools/dw-database.model";
import { DwDatabaseItem, DwDatabasesData, DwDatabasesEnum } from "../dw-databases";

@Component({
  selector: "smart-database-form",
  templateUrl: "./database-form.component.html",
  styleUrls: ["./database-form.component.scss"]
})
export class DatabaseFormComponent implements OnInit {

  databasesData = DwDatabasesData;

  isEditingDatabase = false;

  database: DwDatabaseModel = new DwDatabaseModel();

  newDatabase: DwDatabaseModel = new DwDatabaseModel();

  selectedDatabase: DwDatabaseItem;

  @Input() set setDatabase(database: DwDatabaseModel) {
    this.isEditingDatabase = false;

    this.database = database;

    if (database && database.name && database.name.length > 0) {
      this.isEditingDatabase = true;
      this.fulfilDatabase();
    }

    this.newDatabase = { ...database };
  }

  @Output() onSubmit: EventEmitter<DwDatabaseModel> = new EventEmitter<DwDatabaseModel>();

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  fulfilDatabase() {
    const item = this.databasesData.find(i => i.name.toLowerCase().trim() === this.database.name.toLowerCase().trim());

    if (item) {
      this.selectedDatabase = item;
    }

  }

  changedDatabase(database: DwDatabaseItem) {
    if (!database.value) return;

    this.newDatabase.code = "";

    if (database.name === DwDatabasesEnum.WOS) {
      this.newDatabase.code = "WOS:";
    }

    if (database.name === DwDatabasesEnum.CCC) {
      this.newDatabase.code = "CCC:";
    }

    this.newDatabase.name = this.selectedDatabase.value;
  }

  clickedClose() {
    this.onClose.emit(true);
  }

  clickedSave() {
    this.onSubmit.emit(this.newDatabase);
  }

}
