import { Pipe, PipeTransform } from "@angular/core";
import { TicketStatusesObj } from "../constants";

@Pipe({
  name: "ticketLifecycleStatus"
})
export class TicketLifecycleStatusPipe implements PipeTransform {

  constructor() {

  }

  transform(statusId: number): string {
    return TicketStatusesObj[statusId];
  }

}
