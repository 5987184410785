export class FormPatentModel {
  id?: number;
  registrationCode: string;
  patentCode?: string;
  dateOfRegistration?: Date | string;
  dateOfGrant?: Date | string;
  patentTypeId?: number;
  publisher?: string;

  static createEmptyFormPatentModel(): FormPatentModel {
    const f = new FormPatentModel();
    f.registrationCode = '';
    return f;
  }
}
