import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TicketFullModel } from "../../../../models/ticket/ticket-full.model";
import { FileService } from "../../../../services/file.service";
import { FileMetadataModel } from "../../../../models/biblio/file-metadata.model";
import { FormParticipantModel } from "../../../../models/registration-form/form-participant.model";
import { ConfirmationService } from "primeng";
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";
import { ToastrService } from "ngx-toastr";
import { getDownloadFileUrl } from "../../../../app.urls";

@Component({
  selector: "smart-files",
  templateUrl: "./files.component.html",
  styleUrls: ["./files.component.scss"]
})
export class FilesComponent implements OnInit {
  files: FileMetadataModel[] = [];

  @Input() editedTicket: TicketFullModel;

  @Output() fileWasDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() readonly = false;

  constructor(
    protected fileService: FileService,
    protected confirmationService: ConfirmationService,
    protected translationService: I18nService,
    protected toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.loadUploadedFile();
  }

  loadUploadedFile() {
    if (this.editedTicket && this.editedTicket.fileMetadataId) {
      this.fileService.getInfo(this.editedTicket.fileMetadataId).subscribe(v => {
        this.files.push(v);
      });
    }
  }

  downloadFile(file: FileMetadataModel) {
    this.fileService
      .download(getDownloadFileUrl(this.editedTicket.fileMetadataId))
      .subscribe(() => {
        this.toastr.success(this.translationService.getTranslation("Súbor úspešne stiahnutý."));
      }, err => {
        this.toastr.error(this.translationService.getTranslation('Súbor sa stiahnuť nepodarilo.'));
      });
  }

  deleteFile(file: FileMetadataModel, e: Event) {
    e.stopPropagation();
    this.confirmationService.confirm({
      message: this.translationService.getTranslation("Skutočne si prajete zmazať nahraný súbor?"),
      header: this.translationService.getTranslation("Zmazanie súboru"),
      acceptLabel: this.translationService.getTranslation("Áno"),
      rejectLabel: this.translationService.getTranslation("Nie"),
      accept: () => {
        this.fileService
          .deleteFile(file.id)
          .subscribe(resultStatus => {
            if (resultStatus) {
              this.toastr.success(this.translationService.getTranslation("Súbor úspešne zmazaný."));
              this.files = [];
              this.fileWasDeleted.emit(true);
            } else {
              this.toastr.error(this.translationService.getTranslation("Súbor sa zmazať nepodarilo"));
            }
          }, error => {
            console.log(error);
            this.toastr.error(this.translationService.getTranslation("Súbor sa zmazať nepodarilo"));
          });
      }
    });
  }

  getImageByFile(fileName: string) {
    if (!fileName) {
      console.log("No filename!");
      return;
    }

    const baseUrl = "/assets/img/custom/file-extensions";

    const extension = fileName.match(/\.[0-9a-z]+$/i);

    if (extension && extension.length > 0) {

      const file = extension[0];

      if (file.includes("doc")) {
        return `${baseUrl}/word.png`;
      }

      if (file.includes("zip")) {
        return `${baseUrl}/zip.png`;
      }

      if (file.includes("rar")) {
        return `${baseUrl}/rar.png`;
      }

      if (file.includes("pdf")) {
        return `${baseUrl}/pdf.png`;
      }
    }

    return `${baseUrl}/unknown.png`;
  }

}
