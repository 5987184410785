import { NgModule } from "@angular/core";
import { QuotesPageComponent } from "./quotes-page.component";
import {
  AccordionModule,
  AutoCompleteModule,
  ButtonModule,
  CalendarModule,
  DropdownModule,
  InputTextModule,
  SelectButtonModule
} from "primeng";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";
import { CustomPipesModule } from "../../pipes/customPipes.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { TooltipModule } from "ngx-bootstrap";
import { BiblioPersonSearchMk2Module } from "../../components/biblio-person-search-mk2/biblio-person-search-mk2.module";
import { AttachmentsModule } from "../ticket-registration/components/attachments/attachments.module";
import { CitedPublicationSectionComponent } from "./sections/cited-publication-section/cited-publication-section.component";
import { QuoteSectionBaseComponent } from "./sections/quote-section-base.component";
import { CitationCategorySectionComponent } from "./sections/citation-category-section/citation-category-section.component";
import { QuotingDocumentSectionComponent } from "./sections/quoting-document-section/quoting-document-section.component";
import { AuthorsSectionComponent } from "./sections/authors-section/authors-section.component";
import { AttachmentsSectionComponent } from "./sections/attachments-section/attachments-section.component";
import { CustomDatepickerModule } from "../../components/custom-datepicker/custom-datepicker.module";
import { UserPublicationDataDialogModule } from "../../components/user-publication-data-dialog/user-publication-data-dialog.module";
import { DocumentDetailDialogModule } from "./components/document-detail-dialog/document-detail-dialog.module";
import { BiblioDetailModule } from "../../components/biblio-detail/biblio-detail.module";

@NgModule({
  imports: [
    AccordionModule,
    I18nModule,
    CustomPipesModule,
    DropdownModule,
    CommonModule,
    InputTextModule,
    ButtonModule,
    FormsModule,
    SelectButtonModule,
    DragDropModule,
    TooltipModule,
    BiblioPersonSearchMk2Module,
    AutoCompleteModule,
    ReactiveFormsModule,
    CalendarModule,
    AttachmentsModule,
    CustomDatepickerModule,
    UserPublicationDataDialogModule,
    DocumentDetailDialogModule,
    BiblioDetailModule
  ],
  exports: [
    QuotesPageComponent
  ],
  declarations: [
    QuoteSectionBaseComponent,
    QuotesPageComponent,
    CitedPublicationSectionComponent,
    CitationCategorySectionComponent,
    QuotingDocumentSectionComponent,
    AuthorsSectionComponent,
    AttachmentsSectionComponent,
  ]
})

export class QuotesPageModule {}
