import { NgModule } from "@angular/core";
import { BiblioDetailComponent } from "./biblio-detail.component";
import { ModalModule, TooltipModule } from "ngx-bootstrap";
import { CommonModule } from "@angular/common";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";
import { CustomPipesModule } from "../../pipes/customPipes.module";
import { CitationModule } from "../../views/catalog/components/citation/citation.module";
import { CommonSharedPipesModule } from "../../../core-frontend-common/pipes/common-shared-pipes.module";

@NgModule({
  imports: [
    ModalModule,
    CommonModule,
    TooltipModule,
    I18nModule,
    CustomPipesModule,
    CitationModule,
    CommonSharedPipesModule
  ],
  exports: [
    BiblioDetailComponent
  ],
  declarations: [
    BiblioDetailComponent
  ]
})

export class BiblioDetailModule {}
