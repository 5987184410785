export function checkDwToolsErrorResponse(err): string {
  if (err && err.error && err.error.errors && err.error.errors.length > 0) {
    return generateErrorMessage(err.error.errors[0]);
  }
}

export function generateErrorMessage(status: 4001 | 8000): string {
  switch (status) {
    case 4001:
      return "AC_NOT_FOUND";
    case 8000:
      return "DW_TO_BIBLIO_MAP_GENERIC_ERROR";
  }

  return null;
}
