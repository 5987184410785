import {PreloadingStrategy, Route} from '@angular/router';
import { Observable, of } from "rxjs";
import { CODEBOOK_ITEMS, CodebooksService } from "./+app-custom/services/codebooks.service";

export class CustomPreloadingStrategy implements PreloadingStrategy {

  constructor(
    private codebooksService: CodebooksService
  ) {
  }

  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      if (route.data.preloadCodebooks) {
        this.preloadCodebooks();
      }
    }
    return of(null);
  }

  async preloadCodebooks() {
    await this.codebooksService.get(CODEBOOK_ITEMS.citationCategories);
    await this.codebooksService.get(CODEBOOK_ITEMS.biblioCategories);
  }
}
