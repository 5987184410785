// Sem patria globalne konstanty

import { CitationDatabaseType } from "./types/citation-database.type";
import { I18nService } from "../core-frontend-common/template/shared/i18n";

export const SYSTEM_NAME_EMPTY = "";
export const SYSTEM_NAME_AUTH = "AUTH";
export const SYSTEM_NAME_EPC = "EPC";
export const SYSTEM_NAME_ETD = "ETD";
export const SYSTEM_NAME_ISBN = "ISBN";
export const SYSTEM_NAME_ROOMBOOK = "ROOMBOOK";
export const SYSTEM_NAME_ZP = "ZP";
export const SYSTEM_NAME_UIK = "UIK";
export const SYSTEM_NAME_HELPDESK = "HELPDESK";
export const SYSTEM_NAME_TOOLS = "TOOLS";
export const SYSTEM_NAME_LIBADMIN = "LIBADMIN";
export const SYSTEM_NAME_WAREHOUSE = "WAREHOUSE";
export const SYSTEM_NAME_ALFRED = "ALFRED";
export const SYSTEM_NAME_HELIOS = "HELIOS";

export const SYSTEM_FULL_NAME_ETD = "thesisEvidence";
export const SYSTEM_FULL_NAME_ROOMBOOK = "roombook";
export const SYSTEM_FULL_NAME_EPC = "publicationEvidence";
export const SYSTEM_FULL_NAME_LIBADMIN = "libadmin";

/*
 * USER ROLES
 *
 *
 *
 */
const USER_ROLES = {
  SUPERADMIN: "SUPERADMIN",
  EPC_WORKFLOW: "EPC_WORKFLOW",
  EPC_WORKFLOW_ADMIN: "EPC_WORKFLOW_ADMIN",
  EPC_USER: "EPC_USER",
  EPC_PROJECT_ADMIN: "EPC_PROJECT_ADMIN"
} as const;

export const UserRoles = {
  All: [
    USER_ROLES.SUPERADMIN,
    USER_ROLES.EPC_WORKFLOW,
    USER_ROLES.EPC_WORKFLOW_ADMIN,
    USER_ROLES.EPC_USER,
    USER_ROLES.EPC_PROJECT_ADMIN
  ],
  Superadmin: USER_ROLES.SUPERADMIN,
  EpcWorkflowAdmin: USER_ROLES.EPC_WORKFLOW_ADMIN,
  EpcUser: USER_ROLES.EPC_USER,
  EpcWorkflow: USER_ROLES.EPC_WORKFLOW,
  EpcProjectAdmin: USER_ROLES.EPC_PROJECT_ADMIN
} as const;

export const skCalendarLocale = {
  firstDayOfWeek: 1,
  dayNames: ["Nedeľa", "Pondelok", "Utorok", "Streda", "Štvrtok", "Piatok", "Sobota"],
  dayNamesShort: ["Ne", "Po", "Ut", "St", "Št", "Pi", "So"],
  dayNamesMin: ["Ne", "Po", "Ut", "St", "Št", "Pi", "So"],
  monthNames: [
    "Január",
    "Február",
    "Marec",
    "Apríl",
    "Máj",
    "Jún",
    "Júl",
    "August",
    "September",
    "Október",
    "November",
    "December"
  ],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Máj", "Jún", "Júl", "Aug", "Sep", "Okt", "Nov", "Dec"],
  today: "dnes",
  clear: "Zmazať"
};

export const enCalendarLocale = {
  firstDayOfWeek: 1,
  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  dayNamesShort: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
  today: "today",
  clear: "Clear"
};

export function getCalendarLocale() {
  const lang = localStorage.getItem('language');

  if (lang === 'en' || lang === 'us') {
    return enCalendarLocale;
  } else {
    return skCalendarLocale;
  }
}

export const REGISTRATION_FORM_GROUP = {
  articleInConference: "articleInConference",
  articleNotInConference: "articleNotInConference",
  articleInJournal: "articleInJournal",
  monograph: "monograph"
};

export const REGISTRATION_FORM_GROUP_2022 = {
  partOfBook: "partOfBook",
  partOfJournal: "partOfJournal",
  monounit: "monounit",
  patent: "patent"
};

export const REGISTRATION_FORM_GROUPS = [
  {
    title: "articleInConference",
    description: ""
  },
  {
    title: "articleNotInConference",
    description: ""
  },
  {
    title: "articleInJournal",
    description: ""
  },
  {
    title: "monograph",
    description: ""
  }
];

export const REGISTRATION_FORM_GROUPS_2022 = [
  {
    title: "monounit",
    description: ""
  },
  {
    title: "partOfBook",
    description: ""
  },
  {
    title: "partOfJournal",
    description: ""
  },
  {
    title: "patent",
    description: ""
  }
];

export enum FormAttachmentTypes {
  "without_attachment" = 1,
  "file" = 2,
  "url_link" = 3,
  "origin_print" = 10,
  "origin_print_with_medium" = 11,
  "copy_print" = 20,
  "copy_print_with_medium" = 21,
  "medium" = 30,
  "no_return_attachment" = 40,
}

export const FormAttachmentTypesArray = [
  {
    name: "file",
    value: FormAttachmentTypes.file,
    description: "Nahratie prílohy"
  },
  {
    name: "url_link",
    value: FormAttachmentTypes.url_link,
    description: "Internetový odkaz"
  },
  {
    name: "origin_print",
    value: FormAttachmentTypes.origin_print,
    description: "Originálny výtlačok"
  },
  {
    name: "no_return_attachment",
    value: FormAttachmentTypes.no_return_attachment,
    description: "Podklady bez vrátenia (povinné výtlačky, cestovný príkaz)"
  },
  {
    name: "without_attachment",
    value: FormAttachmentTypes.without_attachment,
    description: "Bez prílohy"
  },
  {
    name: "medium",
    value: FormAttachmentTypes.medium,
    description: "Médium (CD, DVD, USB, ...)"
  }
];

export enum PriorityTypes {
  "no_priority" = 0,
  "project" = 1,
  "employee_evaluation" = 2,
  "publish_activity_completion" = 3,
  "qualification_progress" = 4,
  "other_with_comment" = 5
}

export const PriorityTypesArray = [
  {
    name: "no_priority",
    value: PriorityTypes.no_priority,
    description: "Bez priority"
  },
  {
    name: "project",
    value: PriorityTypes.project,
    description: "Termíny v projektoch (KEGA, VEGA,...)"
  },
  // {
  //   name: "employee_evaluation",
  //   value: PriorityTypes.employee_evaluation,
  //   description: "Hodnotenie zamestnancov"
  // },
  // {
  //   name: "publish_activity_completion",
  //   value: PriorityTypes.publish_activity_completion,
  //   description: "Kompletizácia publikacnej cinnosti"
  // },
  {
    name: "qualification_progress",
    value: PriorityTypes.qualification_progress,
    description: "Kvalifikacný postup (Dizertácia, Habilitácia,...)"
  },
  {
    name: "other_with_comment",
    value: PriorityTypes.other_with_comment,
    description: "Iné (uveďte v komentári)"
  }

];

export const TicketStatusesObj = {
  100: "created",
  110: "updated_by_pedagogue",
  120: "to_rework",
  200: "sent_to_acceptance",
  210: "in_acceptance",
  300: "to_scan",
  310: "in_scan",
  400: "to_conversion",
  410: "in_conversion",
  500: "scanned",
  505: "converted",
  510: "in_validation",
  600: "to_catalogize",
  610: "in_catalogization",
  620: "sent_to_crepc",
  700: "imported_to_dawinci",
  710: "in_dawinci_check",
  800: "to_hand_over",
  810: "in_hand_over_to_author",
  900: "to_contact",
  910: "in_contact",
  1000: "finished"
};

export enum TicketStatuses {
  "created" = 100,
  "updated_by_pedagogue" = 110,
  "to_rework" = 120,
  "sent_to_acceptance" = 200,
  "in_acceptance" = 210,
  "to_scan" = 300,
  "in_scan" = 310,
  "to_conversion" = 400,
  "in_conversion" = 410,
  "scanned" = 500,
  "converted" = 505,
  "in_validation" = 510,
  "to_catalogize" = 600,
  "in_catalogization" = 610,
  "sent_to_crepc" = 620,
  "imported_to_dawinci" = 700,
  "in_dawinci_check" = 710,
  "to_hand_over" = 800,
  "in_hand_over_to_author" = 810,
  "to_contact" = 900,
  "in_contact" = 910,
  "finished" = 1000
}

export const COLORS = {
  blueLight: "#92a2a8",
  blue: "#57889c",
  teal: "#568a89",
  blueDark: "#4c4f53",
  green: "#356e35",
  greenDark: "#496949",
  greenLight: "#71843f",
  purple: "#6e587a",
  magenta: "#6e3671",
  pink: "#ac5287",
  pinkDark: "#a8829f",
  yellow: "#b09b5b",
  orange: "#c79121",
  red: "#a90329",
  redLight: "#a65858"
};

export enum handOverTypes {
  nothing = 0,
  sending_by_post = 1,
  picking_up_in_uk = 2
}

export enum OLD_EPC_SEARCH_OPTIONS_ENUM {
  Nazov = "Názov",
  Autor = "Autor",
  Pracovisko = "Pracovisko",
  Zdroj = "Náz. zdroja"
}

export enum OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES {
  Nazov = "Nazov",
  Autor = "Autor",
  Pracovisko = "Pracovisko",
  Zdroj = "Vazba"
}

export const OLD_EPC_SEARCH_OPTIONS = [
  {
    title: OLD_EPC_SEARCH_OPTIONS_ENUM.Autor,
    value: OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Autor
  },
  {
    title: OLD_EPC_SEARCH_OPTIONS_ENUM.Nazov,
    value: OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Nazov
  },
  {
    title: OLD_EPC_SEARCH_OPTIONS_ENUM.Zdroj,
    value: OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Zdroj
  },
  {
    title: OLD_EPC_SEARCH_OPTIONS_ENUM.Pracovisko,
    value: OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Pracovisko
  }
];

export const WHOLE_FACULTY_OLD_EPC = "Celá fakulta";

export enum OLD_EPC_CATEGORIES {
  ALL = 0,
  BIBLIO = 1,
  GRANT = 2
}

export enum OLD_EPC_GRANT_CATEGORIES_ENUM {
  ALL = "VsetkyDotacne",
  A1 = "A1",
  A2 = "A2",
  B = "B",
  C = "C",
  N = "N"
}

export const OLD_EPC_GRANT_CATEGORIES = [
  {
    title: "Všetky dotačné kategórie",
    value: OLD_EPC_GRANT_CATEGORIES_ENUM.ALL
  },
  {
    title: "Skupina A1 (AAA,AAB,ABA,ABB,ABC,ABD)",
    value: OLD_EPC_GRANT_CATEGORIES_ENUM.A1
  },
  {
    title: "Skupina A2 (ACA,ACB,BAA,BAB,BCB,BCI,EAI,CAA,CAB,EAJ,FAI)",
    value: OLD_EPC_GRANT_CATEGORIES_ENUM.A2
  },
  {
    title: "Skupina B (ADC,ADD,AEG,AEH,BDC,BDD,CDC,CDD,AGJ)",
    value: OLD_EPC_GRANT_CATEGORIES_ENUM.B
  },
  {
    title: 'Skupina C (ACC,ACD,ADE,ADF,AEC,AED,AFA,AFB,AFC,AFD,AFE,AFF,AFG,AFH,BBA,BBB,BCK,BDA,BDB,BDE,BDF,BEC,BED,BFA,BFB,BGH,CDE,CDF)',
    value: OLD_EPC_GRANT_CATEGORIES_ENUM.C
  },
  {
    title: 'Skupina N (ADM, ADN, AEM, AEN, BDM, BDN, CBA, CBB)',
    value: OLD_EPC_GRANT_CATEGORIES_ENUM.N
  },
];

export enum OLD_EPC_BIBLIO_EXPORT_ENUM {
  ISBD_GENERIC = 'ISBD',
  PERSONAL_BIBLIO = 'PB',
  GRANT_AGENCIES = 'GA',
  STATISTIC_ONLY = 'STATISTIKA'
}

export const OLD_EPC_BIBLIO_EXPORT = [
  {
    title: 'Iba štatistika',
    value: OLD_EPC_BIBLIO_EXPORT_ENUM.STATISTIC_ONLY
  },
  {
    title: 'Personálna bibliografia',
    value: OLD_EPC_BIBLIO_EXPORT_ENUM.PERSONAL_BIBLIO
  },
  {
    title: 'Grantové agentúry (KEGA, VEGA a iné..)',
    value: OLD_EPC_BIBLIO_EXPORT_ENUM.GRANT_AGENCIES
  },
  {
    title: 'ISBD - všeobecný',
    value: OLD_EPC_BIBLIO_EXPORT_ENUM.ISBD_GENERIC
  },
];

export const MAX_FILESIZE_ATTACHMENT_UPLOAD_TICKET_REGISTRATION = 200 * 1024 * 1024 * 8;

export const CitationDatabases: CitationDatabaseType[] = [
  CitationDatabaseType.wos,
  CitationDatabaseType.scopus,
  CitationDatabaseType.other
];

export enum TicketTypeEnum {
  biblio = 'biblio',
  citation = 'citation'
}

export const EPC_HELP_EMAIL = 'is@helpdesk.tuke.sk';

export const YOUTUBE_URLS = {
  publicationTutorialUrl: 'https://www.youtube.com/embed/5egkfde59eQ',
  citationTutorialUrl: 'https://www.youtube.com/embed/Ib4RNOATo3w',
  categoryChangesUrl: 'https://www.youtube.com/embed/GxlFGI8VGzI'
};

export enum ErrorCodes {
  AnotherUsersOrcIdOrPublons = 1005
}
