import { SYSTEM_NAME_ALFRED, SYSTEM_NAME_AUTH, UserRoles } from "./+app-custom/constants";
import { APP_URL_HELIOS } from "./core-frontend-common/auth/auth.urls";
import { getBaseFrontendUrl } from "./+app-custom/app.urls";

export const NavigationItems = [
  {
    title: "modules",
    tags: "modules",
    icon: "fal fa-angle-down",
    routerLink: "null",
    externalUrl: `${getBaseFrontendUrl(SYSTEM_NAME_AUTH)}`,
    roles: UserRoles.All
  },
  {
    title: "Hlavná stránka",
    tags: "application intel",
    icon: "fal fa-list-alt",
    routerLink: "/dashboard",
    roles: UserRoles.All
  },
  {
    title: "Moje publikácie",
    tags: "application intel",
    icon: "fal fa-book",
    routerLink: "",
    items: [
      {
        title: "Registrácia publikácie",
        tags: "application intel",
        icon: "fal fa-file",
        routerLink: "/form",
        roles: UserRoles.All
      },
      {
        title: "Registrácia citácie",
        tags: "application intel",
        icon: "fal fa-quote-right",
        routerLink: "/quotes",
        roles: UserRoles.All
      },
      {
        title: "Žiadosť o korekciu citácie (Modul Alfred)",
        tags: "application intel",
        icon: "fal fa-quote-right",
        routerLink: "null",
        externalUrl: `${getBaseFrontendUrl(SYSTEM_NAME_ALFRED)}/#/forms/epcBiblioCorrection`,
        roles: UserRoles.All
      },
      {
        title: "Zoznam žiadostí",
        tags: "application intel",
        icon: "fa-light fa-books",
        routerLink: "/my-publications",
        roles: UserRoles.All
      }
    ],
    roles: UserRoles.All
  },
  {
    title: "Katalóg tiketov",
    tags: "application intel",
    icon: "fal fa-list",
    routerLink: "/ticket",
    roles: [UserRoles.Superadmin, UserRoles.EpcWorkflowAdmin, UserRoles.EpcWorkflow]
  },
  {
    title: "DW Nástroje",
    tags: "application intel",
    icon: "fal fa-list",
    routerLink: "/dwTools",
    roles: [UserRoles.Superadmin, UserRoles.EpcWorkflowAdmin, UserRoles.EpcWorkflow]
  },
  {
    title: "Výstupy",
    tags: "application intel",
    icon: "fa-light fa-book-arrow-right",
    routerLink: "/exports",
    roles: UserRoles.All
  },
  {
    title: "Výstupy - nové",
    badge: {
      class: "badge badge-danger",
      label: "BETA"
    },
    tags: "application intel",
    icon: "fa-light fa-book-arrow-right",
    routerLink: "null",
    externalUrl: APP_URL_HELIOS,
    roles: UserRoles.All
  },
  {
    title: "Katalog 1",
    tags: "application intel",
    icon: "fal fa-file-excel",
    routerLink: "/catalog",
    roles: [UserRoles.Superadmin],
    onlyForLogin: "mh602bd"
  },
  {
    title: "Novy katalog",
    tags: "application intel",
    icon: "fal fa-file-excel",
    routerLink: "/biblio-catalog",
    roles: [UserRoles.Superadmin],
    onlyForLogin: "mh602bd"
  },
  {
    title: "Evidencia projektov",
    tags: "application intel",
    icon: "fa-light fa-file-chart-pie",
    routerLink: "/projects-management",
    roles: UserRoles.All
  },
  {
    title: "FAQ - často kladené otázky",
    tags: "application intel",
    icon: "fal fa-question",
    externalUrl: "https://www.lib.tuke.sk/#/faq?system=epc",
    routerLink: "null",
    roles: UserRoles.All
  }
];
