import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormMeetingModel } from '../../../../models/registration-form/form-meeting.model';
import { FormElements } from "../../../ticket-catalog/misc/form-elements";
import { getCalendarLocale } from "../../../../constants";
import { Calendar } from "primeng";

@Component({
  selector: 'smart-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss'],
})
export class MeetingComponent implements OnInit {

  isValid = false;

  skLocaleCalendar = getCalendarLocale();

  @Input() meeting: FormMeetingModel = new FormMeetingModel();

  @Input() formElements: FormElements;

  @ViewChild(Calendar, {static: false}) calendarEl: Calendar;

  @Output() changeMeeting: EventEmitter<{ valid: boolean, data: FormMeetingModel }> = new EventEmitter<{ valid: boolean, data: FormMeetingModel }>();

  constructor() {
  }

  ngOnInit() {
    this.change();
  }

  change() {
    if (Object.keys(this.meeting).length === 0) {
      this.isValid = true;
      this.changeMeeting.emit({ valid: this.isValid, data: this.meeting });
      return;
    }

    if (this.formElements['meeting.Name'].required &&
      (!this.meeting.name || this.meeting.name.length === 0)) {
      this.isValid = false;
      this.changeMeeting.emit({ valid: this.isValid, data: this.meeting });
      return;
    }

    if (this.formElements['meeting.Location'].required &&
      (!this.meeting.location || this.meeting.location.length === 0)) {
      this.isValid = false;
      this.changeMeeting.emit({ valid: this.isValid, data: this.meeting });
      return;
    }

    if (this.formElements['meeting.MeetingStartDate'].required) {
      this.isValid = this.isValidMeeting();
      if (!this.isValid) {
        this.changeMeeting.emit({ valid: this.isValid, data: this.meeting });
        return;
      }
    }

    if (this.formElements['meeting.PartNumber'].required &&
      !this.meeting.partNumber) {
      this.isValid = false;
      this.changeMeeting.emit({ valid: this.isValid, data: this.meeting });
      return;
    }

    this.isValid = true;
    this.changeMeeting.emit({ valid: this.isValid, data: this.meeting });
  }

  getMeeting(): FormMeetingModel {
    return this.meeting;
  }

  clickedDatepickerOutside() {
    const isDateArray = Array.isArray(this.meeting.meetingStartDate);

    if (isDateArray) {
      this.change();
    } else {
      this.meeting.meetingStartDate = null;
      this.meeting.meetingEndDate = null;
    }
  }

  isValidMeeting(): boolean {
    let validMeeting = false;

    const isDateArray = Array.isArray(this.meeting.meetingStartDate);

    if (!isDateArray) {
      validMeeting = false;
    } else {
      // @ts-ignore
      const dateArray = this.meeting.meetingStartDate as Date[];

      if (dateArray.length !== 2) {
        validMeeting = false;
      } else {
        const startDate = dateArray[0];
        const endDate = dateArray[1];

        if (startDate && endDate && startDate instanceof Date && endDate instanceof Date) {
          validMeeting = true;
        } else {
          validMeeting = false;
        }
      }
    }

    return validMeeting;
  }

}
