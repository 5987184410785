import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BaseDropdownModel } from "../../../../../models/base-dropdown.model";
import { I18nService } from "../../../../../../core-frontend-common/template/shared/i18n";
import { OpenAccessPayerModel } from "../../../../../models/open-access/open-access-payer.model";
import { FormProjectModel } from "../../../../../models/registration-form/form-project.model";
import { searchByProjectTypes } from "../../project-section/project-section.component";
import { ProjectService } from "../../../../../services/project.service";
import { feeSources } from "../../../open-access-fee-sources.data";
import { Dropdown } from "primeng";

@Component({
  selector: 'smart-open-access-payer',
  templateUrl: './open-access-payer.component.html',
  styleUrls: ['./open-access-payer.component.scss']
})
export class OpenAccessPayerComponent implements OnInit, AfterViewInit {

  feeSources: BaseDropdownModel[] = feeSources;

  isEditing = false;

  @Input() payer: OpenAccessPayerModel;

  isValid = true;

  project: FormProjectModel = new FormProjectModel();
  searchedProjects: FormProjectModel[] = [];

  // @Input() set setPayer(payer: OpenAccessPayerModel) {
  //   this.payer = payer;
  //
  //   if (this.payer && this.payer.project) {
  //     this.project = this.payer.project;
  //   }
  //
  //   // if (this.payer && this.payer.feeSourceInfo) {
  //   //   const source = this.feeSources.find(f => f.item_id === this.payer.feeSourceInfo);
  //   //
  //   //   if (source) {
  //   //     this.payer.feeSourceInfo = source;
  //   //   }
  //   // }
  // }

  @ViewChild("feeSourcesEl", { static: false }) feeSourcesEl: Dropdown;

  @Output() deletePayer: EventEmitter<OpenAccessPayerModel> = new EventEmitter<OpenAccessPayerModel>();

  @Output() changedPayer: EventEmitter<OpenAccessPayerModel> = new EventEmitter<OpenAccessPayerModel>();

  constructor(
    private translationService: I18nService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    console.log('payer: ', this.payer)

    if (this.payer && this.payer.project) {
      this.project = this.payer.project;
      this.payer.isValid = true;

      setTimeout(() => {
        this.checkValidity();
      }, 50);
    }
  }

  ngAfterViewInit() {
    if (this.payer && this.payer.feeSourceInfo) {

      this.feeSourcesEl.selectedOption = { label: this.payer.feeSourceInfo, value: {item_text: this.payer.feeSourceInfo, item_id: this.payer.feeSourceInfo} };

    }
  }

  feeSurceChange(e) {
    this.payer.feeSourceInfo = e.value.item_text;
    this.checkValidity();
  }

  onDelete() {
    this.deletePayer.emit(this.payer);
  }

  selectProject(project: FormProjectModel) {
    this.project = project;
    this.payer.projectId = project.id;
    this.checkValidity();
  }

  searchProject(event, type: searchByProjectTypes = searchByProjectTypes.searchByProjectName) {
    let searchType;
    if (type === searchByProjectTypes.searchByGrantNumber) {
      searchType = {
        grantNumber: event.query
      };
    } else {
      searchType = {
        name: event.query
      };
    }

    this.projectService
      .searchProject(searchType)
      .subscribe(val => {
        this.searchedProjects = val.items;
      });
  }

  checkValidity() {
    if ((this.payer.name && this.payer.name.trim().length) > 0 &&
      this.payer.acpFees >= 0 &&
      this.payer.otherFees >= 0 &&
      (this.payer.feeSourceInfo && this.payer.feeSourceInfo.trim().length > 0)) {
      this.isValid = true;
      this.payer.isValid = true;
    } else {
      this.isValid = false;
      this.payer.isValid = false;
    }

    this.payer.projectId = this.project.id;
    this.payer.project = this.project;

    this.changedPayer.emit(this.payer);
  }

}
