import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '../core-frontend-common/template/shared/i18n/i18n.module';
import { UserProfileViewComponent } from './views/user-profile-view/user-profile-view.component';
import { AppCommunicationService } from './services/app-communication.service';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { CustomPipesModule } from './pipes/customPipes.module';
import { TileComponent } from './components/tile/tile.component';
import { UserSearchComponent } from './components/user-search/user-search.component';
import { ExternalUserModalFormComponent } from './components/external-user-modal-form/external-user-modal-form.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ModalModule, PopoverModule, TooltipModule } from "ngx-bootstrap";
import { CalendarModule } from 'primeng/calendar';
import { DevComponent } from './components/dev/dev.component';
import { UserPipe } from './pipes/user.pipe';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { TableModule } from 'primeng/table';
import { SharedModule } from '../core-frontend-common/template/shared/shared.module';
import { SmartClockpickerDirective } from '../core-frontend-common/template/shared/utils/smart-clockpicker.directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CatalogComponent } from './views/catalog/catalog.component';
import { NavTabsComponent } from './views/catalog/components/nav-tabs/nav-tabs.component';
import { FilterComponent } from './views/catalog/components/filter/filter.component';
import { BiblioTableComponent } from './views/catalog/components/biblio-table/biblio-table.component';
import { PersonTableComponent } from './views/catalog/components/person-table/person-table.component';
import { MeetingTableComponent } from './views/catalog/components/meeting-table/meeting-table.component';
import { PersonDetailComponent } from './views/catalog/components/person-detail/person-detail.component';
import { BiblioItemComponent } from './views/catalog/components/person-table/components/biblio-item/biblio-item.component';
import { BreadcrumbsModule } from './components/breadcrumbs/breadcrumbs.module';
import {
  AccordionModule,
  CheckboxModule,
  ChipsModule,
  ConfirmationService,
  ConfirmDialogModule, DialogModule,
  DropdownModule, FieldsetModule, FileUploadModule, InputMaskModule, InputTextareaModule,
  InputTextModule, RadioButtonModule, SelectButtonModule, ToastModule, TooltipModule as PTooltipModule
} from "primeng";
import { RegistrationFormService } from './services/registration-form.service';
import { OtherIdentifierComponent } from './views/ticket-registration/components/other-identifier/other-identifier.component';
import { ToastrModule } from 'ngx-toastr';
import { BaseModalDialogModule } from './components/dialog/base-modal-dialog.module';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UtilsModule } from '../core-frontend-common/template/shared/utils/utils.module';
import { InlineLoaderModule } from "../core-frontend-common/components/inline-loader/inline-loader.module";
import { NotificationModule } from "./components/notification/notification.module";
import { ExportsViewComponent } from './views/exports-view/exports-view.component';
import { CustomDatepickerModule } from "./components/custom-datepicker/custom-datepicker.module";
import { QuotesPageModule } from "./views/quotes-page/quotes-page.module";
import { BiblioPersonSearchMk2Module } from "./components/biblio-person-search-mk2/biblio-person-search-mk2.module";
import { AttachmentsModule } from "./views/ticket-registration/components/attachments/attachments.module";
import { FormElementValueValidDirective } from './directives/form-element-value-valid.directive';
import { BiblioDetailModule } from "./components/biblio-detail/biblio-detail.module";
import { CitationModule } from "./views/catalog/components/citation/citation.module";
import { UserPublicationDataDialogModule } from "./components/user-publication-data-dialog/user-publication-data-dialog.module";
import { DevTestComponent } from './views/dev-test/dev-test.component';
import { BiblioCatalogViewModule } from "./views/biblio-catalog-view/biblio-catalog-view.module";
import { CommonSharedPipesModule } from "../core-frontend-common/pipes/common-shared-pipes.module";
import { PdfViewerModule } from "../core-frontend-common/components/pdf-viewer/pdf-viewer.module";
import { UppercaseCheckDirective } from './directives/uppercase-check.directive';
import { YoutubePlayerModule } from "./components/youtube-player/youtube-player.module";
import { MouseHoverStyleDirective } from './components/tile/mouse-hover-style.directive';
import { ItemTableRowModule } from "./views/ticket-catalog/components/item-table-row/item-table-row.module";
import { TicketRegistrationModule } from "./views/ticket-registration/ticket-registration.module";
import { ProjetsManagementViewComponent } from './views/projets-management-view/projets-management-view.component';
import { ProjectFormComponent } from './views/projets-management-view/project-form/project-form.component';
import { UserSearchMk2Module } from "./components/user-search-mk2/user-search-mk2.module";
import { ProjectFormPageComponent } from "./views/projets-management-view/project-form-page/project-form-page.component";
import { ImportProjectPageComponent } from './views/projets-management-view/import-project-page/import-project-page.component';
import { GrantPricesSubformComponent } from './views/projets-management-view/project-form-page/grant-prices-subform/grant-prices-subform.component';
import { ProjectsInfoModalComponent } from './views/projets-management-view/projects-info-modal/projects-info-modal.component';
import { ProjectsImportInfoModalComponent } from './views/projets-management-view/projects-import-info-modal/projects-import-info-modal.component';
import { MyPublicationsModule } from "./views/my-publications/my-publications.module";
import { TicketCatalogModule } from "./views/ticket-catalog/ticket-catalog.module";
import { DwToolsViewModule } from "./views/dw-tools-view/dw-tools-view.module";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    I18nModule,
    CustomPipesModule,
    RouterModule,
    AutoCompleteModule,
    ModalModule,
    CalendarModule,
    TooltipModule,
    FullCalendarModule,
    TableModule,
    InputTextModule,
    PopoverModule,
    ConfirmDialogModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    UtilsModule,
    CheckboxModule,
    DropdownModule,
    BreadcrumbsModule,
    AccordionModule,
    ChipsModule,
    ToastModule,
    ToastrModule.forRoot(),
    BaseModalDialogModule,
    FieldsetModule,
    FileUploadModule,
    SelectButtonModule,
    InlineLoaderModule,
    InputTextareaModule,
    NotificationModule,
    RadioButtonModule,
    CustomDatepickerModule,
    QuotesPageModule,
    BiblioPersonSearchMk2Module,
    AttachmentsModule,
    InputMaskModule,
    BiblioDetailModule,
    CitationModule,
    UserPublicationDataDialogModule,
    BiblioCatalogViewModule,
    CommonSharedPipesModule,
    PdfViewerModule,
    YoutubePlayerModule,
    ItemTableRowModule,
    TicketRegistrationModule,
    UserSearchMk2Module,
    TooltipModule,
    PTooltipModule,
    DialogModule,
    MyPublicationsModule,
    TicketCatalogModule,
    DwToolsViewModule
  ],
  declarations: [
    DashboardComponent,
    UserProfileViewComponent,
    DashboardComponent,
    TileComponent,
    UserSearchComponent,
    ExternalUserModalFormComponent,
    UserPipe,
    DevComponent,
    SmartClockpickerDirective,
    CatalogComponent,
    NavTabsComponent,
    FilterComponent,
    BiblioTableComponent,
    PersonTableComponent,
    MeetingTableComponent,
    PersonDetailComponent,
    BiblioItemComponent,
    OtherIdentifierComponent,
    ExportsViewComponent,
    FormElementValueValidDirective,
    DevTestComponent,
    UppercaseCheckDirective,
    MouseHoverStyleDirective,
    ProjetsManagementViewComponent,
    ProjectFormComponent,
    ProjectFormPageComponent,
    ImportProjectPageComponent,
    GrantPricesSubformComponent,
    ProjectsInfoModalComponent,
    ProjectsImportInfoModalComponent
  ],
  exports: [
    FilterComponent,
    UserPipe,
    UppercaseCheckDirective
  ],
  providers: [
    AppCommunicationService,
    ConfirmationService,
    DeviceDetectorService,
    RegistrationFormService,
    UppercaseCheckDirective,
  ]
})
export class CustomModule {}
