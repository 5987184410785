import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { QuoteSectionBaseComponent } from "../quote-section-base.component";

@Component({
  selector: "cited-publication-section",
  templateUrl: "./cited-publication-section.component.html",
  styleUrls: ["./cited-publication-section.component.scss"]
})
export class CitedPublicationSectionComponent extends QuoteSectionBaseComponent implements OnInit {

  searchText = "";

  @Output() citedPublicationTextChanged: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    if (this.archiveId) {
      this.searchText = this.archiveId.toString();
      this.searchCitedPublication();
    }
  }

  // STEP 1 - search publication by archive ID
  searchCitedPublication() {
    this.searchText = this.searchText.trim();

    if (this.searchText) {

      const isNum = /^\d+$/.test(this.searchText);

      if (!isNum) {
        this.notification.error(this.translationService.getTranslation('Zadali ste archívne číslo v nesprávnom formáte.'));
        return;
      }

      if (this.citedPublication) {

        const searchedArchiveId = this.citedPublication.archiveId.toString();

        if (this.searchText === searchedArchiveId) {
          return;
        }

      }
      this.citedPublicationTextChanged.emit(this.searchText);
    }
  }

  citedPublicationChanged() {
    super.citedPublicationChanged();
    this.sectionValidityChanged();
  }

  sectionValidityChanged() {
    super.sectionValidityChanged();

    if (this.citedPublication && this.citedPublication.id) {
      this.searchText = this.citedPublication.archiveId.toString();
      this.validityChanged.emit({valid: true, data: this.citedPublication});
    } else {
      this.validityChanged.emit({valid: false, data: this.citedPublication});
    }
  }

  changeCitedPublicationClicked(e: Event) {
    e.preventDefault();
    this.searchText = '';
    this.archiveId = null;
    this.citedPublication = null;
    this.citedPublicationChanged();
  }

}
