import { NgModule } from "@angular/core";
import { UserSearchMk2Component } from "./user-search-mk2.component";
import { UserSearchService } from "./user-search.service";
import { CommonModule } from "@angular/common";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";
import { AutoCompleteModule, DropdownModule, InputTextModule } from "primeng";
import { TooltipModule } from "ngx-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    UserSearchMk2Component
  ],
  imports: [
    CommonModule,
    I18nModule,
    DropdownModule,
    TooltipModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule
  ],
  exports: [
    UserSearchMk2Component
  ],
  providers: [
    UserSearchService
  ]
})

export class UserSearchMk2Module {}
