import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { TicketSection, ticketSectionsEnum, ticketTabs } from "../../misc/ticket-sections";

export enum FILTER_ACTIONS {
  "refresh",
  "print",
  "submit",
  "delete",
  "contact"
}

@Component({
  selector: "smart-general-filter",
  templateUrl: "./general-filter.component.html",
  styleUrls: ["./general-filter.component.scss"]
})
export class GeneralFilterComponent implements OnInit, OnChanges {
  filterActions = FILTER_ACTIONS;

  ticketTabs = ticketTabs;

  visibleActions = {
    refresh: true,
    print: false,
    submit: false,
    delete: false,
    contact: false
  };

  @Output() filterChange: EventEmitter<FILTER_ACTIONS> = new EventEmitter<FILTER_ACTIONS>();

  @Input() disabledActions = false;

  @Input() activeTab: TicketSection;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['activeTab']) {
      this.regenerateActions();
    }
  }

  action(type: FILTER_ACTIONS, e: Event) {
    e.preventDefault();
    this.filterChange.emit(type);
  }

  regenerateActions() {
    this.visibleActions.submit = false;
    this.visibleActions.print = false;
    this.visibleActions.contact = false;
    this.visibleActions.delete = true;

    switch (this.activeTab.section) {
      case ticketSectionsEnum.acceptance:
        this.visibleActions.submit = true;
        this.visibleActions.delete = true;
        this.visibleActions.contact = true;
        break;
      case ticketSectionsEnum.scan:
        this.visibleActions.submit = true;
        break;
      case ticketSectionsEnum.conversion:
        this.visibleActions.submit = true;
        break;
      case ticketSectionsEnum.validation:
        this.visibleActions.submit = true;
        break;
      case ticketSectionsEnum.cataloging:
        break;
      case ticketSectionsEnum.importDawinci:
        break;
      case ticketSectionsEnum.contact:
        break;
      case ticketSectionsEnum.hand_over:
        this.visibleActions.submit = true;
        this.visibleActions.print = true;
        break;
    }
  }

}
