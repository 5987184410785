export class FormElements {
  group: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  isbn: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  issn: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  doi: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  eIssn: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  languageId: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  countryCodeIsoId: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  title: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  restOfTitle: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  issueNumber: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  placeOfPublishing: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  nameOfPublisher: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  pageCount: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  uri: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  citationIndexCode: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  biblioCategoryId: IFormElements = {
    visible: true,
    required: false,
    disabled: false
  };
  yearOfPublishing: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  abstract: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "author.FullName": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };

  /* ### MEETING PART ### */
  "meeting.Name": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "meeting.Location": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "meeting.MeetingStartDate": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "meeting.MeetingEndDate": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "meeting.PartNumber": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };

  /* ### HOST ITEM PART ### */
  "hostItem.PageRangeString": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "hostItem.Title": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "hostItem.SubTitle": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "hostItem.Volume": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "hostItem.IssueNumber": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "hostItem.Isbn": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "hostItem.Issn": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "hostItem.Doi": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "hostItem.Eissn": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  /* PATENT SECTION */
  "patent.RegistrationCode": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "patent.PatentCode": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "patent.PatentTypeId": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "patent.DateOfRegistration": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "patent.DateOfGrant": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "patent.Publisher": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  /* PROJECT SECTION */
  "project.Name": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "project.AgencyCode": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "project.AgencyName": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "project.GrantNumber": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "project.OrderNumber": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  /* PARTICIPANTS */
  "participants.FullName": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  "keywords.Description": IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  researchAreaId: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
  biblioFieldOfStudyId: IFormElements = {
    visible: false,
    required: false,
    disabled: false
  };
}

export interface IFormElements {
  visible: boolean;
  required: boolean;
  disabled: boolean;
}
