export class TicketAcceptanceModel {
  attachmentType: number;
  handOverType: number;
  processingType: 'scan' | 'conversion';
  comment: string;

  static createEmptyModel(): TicketAcceptanceModel {
    const c = new TicketAcceptanceModel();
    c.attachmentType = 0;
    c.handOverType = 0;
    c.processingType = 'scan';
    c.comment = '';
    return c;
  }
}

