export class DwDatabaseModel {
  field: string;
  name: string;
  code: string;
  orderNumber: number;
  quartil: string | any;

  constructor() {
  }
}
