import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'smart-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: ['./inline-loader.component.scss']
})
export class InlineLoaderComponent implements OnInit {

  @Input() size: 'sm' | 'md' | 'lg' | 'elg' = 'sm';

  constructor() { }

  ngOnInit() {
  }

}
