import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Pipe({
  name: "trustUrl"
})
export class TrustUrlPipe implements PipeTransform {

  constructor(
    protected sanitizer: DomSanitizer
  ) {
  }

  transform(value: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

}
