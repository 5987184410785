import { FormBiblioModel } from "../registration-form/form-biblio.model";
import { BiblioModel } from "../biblio/biblio.model";
import { OpenAccessPayerModel } from "./open-access-payer.model";

export class OpenAccessBiblioModel {
  id: number;
  formBiblioId: number;
  formBiblio: FormBiblioModel;
  biblioId: number;
  biblio: BiblioModel;
  licenceCode: string;
  payers: OpenAccessPayerModel[] = [];
}
