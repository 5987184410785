import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppCommunicationService } from "../services/app-communication.service";
import { MessageService } from "primeng";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(
    protected messageService: MessageService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // check if has token

    const user = AppCommunicationService.getCurrentUser();

    if (user) {

      const token: string = AppCommunicationService.getCurrentUser().access_token;

      if (!token) {
        console.log("No token found!");
        return;
      }

      const isFileRequest = request.url.includes("/api/file/") || request.url.includes("assets/pdf/");

      const isCrossRef = request.url.includes("crossref");

      if (token && !isCrossRef) {
        request = request.clone({ headers: request.headers.set("Authorization", "Bearer " + token) });
      }

      if (!request.headers.has("Content-Type") && !isFileRequest && !isCrossRef) {
        request = request.clone({ headers: request.headers.set("Content-Type", "application/json") });
        request = request.clone({ headers: request.headers.set("Accept", "application/json") });
      }

      if (isFileRequest || isCrossRef) {
        request = request.clone({ headers: request.headers.delete("Content-Type") });
        request = request.clone({ headers: request.headers.delete("Accept") });
      }

    } else {

    }



    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        // if (event instanceof HttpResponse) {
        //   console.log('event--->>>', event);
        // }
        return event;
      }));
      // catchError((error: HttpErrorResponse) => {
      //   let data: any;
      //   console.log(data);
      //   data = {
      //     reason: error && error.error && error.error.reason ? error.error.reason : "",
      //     status: error.status
      //   };
      //   this.messageService.add({ severity: "error", detail: data.reason });
      //   return error;
      //   // return throwError(error);
      // }));
  }
}
