import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotificationModel } from "../components/notification/notification.model";
import { getAllNotificationsForSystem } from "../app.urls";
import { SYSTEM_NAME } from "../app.settings";
import { map } from "rxjs/operators";

@Injectable()
export class GeneralService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  getNotifications(): Observable<NotificationModel[]> {
    return this.http
        .post(getAllNotificationsForSystem(false, SYSTEM_NAME), {})
        .pipe(map((res: any) => res.items));
  }
}
