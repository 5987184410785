import { RoleCodebookModel } from '../codebook/role-codebook.model';
import { BiblioModel } from './biblio.model';

export class BiblioPersonModel {
  participationPercentage?: number;
  type: number;
  orderNumber: number;
  authorRoleCode: string;
  participantRoleCode: string;
  reviewerRoleCode: string;
  roleCode: RoleCodebookModel;
  authorDatesString: string;
  biblioId: number;

  fullName: string;
  login: string;
  userId: number;
  biblios?: BiblioModel[];
  departmentId: number;
  facultyId: number;

  constructor() {
    this.participationPercentage = 0;
    this.type = 0;
    this.orderNumber = 0;
    this.authorRoleCode = '';
    this.participantRoleCode = '';
    this.reviewerRoleCode = '';
    this.roleCode = new RoleCodebookModel();
    this.authorDatesString = '';
    this.biblioId = 0;
    this.fullName = '';
    this.login = '';
    this.userId = 0;
    this.biblios = [];
    this.departmentId = 0;
    this.facultyId = 0;
  }
}
