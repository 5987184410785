import { Injectable } from "@angular/core";
import { BiblioModel } from "../../models/biblio/biblio.model";
import { PublishingModel } from "../../models/biblio/publishing.model";
import { FormKeywordModel } from "../../models/registration-form/form-keyword.model";
import { ProjectModel } from "../../models/biblio/project.model";
import { MeetingModel } from "../../models/biblio/meeting.model";
import { CitationModel } from "../../models/biblio/citation.model";
import { BehaviorSubject, Subject } from "rxjs";
import { BiblioCatalogActionsEnum } from "./types/biblio-catalog-actions.enum";

@Injectable({
  providedIn: "root"
})
export class BiblioCatalogSharedService {

  data: BiblioSharedData = new BiblioSharedData();

  actionChange: Subject<BiblioCatalogActionsEnum> = new Subject<BiblioCatalogActionsEnum>();

  constructor() {
  }

  openBiblioDetail(
    biblio: BiblioModel
  ) {
    this.data.biblio = biblio;
    this.actionChange.next(BiblioCatalogActionsEnum.biblioDetail);
  }

  openCitationDetail(
    biblio: BiblioModel
  ) {
    this.data.biblio = biblio;
    this.actionChange.next(BiblioCatalogActionsEnum.citationDetail);
  }

  openAuthor(
    biblio: BiblioModel,
    author: PublishingModel
  ) {
    this.data.biblio = biblio;
    this.data.author = author;
    this.actionChange.next(BiblioCatalogActionsEnum.clickedAuthor);
  }

  openKeyword(keyword: FormKeywordModel) {
    this.data.keyword = keyword;
    this.actionChange.next(BiblioCatalogActionsEnum.clickedKeyword);
  }

  openProject(project: ProjectModel) {
    this.data.project = project;
    this.actionChange.next(BiblioCatalogActionsEnum.clickedProject);
  }

  openMeeting(meeting: MeetingModel) {
    this.data.meeting = meeting;
    this.actionChange.next(BiblioCatalogActionsEnum.clickedMeeting);
  }

  openCitations(citations: CitationModel[], biblio: BiblioModel) {
    this.data.citations = citations;
    this.data.biblio = biblio;
    this.actionChange.next(BiblioCatalogActionsEnum.clickedCitations);
  }


}

export class BiblioSharedData {
  biblio: BiblioModel;
  author: PublishingModel;
  meeting: MeetingModel;
  project: ProjectModel;
  citations: CitationModel[];
  keyword: FormKeywordModel;
}
