import { Pipe, PipeTransform } from '@angular/core';
import { CODEBOOK_ITEMS, CodebooksService } from "../services/codebooks.service";
import { CitationCategoryModel } from "../models/codebook/citation-category.model";

@Pipe({
  name: 'citationCategory',
  pure: false
})
export class CitationCategoryPipe implements PipeTransform {

  citationCategories: CitationCategoryModel[] = [];

  constructor(
    protected codebooks: CodebooksService
  ) {
    this.loadCategories();
  }

  async loadCategories() {
    this.citationCategories = await this.codebooks.get(CODEBOOK_ITEMS.citationCategories);
  }

  transform(categoryCode: string): any {
    if (this.citationCategories && categoryCode) {
      const item = this.citationCategories.find(c => c.code == categoryCode);
      if (item) {
        return item.description;
      }
      return '-';
    }
    return ' - ';
  }

}
