import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormElements } from "../../../ticket-catalog/misc/form-elements";
import { FormHostItemMetadataModel } from "../../../../models/registration-form/form-host-item-metadata.model";
import { UppercaseCheckDirective } from "../../../../directives/uppercase-check.directive";
import { ToastrService } from "ngx-toastr";
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-host-item",
  templateUrl: "./host-item.component.html",
  styleUrls: ["./host-item.component.scss"]
})
export class HostItemComponent implements OnInit, OnChanges {
  isValid = false;

  requiredKeys = [];

  isUppercase = false;

  @Input() hostItem: FormHostItemMetadataModel;

  @Input() formElements: FormElements;

  @Output() changeHostItem: EventEmitter<{ valid: boolean, data: FormHostItemMetadataModel }> = new EventEmitter<{ valid: boolean, data: FormHostItemMetadataModel }>();

  constructor(
    private uppercaseCheck: UppercaseCheckDirective,
    protected notification: ToastrService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.loadRequiredKeys();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['hostItem']) {
      this.loadRequiredKeys();
    }
  }

  isTitleUppercase(): boolean {
    let text = this.hostItem.title || '';
    let isValid = true;

    if (text && text.length > 0) {
      text = text.replace(/[^a-zA-Z]+/g, '');
    }

    const titleUpper = this.uppercaseCheck.checkWholeTextUppercase(text);

    if (titleUpper && text.length >= 3) {
      isValid = false;
    }

    return isValid;
  }

  loadRequiredKeys() {
    const hostItemFormElements = {};

    const keys = [];

    this.requiredKeys = [];

    Object.assign(hostItemFormElements, this.formElements);

    Object.keys(hostItemFormElements)
      .forEach(key => {
        if (key.indexOf("hostItem") === -1) {
          delete hostItemFormElements[key];
        } else {
          if (hostItemFormElements[key].required) {
            keys.push(key);
          }
        }
      });

    keys.forEach((key: string) => {
      let truncated = key.replace("hostItem.", "");
      truncated = truncated.charAt(0).toLowerCase() + truncated.substring(1, key.length - 1);
      this.requiredKeys.push(truncated);
    });

    setTimeout(() => {
      this.change();
    }, 1000);
  }

  change() {
    this.isValid = true;
    this.isUppercase = false;

    const err = this.requiredKeys
      .find(key => {
        return !this.hostItem[key];
      });
    if (err) {
      this.isValid = false;
    }

    if (this.formElements["hostItem.Title"].visible && this.isValid) {
      this.isValid = this.isTitleUppercase();

      if (!this.isValid) {
        this.isUppercase = true;
        this.notification.warning(`${this.i18n.getTranslation('Zaznamenali sme, že ste zadali názov zborníka/časopisu veľkými písmenami. Prosím zmeňte názov tak, aby obsahoval menej veľkých písmen.')}`);
      }
    }

    this.changeHostItem.emit({valid: this.isValid, data: this.hostItem});
    return;

  }

  getHostItem(): FormHostItemMetadataModel {
    return this.hostItem;
  }

  isValidHostItem() {
    return this.isValid;
  }

  checkValidity() {
    this.loadRequiredKeys();
  }

}
