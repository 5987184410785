import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { TicketFullModel } from "../../../../models/ticket/ticket-full.model";
import { BiblioModel } from "../../../../models/biblio/biblio.model";
import { BiblioService } from "../../../../services/biblio.service";
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-document-detail-dialog",
  templateUrl: "./document-detail-dialog.component.html",
  styleUrls: ["./document-detail-dialog.component.scss"]
})
export class DocumentDetailDialogComponent implements OnInit {

  dataToShow = [];

  biblio: BiblioModel = new BiblioModel();

  ticket: TicketFullModel = new TicketFullModel();

  @ViewChild("staticModal", { static: false }) modal: ModalDirective;

  constructor(
    protected biblioService: BiblioService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.generateData();
  }

  generateData() {
    this.dataToShow = [
      {
        param: 'ISBN',
        property: 'isbn',
        value: ''
      },
      {
        param: 'ISSN',
        property: 'issn',
        value: ''
      },
      {
        param: 'eISSN',
        property: 'eissn',
        value: ''
      },
      {
        param: 'DOI',
        property: 'doi',
        value: ''
      },
      {
        param: this.i18n.getTranslation('Názov'),
        property: 'hostItemTitle',
        value: ''
      },
      {
        param: this.i18n.getTranslation('Rozsah strán'),
        property: 'hostItemPageRange',
        value: ''
      },
      {
        param: this.i18n.getTranslation('Issue'),
        property: 'hostItemIssue',
        value: ''
      },
      {
        param: this.i18n.getTranslation('Ročník'),
        property: 'hostItemVolume',
        value: ''
      },
      {
        param: this.i18n.getTranslation('Vydavateľ'),
        property: 'publisherName',
        value: ''
      },
      {
        param: this.i18n.getTranslation('Vydavateľ - adresa'),
        property: 'publisherAddress',
        value: ''
      }
    ];
  }

  openModal(
    biblioId: number = null,
    ticket: TicketFullModel = null
  ) {

    if (biblioId) {
      this.biblio = new BiblioModel();
      this.biblioService
        .getById(biblioId)
        .subscribe(v => {
          this.biblio = v;
          this.fullFillData();
        });
    } else {
      this.ticket = new TicketFullModel();
      this.ticket = ticket;
      this.fullFillData();
    }

    setTimeout(() => {
      this.modal.show();
    }, 100);
  }

  fullFillData() {
    if (this.biblio) {
      this.dataToShow.forEach(data => {
        if (this.biblio[data.property]) {
          data.value = this.biblio[data.property];
        }
        if (data.property.indexOf('hostItem') !== -1) {
          const deletedHostItemPrefix = data.property.replace('hostItem', '').toLowerCase();

          if (this.biblio.hostItem && this.biblio[deletedHostItemPrefix]) {
            data.value = this.biblio[deletedHostItemPrefix];
          }
        }
      });
    } else if (this.ticket) {
      this.dataToShow.forEach(data => {
        if (this.ticket.formBiblio && this.ticket.formBiblio[data.property]) {
          data.value = this.ticket.formBiblio[data.property];
        }
        if (data.property.indexOf('hostItem') !== -1) {
          const deletedHostItemPrefix = data.property.replace('hostItem', '').toLowerCase();

          if (this.ticket.formBiblio.hostItem && this.ticket.formBiblio[deletedHostItemPrefix]) {
            data.value = this.ticket.formBiblio[deletedHostItemPrefix];
          }
        }
      });
    }
  }

}
