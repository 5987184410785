import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";

@Pipe({
  name: 'authorAccordionHeader'
})
export class AuthorAccordionHeaderPipe implements PipeTransform {

  constructor(
    private datePipe: DatePipe
  ) {
  }

  transform(item: any): string {
    let text = '';

    if (item && item.startEmploymentDate) {
      text += `${this.datePipe.transform(item.startEmploymentDate, 'dd.MM.yyyy')}`;
    }

    if (item && item.endEmploymentDate) {
      text += ` - ${this.datePipe.transform(item.endEmploymentDate, 'dd.MM.yyyy')}`;
    }

    if (item && item.name) {
      text += ' ' + item.name;
    }

    if (item && item.surname) {
      text += ' ' + item.surname;
    }

    if (item && item.facultyCode) {
      text += `, ${item.facultyCode}`;
    }

    if (item && item.departmentCode) {
      text += `, ${item.departmentCode}`;
    }

    return text;
  }

}
