import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  getAllTicketsByArchiveIdUrl,
  getAllTicketsByFilterUrl,
  getAllTicketsForAuthorByFilterUrl,
  getAllTicketsForOwnerByFilterUrl,
  getAllTicketsForResponsibleByFilterUrl,
  getCreateTicketUrl,
  getDeleteTicketByOwnerUrl,
  getDeleteTicketUrl,
  getDeleteTicketWithCommentUrl,
  getDownloadDispatchNoteUrl,
  getDownloadFileUrl,
  getImportFromDwByIdUrl, getOpenAccessLicencesUrl, getOpenAccessTypesUrl,
  getTicketByIdForOwnerUrl,
  getTicketByIdUrl,
  getTicketCommentUrl,
  getTicketContactEmailBulkUrl,
  getTicketContactEmailUrl,
  getTicketGoToUrl,
  getTicketPriorityApprovementUrl,
  getTicketSetStatusUrl,
  getTicketToContactUrl,
  getUpdateTicketByOwnerUrl,
  getUpdateTicketByResponsibleUrl
} from "../app.urls";
import { map } from "rxjs/operators";
import { TicketModel } from "../models/ticket/ticket.model";
import { Observable, of } from "rxjs";
import { TicketContactEmailRequestModel } from "../models/requests/ticket-contact-email-request.model";
import { TicketToContactRequestModel } from "../models/requests/ticket-to-contact-request.model";
import { TicketGotoRequestModel } from "../models/requests/ticket-goto-request.model";
import { TicketStatusUpdateRequestModel } from "../models/requests/ticket-status-update-request.model";
import { TicketPriorityRequestModel } from "../models/requests/ticket-priority-request.model";
import { TicketSimpleModel } from "../models/ticket/ticket-simple.model";
import { TicketCreateRequestModel } from "../models/requests/ticket-create-request.model";
import { TicketFullModel } from "../models/ticket/ticket-full.model";
import { TicketCommentRequestModel } from "../models/requests/ticket-comment-request.model";
import { TicketContactEmailBulkRequestModel } from "../models/requests/ticket-contact-email-bulk-request.model";
import { FileService } from "./file.service";
import { ToastrService } from "ngx-toastr";
import { I18nService } from "../../core-frontend-common/template/shared/i18n";
import { BaseConstantModel } from "../models/base-constant.model";

@Injectable({
  providedIn: "root"
})
export class TicketService {

  constructor(
    protected http: HttpClient,
    protected fileService: FileService,
    protected toastr: ToastrService,
    protected translationService: I18nService
  ) {
  }

  getAllTicketsByFilter(filter: any): Observable<{ totalItems: number, items: TicketSimpleModel[] }> {
    return this.http
      .post(getAllTicketsByFilterUrl(), filter)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAllTicketsForOwnerByFilter(filter: any): Observable<{ totalItems: number, items: TicketSimpleModel[] }> {
    return this.http
      .post(getAllTicketsForOwnerByFilterUrl(), filter)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAllTicketsForResponsibleByFilter(filter: any): Observable<{ totalItems: number, items: TicketSimpleModel[] }> {
    return this.http
      .post(getAllTicketsForResponsibleByFilterUrl(), filter)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAllTicketsForAuthorByFilter(filter: any): Observable<{ totalItems: number, items: TicketSimpleModel[] }> {
    return this.http
      .post(getAllTicketsForAuthorByFilterUrl(), filter)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getAllTicketsByArchiveId(archiveId: number): Observable<{ totalItems: number, items: TicketSimpleModel[] }> {
    return this.http
      .get(getAllTicketsByArchiveIdUrl(archiveId))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getTicketById(id: number): Observable<TicketFullModel> {
    return this.http
      .get(getTicketByIdUrl(id))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getTicketByIdForOwner(id: number): Observable<TicketFullModel> {
    return this.http
      .get(getTicketByIdForOwnerUrl(id))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  createTicket(ticket: TicketCreateRequestModel): Observable<TicketModel> {
    return this.http
      .post(getCreateTicketUrl(), ticket)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  updateTicketByOwner(ticket: TicketCreateRequestModel): Observable<TicketModel> {
    return this.http
      .post(getUpdateTicketByOwnerUrl(), ticket)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  updateTicketByResponsible(ticket: TicketCreateRequestModel): Observable<TicketModel> {
    return this.http
      .post(getUpdateTicketByResponsibleUrl(), ticket)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  deleteTicket(id: number): Observable<any> {
    return this.http
      .delete(getDeleteTicketUrl(id))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  deleteTicketWithComment(ticketComment: TicketCommentRequestModel): Observable<any> {
    return this.http
      .post(getDeleteTicketWithCommentUrl(), ticketComment)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  deleteTicketByOwner(id: number): Observable<any> {
    return this.http
      .delete(getDeleteTicketByOwnerUrl(id))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  priorityApprovement(ticketPriority: TicketPriorityRequestModel): Observable<any> {
    return this.http
      .post(getTicketPriorityApprovementUrl(), ticketPriority)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  setStatus(ticketStatus: TicketStatusUpdateRequestModel): Observable<TicketModel> {
    return this.http
      .post(getTicketSetStatusUrl(), ticketStatus)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getGoto(goto: TicketGotoRequestModel): Observable<TicketModel> {
    return this.http
      .post(getTicketGoToUrl(), goto)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  toContact(ticketContact: TicketToContactRequestModel): Observable<TicketModel> {
    return this.http
      .post(getTicketToContactUrl(), ticketContact)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  contactEmail(emailRequest: TicketContactEmailRequestModel): Observable<TicketModel> {
    return this.http
      .post(getTicketContactEmailUrl(), emailRequest)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  contactEmailBulk(emailRequest: TicketContactEmailBulkRequestModel): Observable<TicketModel> {
    return this.http
      .post(getTicketContactEmailBulkUrl(), emailRequest)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  ticketComment(req: TicketCommentRequestModel): Observable<TicketModel> {
    return this.http
      .post(getTicketCommentUrl(), req)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  importFromDwById(id: number): Observable<TicketModel> {
    return this.http
      .get(getImportFromDwByIdUrl(id))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  downloadDispatchNote(id: number): Observable<any> {
    return this.fileService
      .download(getDownloadDispatchNoteUrl(id));
  }

  getOpenAccessLicences(): Observable<BaseConstantModel[]> {
    return this.http
      .get(getOpenAccessLicencesUrl())
      .pipe(map((res: any) => {
        return res.result as BaseConstantModel[];
      }));
  }

  getOpenAccessTypes(): Observable<BaseConstantModel[]> {
    return this.http
      .get(getOpenAccessTypesUrl())
      .pipe(map((res: any) => {
        return res.result as BaseConstantModel[];
      }));
  }
}
