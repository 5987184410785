import { Component, OnInit } from "@angular/core";
import { BaseEpc2Component } from "../base-epc2.component";
import { first } from "rxjs/operators";
import { checkDwToolsErrorResponse } from "../dw-tools-errors";
import { DwToolsService } from "../../../services/dw-tools.service";
import { DwRecordSimpleModel } from "../../../models/dwtools/dw-record-simple.model";
import { DwDatabaseModel } from "../../../models/dwtools/dw-database.model";
import { AppCommunicationService } from "../../../services/app-communication.service";
import { DwRecordCrudDatabaseRequest } from "../../../models/dwtools/dw-record-crud-database-request";
import { DwDatabaseCrudRequest } from "../../../models/dwtools/dw-database-crud-request";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng";
import { DwDatabasesEnum } from "../dw-databases";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-quartil-update-section",
  templateUrl: "./quartil-update-section.component.html",
  styleUrls: ["./quartil-update-section.component.scss"]
})
export class QuartilUpdateSectionComponent extends BaseEpc2Component implements OnInit {

  dwRecord: DwRecordSimpleModel;

  databasesToChange: DwDatabaseModel[] = [];

  changedDatabases: DwDatabaseModel[] = [];

  constructor(
    private dwTools: DwToolsService,
    private appCommunication: AppCommunicationService,
    private toastr: ToastrService,
    private confirm: ConfirmationService,
    private i18n: I18nService
  ) {
    super();
  }

  ngOnInit() {
  }

  resetData() {
    this.databasesToChange = [];
  }

  async pasteValue() {
    const text = await navigator.clipboard.readText();
    this.ac = Number.parseInt(text, 0);
  }

  getBiblioFromDw(ac: number) {
    this.ac = ac;

    if (!this.ac) {
      return;
    }

    this.appCommunication.loadingOn(this.i18n.getTranslation("Načítavam záznam."));

    this.dwTools
      .getFromDw(this.ac)
      .pipe(first())
      .subscribe({
        next: (value) => {
          this.dwRecord = this.reorderDatabases(value);

          this.loadedDwRecord.emit(this.dwRecord);

          this.appCommunication.loadingOff();
        },
        error: (err) => {
          const message = checkDwToolsErrorResponse(err);

          if (message) {
            this.showingErrorMessage(message);
          }

          this.appCommunication.loadingOff();
        }
      });
  }

  reorderDatabases(record: DwRecordSimpleModel): DwRecordSimpleModel {
    const order = {
      [DwDatabasesEnum.WOS]: 1,
      [DwDatabasesEnum.CCC]: 2,
      [DwDatabasesEnum.SCOPUS]: 3,
      [DwDatabasesEnum.DOI]: 4,
      default: Number.MAX_VALUE
    };

    record.databases.sort((a, b) => order[a.name] - order[b.name]);

    return record;
  }

  submittedQuartil(data: { allDatabases: DwDatabaseModel[], database: DwDatabaseModel }) {
    this.changedDatabases = data.allDatabases;

    const find = this.databasesToChange.find(d => d.name === data.database.name && d.code === data.database.code && d.field === data.database.field);

    if (!find) {
      this.databasesToChange.push(data.database);
    }

    if (find && find["quartilButton"]["name"] && find["quartilButton"]["name"] !== data.database["quartilButton"]["name"]) {
      this.deleteFromChanges(data.database);
      this.databasesToChange.push(data.database);
    }
  }

  deleteFromChanges(database: DwDatabaseModel) {
    const index = this.databasesToChange.findIndex(d => d.name === database.name && d.code === database.code && d.field === database.field);

    if (index !== -1) {
      delete database["quartilButton"];
      delete database["submitted"];
      this.databasesToChange.splice(index, 1);
    }
  }

  async onSubmit(): Promise<boolean> {

    if (this.databasesToChange.length === 0) {
      return Promise.resolve(false);
    }

    this.appCommunication.loadingOn();
    const request = new DwRecordCrudDatabaseRequest();
    request.archiveId = this.dwRecord.archiveId;
    request.objectId = this.dwRecord.objectId;

    const backupData = [...this.databasesToChange];

    this.databasesToChange.map(database => {
      delete database["submitted"];
      if (database["quartilButton"] && database["quartilButton"]["name"]) {
        database.quartil = database["quartilButton"]["name"];
        delete database["quartilButton"];
      }
    });

    const databases: DwDatabaseCrudRequest[] = [];

    this.databasesToChange.forEach(database => {
      const item = new DwDatabaseCrudRequest();
      item.code = database.code;
      item.field = database.field;
      item.name = database.name;
      item.orderNumber = database.orderNumber;
      item.quartil = database.quartil;

      databases.push(item);
    });

    const otherDatabases = this.changedDatabases.filter(d => !d["edited"]);

    if (otherDatabases && otherDatabases.length > 0) {
      otherDatabases.forEach(database => {
        const item = new DwDatabaseCrudRequest();
        item.code = database.code;
        item.field = database.field;
        item.name = database.name;
        item.orderNumber = database.orderNumber;
        item.quartil = database.quartil;

        databases.push(item);
      });
    }

    request.databases = [...databases];

    return new Promise((resolve, reject) => {
      this.dwTools.biblioUpdateQuartil(request)
        .pipe(first())
        .subscribe({
          next: (value) => {
            console.log(value);
            this.toastr.success(this.i18n.getTranslation('Operácia bola úspešná'), this.i18n.getTranslation('Úspech'));
            this.appCommunication.loadingOff();
            this.resetData();
            this.resetMessages();

            if (value) {
              this.dwRecord = value;
            }

            resolve(true);
          },
          error: (error) => {
            console.log(error);
            this.databasesToChange = [...backupData];
            this.toastr.error(this.i18n.getTranslation('Operácia sa nepodarila'), this.i18n.getTranslation('Chyba'));
            this.appCommunication.loadingOff();

            reject(error);
          }
        });

    });
  }

  async submitAndImport() {

    const res = await this.onSubmit();

    if (res) {

      this.appCommunication.loadingOn(this.i18n.getTranslation('Prebieha import.'));

      this.dwTools
        .biblioImportFromDw(this.ac, true)
        .pipe(first())
        .subscribe({
          next: (val) => {
            this.generateSuccessMessage();

            this.appCommunication.loadingOff();
          },
          error: (err) => {
            const message = checkDwToolsErrorResponse(err);

            if (message) {
              this.showingErrorMessage(message);
            }

            this.appCommunication.loadingOff();
          }
        });
    }

  }

  generateSuccessMessage() {
    this.showingSuccessMessage("DW_TO_BIBLIO_MAP_SUCCESS");
  }

  deleteQuartils() {
    this.confirm
      .confirm({
        header: this.i18n.getTranslation('Premazanie kvartilov'),
        message: this.i18n.getTranslation('Skutočne si prajete vymazať kvartily? Touto operáciou bude vymazané pole 977$q.'),
        accept: () => {
          this.appCommunication.loadingOn();

          this.dwTools
            .biblioDeleteQuartils(this.ac)
            .pipe(first())
            .subscribe({
              next: () => {
                this.getBiblioFromDw(this.ac);
                this.toastr.success(this.i18n.getTranslation("Premazanie kvartilov prebehlo v poriadku."), this.i18n.getTranslation('Úspech'));
                this.appCommunication.loadingOff();
              },
              error: err => {
                console.log(err);
                this.toastr.error(this.i18n.getTranslation("Premazanie kvartilov neúspešné."), this.i18n.getTranslation('Chyba'));
                this.appCommunication.loadingOff();
              }
            });
        },
        acceptLabel: this.i18n.getTranslation('Áno'),
        rejectLabel: this.i18n.getTranslation('Nie')
      });
  }


}
