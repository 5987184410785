import { NgModule } from "@angular/core";
import { DocumentDetailDialogComponent } from "./document-detail-dialog.component";
import { ModalModule } from "ngx-bootstrap";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    ModalModule,
    CommonModule
  ],
  exports: [
    DocumentDetailDialogComponent
  ],
  declarations: [
    DocumentDetailDialogComponent
  ]
})

export class DocumentDetailDialogModule {}
