import { NgModule } from '@angular/core';
import { BaseModalDialogComponent } from './base-modal-dialog.component';
import { MatButtonModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomPipesModule } from "../../pipes/customPipes.module";

@NgModule({
  imports: [
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    CommonModule,
    CustomPipesModule
  ],
  declarations: [
    BaseModalDialogComponent,
  ],
  providers: [
  ],
  entryComponents: [
    BaseModalDialogComponent
  ]
})

export class BaseModalDialogModule {}
