import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

const LOCAL_STORAGE_USER_FILTER = 'USER_FILTER';

@Injectable({
  providedIn: 'root'
})
export class CatalogFilterService {

  filter: Subject<any> = new Subject<any>();

  currentFilter: any;

  constructor() { }

  saveToLocalStorage(filter) {
    localStorage.removeItem(LOCAL_STORAGE_USER_FILTER);
    localStorage.setItem(LOCAL_STORAGE_USER_FILTER, JSON.stringify(filter));
  }

  loadFromLocalStorage() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_FILTER));
  }

  public getCurrentFilter(): any {
    return this.currentFilter;
  }

  public setFilter(filter: any) {
    this.currentFilter = filter;
    this.filter.next(filter);

  }
}
