import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { TicketFullModel } from "../../../../models/ticket/ticket-full.model";
import { FormAttachmentTypesArray, TicketStatuses } from "../../../../constants";
import { TicketLifecycleModel } from "../../../../models/ticket/ticket-lifecycle.model";
import { copyTextToClipboard } from "../../../../misc/copy-text-to-clipboard";
import { getDownloadFileUrl } from "../../../../app.urls";
import { TicketSimpleModel } from "../../../../models/ticket/ticket-simple.model";
import { BiblioModel } from "../../../../models/biblio/biblio.model";
import { getCurrentStatusForPedagogue, getCurrentStatus } from '../../misc/get-current-status';
import { FileService } from "../../../../services/file.service";
import { ToastrService } from "ngx-toastr";
import { FileMetadataModel } from "../../../../models/biblio/file-metadata.model";
import { TableColumn, TicketTableColumnNames } from "../../../../misc/table-responsive";
import { BiblioDetailComponent } from "../../../../components/biblio-detail/biblio-detail.component";
import { BiblioService } from "../../../../services/biblio.service";
import { TicketSection, ticketSectionsEnum, ticketTabs } from "../../misc/ticket-sections";

@Component({
  template: ''
})

export class RowexpansionBaseComponent implements OnChanges {
  lifecycles: TicketLifecycleModel[] = [];

  fileAttachments = FormAttachmentTypesArray;

  file: FileMetadataModel;

  ticketTableColumnNames = TicketTableColumnNames;

  ticketSectionsEnum = ticketSectionsEnum;

  @Input() ticket: TicketSimpleModel;

  @Input() viewType: "admin" | "pedagogue" = "admin";

  @Input() activeTab: TicketSection;

  @Input() fullTicket: TicketFullModel;

  @Input() notVisibleCols: TableColumn[] = [];

  @Output() openAuthorEmploymentsDialogEvent: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(BiblioDetailComponent, {static: false}) biblioDetail: BiblioDetailComponent;

  constructor(
    protected fileService: FileService,
    protected messageService: ToastrService,
    protected biblioService: BiblioService
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.fullTicket) {
      this.reorderParticipants();
    }
  }

  reorderParticipants() {
    if (this.fullTicket.formCitation) {
      this.fullTicket.formCitation.persons.sort((a, b) => a.orderNumber - b.orderNumber);
    } else if (this.fullTicket.formBiblio) {
      this.fullTicket.formBiblio.participants.sort((a, b) => a.orderNumber - b.orderNumber);
    }
  }

  fulfillLifecycles() {
    // const notShowLifecyclesIds = [
    //   TicketStatuses.in_acceptance,
    //   TicketStatuses.in_scan,
    //   TicketStatuses.in_conversion,
    //   TicketStatuses.in_validation,
    //   TicketStatuses.in_catalogization,
    //   TicketStatuses.imported_to_dawinci,
    //   TicketStatuses.in_contact,
    //   TicketStatuses.in_hand_over_to_author,
    //   TicketStatuses.updated_by_pedagogue
    // ];

    const notShowLifecyclesIds = [
    ];

    this.lifecycles = this.fullTicket.lifecycles.filter(l => {
      return l.status !== TicketStatuses.updated_by_pedagogue || (l.status === TicketStatuses.updated_by_pedagogue && l.comment.length > 0);
    });
    //
    // this.lifecycles = this.fullTicket.lifecycles.filter(l => {
    //   return notShowLifecyclesIds.indexOf(l.status) === -1 || (l.status === TicketStatuses.updated_by_pedagogue && l.comment.length > 0);
    // });

    this.lifecycles.sort((a, b) => {
      // @ts-ignore
      return (new Date(b.createdDatetime) - new Date(a.createdDatetime));
    });
  }

  copyEmailBtnClick(email: string) {
    copyTextToClipboard(email);
    this.messageService.success("E-Mail skopírovany do schránky.");
  }

  emailBtnClick(email: string) {
    window.location.assign(`mailto:${email}`);
  }

  getFileInfo() {
    if (this.fullTicket && this.fullTicket.fileMetadataId) {
      this.fileService.getInfo(this.fullTicket.fileMetadataId).subscribe(f => this.file = f);
    }
  }

  getFileAttachmentDescription(attachmentId: number) {
    if (!attachmentId) {
      return "-";
    }

    return this.fileAttachments.find(a => a.value === attachmentId).description;
  }

  downloadFile() {
    this.fileService
      .download(getDownloadFileUrl(this.file.id))
      .subscribe(() => {
        this.messageService.success("Súbor úspešne stiahnutý.");
      }, err => {
        this.messageService.error("Súbor sa stiahnuť nepodarilo.");
      });
  }

  getCurrentStatusForPedagogue(status: number): string {
    return getCurrentStatusForPedagogue(status);
  }

  getCurrentStatus(ticket: TicketSimpleModel): string {
    return getCurrentStatus(ticket);
  }

  showBiblioDetail(biblio: BiblioModel) {
    this.biblioDetail.show(null, biblio.id, biblio);
  }

  openAuthorEmploymentsDialog(login: string) {
    this.openAuthorEmploymentsDialogEvent.emit(login);
  }
}
