import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UserSearchService } from "./user-search.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormParticipantModel } from "../../models/registration-form/form-participant.model";
import { AutoComplete } from "primeng";
import { AppCommunicationService } from "../../services/app-communication.service";
import { ProjectInstitutionModel } from "../../models/project-institution.model";
import { CONSTANT_ITEMS, ConstantsService } from "../../services/constants.service";
import { CodebooksService } from "../../services/codebooks.service";
import { ProjectParticipantModel } from "../../models/project/project-participant.model";
import { sortByPriority } from "../../misc/sort-by-priority";
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";
import { createSubQuery } from "../../../core-frontend-common/misc/query-designer/query-designer-functions";
import { queryFilterTypes, queryOperators } from "../../../core-frontend-common/misc/query-designer/query-designer";

@Component({
  selector: "smart-user-search-mk2",
  templateUrl: "./user-search-mk2.component.html",
  styleUrls: ["./user-search-mk2.component.scss"]
})
export class UserSearchMk2Component implements OnInit, OnChanges {
  data = [];

  states = {
    opened: true,
    searching: true,
    fromTuke: true,
    notFromTuke: false,
    submitted: false
  };

  isFormValid = false;

  userForm: FormGroup;

  isEditing = false;

  institutions: ProjectInstitutionModel[] = [];

  tukeDepartments = [];

  filteredTukeDepartments = [];

  selectedInstitution = new ProjectInstitutionModel();

  filteredInstitutions: ProjectInstitutionModel[] = [];

  selectedDepartment = {
    departmentName: null,
    departmentCode: null,
    departmentIdAsString: null
  };

  projectParticipant: ProjectParticipantModel = new ProjectParticipantModel();

  selectedUser: FormParticipantModel = new FormParticipantModel();

  @Output() submittedParticipant: EventEmitter<ProjectParticipantModel> = new EventEmitter<ProjectParticipantModel>();

  @Output() onDeleteParticipant: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onEditParticipant: EventEmitter<ProjectParticipantModel> = new EventEmitter<ProjectParticipantModel>();

  @Input() fulfilledUser: ProjectParticipantModel;

  @Input() isAlreadySubmitted = false;

  @ViewChild("searchInput", { static: false }) searchInput: AutoComplete;

  constructor(
    private userSearch: UserSearchService,
    private formBuilder: FormBuilder,
    private appCommunicationService: AppCommunicationService,
    private constantService: ConstantsService,
    private codebookService: CodebooksService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {

    this.loadConstants();

    this.userForm = this.formBuilder.group({
      title: [""],
      name: ["", [Validators.required]],
      surname: ["", [Validators.required]],
      login: [""],
      titleAfter: [""],
      institution: ["", [Validators.required]],
      workcenter: ["", [Validators.required]],
    });

    this.userForm.valueChanges.subscribe(v => {
      this.formChange();
    });

    this.states.submitted = this.isAlreadySubmitted;

    if (this.states.submitted) {
      this.disableAllInputs();
    }

    setTimeout(() => {
      // check if form was already submitted
      if (this.isAlreadySubmitted) {

        Object.assign(this.selectedUser, this.fulfilledUser);

        this.patchUserValuesToForm();

        this.disableAllInputs();

        this.isFormValid = true;
      }
    }, 300);
  }

  async loadConstants() {
    const data = await this.constantService.get(CONSTANT_ITEMS.projectInstitutions);

    if (data) {
      this.institutions = sortByPriority(data);
    }
  }

  async patchUserValuesToForm() {

    this.userForm.patchValue(
      {
        title: this.selectedUser.title,
        titleAfter: this.selectedUser.titleAfter,
        name: this.selectedUser.name,
        surname: this.selectedUser.surname,
        login: this.selectedUser.login,
        institution: "",
        workcenter: this.selectedUser.departmentName
      }
    );

    if (!this.selectedUser.isExternal) {

      if (this.selectedUser.facultyId) {
        this.tukeDepartments = await this.codebookService.getDepartments(this.selectedUser.facultyId).toPromise();

        this.filteredTukeDepartments = [...this.tukeDepartments];
      }

      const institution = this.institutions.find(i => i.code.toLowerCase() === "tuke");

      if (institution) {
        this.selectedInstitution = institution;

        this.userForm.patchValue({
          institution: institution.code
        });
      }

      if (this.selectedUser.workCenterName) {
        this.selectedDepartment = {
          departmentName: this.selectedUser.workCenterName,
          departmentCode: this.selectedUser.workCenterCode,
          departmentIdAsString: this.selectedUser.workCenterId.toString()
        };

        this.userForm.patchValue({
          workcenter: this.selectedDepartment.departmentName
        });
      } else {
        // ak nema department tak povolim input pre workcenter aby som naplnil manualne
        if (!this.isAlreadySubmitted) {
          this.userForm.get("workcenter").enable();
        }
      }

    } else {
      if (this.fulfilledUser) {
        const institution = this.institutions.find(i => i.code === this.fulfilledUser.institutionCode);

        if (institution) {
          this.selectedInstitution = institution;
        }
      }
    }

    if (this.fulfilledUser && this.isAlreadySubmitted) {
      setTimeout(() => {
        this.selectedDepartment = {
          departmentName: this.fulfilledUser.departmentName,
          departmentCode: this.fulfilledUser.departmentCode,
          departmentIdAsString: this.fulfilledUser.departmentIdAsString
        };
      }, 50);
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    // was change detect, maybe changed order of participants and is change on first position so change role to author
    if (changes) {
      if (this.states.submitted) {
        this.isFormValid = true;
      }
    }
  }

  onFilterUsers(event) {
    this.searchUser(event.query);
  }

  searchUser(text: string) {
    const t = text.replace(" ", "_");

    const queryDesigner = {
      search: [],
      where: {
        "operatorType": "AND",
        "operands": []
      },
    };

    queryDesigner.where.operands.push(createSubQuery(queryOperators.AND, ['H'], 'ActiveStatusCode', queryFilterTypes.NOTEQUAL));

    queryDesigner.search.push({
      fields: ["name", "surname"],
      value: t
    });

    this.userSearch.searchUser(queryDesigner).subscribe(val => {
      if (val === null) {
        val = [];
      }
      val.push({
        id: 0,
        name: "",
        surname: this.i18n.getTranslation("Nenašli ste čo ste hľadali?"),
        titleAfter: "",
        workCenterCode: "",
        facultyCode: "",
        userId: 0
      });
      this.data = val;
    });
  }

  searchUserClick(e: Event) {
    e.preventDefault();

    this.searchUser(this.selectedUser.surname);

    setTimeout(() => {
      this.searchInput.overlayVisible = true;
    }, 50);
  }

  async onSelectOption() {

    if (this.selectedUser.id === 0) {

      this.userNotFromTuke();

      this.selectedUser = new FormParticipantModel();

      setTimeout(() => {
        this.enableAllInputs();
      }, 50);

    }

    if (this.selectedUser.isExternal) {

      this.userNotFromTuke();

      this.patchUserValuesToForm();

      return;
    }

    this.appCommunicationService.loadingOn(this.i18n.getTranslation("Načítavam dáta."));

    this.selectedUser = await this.userSearch.getSearchedUser(this.selectedUser).toPromise();

    this.patchUserValuesToForm();

    this.userFromTuke();

    this.states.searching = false;

    this.appCommunicationService.loadingOff();
  }

  editParticipant() {
    this.generateProjectParticipant();
    this.states.searching = false;
    this.states.opened = true;
    this.states.submitted = false;
    this.onEditParticipant.emit(this.projectParticipant);
    this.isEditing = true;
  }

  onCloseButton() {
    this.states.opened = true;
    this.states.searching = true;
    this.states.fromTuke = true;
    this.onDeleteParticipant.emit(true);
    this.states.submitted = false;
  }

  userFromTuke() {
    this.states.opened = true;
    this.states.fromTuke = true;
    this.states.notFromTuke = false;
    this.states.searching = true;

    this.disableAllInputs();

    this.userForm.get("workcenter").enable();
  }

  userNotFromTuke() {
    this.selectedUser.isExternal = true;
    this.states.opened = true;
    this.states.notFromTuke = true;
    this.states.searching = false;
    this.states.fromTuke = false;

    this.disableAllInputs();

    this.userForm.get("institution").enable();

    this.userForm.get("workcenter").enable();

  }

  enableAllInputs() {
    this.userForm.enable();
  }

  disableAllInputs() {
    this.userForm.disable();
  }

  formChange() {
    if (this.selectedUser) {
      if (
        this.selectedUser.name && this.selectedUser.surname
      ) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    } else {
      this.isFormValid = this.userForm.valid;
    }
  }

  onSubmitParticipant() {
    if (this.states.submitted) {
      this.editParticipant();
      return;
    }

    this.generateProjectParticipant();

    this.isEditing = false;
    this.submittedParticipant.emit(this.projectParticipant);
    this.states.submitted = true;
    this.disableAllInputs();
  }

  generateProjectParticipant() {
    this.projectParticipant.title = this.userForm.get('title').value;
    this.projectParticipant.titleAfter = this.userForm.get('titleAfter').value;
    this.projectParticipant.name = this.userForm.get('name').value;
    this.projectParticipant.surname = this.userForm.get('surname').value;
    this.projectParticipant.institutionName = this.selectedInstitution.description;
    this.projectParticipant.institutionCode = this.selectedInstitution.code;
    this.projectParticipant.departmentName = this.selectedDepartment.departmentName;
    this.projectParticipant.departmentCode = this.selectedDepartment.departmentCode;
    this.projectParticipant.departmentIdAsString = this.selectedDepartment.departmentIdAsString;
    this.projectParticipant.login = this.userForm.get('login').value;
    this.projectParticipant.isExternal = this.selectedUser.isExternal;

    if (this.projectParticipant.isExternal || this.selectedUser.id === 0) {
      this.projectParticipant.departmentName = this.userForm.get('workcenter').value;
    }
  }

  departmentElCleared() {
    this.filteredTukeDepartments = [...this.tukeDepartments];

    this.userForm.patchValue({
      workcenter: ""
    });
  }

  departmentElAutocomplete(event) {

    this.filteredTukeDepartments = [...this.tukeDepartments];

    if (!event || event.query.length === 0) {
      return;
    }

    if (!this.tukeDepartments || this.tukeDepartments.length === 0) {
      return;
    }

    const text = event.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    this.filteredTukeDepartments = [];

    for (let i = 0; i < this.tukeDepartments.length; i++) {
      const inst = this.tukeDepartments[i].departmentName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

      if (inst.indexOf(text) !== -1) {
        this.filteredTukeDepartments.push(this.tukeDepartments[i]);
      }
    }
  }

  institutionElAutocomplete(event) {
    if (!event || event.query.length === 0) {
      return;
    }

    const text = event.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    this.filteredInstitutions = [];

    for (let i = 0; i < this.institutions.length; i++) {
      const inst = this.institutions[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

      if (inst.indexOf(text) !== -1) {
        this.filteredInstitutions.push(this.institutions[i]);
      }
    }
  }

  institutionElCleared() {
    this.filteredInstitutions = [...this.institutions];

    this.userForm.patchValue({
      institution: ""
    });

    this.selectedInstitution = new ProjectInstitutionModel();
  }

  onSelectInstitution() {
    if (!this.selectedInstitution) {
      return;
    }

    this.userForm.patchValue({
      institution: this.selectedInstitution.description
    });
  }

  onSelectDepartment() {
    if (!this.selectedDepartment) {
      return;
    }

    this.userForm.patchValue({
      workcenter: this.selectedDepartment.departmentName
    });
  }


}
