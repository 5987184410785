import { Component, OnInit } from '@angular/core';
import { BaseOverlayDialogComponent } from "../base-overlay-dialog/base-overlay-dialog";

@Component({
  selector: 'smart-tutorial-videos-dialog',
  templateUrl: './tutorial-videos-dialog.component.html',
  styleUrls: ['./tutorial-videos-dialog.component.scss']
})
export class TutorialVideosDialogComponent extends BaseOverlayDialogComponent implements OnInit {

  videourl = '';
  title: string;

  ngOnInit() {
  }

  initialize(
    videourl: string,
    title: string = null
  ) {
    this.videourl = videourl;
    this.title = title;
  }

}
