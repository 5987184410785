import { TicketLifecycleModel } from "./ticket-lifecycle.model";
import { UserModel } from "../user.model";

export class BaseTicketModel {
  id?: number;
  type: 'biblio' | 'citation';
  isFinished: boolean;
  isDeleted?: boolean;
  isInProgress: boolean;
  isPrioritized: boolean;
  priorityType?: number;
  attachmentType?: number;
  handOverType?: number;
  fileMetadataId?: number;
  currentStatus?: number;
  comment?: string;
  deleteComment?: string;
  priorityComment?: string;
  correctionComment?: string;
  responsibleById?: number;
  lifecycles: TicketLifecycleModel[];
  createdBy: UserModel;
  updatedBy: UserModel;
  biblioId?: number;

  createdById?: number;
}
