import { BaseTicketModel } from './base-ticket.model';

export class TicketSimpleModel extends BaseTicketModel {
  formBiblioId?: number;
  formCitationId?: number;
  archiveId: number;
  title: string;
  description: string;
  categoryCode: string;
  citationCategoryCode?: string;
  authorFullName: string;
  authorEmail?: string;
  yearOfPublishing: number;
  dwImportedDatetime?: Date;
  citationId?: number;

  isChecked?: boolean;
  isDeleted?: boolean;
}
