import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DwRecordSimpleModel } from "../../../models/dwtools/dw-record-simple.model";
import { DwDatabaseModel } from "../../../models/dwtools/dw-database.model";
import { DwDatabasesEnum } from "../dw-databases";

@Component({
  selector: "smart-quartil-editor",
  templateUrl: "./quartil-editor.component.html",
  styleUrls: ["./quartil-editor.component.scss"]
})
export class QuartilEditorComponent implements OnInit {

  quartilButtons = [
    { name: "Q0", value: "Q0" },
    { name: "Q1", value: "Q1" },
    { name: "Q2", value: "Q2" },
    { name: "Q3", value: "Q3" },
    { name: "Q4", value: "Q4" }
  ];

  dwRecord: DwRecordSimpleModel;

  quartilDatabases: DwDatabaseModel[] = [];

  @Input() set setDwRecord(dwRecord: DwRecordSimpleModel) {
    this.dwRecord = dwRecord;

    this.generateQuartils();
  }

  @Output() submittedQuartil: EventEmitter<{allDatabases: DwDatabaseModel[], database: DwDatabaseModel}> =
    new EventEmitter<{allDatabases: DwDatabaseModel[], database: DwDatabaseModel}>();

  constructor() {
  }

  ngOnInit() {
  }

  generateQuartils() {
    if (!this.dwRecord) {
      return;
    }

    this.quartilDatabases = [...this.dwRecord
      .databases
      .filter(d => d.name.trim().toLowerCase() === DwDatabasesEnum.WOS.trim().toLowerCase() ||
        d.name.trim().toLowerCase() === DwDatabasesEnum.SCOPUS.trim().toLowerCase())];

    this.quartilDatabases.map(v => {
      if (!v.quartil) {
        return v;
      }

      const i = this.quartilButtons.find(b => b.name.trim().toLowerCase() === v.quartil.trim().toLowerCase());

      if (i) {
        v["quartilButton"] = i;
      }

      return v;
    });
  }

  changedQuartil(database: DwDatabaseModel, index: number) {
    database["submitted"] = false;
    this.quartilDatabases[index]["edited"] = false;
  }

  submitQuartilChange(database: DwDatabaseModel, index: number) {

    database["submitted"] = true;

    this.quartilDatabases[index]["edited"] = true;

    this.submittedQuartil.emit({ allDatabases: this.quartilDatabases, database });

  }

}
