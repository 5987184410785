import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LanguageCodebookModel } from "../../models/codebook/language-codebook.model";
import { CODEBOOK_ITEMS, CodebooksService } from "../../services/codebooks.service";
import { RegistrationFormService } from "../../services/registration-form.service";
import {
  EPC_HELP_EMAIL,
  FormAttachmentTypes,
  FormAttachmentTypesArray,
  PriorityTypes,
  PriorityTypesArray,
  REGISTRATION_FORM_GROUP_2022,
  REGISTRATION_FORM_GROUPS_2022, TicketStatuses, YOUTUBE_URLS
} from "../../constants";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FormParticipantModel } from "../../models/registration-form/form-participant.model";
import { CountryCodebookModel } from "../../models/codebook/country-codebook.model";
import { FormBiblioModel } from "../../models/registration-form/form-biblio.model";
import { FormKeywordModel } from "../../models/registration-form/form-keyword.model";
import { FormHostItemMetadataModel } from "../../models/registration-form/form-host-item-metadata.model";
import { FormMeetingModel } from "../../models/registration-form/form-meeting.model";
import { ActivatedRoute, Router } from "@angular/router";
import { TicketService } from "../../services/ticket.service";
import { TicketFullModel } from "../../models/ticket/ticket-full.model";
import { HostItemComponent } from "./components/host-item/host-item.component";
import { AutoComplete, ConfirmationService, Dropdown, SelectItem } from "primeng";
import { AttachmentsComponent } from "./components/attachments/attachments.component";
import { AppCommunicationService } from "../../services/app-communication.service";
import { FormElements } from "../ticket-catalog/misc/form-elements";
import { BiblioCategoryCodebookWithFormGroupModel } from "../../models/codebook/biblio-category-codebook-with-formgroup.model";
import { delay } from "rxjs/operators";
import { CrossRefService } from "../../services/cross-ref.service";
import { CrossrefMetadataModel } from "../../models/crossref/crossref-metadata.model";
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";
import { CONSTANT_ITEMS, ConstantsService } from "../../services/constants.service";
import { TicketCreateRequestModel } from "../../models/requests/ticket-create-request.model";
import { FormPatentModel } from "../../models/registration-form/form-patent.model";
import { FormProjectModel } from "../../models/registration-form/form-project.model";
import { CitationIndexModel } from "../../models/codebook/citation-index.model";
import { copyTextToClipboard } from "../../misc/copy-text-to-clipboard";
import * as moment from "moment";
import { TicketErrorCheckModalComponent } from "./ticket-error-check-modal/ticket-error-check-modal.component";
import { capitalizeFirstLetterInString, checkCorrectDoiFormat, deleteSpecialCharactersFromString } from "../../helper-functions";
import { PatentPublishingSourceModel } from "../../models/patent-publishing-source.model";
import { PatentTypeCodebook } from "../../models/codebook/patent-type.codebook";
import { TicketRequestType } from "../../types/ticket-request.type";
import { UserPublicationDataDialogComponent } from "../../components/user-publication-data-dialog/user-publication-data-dialog.component";
import { PersonsService } from "../../services/persons.service";
import { PersonValidityModel } from "../../models/person-validity.model";
import { ToastrService } from "ngx-toastr";
import { TicketAttachmentPhysicalDocument } from "../ticket-catalog/misc/ticket-attachment-physical-document";
import { UppercaseCheckDirective } from "../../directives/uppercase-check.directive";
import { TutorialVideosDialogComponent } from "../../components/overlay-dialog/tutorial-videos-dialog/tutorial-videos-dialog.component";
import { OverlayService } from "../../components/overlay-dialog/overlay-service";
import { checkIsDisabledPageForDate } from "../../misc/check-disabled-page-for-date";
import { DEVAPI_BUILD, PRODUCTION_BUILD } from "../../app.settings";
import { BiblioFieldOfStudyCodebookModel } from "../../models/codebook/biblio-field-of-study-codebook.model";
import { ResearchAreaCodebookModel } from "../../models/codebook/research-area-codebook.model";
import { OpenAccessHostItemRequestModel } from "../../models/open-access/open-access-host-item-request.model";
import { OpenAccessBiblioRequestModel } from "../../models/open-access/open-access-biblio-request.model";
import { ProjectAdministratorsListDialogComponent } from "./components/project-administrators-list-dialog/project-administrators-list-dialog.component";

@Component({
  selector: "ticket-registration",
  templateUrl: "./ticket-registration.component.html",
  styleUrls: ["./ticket-registration.component.scss"]
})
export class TicketRegistrationComponent implements OnInit {
  PARTICIPANT_LIMIT_LENGTH = 25;

  biblioForm: FormGroup;

  epcHelpEmail = EPC_HELP_EMAIL;

  loaded = false;

  /* ####### CODEBOOKS ####### */
  allBiblioCategories: BiblioCategoryCodebookWithFormGroupModel[] = [];
  filteredBiblioCategories: BiblioCategoryCodebookWithFormGroupModel[] = [];
  catalogLanguages: LanguageCodebookModel[] = [];
  filteredLanguages: LanguageCodebookModel[] = [];
  catalogCountries: CountryCodebookModel[] = [];
  filteredCountries: CountryCodebookModel[] = [];
  citationIndexes: CitationIndexModel[] = [];

  selectButtonOptions: SelectItem[] = [];

  selectedDoiOption: "doi" | "no-doi";

  selectOaButtonOptions: SelectItem[] = [];

  selectedOaButton: "oa" | "no-oa" = "no-oa";

  showOpenAccessSection = false;

  keywords: string[] = [];

  insertedDoiValue: string;

  doiSuccessfullyLoaded = false;

  crossRefBiblio: CrossrefMetadataModel;

  participantsFromCrossref = false;

  isReviewed = false;

  prioritizedComment = "";

  comment = '';

  yearsOfPublishing = [];

  formGroups = REGISTRATION_FORM_GROUPS_2022;

  formGroupsEnum = REGISTRATION_FORM_GROUP_2022;

  minPublishingCount = 0;

  currentFormGroup = "";

  formSections = {
    category: {
      disabled: false,
      opened: true,
      valid: false
    },
    participants: {
      disabled: true,
      opened: false,
      valid: false
    },
    mySection: {
      disabled: true,
      opened: false,
      valid: false
    },
    hostSection: {
      disabled: true,
      opened: false,
      valid: false
    },
    attachments: {
      disabled: true,
      opened: false,
      valid: false
    },
    others: {
      disabled: true,
      opened: false,
      valid: false
    }
  };

  formElements: FormElements = new FormElements();

  participants: FormParticipantModel[] = [new FormParticipantModel()];

  hostItemSection = {
    data: FormHostItemMetadataModel.createEmptyFormHostItemModel(),
    valid: false,
    show: false
  };

  meetingSection = {
    data: FormMeetingModel.createEmptyMeeting(),
    valid: false,
    show: false
  };

  patentSection = {
    data: FormPatentModel.createEmptyFormPatentModel(),
    valid: false,
    show: false
  };

  projectSection = {
    data: [FormProjectModel.createEmptyModel()],
    valid: false,
    show: false
  };

  maxPercentage = 100;

  isFormValid = false;

  isInEditMode = false;

  editedTicket: TicketFullModel;

  problematicElements = ["languageId", "countryCodeIsoId", "citationIndexCode"];

  formAttachments = FormAttachmentTypes;

  maxFileSizeForUploadMB = 200;

  formAttachmentTypes: any[] = FormAttachmentTypesArray;

  priorityTypes = PriorityTypes;

  priorityTypesArray = PriorityTypesArray;

  patentPublishingSources: PatentPublishingSourceModel[] = [];

  patentTypeCodebook: PatentTypeCodebook[] = [];

  filteredPatentPublishingSources: PatentPublishingSourceModel[] = [];

  currentFormAttachmentType: number;

  currentPriorityType: number;

  formErrors = [];

  isFAIForm = false;

  isAGJForm = false;

  smartFormHints: { key: string, original: string, hint: string }[] = [];

  personValidity: PersonValidityModel;

  isSubmittingForm = false;

  allLettersUppercase = {
    title: false
  };

  identifiers = {
    required: false,
    valid: false
  };

  ignorePercentage = false;

  allFieldOfStudies: BiblioFieldOfStudyCodebookModel[] = [];
  filteredFieldOfStudies: BiblioFieldOfStudyCodebookModel[] = [];
  selectedFieldOfStudies: BiblioFieldOfStudyCodebookModel[] = [];
  allResearchAreas: ResearchAreaCodebookModel[] = [];
  filteredResearchAreas: ResearchAreaCodebookModel[] = [];
  selectedResearchAreas: ResearchAreaCodebookModel[] = [];

  openAccessHostItemRequestModel: OpenAccessHostItemRequestModel;
  openAccessBiblioRequestModel: OpenAccessBiblioRequestModel;

  @ViewChild(HostItemComponent, { static: false }) hostItemComponent: HostItemComponent;

  @ViewChild("documentTypeEl", { static: false }) documentTypeEl: Dropdown;
  @ViewChild("biblioCategoryEl", { static: false }) biblioCategoryEl: Dropdown;
  @ViewChild("countryEl", { static: false }) countryEl: AutoComplete;
  @ViewChild("priorityTypeEl", { static: false }) priorityTypeEl: Dropdown;
  @ViewChild("attachmentTypeEl", { static: false }) attachmentTypeEl: Dropdown;

  @ViewChild(AttachmentsComponent, { static: false }) uploadComponent: AttachmentsComponent;

  @ViewChild(TicketErrorCheckModalComponent, { static: false }) ticketErrorModal: TicketErrorCheckModalComponent;

  @ViewChild(UserPublicationDataDialogComponent, { static: false }) userPublicationDataDialogComponent: UserPublicationDataDialogComponent;

  @ViewChild(ProjectAdministratorsListDialogComponent, { static: false }) projectAdministratorsListDialogComponent: ProjectAdministratorsListDialogComponent;

  @Input() editedTicketId: number = null;

  @Input() allInputsLocked = false;

  @Input() isOpenedByAdmin = false;

  @Output() submittedFormEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    protected codebooksService: CodebooksService,
    protected constantsService: ConstantsService,
    protected registrationFormService: RegistrationFormService,
    protected notification: ToastrService,
    protected router: Router,
    protected ticketService: TicketService,
    protected activatedRoute: ActivatedRoute,
    protected appCommunicationService: AppCommunicationService,
    protected crossRef: CrossRefService,
    protected translationService: I18nService,
    private confirmationService: ConfirmationService,
    protected personsService: PersonsService,
    protected uppercaseCheck: UppercaseCheckDirective,
    protected dialogService: OverlayService
  ) {
  }

  ngOnInit() {
    this.generateSelectButton();

    this.generateOaSelectButton();

    if (!this.isOpenedByAdmin && PRODUCTION_BUILD) {
      const blocked = checkIsDisabledPageForDate(this.router, this.notification, this.appCommunicationService, true);

      if (!blocked) {
        this.init();
      }
    } else {
      this.init();
    }
  }

  @HostListener("dblclick", ["$event"])
  onDoubleClickEl(e) {
    if (!this.editedTicketId || !this.allInputsLocked) {
      return;
    }
    let text: string;
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
      text = e.target.value;
    } else if (e.target instanceof HTMLSpanElement || e.target instanceof HTMLDivElement) {
      text = e.target.innerText;
    } else {
      console.log(e);
    }

    copyTextToClipboard(text);

    this.notification.success(this.translationService.getTranslation("Text skopírovaný do schránky."));
  }

  generateOaSelectButton() {
    this.selectOaButtonOptions = [
      {
        label: this.translationService.getTranslation("Zdrojový dokument v Open Access (OA)"),
        value: "oa"
      },
      {
        label: this.translationService.getTranslation("Zdrojový dokument nie je v Open Access (OA)"),
        value: "no-oa"
      }
    ];
  }

  generateSelectButton() {
    this.selectButtonOptions = [
      {
        label: this.translationService.getTranslation("Mám DOI"),
        value: "doi"
      },
      {
        label: this.translationService.getTranslation("Nemám DOI"),
        value: "no-doi"
      }
    ];
  }

  oaButtonsChanged() {
    if (this.selectedOaButton === "no-oa") {
      this.clearOaValues();
      this.showOpenAccessSection = false;
    } else {
      this.showOpenAccessSection = true;
    }
    this.checkFormValidity();
  }

  openAccessHostItemChanged(openAccessHostItem: OpenAccessHostItemRequestModel) {
    console.log(openAccessHostItem)
    this.openAccessHostItemRequestModel = openAccessHostItem;
    this.checkFormValidity();
  }

  openAccessBiblioChanged(openAccessBiblio: OpenAccessBiblioRequestModel) {
    console.log('oa changed')
    console.log(openAccessBiblio)
    this.openAccessBiblioRequestModel = openAccessBiblio;
    this.checkFormValidity();
  }

  clearOaValues() {

  }

  async init() {
    this.appCommunicationService.loadingOn(this.translationService.getTranslation("Načítavam dáta."));

    setTimeout(() => {
      this.appCommunicationService.loadingOff();
    }, 45000);

    await this.initCodebooks();

    await this.initConstants();

    await this.initFormPart();

    this.activatedRoute.params.subscribe(v => {
      if (v && v.id) {
        this.isInEditMode = true;

        if (this.isInEditMode) {
          this.loadEditedTicket(v.id);
        } else {
          this.appCommunicationService.loadingOff();
        }
      } else {
        this.appCommunicationService.loadingOff();
      }
    });

    if (this.editedTicketId) {
      this.isInEditMode = true;
      if (this.allInputsLocked) {
        setTimeout(() => {
          this.lockAllInputs();
        }, 1000);
      }
      this.loadEditedTicket(this.editedTicketId);
    }

    if (!this.isInEditMode && !this.editedTicketId) {
      await this.checkIfUserIsAllowedToSystemEpc();
    }

    setTimeout(() => {
      this.onPriorityTypeChange(null, this.priorityTypes.no_priority);
    }, 400);
  }

  async checkIfUserIsAllowedToSystemEpc() {

    if (!PRODUCTION_BUILD) {
      return;
    }

    const valid = await this.personsService.isValidForCurrentUser().toPromise();

    if (valid) {
      this.personValidity = valid;

      if (valid && !valid.isValid) {
        this.userPublicationDataDialogComponent.show(valid, false);
      }
    }
  }

  lockAllInputs() {
    this.biblioForm.disable();
  }

  loadEditedTicket(ticketId: number) {
    this.appCommunicationService.loadingOn(this.translationService.getTranslation("Načítavam dáta publikácie."));

    if (this.isOpenedByAdmin) {
      this.ticketService.getTicketById(ticketId).subscribe(
        t => {
          this.editedTicket = t;
          this.processEditedTicket();
        },
        error => {
          console.log(error);
          this.notification.error(this.translationService.getTranslation(this.translationService.getTranslation("Nastala chyba.")));
          this.router.navigate(["/my-publications"]);
          this.appCommunicationService.loadingOff();
        }
      );
    } else {
      this.ticketService.getTicketByIdForOwner(ticketId).subscribe(
        t => {
          this.editedTicket = t;
          this.processEditedTicket();
        },
        error => {
          console.log(error);
          this.notification.error(this.translationService.getTranslation(this.translationService.getTranslation("Nastala chyba.")));
          this.router.navigate(["/my-publications"]);
          this.appCommunicationService.loadingOff();
        }
      );
    }
  }

  async initConstants() {
    this.patentPublishingSources = await this.constantsService.get(CONSTANT_ITEMS.patentPublishingSources);
    // this.formAttachmentTypes = await this.constantsService.get(CONSTANT_ITEMS.formAttachmentTypes);
  }

  async initCodebooks() {
    this.codebooksService.get(CODEBOOK_ITEMS.languages).then((langs: LanguageCodebookModel[]) => {
      this.catalogLanguages = langs
        .filter(l => l.isValid)
        .sort((a, b) => {
          return b.priority - a.priority;
        });
    });

    this.allResearchAreas = await this.codebooksService
      .get(CODEBOOK_ITEMS.researchAreas);

    this.allFieldOfStudies = await this.codebooksService
      .get(CODEBOOK_ITEMS.fieldOfStudy);

    this.allBiblioCategories = await this.codebooksService
      .get(CODEBOOK_ITEMS.biblioCategories);

    // this.citationIndexes = await this.codebooksService.get(CODEBOOK_ITEMS.citationIndexes);

    this.codebooksService.get(CODEBOOK_ITEMS.biblioCategoriesWithFormGroups)
      .then((categories: BiblioCategoryCodebookWithFormGroupModel[]) => {
        this.allBiblioCategories = categories.filter(c => c.isValid && c.showInRegistrationForm)
          .sort((a, b) => {
            return b.priority - a.priority;
          });
      });

    this.catalogCountries = await this.codebooksService.get(CODEBOOK_ITEMS.countries);

    this.fullFillDescriptionsForFormGroups();
  }

  fullFillDescriptionsForFormGroups() {
    // this.formGroups.forEach(doc => {
    //   doc.description = "";
    //   const group = this.allBiblioCategories.filter(c => c.formGroups.find(g => g.code === doc.title));
    //   if (group && group.length > 0) {
    //     group.forEach(g => {
    //       // if (g.isReviewRequired === this.isReviewed) {
    //       doc.description += `${g.code}, `;
    //       // }
    //     });
    //     doc.description = doc.description.substring(0, doc.description.length - 2);
    //   }
    // });
  }

  async initFormPart() {
    this.biblioForm = this.formBuilder.group({
      biblioCategoryId: [],
      biblioCategoryCode: [""],
      yearOfReporting: [""],
      title: [""],
      restOfTitle: [""],
      languageId: [""],
      countryCodeIsoId: [""],
      placeOfPublishing: [""],
      nameOfPublisher: [""],
      yearOfPublishing: [""],
      group: [""],
      issueNumber: [""],
      pageCount: [""],
      citationIndexCode: [""],
      isbn: [""],
      issn: [""],
      doi: [""],
      eIssn: [""],
      uri: [""],
      keywords: [""],
      abstract: [""]
    });

    this.biblioForm.valueChanges.subscribe(f => {
      this.checkSectionsValidity(true);
    });

    this.generateYearsFilter();

    setTimeout(() => (this.loaded = true), 50);

    return Promise.resolve(true);
  }

  async processEditedTicket() {
    if (!this.editedTicket || !this.isInEditMode) {
      return;
    }

    const form = this.editedTicket.formBiblio;

    this.selectedDoiOption = "no-doi";

    if (this.editedTicket.attachmentType !== null) {
      const type = FormAttachmentTypesArray.filter(t => t.value === this.editedTicket.attachmentType);
      if (type && type.length > 0) {
        setTimeout(() => {
          this.attachmentTypeEl.selectedOption = { label: type[0].name, value: type[0] };
          this.onFormAttachmentTypeChange(null, type[0].value);
        }, 500);
      }
    }

    if (this.editedTicket.priorityType !== null) {
      this.currentPriorityType = this.editedTicket.priorityType;
      const type = PriorityTypesArray.filter(t => t.value === this.currentPriorityType);
      if (type && type.length > 0) {
        setTimeout(() => {
          this.priorityTypeEl.selectedOption = { label: type[0].description, value: type[0] };
          this.onPriorityTypeChange(null, type[0].value);
        }, 350);
      }
    }

    this.comment = this.editedTicket.comment || '';

    this.prioritizedComment = this.editedTicket.priorityComment || "";

    if (!this.comment || this.comment === "") {

      const newestLifeCycle = this.editedTicket.lifecycles
        .filter(l => {
          return l.createdById === this.editedTicket.createdById && l.status <= TicketStatuses.updated_by_pedagogue;
        })
        .sort((a, b) => {
        return new Date(b.createdDatetime).getTime() - new Date(a.createdDatetime).getTime();
      }).pop();

      if (newestLifeCycle) {
        this.comment = newestLifeCycle.comment || '';
      }

    }

    if (this.editedTicket.formBiblio && this.editedTicket.formBiblio.participantCount && this.editedTicket.formBiblio.participantCount > this.PARTICIPANT_LIMIT_LENGTH) {
      this.ignorePercentage = true;
    }

    this.isReviewed = form.isReviewed;

    Object.keys(form).forEach(key => {
      const exists = this.biblioForm.get(key);
      if (exists && form[key]) {
        exists.patchValue(form[key]);
      }

      // also process problematic data (mostly theirs ID's)

      if (key === "group") {
        this.onFormGroupChange(null, form[key]);
      }

      if (key === "language") {
        this.biblioForm.get("languageId").patchValue(form[key]);
      }

      if (key === "countryCodeIso") {
        this.biblioForm.get("countryCodeIsoId").patchValue(form[key]);
      }

      if (key === "citationIndexCode") {
        this.biblioForm.get("citationIndexCode").patchValue(form[key]);
      }

      if (key === "researchAreas") {
        this.selectedResearchAreas = [];
        this.selectedResearchAreas = form.researchAreas;
      }

      if (key === "biblioFieldOfStudies") {
        this.selectedFieldOfStudies = [];
        this.selectedFieldOfStudies = form.biblioFieldOfStudies;
      }

      if (key === "author") {
        const participant = new FormParticipantModel();
        Object.assign(participant, form[key]);
        participant.orderNumber = 1;
        this.onSubmittedParticipant(participant, 0, true);
      }

      if (key === "keywords") {
        const wordsObj = form.keywords;
        const words = wordsObj.map(w => w.description);
        setTimeout(() => {
          this.biblioForm.get(key).patchValue(words);
          this.keywords = words;
        }, 100);
      }

      if (key === "participants") {
        let idx = 1;
        if (form["author"]) {
          idx = 2;
        }
        form.participants
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .forEach(participant => {
          const p = new FormParticipantModel();
          Object.assign(p, participant);
          p.orderNumber = idx;
          this.onSubmittedParticipant(p, idx - 1, true);
          idx++;
        });
      }

      if (key === "hostItem") {
        if (form.hostItem) {
          this.hostItemSection.data = form.hostItem;

          if (form.hostItem.openAccessHostItem) {
            this.selectedOaButton = 'oa';
          }

        }
      }

      if (key === "meeting") {
        if (form.meeting) {
          const startDate = form.meeting.meetingStartDate;
          const endDate = form.meeting.meetingEndDate;
          this.meetingSection.data = form.meeting;
          if (form.meeting.meetingEndDate) {
            // @ts-ignore
            this.meetingSection.data.meetingStartDate = [];
            this.meetingSection.data.meetingStartDate[0] = moment(startDate, "YYYY-MM-DD HH:mm:ss").toDate();
            this.meetingSection.data.meetingStartDate[1] = moment(endDate, "YYYY-MM-DD HH:mm:ss").toDate();
          }
        }
      }

      if (key === "patent") {
        if (form.patent) {
          this.patentSection.data = form.patent;
          if (this.patentSection.data.dateOfRegistration) {
            this.patentSection.data.dateOfRegistration = moment(this.patentSection.data.dateOfRegistration, "YYYY-MM-DD HH:mm:ss").toDate();
          }
          if (this.patentSection.data.dateOfGrant) {
            this.patentSection.data.dateOfGrant = moment(this.patentSection.data.dateOfGrant, "YYYY-MM-DD HH:mm:ss").toDate();
          }

          setTimeout(() => {
            if (this.isAGJForm && this.patentSection.data.publisher) {
              const p = this.patentPublishingSources.find(pub => pub.description === this.patentSection.data.publisher);

              this.biblioForm.get("nameOfPublisher").patchValue(p);
            }
          }, 300);
        }
      }

      if (key === "projects") {
        if (form.projects) {
          if (form.projects.length !== 0) {
            this.projectSection.data = form.projects;
          }
        }
      }

      setTimeout(() => {
        this.checkFormAttachmentValidity();
      }, 400);
    });

    this.checkSectionsValidity();

    setTimeout(() => {
      this.appCommunicationService.loadingOff();
    }, 1000);
  }

  generateYearsFilter() {
    this.yearsOfPublishing = [];
    const currentYear = new Date().getFullYear();
    this.yearsOfPublishing.push(
      { value: (currentYear + 1).toString() },
      { value: currentYear.toString() },
      { value: (currentYear - 1).toString() }
    );
    this.biblioForm.get("yearOfReporting").patchValue(currentYear.toString());
  }

  onFormGroupChange(e, valueDirectly: string = null) {
    setTimeout(() => {
      if (valueDirectly) {
        this.currentFormGroup = valueDirectly;
        this.documentTypeEl.selectedOption = { label: valueDirectly };
      } else {
        this.currentFormGroup = e.value.title;
      }
      this.deleteChosenBiblioCategoryDueToChange();
      this.filterCategories(this.currentFormGroup, !!valueDirectly);
    }, 70);
  }

  formAttachmentInputChanged(text: string) {
    if (this.currentFormAttachmentType === this.formAttachments.url_link) {
      if (text && text.length > 0) {
        this.formSections.attachments.valid = true;
      } else {
        this.formSections.attachments.valid = false;
      }
    }
    this.checkFormAttachmentValidity();
  }

  checkFormAttachmentValidity() {
    this.formSections.attachments.valid = false;

    if (!this.currentFormAttachmentType) {
      return;
    }

    if (this.currentFormAttachmentType === this.formAttachments.url_link) {
      setTimeout(() => {
        if (
          !this.biblioForm.get("uri") ||
          !this.biblioForm.get("uri").value ||
          this.biblioForm.get("uri").value === ""
        ) {
          this.checkFormValidity();
          return;
        }
        this.formSections.attachments.valid = true;
        this.checkFormValidity();
      }, 50);
      return;
    }

    if (this.currentFormAttachmentType === this.formAttachments.file) {
      setTimeout(() => {
        if (this.editedTicket && this.editedTicket.fileMetadataId) {
          this.formSections.attachments.valid = true;
          this.checkFormValidity();
          return;
        }
        if (!this.uploadComponent || !this.uploadComponent.hasFilesForUpload()) {
          this.checkFormValidity();
          return;
        }
        this.formSections.attachments.valid = true;
        this.checkFormValidity();
      }, 50);
      return;
    }

    this.formSections.attachments.valid = true;
    this.checkFormValidity();
  }

  onPriorityTypeChange(e = null, valueDirectly = null) {
    if (e || valueDirectly !== null) {
      this.currentPriorityType = e ? e.value.value : valueDirectly;
      if (valueDirectly !== null) {
        const priority = this.priorityTypesArray.filter(p => p.value === valueDirectly);
        if (priority && priority.length > 0) {
          this.priorityTypeEl.selectedOption = {
            label: priority[0].name,
            value: {
              name: priority[0].name,
              description: priority[0].description,
              value: priority[0].value
            }
          };
          this.formSections.others.valid = true;
        }
      }
      this.formSections.others.valid = true;
      this.checkSectionsValidity(true);
    } else {
      this.formSections.others.valid = false;
    }
  }

  onFormAttachmentTypeChange(e = null, valueDirectly = null) {
    if (e !== null || valueDirectly !== null) {
      this.currentFormAttachmentType = e ? e.value.value : valueDirectly;
      if (this.currentFormAttachmentType === this.formAttachments.url_link) {
        this.biblioForm.get("uri").setValidators(Validators.required);
      } else {
        this.biblioForm.get("uri").setValidators([]);
      }
    }

    this.checkFormAttachmentValidity();
  }

  deleteChosenBiblioCategoryDueToChange() {
    this.biblioForm.get("biblioCategoryId").patchValue("");
    this.biblioForm.get("biblioCategoryCode").patchValue("");
    if (this.biblioCategoryEl) {
      this.biblioCategoryEl.clear(null);
    }
  }

  updateBiblioCategories(deleteChosenCategory = false) {
    if (deleteChosenCategory) {
      this.deleteChosenBiblioCategoryDueToChange();
    }
    if (this.isReviewed) {
      this.filteredBiblioCategories = this.allBiblioCategories.filter(c =>
        c.formGroups.find(s => s.code === this.currentFormGroup)
      );
    } else {
      this.filteredBiblioCategories = this.allBiblioCategories
        .filter(c => c.formGroups.find(s => s.code === this.currentFormGroup))
        .filter(b => b.isReviewRequired === this.isReviewed);
    }
    this.fullFillDescriptionsForFormGroups();
    this.checkSectionsValidity();
  }

  hostItemChanged(val: { valid: boolean; data: FormHostItemMetadataModel }) {
    if (val) {
      this.hostItemSection.valid = val.valid;
      this.hostItemSection.data = val.data;
    }
    this.checkFormValidity();
  }

  meetingChanged(val: { valid: boolean; data: FormMeetingModel }) {
    if (val) {
      this.meetingSection.valid = val.valid;
      this.meetingSection.data = val.data;
    }
    this.checkFormValidity();
  }

  fixDateInMeetingDueToDatepicker() {
    const isDateArray = Array.isArray(this.meetingSection.data.meetingStartDate);

    if (isDateArray) {
      // @ts-ignore
      const datesArray = this.meetingSection.data.meetingStartDate as any[];

      if (datesArray && datesArray.length === 2) {
        this.meetingSection.data.meetingStartDate = datesArray[0];
        this.meetingSection.data.meetingEndDate = datesArray[1];
      }
    }
  }

  projectChanged(val: { valid: boolean; data: FormProjectModel }, index: number) {
    if (val) {
      this.projectSection.valid = val.valid;
      this.projectSection.data[index] = val.data;
    }
    this.checkFormValidity();
  }

  patentChanged(val: { valid: boolean; data: FormPatentModel }) {
    if (val) {
      this.patentSection.valid = val.valid;
      this.patentSection.data = val.data;
    }
    this.checkFormValidity();
  }

  addAnotherProject(val: boolean, index: number) {
    if (!val) {
      return;
    }
    this.projectSection.data.push(new FormProjectModel());
    this.checkFormValidity();
  }

  removeProject(val: boolean, index: number) {
    if (!val) {
      return;
    }
    this.projectSection.data.splice(index, 1);
    if (this.projectSection.data && this.projectSection.data.length === 0) {
      this.projectSection.data.push(new FormProjectModel());
    }
    this.checkFormValidity();
  }

  doiButtonsChanged() {
    if (this.doiSuccessfullyLoaded && this.crossRefBiblio) {
      this.showDoiRemovalDialog();
    } else {
      if (this.selectedDoiOption === "no-doi") {
        this.clearDoiValues();
      }
    }
  }

  showDoiRemovalDialog() {
    this.confirmationService.confirm({
      message: this.translationService.getTranslation(
        "Vykonaním zmeny budú všetky predvyplnené dáta zmazané. Skutočne si prajete zmenu vykonať?"
      ),
      header: this.translationService.getTranslation("Potvrdenie"),
      acceptLabel: this.translationService.getTranslation("Áno"),
      rejectLabel: this.translationService.getTranslation("Nie"),
      accept: () => {
        this.clearDoiValues();
      },
      reject: () => {
        this.selectedDoiOption = "doi";
      }
    });
  }

  showParticipantsRemovalDialog() {
    this.confirmationService.confirm({
      message: this.translationService.getTranslation("Boli zadaní niekoľkí participanti. Prajete si ich zmazať?"),
      header: this.translationService.getTranslation("Potvrdenie"),
      acceptLabel: this.translationService.getTranslation("Áno"),
      rejectLabel: this.translationService.getTranslation("Nie"),
      accept: () => {
        this.participants = [];
        this.maxPercentage = 100;
        this.participantsFromCrossref = false;
        this.participants.push(new FormParticipantModel());
        this.checkSectionsValidity();
      }
    });
  }

  clearDoiValues() {
    this.insertedDoiValue = "";
    this.doiSuccessfullyLoaded = false;
    this.clearFormAndEnableInputs();
    if (
      this.participants &&
      (this.participants.length >= 2 ||
        (this.participants.length === 1 && (this.participants[0].surname || this.participants[0].fullName)))
    ) {
      setTimeout(() => {
        this.showParticipantsRemovalDialog();
      }, 300);
    }
    this.checkSectionsValidity();
  }

  searchDoi() {
    const isValid = checkCorrectDoiFormat(this.insertedDoiValue);

    if (!isValid) {
      console.log("invalid doi");
      this.notification.error(this.translationService.getTranslation("Zadali ste DOI v nesprávnom formáte."));
      this.doiSuccessfullyLoaded = false;
    } else {
      this.appCommunicationService.loadingOn(this.translationService.getTranslation("Hľadám podľa DOI."));
      this.crossRef.getByDoi(this.insertedDoiValue).subscribe(
        v => {
          this.doiSuccessfullyLoaded = true;
          this.crossRefBiblio = v;
          this.clearFormAndEnableInputs();
          this.fullFillDataFromCrossRef();
          this.appCommunicationService.loadingOff();
          this.checkSectionsValidity();
        },
        e => {
          this.notification.error(
            this.translationService.getTranslation("Zadané DOI sa v systéme nenašlo. Prosíme, vyplňte údaje manuálne.")
          );
          this.doiSuccessfullyLoaded = false;
          this.selectedDoiOption = "no-doi";
          this.checkSectionsValidity();
          this.appCommunicationService.loadingOff();
        }
      );
    }
  }

  clearFormAndEnableInputs() {
    const biblioCategoryId = this.biblioForm.get("biblioCategoryId").value;
    const biblioCategoryCode = this.biblioForm.get("biblioCategoryCode").value;
    const group = this.biblioForm.get("group").value;
    this.hostItemSection.data = new FormHostItemMetadataModel();
    this.meetingSection.data = new FormMeetingModel();
    this.projectSection.data = [new FormProjectModel()];
    Object.keys(this.formElements).forEach(k => (this.formElements[k].disabled = false));
    this.biblioForm.reset();
    this.biblioForm.get("languageId").enable();
    this.biblioForm.get("biblioCategoryId").patchValue(biblioCategoryId);
    this.biblioForm.get("biblioCategoryCode").patchValue(biblioCategoryCode);
    this.biblioForm.get("group").patchValue(group);
  }

  changeCrossRefValue(targetEl: string, value: string) {
    if (targetEl && value) {
      this.biblioForm.controls[targetEl].patchValue(value);
      this.checkFormValidity();
    }
  }

  fullFillDataFromCrossRef() {
    if (!this.crossRefBiblio) {
      return;
    }

    if (this.crossRefBiblio.author && this.crossRefBiblio.author.length > 0) {

      let authors = [];

      if (this.crossRefBiblio.author.length > this.PARTICIPANT_LIMIT_LENGTH) {
        authors = [...this.crossRefBiblio.author.slice(0, 6)];
      } else {
        authors = [...this.crossRefBiblio.author];
      }
      this.participantsFromCrossref = true;
      this.ignorePercentage = this.crossRefBiblio.author.length > this.PARTICIPANT_LIMIT_LENGTH;
      this.participants = [];
      this.maxPercentage = 100;
      authors.forEach(author => {
        if (author.family && author.given) {
          const participant = new FormParticipantModel();
          participant.surname = author.family;
          participant.name = author.given;

          if (author.orcid) {
            participant.orcid = author.orcid;
          }

          this.participants.push(participant);
        }
      });
    }

    if (this.currentFormGroup === this.formGroupsEnum.monounit && this.crossRefBiblio.editor && this.crossRefBiblio.editor.length > 0) {
      let editors = [];

      if (this.crossRefBiblio.editor.length > this.PARTICIPANT_LIMIT_LENGTH) {
        editors = [...this.crossRefBiblio.editor.slice(0, 6)];
      } else {
        editors = [...this.crossRefBiblio.editor];
      }

      this.participantsFromCrossref = true;
      this.ignorePercentage = this.crossRefBiblio.editor.length > this.PARTICIPANT_LIMIT_LENGTH;
      this.participants = [];
      editors.forEach(author => {
        if (author.family && author.given) {
          const participant = new FormParticipantModel();
          participant.surname = author.family;
          participant.name = author.given;

          if (author.orcid) {
            participant.orcid = author.orcid;
          }

          this.participants.push(participant);
        }
      });
    }

    if (this.crossRefBiblio.title && this.crossRefBiblio.title.length > 0) {
      this.biblioForm.controls["title"].patchValue(capitalizeFirstLetterInString(this.crossRefBiblio.title[0]));
    }

    if (this.crossRefBiblio.subtitle && this.crossRefBiblio.subtitle.length > 0) {
      this.biblioForm.controls["restOfTitle"].patchValue(capitalizeFirstLetterInString(this.crossRefBiblio.subtitle[0]));
    }

    if (this.crossRefBiblio["publisher-location"]) {
      this.biblioForm.controls["placeOfPublishing"].patchValue(this.crossRefBiblio["publisher-location"]);
    }

    if (this.crossRefBiblio.publisher) {
      this.biblioForm.controls["nameOfPublisher"].patchValue(this.crossRefBiblio.publisher);
    }

    if (this.crossRefBiblio["published-online"]) {
      const published = this.crossRefBiblio["published-online"]["date-parts"];
      if (published && published.length > 0) {
        this.biblioForm.controls["yearOfPublishing"].patchValue(published[0][0]);
      }
    } else if (this.crossRefBiblio["published-print"]) {
      const published = this.crossRefBiblio["published-print"]["date-parts"];
      if (published && published.length > 0) {
        this.biblioForm.controls["yearOfPublishing"].patchValue(published[0][0]);
      }
    }

    if (!this.hostItemSection.show && this.crossRefBiblio.ISBN && this.crossRefBiblio.ISBN.length > 0) {
      this.biblioForm.controls["isbn"].patchValue(this.crossRefBiblio.ISBN[0]);
    } else if (this.crossRefBiblio.ISBN && this.crossRefBiblio.ISBN.length > 0) {
      this.hostItemSection.data.isbn = this.crossRefBiblio.ISBN[0];
    }

    if (this.crossRefBiblio["issn-type"] && this.crossRefBiblio["issn-type"].length > 0) {

      if (!this.hostItemSection.show) {
        this.biblioForm.controls["issn"].patchValue(this.crossRefBiblio["issn-type"][0].value);
      } else {
        const printIssn = this.crossRefBiblio["issn-type"].find(t => t.type === "print");
        if (printIssn) {
          this.hostItemSection.data.issn = printIssn.value;
        }
        const eIssn = this.crossRefBiblio["issn-type"].find(t => t.type === "electronic");
        if (eIssn) {
          this.hostItemSection.data.eissn = eIssn.value;
        }
      }

    }

    if (this.crossRefBiblio.funder && this.crossRefBiblio.funder.length > 0) {
      this.crossRefBiblio.funder
        .forEach(proj => {
          if (this.projectSection.data.length !== 0 && Object.keys(this.projectSection.data[0]).length === 0) {
            this.projectSection.data.splice(0, 1);
          }
          let order = 1;
          if (proj.award && proj.award.length > 0) {
            const project = FormProjectModel.createEmptyModel();
            project.grantNumber = proj.award[0];
            project.orderNumber = order;
            order++;
            this.projectSection.data.push(project);
          } else {
            this.projectSection.data.push(new FormProjectModel());
          }
        });
    }

    if (this.crossRefBiblio.DOI) {
      this.biblioForm.controls["doi"].patchValue(this.crossRefBiblio.DOI);
    }

    if (this.crossRefBiblio.URL) {
      this.biblioForm.controls["uri"].patchValue(this.crossRefBiblio.URL);
    }

    if (this.crossRefBiblio.language) {
      const language = this.catalogLanguages.find(
        l => l.code.toLowerCase() === this.crossRefBiblio.language.toLowerCase()
      );
      this.biblioForm.controls["languageId"].patchValue(language);
    }

    if (this.crossRefBiblio.abstract) {
      this.biblioForm.controls["abstract"].patchValue(deleteSpecialCharactersFromString(this.crossRefBiblio.abstract));
    }

    // if (this.crossRefBiblio.subject && this.crossRefBiblio.subject.length > 0) {
    //   this.biblioForm.controls["keywords"].patchValue(this.crossRefBiblio.subject);
    //   this.keywords = this.crossRefBiblio.subject;
    // }

    if (this.crossRefBiblio.volume && this.crossRefBiblio.volume.length > 0) {
      this.hostItemSection.data.volume = this.crossRefBiblio.volume;
    }

    if (this.crossRefBiblio.issue && this.crossRefBiblio.issue.length > 0) {
      this.hostItemSection.data.issueNumber = this.crossRefBiblio.issue;
    }

    if (this.crossRefBiblio.page && this.crossRefBiblio.page.length > 0) {
      this.hostItemSection.data.pageRangeString = this.crossRefBiblio.page;
    }

    if (this.crossRefBiblio.assertion && this.crossRefBiblio.assertion.length > 0) {
      const journal = this.crossRefBiblio.assertion.filter(a => a.name.toLowerCase() === "journaltitle");
      if (journal && journal.length > 0) {
        this.hostItemSection.data.title = journal[0].value;
      }
    }

    if (this.crossRefBiblio["container-title"] && this.crossRefBiblio["container-title"].length > 0) {
      this.hostItemSection.data.title = this.crossRefBiblio["container-title"][0];
    }

    if (this.crossRefBiblio["short-container-title"] &&
      (!this.crossRefBiblio["container-title"] || this.crossRefBiblio["container-title"].length === 0)) {
      this.hostItemSection.data.title = this.crossRefBiblio["short-container-title"][0];
    }

    setTimeout(() => {

      if (this.hostItemSection.show) {
        this.hostItemComponent.checkValidity();
      }

    }, 150);
  }

  filterAutocompleteMethod(event, type: "language" | "country" | "patent" | "researchArea" | "fieldOfStudy") {
    if (event.query.length === 0) {

      if (type === "language") {
        this.filteredLanguages = [...this.catalogLanguages];
      } else if (type === "country") {
        this.filteredCountries = [...this.catalogCountries];
      } else if (type === "patent") {
        this.filteredPatentPublishingSources = [...this.patentPublishingSources];
      } else if (type === "researchArea") {
        this.filteredResearchAreas = [...this.allResearchAreas];
      } else if (type === "fieldOfStudy") {
        this.filteredFieldOfStudies = [...this.allFieldOfStudies];
      }

      return;
    }


    const text = event.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    if (type === "language") {

      this.filteredLanguages = [];

      for (let i = 0; i < this.catalogLanguages.length; i++) {
        const lang = this.catalogLanguages[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        if (lang.indexOf(text) !== -1) {
          this.filteredLanguages.push(this.catalogLanguages[i]);
        }
      }

    } else if (type === "country") {

      this.filteredCountries = [];

      for (let i = 0; i < this.catalogCountries.length; i++) {
        const country = this.catalogCountries[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        if (country.indexOf(text) !== -1) {
          this.filteredCountries.push(this.catalogCountries[i]);
        }
      }

    } else if (type === "patent") {

      this.filteredPatentPublishingSources = [];

      for (let i = 0; i < this.patentPublishingSources.length; i++) {
        const patent = this.patentPublishingSources[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        if (patent.indexOf(text) !== -1) {
          this.filteredPatentPublishingSources.push(this.patentPublishingSources[i]);
        }
      }

    } else if (type === "researchArea") {

      this.filteredResearchAreas = [];

      for (let i = 0; i < this.allResearchAreas.length; i++) {
        const researchArea = this.allResearchAreas[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        if (researchArea.indexOf(text) !== -1) {
          this.filteredResearchAreas.push(this.allResearchAreas[i]);
        }
      }

    } else if (type === "fieldOfStudy") {

      this.filteredFieldOfStudies = [];

      for (let i = 0; i < this.allFieldOfStudies.length; i++) {
        const fieldOfStudy = this.allFieldOfStudies[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        if (fieldOfStudy.indexOf(text) !== -1) {
          this.filteredFieldOfStudies.push(this.allFieldOfStudies[i]);
        }
      }

    }
  }

  onSelectPatentPublisherForAgj(patent: PatentPublishingSourceModel) {
    if (patent && patent.countryCodeIsoId) {
      const country = this.catalogCountries.find(c => c.id === patent.countryCodeIsoId);
      this.countryEl.selectItem(country);
    }

    if (patent && patent.placeOfPublishing) {
      this.biblioForm.get("placeOfPublishing").patchValue(patent.placeOfPublishing);
    }
  }

  filterLanguagesCleared(event) {
    this.filteredLanguages = this.catalogLanguages;
  }

  filterResearchAreaCleared(event) {
    this.filteredResearchAreas = this.allResearchAreas;
    this.checkFormValidity();
  }

  filterFieldOfStudyCleared(event) {
    this.filteredFieldOfStudies = this.allFieldOfStudies;
    this.checkFormValidity();
  }

  onBiblioCategoryChange(e) {
    if (!e || !e.value || !e.value.code) {
      return;
    }
    const biblioCategoryCode = e.value.code;
    const biblioCategory = this.filteredBiblioCategories.filter(v => v.code === biblioCategoryCode)[0];

    if (biblioCategory && biblioCategory.code.toLowerCase() === "fai") {
      this.isFAIForm = true;
    } else {
      this.isFAIForm = false;
    }

    if (this.currentFormGroup === this.formGroupsEnum.patent) {
      this.isAGJForm = true;
    } else {
      this.isAGJForm = false;
    }

    this.minPublishingCount = biblioCategory.minPublishingCount;

    this.biblioForm.get("biblioCategoryId").patchValue(biblioCategory.id);

    this.biblioForm.get("biblioCategoryCode").patchValue(biblioCategoryCode);

    this.formElements = new FormElements();

    this.registrationFormService.getMatrixByCode(this.currentFormGroup, biblioCategoryCode)
      .subscribe(properties => {
        if (properties && properties.length > 0) {
          properties.forEach(property => {
            let prop: string = property.propertyName;
            prop = prop.charAt(0).toLowerCase() + prop.slice(1);
            this.formElements[prop] = {
              visible: property.isVisible,
              required: property.isRequired
            };
            if (property.isRequired && this.biblioForm.get(prop)) {
              if (prop === "yearOfPublishing") {
                this.biblioForm.get(prop).setValidators([Validators.required, Validators.min(1980), Validators.max(new Date().getFullYear() + 1)]);
              } else {
                this.biblioForm.get(prop).setValidators([Validators.required]);
              }
            }
          });
        }

        if (!this.isOpenedByAdmin) {
          this.meetingSection.valid = false;
          this.hostItemSection.valid = false;
          this.projectSection.valid = false;
          this.patentSection.valid = false;

          this.meetingSection.show = false;
          this.hostItemSection.show = false;
          this.projectSection.show = false;
          this.patentSection.show = false;
        }

        setTimeout(() => {
          this.meetingSection.show = biblioCategory.formGroups.find(g => g.code === this.currentFormGroup).showMeetingSection;
          this.hostItemSection.show = biblioCategory.formGroups.find(
            g => g.code === this.currentFormGroup
          ).showHostItemSection;
          this.projectSection.show = this.checkShowProjectSection(biblioCategory);
          this.patentSection.show = biblioCategory.formGroups.find(g => g.code === this.currentFormGroup).showPatentSection;

          if (this.patentSection.show) {
            this.codebooksService.get(CODEBOOK_ITEMS.patentTypes)
              .then(r => {
                this.patentTypeCodebook = r;
              });
          }

          this.checkSectionsValidity();
        }, 50);
      });
  }

  checkShowProjectSection(biblioCategory: BiblioCategoryCodebookWithFormGroupModel): boolean {
    let show = false;
    const showProjectSection = biblioCategory.formGroups.find(g => g.code === this.currentFormGroup).showProjectSection;
    if (showProjectSection) {
      if (this.formElements["project.Name"].visible) {
        show = true;
      }
    }
    return show;
  }

  filterCategories(formType: string, valueDirectly = false) {
    this.updateBiblioCategories();

    if (this.isInEditMode && this.editedTicket && valueDirectly) {
      const category = this.allBiblioCategories.filter(c => c.id === this.editedTicket.formBiblio.biblioCategory.id);
      if (category) {
        this.onBiblioCategoryChange({
          value: {
            code: category[0].code
          }
        });

        setTimeout(() => {
          this.biblioCategoryEl.selectedOption = { label: category[0].code, value: category[0] };
        }, 50);
      }
    }
  }

  disableAllFormElements() {
    Object.keys(this.formElements).forEach(key => {
      this.formElements[key] = false;
    });
  }

  async closeAndDisableAllSections() {
    Object.keys(this.formSections).forEach(key => {
      if (key !== "others") {
        this.formSections[key].opened = false;
        this.formSections[key].valid = false;
        this.formSections[key].disabled = true;
      }
    });
  }

  async checkSectionsValidity(ignoreSectionOpening = false) {
    if (!ignoreSectionOpening) {
      await this.closeAndDisableAllSections();

      delay(100);
    }

    this.checkFormAttachmentValidity();

    if (this.currentFormGroup && this.biblioForm.get("biblioCategoryId").value && this.selectedDoiOption) {
      if (this.selectedDoiOption === "doi" && !this.insertedDoiValue) {
        this.formSections.category.valid = false;
        return;
      }
      this.formSections.category.valid = true;
    } else {
      this.formSections.category.opened = true;
      return;
    }

    if (!ignoreSectionOpening) {
      setTimeout(() => {
        this.formSections.participants.disabled = false;
        this.formSections.participants.opened = true;
      }, 50);
    }

    if (this.ignorePercentage) {
      const countOfSubmittedParticipants = this.participants.filter(p => p.participationPercentage > 0);
      if (countOfSubmittedParticipants.length >= 5) {
        this.formSections.participants.valid = true;
      } else {
        this.formSections.participants.valid = false;
        this.formSections.participants.opened = true;
        return;
      }
    } else if ((this.maxPercentage === 0) && this.participants.length >= 1) {
      this.formSections.participants.valid = true;
    } else {
      this.formSections.participants.opened = true;
      return;
    }

    if (!ignoreSectionOpening) {
      setTimeout(() => {
        this.formSections.mySection.disabled = false;
        this.formSections.participants.opened = this.ignorePercentage ? true : false;
        this.formSections.mySection.opened = this.ignorePercentage ? false : true;

        if (
          this.meetingSection.show ||
          this.hostItemSection.show ||
          this.projectSection.show ||
          this.patentSection.show
        ) {
          this.formSections.hostSection.disabled = false;
        }
      }, 80);
    }

    this.checkFormValidity();
  }

  checkParams(requiredKeys: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      requiredKeys.forEach(key => {
        if (
          !this.biblioForm.controls[key] ||
          !this.biblioForm.controls[key].value ||
          this.biblioForm.controls[key].value === "" ||
          this.biblioForm.controls[key].value === " "
        ) {
          this.isFormValid = false;
          reject();
        }
      });
      resolve();
    });
  }

  keywordsChanged(words: string[]) {
    if (!words) {
      return;
    }
    let w = [];
    words.forEach(word => {
      const splitted = word.split(/[\\;,]+/);
      w = [...w, ...splitted];
    });

    if (w && w.length === 0) {
      w = null;
    }

    this.biblioForm.controls["keywords"].patchValue(w);

    this.keywords = w;
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.biblioForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  showListOfAdministrators(): void {
    this.projectAdministratorsListDialogComponent.show();
  }

  getBetterTextForFormElements(text: string): string {
    switch (text) {
      case "title":
        return this.translationService.getTranslation("Názov");
      case "biblioCategoryId":
        return this.translationService.getTranslation("Kategória biblio záznamu");
      case "biblioCategoryCode":
        return this.translationService.getTranslation("Kategória biblio záznamu");
      case "yearOfReporting":
        return this.translationService.getTranslation("Rok publikovania");
      case "restOfTitle":
        return this.translationService.getTranslation("Podnázov");
      case "languageId":
        return this.translationService.getTranslation("Jazyk");
      case "countryCodeIsoId":
        return this.translationService.getTranslation("Krajina");
      case "placeOfPublishing":
        return this.translationService.getTranslation("Miesto publikovania");
      case "nameOfPublisher":
        return this.translationService.getTranslation("Vydavateľ");
      case "yearOfPublishing":
        return this.translationService.getTranslation("Rok publikovania");
      case "group":
        return this.translationService.getTranslation("Skupina");
      case "issueNumber":
        return this.translationService.getTranslation("Číslo vydania");
      case "pageCount":
        return this.translationService.getTranslation("Počet strán");
      case "citationIndexCode":
        return this.translationService.getTranslation("Citačný index");
      case "isbn":
        return this.translationService.getTranslation("ISBN");
      case "issn":
        return this.translationService.getTranslation("ISSN");
      case "doi":
        return this.translationService.getTranslation("DOI");
      case "eIssn":
        return this.translationService.getTranslation("eISSN");
      case "uri":
        return this.translationService.getTranslation("URL");
      case "keywords":
        return this.translationService.getTranslation("Kľúčové slová");
      case "abstract":
        return this.translationService.getTranslation("Abstrakt");
      default:
        return text;
    }
  }

  checkIfIdentifiersAreValid(): boolean {
    let isValid = true;
    if (this.formElements.isbn.visible || this.formElements.issn.visible || this.formElements.eIssn.visible || this.formElements.doi.visible) {
      const isbn = this.hostItemSection.show ? this.hostItemSection.data.isbn : this.biblioForm.get("isbn").value;
      const issn = this.hostItemSection.show ? this.hostItemSection.data.issn : this.biblioForm.get("issn").value;
      const doi = this.hostItemSection.show ? this.hostItemSection.data.doi : this.biblioForm.get("doi").value;
      const eIssn = this.hostItemSection.show ? this.hostItemSection.data.eissn : this.biblioForm.get("eIssn").value;

      if ((isbn && isbn.length > 0) || (issn && issn.length > 0) || (eIssn && eIssn.length > 0) || (doi && doi.length > 0)) {
        isValid = true;
      } else {
        isValid = false;
      }
    }
    return isValid;
  }

  checkFormValidity() {
    this.formErrors = [];

    if (!this.biblioForm.valid) {
      this.formErrors.push(this.translationService.getTranslation("Formulár obsahuje chyby - nevyplnené všetky povinné polia."));
      const invalidValues = this.findInvalidControls();
      if (invalidValues && invalidValues.length > 0) {
        invalidValues.forEach(invalidValue => {
          this.formErrors.push(`${this.getBetterTextForFormElements(invalidValue)} - ${this.translationService.getTranslation("chybná hodnota")}`);
        });
      }
      if (this.meetingSection.show && !this.meetingSection.valid) {
        this.formErrors.push(this.translationService.getTranslation("Stretnutie / podujatie nevyplnené správne."));
      }
      if (this.patentSection.show && !this.patentSection.valid) {
        this.formErrors.push(this.translationService.getTranslation("Patent nevyplnený správne"));
      }
      if (this.hostItemSection.show && !this.hostItemSection.valid) {
        this.formErrors.push(this.translationService.getTranslation("Zdrojový dokument nevyplnený správne."));
      }
      if (this.projectSection.show && !this.projectSection.valid) {
        this.formErrors.push(this.translationService.getTranslation("Projekt nevyplnený správne."));
      }
      return;
    }

    this.isFormValid = false;

    if (!this.selectedResearchAreas || (this.selectedResearchAreas && this.selectedResearchAreas.length === 0)) {
      this.formErrors.push(this.translationService.getTranslation("Chýbajú vyplnené oblasti výskumu."));
      return;
    }

    if (!this.selectedFieldOfStudies || (this.selectedFieldOfStudies && this.selectedFieldOfStudies.length === 0)) {
      this.formErrors.push(this.translationService.getTranslation("Chýbajú vyplnené štúdijné programy."));
      return;
    }

    if (this.allLettersUppercase.title) {
      this.formErrors.push(this.translationService.getTranslation("Názov vyplnený len veľkými písmenami."));
      return;
    }

    console.log("1.form is valid");

    if (!this.formSections.attachments.valid || !this.formSections.others.valid) {
      console.log("!! attachments or others section not ok !!");
      this.formErrors.push(this.translationService.getTranslation("Prílohy alebo Žiadosť o prioritu obsahuje chyby."));
      return;
    }

    const identifiers = this.checkIfIdentifiersAreValid();
    if (!identifiers) {
      this.formErrors.push(this.translationService.getTranslation("Nieje vyplnený ani jeden identifikátor dokumentu"));
      return;
    }

    console.log("2.attachment ok");

    if (
      this.currentPriorityType &&
      this.currentPriorityType !== this.priorityTypes.no_priority &&
      (!this.prioritizedComment || this.prioritizedComment.length <= 1)
    ) {
      this.formErrors.push(this.translationService.getTranslation("Žiadosť o prioritu obsahuje chyby."));
      console.log("!! current priority type or comment not ok !!");
      return;
    }

    console.log("3.prioritized and priority ok");

    const requiredElements = {};

    Object.keys(this.formElements).forEach(el => {
      if (this.formElements[el].required) {
        requiredElements[el] = this.formElements[el];
      }
    });

    const requiredKeys = Object.keys(requiredElements).filter(k => {
      return (
        !k.includes("author") &&
        !k.includes("participants") &&
        !k.includes("keywords") &&
        !k.includes("hostItem") &&
        !k.includes("patent") &&
        !k.includes("meeting") &&
        !k.includes("isReviewed") &&
        !k.includes("publishingYears") &&
        !k.includes("researchAreaId")
      );
    });

    console.log("4.required elements ok?");

    this.checkParams(requiredKeys).then(
      v => {
        if (!this.participants || this.participants.length === 0 || (this.maxPercentage !== 0 && !this.ignorePercentage)) {
          this.isFormValid = false;
          this.formErrors.push(this.translationService.getTranslation("Chyba pri autoroch."));
          return;
        }
        console.log("2.participants ok");
        if (this.meetingSection.show && !this.meetingSection.valid) {
          this.isFormValid = false;
          this.formErrors.push(this.translationService.getTranslation("Chyba pri vyplnenom podujatí / konferencii."));
          console.log("!! meeting not ok !!");
          return;
        }
        console.log("2.meeting ok");
        if (this.hostItemSection.show && !this.hostItemSection.valid) {
          this.isFormValid = false;
          this.formErrors.push(this.translationService.getTranslation("Chyba pri vypĺňanom zdrojovom dokumente."));
          console.log("!! hostItem not ok !!");
          return;
        }
        console.log("2.hostitem ok");
        if (this.projectSection.show && !this.projectSection.valid) {
          this.isFormValid = false;
          this.formErrors.push(this.translationService.getTranslation("Chyba pri vyplnenom projekte."));
          console.log("!! projekt not ok !!");
          return;
        }
        console.log("2.projekt ok");
        if (this.patentSection.show && !this.patentSection.valid) {
          this.isFormValid = false;
          this.formErrors.push(this.translationService.getTranslation("Chyba pri vyplnenom patente."));
          console.log("!! patent not ok !!");
          return;
        }
        console.log("2.patent ok");
        if ((this.formElements["keywords.Description"] && this.biblioForm.get("keywords").value === "") || this.keywords.length === 0) {
          this.isFormValid = false;
          this.formErrors.push(this.translationService.getTranslation("Chyba pri kľúčových slovách."));
          console.log("!! keywords not ok !!");
          return;
        }
        console.log("2.keywords ok");
        if (this.selectedOaButton === 'oa') {
          if (!this.openAccessHostItemRequestModel || (this.openAccessHostItemRequestModel && !this.openAccessHostItemRequestModel.isValid)) {
            this.isFormValid = false;
            this.formErrors.push(this.translationService.getTranslation("Chyba pri vypĺňanom zdrojovom dokumente - sekcia Open Access."));
            return;
          }
        }
        if (this.openAccessBiblioRequestModel && !this.openAccessBiblioRequestModel.isValid) {
          this.isFormValid = false;
          this.formErrors.push(this.translationService.getTranslation("Chyba v Open Access sekcii."));
          return;
        }
        this.isFormValid = true;
        return;
      },
      e => {
        console.log("2.some error", e);
        this.isFormValid = false;
        return;
      }
    );
  }

  showPercentageAlert() {
    this.confirmationService.confirm({
      message: this.translationService.getTranslation(
        "Sú zadaní viacerí autori momentálne bez zadaného percentualného podielu. Súčasná hodnota percentuálneho podielu ktorú ste zadali je preto nesprávna (súčet všetkých percentuálnych podielov autorov musí byť rovný 100). Prosíme preto, zmeňte túto hodnotu na inú tak, aby bolo možné vyplniť percentuálny podiel aj ostatným autorom. V opačnom prípade nebude možné pokračovať vo vypĺňaní formulára ďalej."
      ),
      header: this.translationService.getTranslation("Upozornenie"),
      acceptLabel: this.translationService.getTranslation("Rozumiem"),
      rejectVisible: false,
      accept: () => {
      }
    });
  }

  // participants
  onSubmittedParticipant(participant: FormParticipantModel, index: number, autoFn = false) {

    if (this.ignorePercentage && !this.isInEditMode) {
      const participantCount = this.crossRefBiblio.author.length > 0 ? this.crossRefBiblio.author.length : this.crossRefBiblio.editor.length;
      const calculatedPercentage = Math.round((100 / participantCount) * 100 + Number.EPSILON) / 100;
      participant.participationPercentage = calculatedPercentage;
      this.maxPercentage = 0;
    }

    this.participants[index] = participant;

    const areThereSomeParticipantsWithoutPercentage = this.participants.filter(
      p => !p.participationPercentage || p.participationPercentage === 0
    );
    const testPercentage = this.maxPercentage - participant.participationPercentage;

    this.maxPercentage -= participant.participationPercentage;

    if (
      testPercentage === 0 &&
      areThereSomeParticipantsWithoutPercentage &&
      areThereSomeParticipantsWithoutPercentage.length > 0
    ) {
      this.showPercentageAlert();
      return;
    }

    if (this.participantsFromCrossref) {
      const author = this.crossRefBiblio.author
        .find(a => (a.given && a.given.toLowerCase() === participant.name.toLowerCase()) &&
          (a.family && a.family.toLowerCase() === participant.surname.toLowerCase()));

      if (author && author.ORCID) {
        const parsedOrcid = author.ORCID.match(/(\d{4}-){3}\d{4}/gm);
        if (parsedOrcid) {
          this.participants[index].orcid = parsedOrcid[0];
        }
      }
    }

    const isThereSomeUnfilledUser = this.participants.find(p => !p.fullName || p.fullName.length === 0);

    if (this.maxPercentage > 0 && !isThereSomeUnfilledUser) {
      this.participants.push(new FormParticipantModel());
    } else if (!isThereSomeUnfilledUser && this.ignorePercentage) {
      this.participants.push(new FormParticipantModel());
    }

    if (!autoFn) {
      this.checkResearchAreaOfParticipant("add", participant);

      this.checkFieldOfStudyOfParticipant("add", participant);
    }

    this.checkSectionsValidity();

    this.checkFormValidity();

    this.checkParticipantOrderNumbers();
  }

  onEditParticipant(index) {
    const isThereSomeUnfilledUser = this.participants.find(p => !p.fullName || p.fullName.length === 0);
    if (this.maxPercentage !== 0 && !this.participantsFromCrossref && isThereSomeUnfilledUser) {
      this.participants.splice(this.participants.length - 1, 1);
    }
    this.maxPercentage += this.participants[index].participationPercentage;

    this.checkParticipantOrderNumbers();
  }

  onDeleteParticipant(index) {
    this.checkResearchAreaOfParticipant("delete", this.participants[index]);

    this.checkFieldOfStudyOfParticipant("delete", this.participants[index]);


    this.maxPercentage += this.participants[index].participationPercentage ? this.participants[index].participationPercentage : 0;

    this.participants.splice(index, 1);

    const isThereAnyUnfilledParticipant = this.participants.find(p => !p.fullName);
    if (this.maxPercentage > 0 && !isThereAnyUnfilledParticipant) {
      this.participants.push(new FormParticipantModel());
    }

    this.checkParticipantOrderNumbers();

    this.checkSectionsValidity(!!isThereAnyUnfilledParticipant);
  }

  checkResearchAreaOfParticipant(type: "add" | "delete", participant: FormParticipantModel) {
    if (!participant.researchAreaId) {
      return;
    }

    const researchArea = this.allResearchAreas.find(r => r.id === participant.researchAreaId);

    if (researchArea) {

      const index = this.selectedResearchAreas.indexOf(researchArea);

      if (type === "add" && index === -1) {

        this.selectedResearchAreas.push(researchArea);

      } else {

        if (index !== -1 && type === "delete") {

          this.selectedResearchAreas.splice(index, 1);

        }

      }

      this.selectedResearchAreas = [...this.selectedResearchAreas];
    }
  }

  checkFieldOfStudyOfParticipant(type: "add" | "delete", participant: FormParticipantModel) {
    if (!participant.biblioFieldOfStudyId) {
      return;
    }

    const fieldOfStudy = this.allFieldOfStudies.find(r => r.id === participant.biblioFieldOfStudyId);
    if (fieldOfStudy) {
      const index = this.selectedFieldOfStudies.indexOf(fieldOfStudy);

      if (type === "add" && index === -1) {

        this.selectedFieldOfStudies.push(fieldOfStudy);

      } else {

        if (index !== -1 && type === "delete") {

          this.selectedFieldOfStudies.splice(index, 1);

        }

      }
      this.selectedFieldOfStudies = [...this.selectedFieldOfStudies];
    }
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.participants, event.previousIndex, event.currentIndex);
    this.notification.info(this.translationService.getTranslation("Poradie autorov bolo zmenené."));
    this.checkParticipantOrderNumbers();
  }

  moveParticipantUp(participant: FormParticipantModel, index: number) {
    if (index - 1 >= 0) {
      const p = this.participants.splice(index, 1)[0];
      this.participants.splice(index - 1, 0, p);

      this.notification.info(this.translationService.getTranslation("Poradie autorov bolo zmenené."));
      this.checkParticipantOrderNumbers();
    }
  }

  moveParticipantDown(participant: FormParticipantModel, index: number) {
    if (index + 1 <= this.participants.length) {
      const p = this.participants.splice(index, 1)[0];
      this.participants.splice(index + 1, 0, p);

      this.notification.info(this.translationService.getTranslation("Poradie autorov bolo zmenené."));
      this.checkParticipantOrderNumbers();
    }
  }

  checkParticipantOrderNumbers() {
    this.participants.forEach((participant, index) => {
      if (participant && participant.name && participant.surname && participant.orderNumber) {
        participant.orderNumber = index + 1;
      }
    });
  }

  resetForm() {
    this.biblioForm.reset();
    this.disableAllFormElements();

    setTimeout(() => {
      this.participants = [];
      this.participants.push(new FormParticipantModel());
      this.meetingSection.data = new FormMeetingModel();
      this.meetingSection.valid = false;
      this.hostItemSection.data = new FormHostItemMetadataModel();
      this.hostItemSection.valid = false;
      this.projectSection.data = [new FormProjectModel()];
      this.projectSection.valid = false;
      this.patentSection.data = new FormPatentModel();
      this.patentSection.valid = false;
      this.checkFormValidity();
      this.currentFormGroup = "";
      this.formSections.category.opened = true;
      this.formSections.participants.opened = false;
      this.formSections.mySection.opened = false;
      this.formSections.hostSection.opened = false;
      this.participantsFromCrossref = false;
      this.ignorePercentage = false;
    }, 200);
  }

  generateFormBiblioModel(): FormBiblioModel {
    const biblio = FormBiblioModel.createEmptyFormBiblioModel();

    if (!this.formElements) {
      return;
    }

    Object.keys(biblio).forEach(key => {
      if (!this.formElements[key] && key !== "keywords" && !key.includes("hostItem") && !key.includes("meeting")) {
        delete biblio[key];
      } else {
        if (this.biblioForm.get(key)) {
          if (this.problematicElements.find(v => v === key)) {
            const val = this.biblioForm.get(key).value;

            if (key === "citationIndexCode" && val) {
              if (this.biblioForm.get(key)) {
                this.biblioForm.get(key).patchValue(val.code);
              }
            } else if (val && val.id) {
              if (this.biblioForm.get(key)) {
                this.biblioForm.get(key).patchValue(val.id);
              }
            }
          }

          if (typeof biblio[key] === "number") {
            biblio[key] = Number.parseInt(this.biblioForm.get(key).value, 0);
          } else {
            biblio[key] = this.biblioForm.get(key).value;
          }
        }
      }
    });

    if (this.currentFormGroup) {
      biblio.group = this.currentFormGroup;
    }

    if (this.participants && this.doiSuccessfullyLoaded && this.crossRefBiblio) {
      let count = 0;
      if (this.crossRefBiblio.author && this.crossRefBiblio.author.length > 0) {
        count = this.crossRefBiblio.author.length;
      } else if (this.crossRefBiblio.editor && this.crossRefBiblio.editor.length > 0) {
        count = this.crossRefBiblio.editor.length;
      } else {
        count = this.participants.length;
      }

      if (count > this.PARTICIPANT_LIMIT_LENGTH) {
        biblio.participantCount = count;
      }
    }

    if (this.isInEditMode && this.editedTicket.formBiblio && this.editedTicket.formBiblio.participantCount && this.editedTicket.formBiblio.participantCount > this.PARTICIPANT_LIMIT_LENGTH) {
      biblio.participantCount = this.editedTicket.formBiblio.participantCount;
    }

    if (this.participants && this.participants.length > 1) {
      const author = this.participants[0];
      const others = this.participants.slice(1, this.participants.length).filter(p => p.name || p.fullName || p.surname);

      biblio.author = author;
      biblio.participants = others;
    } else {
      biblio.author = this.participants[0];
      biblio.participants = [];
    }

    if (this.participants.length === 0) {
      delete biblio.author;
      delete biblio.participants;
    }

    if (biblio.participants && biblio.participants.length > 0) {
      let orderNumber = 1;
      biblio.participants.filter(p => p.name || p.fullName || p.surname).forEach(p => {
        p.orderNumber = orderNumber;
        orderNumber++;
      });
    }

    if (this.hostItemSection.show) {
      biblio.hostItem = this.hostItemSection.data;

      if (this.openAccessHostItemRequestModel) {
        biblio.hostItem.openAccessHostItem = this.openAccessHostItemRequestModel;
      }

    } else {
      delete biblio.hostItem;
    }

    if (this.meetingSection.show && Object.keys(this.meetingSection.data).length > 0) {
      this.fixDateInMeetingDueToDatepicker();
      biblio.meeting = this.meetingSection.data;
      if (this.meetingSection.data && this.meetingSection.data.meetingStartDate) {
        biblio.meeting.meetingStartDate = moment(this.meetingSection.data.meetingStartDate, "DD.MM.YYYY").format("YYYY-MM-DD HH:mm:ss");
      }
      if (this.meetingSection.data && this.meetingSection.data.meetingEndDate) {
        biblio.meeting.meetingEndDate = moment(this.meetingSection.data.meetingEndDate, "DD.MM.YYYY").format("YYYY-MM-DD HH:mm:ss");
      }
    } else {
      delete biblio.meeting;
    }

    if (this.patentSection.show) {
      let hasSomeData = false;
      if (this.patentSection.data) {
        const keys = Object.keys(this.patentSection.data);
        if (keys && keys.length > 0) {
          keys.forEach(key => {
            if (this.patentSection.data[key]) {
              hasSomeData = true;
            }
          });
        }
      }

      if (hasSomeData) {
        biblio.patent = this.patentSection.data;
        if (this.patentSection.data.dateOfRegistration) {
          biblio.patent.dateOfRegistration = moment(this.patentSection.data.dateOfRegistration, "DD.MM.YYYY").format("YYYY-MM-DD HH:mm:ss");
        }
        if (this.patentSection.data.dateOfGrant) {
          biblio.patent.dateOfGrant = moment(this.patentSection.data.dateOfGrant, "DD.MM.YYYY").format("YYYY-MM-DD HH:mm:ss");
        }
      } else {
        delete biblio.patent;
      }
    } else {
      delete biblio.patent;
    }

    if (this.projectSection.show && this.projectSection.data && this.projectSection.data.length > 0) {
      biblio.projects = this.projectSection.data.filter(p => p.id);

      if (biblio.projects.length === 0) {
        delete biblio.projects;
      }

    } else {
      delete biblio.projects;
    }

    biblio.isReviewed = this.isReviewed;

    const nameOfPublisher = this.biblioForm.get("nameOfPublisher").value;

    if (nameOfPublisher) {
      if (nameOfPublisher && nameOfPublisher["description"]) {
        biblio.nameOfPublisher = nameOfPublisher["description"];
      } else {
        biblio.nameOfPublisher = nameOfPublisher;
      }

      if (this.isAGJForm) {
        biblio.patent.publisher = biblio.nameOfPublisher;
      }
    }

    if (this.formElements["keywords.Description"]) {
      let keywords: any[] = this.biblioForm.get("keywords").value;
      if (!Array.isArray(keywords)) {
        keywords = [];
      } else {
        keywords = keywords.map(k => {
          const keyword = new FormKeywordModel();
          keyword.description = k;
          return keyword;
        });
      }

      biblio.keywords = keywords;
    }

    if (this.selectedResearchAreas) {
      const ids = this.selectedResearchAreas.map(r => r.id);
      biblio.researchAreaIds = ids;
    }

    if (this.selectedFieldOfStudies) {
      const ids = this.selectedFieldOfStudies.map(r => r.id);
      biblio.biblioFieldOfStudyIds = ids;
    }

    // TODO dont know if it is right
    delete biblio.researchAreaId;

    const oa = this.openAccessBiblioRequestModel;

    if (oa && oa.payers.length > 0) {
      oa.payers.forEach(p => delete p['project']);
    }

    biblio.openAccessBiblio = oa;

    return biblio;
  }

  checkForm() {
    this.checkFormValidity();
    setTimeout(() => {
      this.ticketErrorModal.show(this.formErrors);
    }, 100);
  }

  async onSubmit(e: Event, sendToAcceptance = false, withoutRedirect = false) {
    e.preventDefault();

    if (!this.isFormValid && !withoutRedirect) {
      return;
    }

    this.isSubmittingForm = true;

    if (
      this.currentFormAttachmentType === this.formAttachments.file &&
      this.uploadComponent &&
      this.uploadComponent.hasFilesForUpload()
    ) {
      this.appCommunicationService.loadingOn(this.translationService.getTranslation("Nahrávam súbor."));

      this.uploadComponent.upload().then(
        (res: { fileId: number; fileName: string }) => {
          this.submitTicketForm(sendToAcceptance, res.fileId, withoutRedirect);
        }, (err) => {
          console.log(err);
          this.isSubmittingForm = false;
          this.appCommunicationService.loadingOff();
        }
      );

      return;
    }

    await this.submitTicketForm(sendToAcceptance, this.editedTicket && this.editedTicket.fileMetadataId ? this.editedTicket.fileMetadataId : null, withoutRedirect);
  }

  async submitTicketForm(sendToAcceptance = false, fileId?: number, withoutRedirect = false) {
    this.appCommunicationService.loadingOn(this.translationService.getTranslation("Nahrávam publikáciu."));

    const biblio = this.generateFormBiblioModel();

    let registration;

    const ticket = new TicketCreateRequestModel();

    ticket.type = TicketRequestType.biblio;
    ticket.formBiblio = biblio;
    ticket.sendToAcceptance = sendToAcceptance;
    ticket.priorityComment = this.prioritizedComment;
    ticket.comment = this.comment;
    ticket.priorityType = this.currentPriorityType;
    ticket.attachmentType = this.currentFormAttachmentType;
    ticket.fileMetadataId = fileId;

    if (this.isInEditMode) {
      if (this.editedTicket && this.editedTicket.id) {
        ticket.id = this.editedTicket.id;
      }

      if (this.editedTicket && this.editedTicket.isPrioritized !== null) {
        ticket.priorityType = this.editedTicket.priorityType;
      }

      if (this.isOpenedByAdmin && !this.allInputsLocked) {
        // edited by responsible person
        try {
          registration = await this.ticketService.updateTicketByResponsible(ticket).toPromise();
        } catch (e) {
          this.isSubmittingForm = false;
          console.log(e);
        }
      } else {
        // edited by owner
        try {
          registration = await this.ticketService.updateTicketByOwner(ticket).toPromise();
        } catch (e) {
          this.isSubmittingForm = false;
          console.log(e);
        }
      }

    } else {
      try {
        registration = await this.ticketService.createTicket(ticket).toPromise();
      } catch (e) {
        this.isSubmittingForm = false;
        console.log(e);
      }
    }

    if (registration) {
      this.isSubmittingForm = false;

      if (withoutRedirect) {
        this.notification.success(this.translationService.getTranslation("Publikácia bola upravená."));

        this.submittedFormEvent.emit(true);

        this.appCommunicationService.loadingOff();

        return;
      }

      if (sendToAcceptance) {
        this.notification.success(this.translationService.getTranslation("Publikácia bola úspešne odoslaná do systému."));

        this.resetForm();

        this.router.navigate(["/dashboard"]);
      }

      this.notification.success(this.translationService.getTranslation("Publikácia bola úspešne uložená ako koncept."));

      this.resetForm();

      this.router.navigate(["/dashboard"]);

      this.appCommunicationService.loadingOff();

      return;
    }

    this.isSubmittingForm = false;

    this.notification.error(this.translationService.getTranslation("Publikáciu sa nepodarilo odoslať."));

    this.appCommunicationService.loadingOff();

    return;
  }

  onDeletedFile(val) {
    if (val) {
      this.editedTicket.fileMetadataId = null;
      this.checkFormAttachmentValidity();
    }
  }

  fileChanged(isAdded: boolean) {
    this.checkFormAttachmentValidity();
  }

  fileUploaded(fileInfo: { fileId: number; fileName: string }) {
    if (fileInfo) {
      this.notification.success(this.translationService.getTranslation("Súbor bol úspešne nahraný do systému."));
    }
  }

  fileUploadError(e: boolean) {
    if (e) {
      this.notification.error(this.translationService.getTranslation("Nastala chyba počas nahrávania súboru."));
    }
  }

  getHostSectionTitle(): string {
    switch (this.currentFormGroup) {
      case REGISTRATION_FORM_GROUP_2022.partOfBook:
        return "Údaje o zdrojovom dokumente";
      case REGISTRATION_FORM_GROUP_2022.partOfJournal:
        return "Údaje o zdrojovom dokumente";
      case REGISTRATION_FORM_GROUP_2022.patent:
        return "Údaje o zdrojovom dokumente";
      case REGISTRATION_FORM_GROUP_2022.monounit:
        return "Údaje o monografii";
    }
  }

  getMySectionTitle(): string {
    switch (this.currentFormGroup) {
      case REGISTRATION_FORM_GROUP_2022.monounit:
        return "Údaje o publikácii";
      default:
        return "Údaje o príspevku / článku";
    }
  }

  isPhysicalDocument(attachment): boolean {
    if (!attachment) {
      return false;
    }
    return TicketAttachmentPhysicalDocument(attachment, this.formAttachments);
  }

  onTitleChange() {
    this.allLettersUppercase.title = false;

    let text = this.biblioForm.get("title").value || "";

    if (text && text.length > 0) {
      text = text.replace(/[^a-zA-Z]+/g, "");
    }

    const titleUpper = this.uppercaseCheck.checkWholeTextUppercase(text);

    if (titleUpper && this.biblioForm.get("title").value.length >= 3) {
      this.allLettersUppercase.title = true;
      this.uppercaseAlert(100);
    }

    this.checkFormValidity();

  }

  uppercaseAlert(uppercasePercentage) {
    this.notification.warning(`${this.translationService.getTranslation("Zaznamenali sme, že približne")} ${uppercasePercentage} ${this.translationService.getTranslation("% textu názvu práce je veľkými písmenami. Prosím zmeňte názov práce tak, aby obsahoval menej veľkých písmen.")}`);
  }

  openTutorialDialog() {
    this.dialogService
      .show(TutorialVideosDialogComponent)
      .then(result => {
        const dialog = result.componentRef.instance;
        dialog.initialize(YOUTUBE_URLS.publicationTutorialUrl, this.translationService.getTranslation("Návod na registrovanie publikácie"));
      });
  }


}
