import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BiblioModel } from '../../../../models/biblio/biblio.model';
import { ToastrService } from 'ngx-toastr';
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: 'app-citation',
  templateUrl: './citation.component.html',
  styleUrls: ['./citation.component.scss']
})
export class CitationComponent implements OnInit {
  biblio: BiblioModel = new BiblioModel();
  citations = [
    {
      name: 'MLA',
      text: ''
    },
    {
      name: 'APA',
      text: ''
    },
    {
      name: 'ISO 690',
      text: ''
    },
    {
      name: 'Bibtex',
      text: ''
    }
  ];

  afterClose: () => void;

  @ViewChild('lgModalDetail', {static: true}) public lgModal: ModalDirective;
  constructor(
    private toastr: ToastrService,
    private i18n: I18nService
  ) { }

  ngOnInit() {
  }

  show(biblio: BiblioModel, afterClose: () => void = null) {
    this.biblio = biblio;
    this.afterClose = afterClose;
    this.generateCitations();
    this.lgModal.show();
  }

  close() {
    if (this.afterClose) {
      this.afterClose();
    }
    this.lgModal.hide();
  }

  copyCitation(citation) {
    const selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = citation;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.citationCopiedMessage();
  }

  generateCitations() {
    this.citations[0].text = this.generateMLACitation();
    this.citations[1].text = this.generateAPACitation();
    this.citations[2].text = this.generateISOCitation();
    this.citations[3].text = this.generateBibtexCitation();
  }

  generateMLACitation() {
    return `
            ${this.biblio.publishings[0].fullName}. "${this.biblio.title}." (${new Date(this.biblio.yearOfPublishing).getFullYear()})
        `;
  }

  generateAPACitation() {
    return `
            ${this.biblio.publishings[0].fullName}. (${new Date(this.biblio.yearOfPublishing).getFullYear()}). ${this.biblio.title}.
        `;
  }

  generateISOCitation() {
    return `
            ${this.biblio.publishings[0].fullName.toUpperCase()}. ${this.biblio.title}. ${new Date(this.biblio.yearOfPublishing).getFullYear()}.
        `;
  }

  generateBibtexCitation() {
    return `
            @thesis{${this.biblio.publishings[0].fullName}${new Date(this.biblio.yearOfPublishing).getFullYear()},\n
                title={${this.biblio.title}},\n
                author={${this.biblio.publishings[0].fullName}},\n
                year={${new Date(this.biblio.yearOfPublishing).getFullYear()}}\n
            }
        `;
  }

  citationCopiedMessage() {
    this.toastr
      .info(this.i18n.getTranslation('Citácia bola úspešne skopírovaná.'), this.i18n.getTranslation('Citácia'));
  }


}
