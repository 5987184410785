import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { CodebooksService } from "../../services/codebooks.service";
import { TicketService } from "../../services/ticket.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CrossrefMetadataModel } from "../../models/crossref/crossref-metadata.model";
import { CrossRefService } from "../../services/cross-ref.service";
import { TicketFullModel } from "../../models/ticket/ticket-full.model";
import { AppCommunicationService } from "../../services/app-communication.service";
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";
import { ConfirmationService } from "primeng";
import { FormCitationModel } from "../../models/registration-form/form-citation.model";
import { CitationCategorySectionType } from "./sections/citation-category-section/citation-category-section.type";
import { TicketCreateRequestModel } from "../../models/requests/ticket-create-request.model";
import { TicketRequestType } from "../../types/ticket-request.type";
import * as moment from "moment";
import { BiblioService } from "../../services/biblio.service";
import { BiblioModel } from "../../models/biblio/biblio.model";
import { copyTextToClipboard } from "../../misc/copy-text-to-clipboard";
import { FileService } from "../../services/file.service";
import { getUploadFormAttachmentUrl } from "../../app.urls";
import { PersonsService } from "../../services/persons.service";
import { UserPublicationDataDialogComponent } from "../../components/user-publication-data-dialog/user-publication-data-dialog.component";
import { PersonValidityModel } from "../../models/person-validity.model";
import { BiblioDetailComponent } from "../../components/biblio-detail/biblio-detail.component";
import { YOUTUBE_URLS } from "../../constants";
import { OverlayService } from "../../components/overlay-dialog/overlay-service";
import { TutorialVideosDialogComponent } from "../../components/overlay-dialog/tutorial-videos-dialog/tutorial-videos-dialog.component";
import { checkIsDisabledPageForDate } from "../../misc/check-disabled-page-for-date";
import { PRODUCTION_BUILD } from "../../app.settings";

export enum QuotesPageTabs {
  citedPublication,
  category,
  quotingDocument,
  participants,
  attachments
}

@Component({
  selector: "smart-quotes-page",
  templateUrl: "./quotes-page.component.html",
  styleUrls: ["./quotes-page.component.scss"]
})
export class QuotesPageComponent implements OnInit {
  tabs = QuotesPageTabs;

  currentTab = QuotesPageTabs.citedPublication;

  tabsData: { [key: string]: { valid: boolean, data: any, disabled: boolean } } = {};

  dataFromCrossRef: CrossrefMetadataModel;

  quotingBiblio: BiblioModel;

  citedPublication: BiblioModel;

  citationForm: FormCitationModel = new FormCitationModel();

  ticket: TicketCreateRequestModel = new TicketCreateRequestModel();

  editedCitation: FormCitationModel;

  editedTicket: TicketFullModel;

  archiveId: number;

  isEditing = false;

  isFormValid = false;

  personValidity: PersonValidityModel;

  isSubmittingForm = false;

  @Input() editedTicketId: number = null;

  @Input() allInputsLocked = false;

  @Input() isOpenedByAdmin = false;

  @Output() submittedFormEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(UserPublicationDataDialogComponent, { static: false }) userPublicationDataDialogComponent: UserPublicationDataDialogComponent;

  @ViewChild(BiblioDetailComponent, { static: false }) biblioDetailComponent: BiblioDetailComponent;

  constructor(
    protected notification: ToastrService,
    protected codebooksService: CodebooksService,
    protected ticketService: TicketService,
    protected activatedRoute: ActivatedRoute,
    protected crossRef: CrossRefService,
    protected appCommunicationService: AppCommunicationService,
    protected translationService: I18nService,
    protected confirmationService: ConfirmationService,
    protected biblioService: BiblioService,
    protected router: Router,
    protected fileService: FileService,
    protected personsService: PersonsService,
    protected dialogService: OverlayService
  ) {
  }

  ngOnInit() {
    if (!this.isOpenedByAdmin && PRODUCTION_BUILD) {
      checkIsDisabledPageForDate(this.router, this.notification, this.appCommunicationService, true);
    }

    this.generateTabsData();

    this.activatedRoute
      .params
      .subscribe(v => {
        if (v && v.archiveId) {
          this.archiveId = v.archiveId;
        }

        if (v && v.id) {
          this.loadEditedQuote(v.id);
          this.isEditing = true;
        }
      });

    if (this.editedTicketId) {
      this.loadEditedQuote(this.editedTicketId);
      this.isEditing = true;
    }

    if (!this.isEditing) {
      this.checkIfUserIsAllowedToSystemEpc();
    }
  }

  async checkIfUserIsAllowedToSystemEpc() {
    const valid = await this.personsService.isValidForCurrentUser().toPromise();

    if (valid) {
      this.personValidity = valid;

      if (valid && !valid.isValid) {
        this.userPublicationDataDialogComponent.show(valid, false);
      }
    }
  }

  @HostListener("dblclick", ["$event"])
  onDoubleClickEl(e) {
    if (!this.editedTicketId || !this.allInputsLocked) {
      return;
    }
    let text: string;
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
      text = e.target.value;
    } else if (e.target instanceof HTMLSpanElement || e.target instanceof HTMLDivElement) {
      text = e.target.innerText;
    } else {
      console.log(e);
    }

    copyTextToClipboard(text);

    this.notification.success(this.translationService.getTranslation("Text skopírovaný do schránky."));
  }

  async loadEditedQuote(id: number) {
    if (id) {
      this.appCommunicationService.loadingOn(this.translationService.getTranslation("Načítavam údaje."));

      let ticket;

      if (this.isOpenedByAdmin) {
        ticket = await this.ticketService
          .getTicketById(id)
          .toPromise();
      } else {
        ticket = await this.ticketService
          .getTicketByIdForOwner(id)
          .toPromise();
      }

      if (ticket && ticket.formCitation) {
        Object.assign(this.citationForm, ticket.formCitation);

        this.editedTicket = ticket;

        this.editedCitation = ticket.formCitation;

        if (this.citationForm.biblioId) {
          const sourceBiblio = await this.biblioService.getById(this.citationForm.biblioId).toPromise();

          if (sourceBiblio) {
            this.archiveId = sourceBiblio.archiveId;
            this.citedPublication = sourceBiblio;
          }
        }

        if (this.citationForm.sourceId) {
          const citedBiblio = await this.biblioService.getById(this.citationForm.sourceId).toPromise();

          if (citedBiblio) {
            this.quotingBiblio = citedBiblio;
          }
        }

        setTimeout(() => {
          this.appCommunicationService.loadingOff();
        }, 250);

      } else {

        setTimeout(() => {
          this.appCommunicationService.loadingOff();
        }, 70);

      }

      this.recalculateOpenedSection();
    }
  }

  generateTabsData() {
    this.tabsData = {};
    Object.keys(this.tabs)
      .forEach(key => {
        this.tabsData[key] = { valid: false, data: {}, disabled: true };
      });

    setTimeout(() => {
      this.recalculateOpenedSection();
    }, 100);
  }

  async searchByDoi(doi: string) {
    this.appCommunicationService.loadingOn(this.translationService.getTranslation("Hľadám podľa DOI."));
    const crossRefData = await this.crossRef
      .getByDoi(doi).toPromise();

    if (crossRefData) {
      this.dataFromCrossRef = crossRefData;
      this.appCommunicationService.loadingOff();
      this.notification.success(this.translationService.getTranslation("DOI bolo úspešne nájdené."));
    } else {
      this.appCommunicationService.loadingOff();
      this.notification.error(
        this.translationService.getTranslation("Zadané DOI sa v systéme nenašlo. Prosíme, vyplňte údaje manuálne.")
      );
      this.dataFromCrossRef = new CrossrefMetadataModel();
    }
  }


  /* STEP 1 search archive ID */
  async searchCitedPublication(searchText: string) {
    if (searchText) {
      this.appCommunicationService.loadingOn(this.translationService.getTranslation("Hľadám citovaný dokument."));

      this.archiveId = Number.parseInt(searchText, 0);
      const queryDesigner = {
        "search": [],
        "page": 1,
        "pageSize": 10,
        "orderBy": [
          {
            "field": "CreatedDatetime",
            "order": "DESC"
          },
          {
            "field": "Id",
            "order": "DESC"
          }
        ]
      };

      queryDesigner["search"].push({
        "fields": [],
        "value": ""
      });
      queryDesigner["search"][0]["fields"] = ["archiveId"];
      queryDesigner["search"][0]["value"] = searchText;

      this.biblioService
        .getByArchiveId(this.archiveId)
        .subscribe(v => {
          if (v) {
            this.citedPublication = v;
            this.notification.success(this.translationService.getTranslation("Citovaný dokument bol úspešne nájdený."));
          } else {
            this.notification.error(this.translationService.getTranslation("Citovaný dokument sa nájsť nepodarilo."));
          }
          this.appCommunicationService.loadingOff();

        }, e => {
          console.log(e);
          this.appCommunicationService.loadingOff();
          this.notification.error(this.translationService.getTranslation("Citovaný dokument sa nájsť nepodarilo."));
        });
    } else {
      this.archiveId = null;
      this.citedPublication = null;
    }
  }

  /* STEP 2 citation category */
  async sectionCategoryChanged(sectionData: CitationCategorySectionType) {
    this.validitySectionChanged(sectionData, QuotesPageTabs.category);

    if (sectionData && sectionData.data && sectionData.data.category && sectionData.data.database) {
      this.citationForm.categoryId = sectionData.data.category.id;
      this.citationForm.databaseType = sectionData.data.database;

      this.citationForm = { ...this.citationForm };
    }

  }

  /* STEP 3 quoting document */
  async quotingDocumentChanged(formData: any) {
    this.validitySectionChanged(formData, QuotesPageTabs.quotingDocument);

    const data = formData.data;

    if (data) {
      Object.keys(data)
        .forEach(formKey => {
          if (data[formKey]) {
            this.citationForm[formKey] = data[formKey];
          }
        });
    }
  }

  /* STEP 4 participants */
  async participantsChanged(participantsData: any) {
    this.validitySectionChanged(participantsData, QuotesPageTabs.participants);

    const participants = participantsData.data;

    if (participants) {
      this.citationForm.persons = [];
      this.citationForm.persons = participants.filter(p => p.name || p.fullName);
    }
  }

  /* STEP 5 attachments */
  async attachmentsChanged(attachmentsData: any) {
    this.validitySectionChanged(attachmentsData, QuotesPageTabs.attachments);

    const attach = attachmentsData.data;

    if (attach) {
      this.ticket.attachmentType = attach.type;
      this.citationForm.uri = attach.uri;
    }
  }

  async searchQuotingBiblio(biblio: BiblioModel) {
    if (biblio) {
      this.appCommunicationService.loadingOn(this.translationService.getTranslation("Hľadám citujúci dokument."));

      const res = await this.biblioService.getById(biblio.id).toPromise();

      if (res) {
        this.quotingBiblio = res;
        this.appCommunicationService.loadingOff();
        this.notification.success(this.translationService.getTranslation("Citujúci dokument bol úspešne nájdený."));
        return;
      }

      this.appCommunicationService.loadingOff();
      this.notification.error(this.translationService.getTranslation("Citujúci dokument sa nájsť nepodarilo."));
    }
  }

  resetCrossrefData(e) {
    if (e) {
      this.dataFromCrossRef = null;
    }
  }

  resetQuotingDocument(e) {
    if (e) {
      this.quotingBiblio = null;

      if (this.isEditing) {
        if (this.editedTicket && this.editedTicket.formCitation && this.editedTicket.formCitation.sourceId) {
          this.editedTicket.formCitation.sourceId = null;
        }
      }
    }
  }

  showSourceDocumentDetail() {
    if (this.editedTicket.formCitation.biblioId) {
      this.biblioDetailComponent.show(null, this.editedTicket.formCitation.biblioId);
    }
  }


  showQuotingDocumentDetail() {
    if (this.editedTicket.formCitation.sourceId) {
      this.biblioDetailComponent.show(null, this.editedTicket.formCitation.sourceId);
    }
  }

  validitySectionChanged(change: { valid: boolean, data: any }, section: QuotesPageTabs) {
    this.tabsData[section].valid = change.valid;
    this.tabsData[section].data = change.data;

    setTimeout(() => {
      this.recalculateOpenedSection();
    }, 100);
  }

  recalculateOpenedSection() {
    let isValidForm = true;
    // first tab is always enabled
    this.tabsData[this.tabs.citedPublication].disabled = false;

    // if archiveId is fulfilled and citedPublication loaded, enable category section and maybe open it
    if (this.archiveId && this.citedPublication && this.tabsData[this.tabs.citedPublication].data && this.tabsData[this.tabs.citedPublication].valid) {
      this.tabsData[this.tabs.category].disabled = false;

      if (this.currentTab === this.tabs.citedPublication) {
        this.currentTab = this.tabs.category;
      }
    } else {
      this.tabsData[this.tabs.category].disabled = true;
      isValidForm = false;
    }

    if (this.tabsData[this.tabs.category].data && this.tabsData[this.tabs.category].valid) {
      this.tabsData[this.tabs.quotingDocument].disabled = false;

      if (this.currentTab === this.tabs.category) {
        this.currentTab = this.tabs.quotingDocument;
      }
    } else {
      this.tabsData[this.tabs.quotingDocument].disabled = true;
      isValidForm = false;
    }

    if (this.tabsData[this.tabs.quotingDocument].data && this.tabsData[this.tabs.quotingDocument].valid) {
      this.tabsData[this.tabs.participants].disabled = false;
    } else {
      this.tabsData[this.tabs.participants].disabled = true;
      isValidForm = false;
    }

    if (this.tabsData[this.tabs.participants].data && this.tabsData[this.tabs.participants].valid) {
      this.tabsData[this.tabs.attachments].disabled = false;
    } else {
      this.tabsData[this.tabs.attachments].disabled = true;
      isValidForm = false;
    }

    if (this.tabsData[this.tabs.attachments].data && this.tabsData[this.tabs.attachments].valid) {

    } else {
      isValidForm = false;
    }

    this.isFormValid = isValidForm;
  }

  async resetForm(withoutConfirmation = false) {

    if (withoutConfirmation) {

    } else {
      this.confirmationService.confirm({
        message: this.translationService.getTranslation("Skutočne si prajete vyplnené údaje vo formulári vymazať?"),
        header: this.translationService.getTranslation("Potvrdenie"),
        acceptLabel: this.translationService.getTranslation("Áno"),
        rejectLabel: this.translationService.getTranslation("Nie"),
        accept: () => {
          // TODO reset form functionality
        }
      });
    }
  }

  async onSubmit(e: Event, sendToAcceptance = false, withoutRedirect = false) {
    e.preventDefault();

    this.isSubmittingForm = true;

    if (
      this.tabsData[this.tabs.attachments].data && this.tabsData[this.tabs.attachments].data.file
    ) {
      this.appCommunicationService.loadingOn(this.translationService.getTranslation("Nahrávam súbor."));

      try {
        const upload = await this.fileService
          .upload(getUploadFormAttachmentUrl(), this.tabsData[this.tabs.attachments].data.file[0])
          .toPromise();

        if (upload) {

          if (upload.status === 200) {
            const fileId = upload.body.result.id;
            await this.submitForm(sendToAcceptance, fileId, withoutRedirect);
          }
        } else {
          this.isSubmittingForm = false;
          this.appCommunicationService.loadingOff();
        }
      } catch (e) {
        console.log(e);
        this.isSubmittingForm = false;
        this.appCommunicationService.loadingOff();
      }

      return;
    }

    await this.submitForm(sendToAcceptance, this.editedTicket && this.editedTicket.fileMetadataId ? this.editedTicket.fileMetadataId : null, withoutRedirect);
  }

  async submitForm(sendToAcceptance = false, fileId?: number, withoutRedirect = false) {
    this.ticket.type = TicketRequestType.citation;

    this.citationForm.yearOfPublishing = Number.parseInt(moment(this.citationForm.yearOfPublishing).format("YYYY"), 0);

    this.ticket.formCitation = this.citationForm;

    if (this.citedPublication && this.citedPublication.id) {
      this.ticket.formCitation.biblioId = this.citedPublication.id;
    }

    if (this.quotingBiblio && this.quotingBiblio.id) {
      this.ticket.formCitation.sourceId = this.quotingBiblio.id;
    }

    this.ticket.sendToAcceptance = sendToAcceptance;
    this.ticket.fileMetadataId = fileId;

    if (this.ticket.formCitation.pageCount && (typeof this.ticket.formCitation.pageCount === 'string')) {
      this.ticket.formCitation.pageCount = parseFloat( this.ticket.formCitation.pageCount.replace( /[^\d\.]*/g, ''));
    }

    if (this.isEditing) {

      this.ticket.id = this.editedTicket.id;

      if (!this.quotingBiblio) {
        this.ticket.formCitation.sourceId = null;
      }

      this.ticketService
        .updateTicketByOwner(this.ticket)
        .subscribe(v => {

          if (sendToAcceptance) {

            this.notification.success(this.translationService.getTranslation("Citácia bola úspešne odoslaná do systému."));

            this.router.navigate(["/dashboard"]);
            this.isSubmittingForm = false;
            this.appCommunicationService.loadingOff();

          } else {

            this.notification.success(this.translationService.getTranslation("Citácia bola úspešne upravená."));

            this.router.navigate(["/dashboard"]);
            this.isSubmittingForm = false;
            this.appCommunicationService.loadingOff();

          }

          this.resetForm(true);
        }, e => {
          console.log(e);

          this.notification.error(this.translationService.getTranslation("Citáciu sa nepodarilo upraviť."));
          this.isSubmittingForm = false;
          this.appCommunicationService.loadingOff();
        });

    } else {

      this.ticketService
        .createTicket(this.ticket)
        .subscribe(v => {

          if (sendToAcceptance) {

            this.notification.success(this.translationService.getTranslation("Citácia bola úspešne odoslaná do systému."));
            this.isSubmittingForm = false;
            this.router.navigate(["/dashboard"]);

            this.appCommunicationService.loadingOff();

          } else {

            this.notification.success(this.translationService.getTranslation("Citácia bola úspešne uložená ako koncept."));
            this.isSubmittingForm = false;
            this.router.navigate(["/dashboard"]);

            this.appCommunicationService.loadingOff();

          }

          this.resetForm(true);
        }, e => {
          console.log(e);

          this.notification.error(this.translationService.getTranslation("Citáciu sa nepodarilo odoslať."));
          this.isSubmittingForm = false;
          this.appCommunicationService.loadingOff();
        });

    }
  }

  openTutorialDialog() {
    this.dialogService
      .show(TutorialVideosDialogComponent)
      .then(result => {
        const dialog = result.componentRef.instance;
        dialog.initialize(YOUTUBE_URLS.citationTutorialUrl, this.translationService.getTranslation("Návod na registrovanie citácie"));
      });
  }

}
