import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { DwToolsService } from "../../../services/dw-tools.service";
import { first } from "rxjs/operators";
import { AppCommunicationService } from "../../../services/app-communication.service";
import { DwArticleIdentifier } from "../../../models/dwtools/dw-article-identifier";
import { copyTextToClipboard } from "../../../misc/copy-text-to-clipboard";
import { ToastrService } from "ngx-toastr";

export class SearchedIdentifierOutput {
  wosid: string;
  scopusid: string;
  cccid: string;
}

@Component({
  selector: "smart-article-identifiers-section",
  templateUrl: "./article-identifiers-section.component.html",
  styleUrls: ["./article-identifiers-section.component.scss"]
})
export class ArticleIdentifiersSectionComponent implements OnInit {

  article = "";

  searchResult: DwArticleIdentifier[] = [];

  hasError = false;

  showSearchInput = true;

  @Input() set articleTitle(value: string) {
    this.article = value;
    this.showSearchInput = false;
    this.submit();
  }

  @Output() searchedIdentifiers: EventEmitter<SearchedIdentifierOutput> = new EventEmitter<SearchedIdentifierOutput>();

  constructor(
    private dwToolsService: DwToolsService,
    private appCommunicationService: AppCommunicationService,
    protected toastrService: ToastrService,
  ) {
  }

  ngOnInit() {
  }

  copyValueToClipboard(text: string) {
    copyTextToClipboard(text);
    this.toastrService.success('Skopírované do schránky', 'OK');
  }

  submit() {
    this.appCommunicationService.loadingOn();

    this.searchResult = [];

    this.dwToolsService
      .searchArticleIdentifier(
        this.article
      )
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.searchResult = data;
          this.appCommunicationService.loadingOff();

          this.parseData();
        },
        error: (err) => {
          console.log(err);
          this.appCommunicationService.loadingOff();
        }
      });
  }

  parseData() {

    this.hasError = false;

    if (this.searchResult && this.searchResult.length > 0) {

      this.searchResult.forEach((item) => {

        item.identifiers = [];

        if (item.name && item.data.length > 0) {

          // if (item.data.length > 1) {
          //   this.hasError = true;
          // }

          item.data.forEach((dataItem) => {

            let regex = new RegExp(`eid=([^&]+)`);

            if (item.type === 'wos') {
              regex = new RegExp(`/${item.name}:(\\d+)`);
            }

            // dataItem is URL link, parse ID from URL link based on item.name
            const match = regex.exec(dataItem);

            let wosId = null;
            let scopusId = null;
            let cccId = null;

            if (match && match.length > 1) {

              if (item.name.toUpperCase() === "WOS") {
                wosId = match[1];
              } else if (item.name.toUpperCase() === "SCOPUS") {
                scopusId = match[1];
              } else if (item.name.toUpperCase() === "CCC") {
                cccId = match[1];
              }

              item.identifiers.push({
                url: dataItem,
                wosid: wosId,
                scopusid: scopusId,
                cccid: cccId
              });

            }


          });

          if (!this.hasError) {
            this.searchedIdentifiers.emit({
              wosid: item.identifiers[0].wosid,
              scopusid: item.identifiers[0].scopusid,
              cccid: item.identifiers[0].cccid
            });
          }

        }

      });

    } else {
      this.hasError = true;
    }

  }

}
