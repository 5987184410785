import { TicketSimpleModel } from "../../../models/ticket/ticket-simple.model";
import { TicketStatuses } from "../../../constants";
import { TicketAcceptanceModel } from "./ticket-acceptance.model";

export class SubmitTicketAcceptanceModel {
  ticket: TicketSimpleModel;
  ticketAccept: TicketAcceptanceModel;
  onlyComment: boolean;
  forceStatus: TicketStatuses;

  static create(): SubmitTicketAcceptanceModel {
    const t = new SubmitTicketAcceptanceModel();
    t.ticket = new TicketSimpleModel();
    t.ticketAccept = new TicketAcceptanceModel();
    t.onlyComment = false;
    t.forceStatus = null;
    return t;
  }
}
