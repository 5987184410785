import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "smart-search-ac",
  templateUrl: "./search-ac.component.html",
  styleUrls: ["./search-ac.component.scss"]
})
export class SearchACComponent implements OnInit {

  ac;

  @Output() onSubmit: EventEmitter<number> = new EventEmitter<number>();

  @Input() set setAC(ac: number) {
    this.ac = ac;
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.submit();
  }

  constructor() {
  }

  ngOnInit() {
  }

  submit() {
    if (this.ac.toString().length !== 6) return;

    this.onSubmit.emit(this.ac);
  }

}
