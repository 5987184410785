import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { TicketSimpleModel } from "../../../models/ticket/ticket-simple.model";

@Component({
  selector: 'smart-ticket-catalog-edit-modal',
  templateUrl: './ticket-edit-modal.component.html',
  styleUrls: ['./ticket-edit-modal.component.scss']
})
export class TicketEditModalComponent implements OnInit {
  ticket: TicketSimpleModel;

  allInputsLocked = false;

  @ViewChild("staticModal", { static: false }) modal: ModalDirective;

  constructor() { }

  ngOnInit() {
  }

  destroyValues() {
    this.ticket = null;
    this.allInputsLocked = false;
  }

  formSubmitted(v) {
    if (v) {
      this.close();
    }
  }

  show(ticket: TicketSimpleModel, allInputsLocked = false) {
    this.ticket = ticket;
    this.allInputsLocked = allInputsLocked;
    this.modal.show();
  }

  close() {
    this.destroyValues();
    this.modal.hide();
  }

}
