import { Component, OnInit, ViewChild } from "@angular/core";
import { UserPublicationDataDialogComponent } from '../../components/user-publication-data-dialog/user-publication-data-dialog.component';
import { PersonValidityModel } from "../../models/person-validity.model";

@Component({
  selector: 'smart-dev-test',
  templateUrl: './dev-test.component.html',
  styleUrls: ['./dev-test.component.scss']
})
export class DevTestComponent implements OnInit {

  @ViewChild(UserPublicationDataDialogComponent, {static: false}) UserPublicationDataDialogComponent: UserPublicationDataDialogComponent;

  constructor() { }

  ngOnInit() {
  }

  openUserPublicationDialog() {
    this.UserPublicationDataDialogComponent.show(new PersonValidityModel(), true, true);
  }

}
