import { NgModule } from "@angular/core";
import { BtnGroupFilterComponent } from "./btn-group-filter.component";
import { CommonModule } from "@angular/common";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";

@NgModule({
  imports: [
    CommonModule,
    I18nModule
  ],
  exports: [
    BtnGroupFilterComponent
  ],
  declarations: [
    BtnGroupFilterComponent
  ]
})

export class BtnGroupFilterModule {}
