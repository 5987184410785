import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { getAllBiblioByFilterUrl, getBiblioByArchiveIdUrl, getBiblioById } from "../app.urls";
import { map } from 'rxjs/operators';
import { BiblioModel } from '../models/biblio/biblio.model';

@Injectable({
  providedIn: 'root',
})
export class BiblioService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getAllByFilter(filter: any): Observable<{ totalItems: number, items: BiblioModel[] }> {
    return this.http
      .post(getAllBiblioByFilterUrl(), filter)
      .pipe(map((res: any) => {
        return {
          totalItems: res.result.totalItems,
          items: res.result.items as BiblioModel[],
        };
      }));
  }

  getById(biblioId: number): Observable<BiblioModel> {
    return this.http
      .get(getBiblioById(biblioId))
      .pipe(map((res: any) => {
        return res.result as BiblioModel;
      }));
  }

  getByArchiveId(archiveId: number): Observable<BiblioModel> {
    return this.http
      .get(getBiblioByArchiveIdUrl(archiveId))
      .pipe(map((res: any) => {
        return res.result as BiblioModel;
      }));
  }
}
