export class BreadcrumbModel {
  id?: number;
  breadcrumb: {
    title: string;
    data: any;
    filter?: any;
    type?: any;
  };

  static createEmpty(): BreadcrumbModel {
    return {
      breadcrumb: {
        title: '',
        data: {},
        type: {}
      }
    };
  }
}
