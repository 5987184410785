import { NgModule } from "@angular/core";
import { InlineLoaderComponent } from "./inline-loader.component";

@NgModule({
  exports: [
    InlineLoaderComponent
  ],
  declarations: [InlineLoaderComponent]
})

export class InlineLoaderModule {}
