export class PersonCreateUpdateRequestModel {
  id: number;
  name: string;
  surname: string;
  login: string;
  publonsId: string;
  orcid: string;
  birthYear: number;
  gender: string;
  biblioFieldOfStudyId: number;
  researchAreaId: number;

  static createEmpty(): PersonCreateUpdateRequestModel {
    const p = new PersonCreateUpdateRequestModel();
    p.name = '';
    p.surname = '';
    p.login = '';
    p.publonsId = '';
    p.orcid = '';
    p.birthYear = 0;

    return p;
  }
}
