import { OpenAccessHostItemRequestModel } from "../open-access/open-access-host-item-request.model";

export class FormHostItemMetadataModel {
  id?: number;
  biblioId?: number;
  crepcId?: number;
  isbn: string;
  issn: string;
  eissn: string;
  doi: string;
  title: string;
  subTitle: string;
  volume?: string;
  issueNumber?: string;
  pageRangeString: string;

  openAccessHostItem?: OpenAccessHostItemRequestModel;

  static createEmptyFormHostItemModel() {
    const f = new FormHostItemMetadataModel();
    f.title = '';
    f.subTitle = '';
    f.pageRangeString = '';
    return f;
  }
}
