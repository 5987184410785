import { Component, OnInit, ViewChild } from "@angular/core";
import { SystemUserModel } from "../../models/system-user.model";
import { UserService } from "../../services/user.service";
import { AppCommunicationService } from "../../services/app-communication.service";
import { PersonValidityModel } from "../../models/person-validity.model";
import { PersonsService } from "../../services/persons.service";
import { UserPublicationDataDialogComponent } from "../../components/user-publication-data-dialog/user-publication-data-dialog.component";

@Component({
  selector: "app-user-profile-view",
  templateUrl: "./user-profile-view.component.html",
  styleUrls: ["./user-profile-view.component.scss"]
})
export class UserProfileViewComponent implements OnInit {
  user: SystemUserModel;

  isImageLoading = false;

  userImage: any;

  personValidity: PersonValidityModel;

  signature = '';

  @ViewChild(UserPublicationDataDialogComponent, {static: false}) userPublicationDataDialogComponent: UserPublicationDataDialogComponent;

  constructor(
    private userService: UserService,
    protected personsService: PersonsService
  ) {
    // this.getImageFromService();
  }

  ngOnInit() {
    this.getLoggedUser();

    this.getUserBiblioPerson();
  }

  async getUserBiblioPerson() {
    const valid = await this.personsService.isValidForCurrentUser().toPromise();

    if (valid) {
      this.personValidity = valid;
    }
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.userImage = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getImageFromService() {
    this.isImageLoading = true;
    this.userService
      .getPhotoForUser(AppCommunicationService.getCurrentUser().user.photoOID)
      .subscribe(
      data => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      },
      error => {
        this.isImageLoading = false;
        console.log(error);
      }
    );
  }

  getLoggedUser() {
    this.user = AppCommunicationService.getCurrentUser().user;
  }

  changeOrcIdBtn(e: Event) {
    e.preventDefault();
    this.showPublisherDialog(this.personValidity);
  }

  changeResearchIdBtn(e: Event) {
    e.preventDefault();
    this.showResearcherDialog(this.personValidity);
  }

  showPublisherDialog(person: PersonValidityModel) {
    this.userPublicationDataDialogComponent.show(person, true, true);
  }

  showResearcherDialog(person: PersonValidityModel) {
    this.userPublicationDataDialogComponent.show(person, true, true);
  }

  generatePublisherSignature(e: Event) {
    e.preventDefault();
    this.signature = `
    https://epc.portal.tuke.sk/#/vystupy?surname=${this.user.surname}&name=${this.user.name}&citations=true&percent=true
    `.trim();
  }

  clickedSignature(e: Event) {
    e.preventDefault();
    window.open(this.signature, '_blank');
  }
}
