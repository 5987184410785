import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  getAllBiblioOldEpcUrl, getBiblioExportOldEpcUrl,
  getFindAuthorOldEpcUrl,
  getFindBiblioHostItemOldEpcUrl,
  getFindBiblioTitleOldEpcUrl,
  getFindDepartmentOldEpcUrl
} from "../app.urls";
import { map, tap } from "rxjs/operators";
import { OldEpcBiblioRequestModel } from "../models/old-epc/old-epc-biblio-request.model";
import { SearchQueryRequestModel } from "../models/search-query-request.model";
import { OldEpcBiblioExportRequestModel } from "../models/old-epc/old-epc-biblio-export-request.model";
import * as FileSaver from "file-saver";
import { StringUtils } from "../../core-frontend-common/utils/string-utils";

@Injectable({
  providedIn: 'root'
})
export class OldEpcService {

  constructor(
    protected http: HttpClient
  ) { }

  getAllBiblioOldEpc(requestModel: OldEpcBiblioRequestModel): Observable<any[]> {
    return this.http
      .post(getAllBiblioOldEpcUrl(), requestModel)
      .pipe(map((res: any) => res));
  }

  findAuthorOldEpc(searchRequest: SearchQueryRequestModel): Observable<string[]> {
    return this.http
      .post(getFindAuthorOldEpcUrl(), searchRequest)
      .pipe(map((res: any) => res));
  }

  findDepartmentOldEpc(requestModel: OldEpcBiblioRequestModel): Observable<any[]> {
    return this.http
      .post(getFindDepartmentOldEpcUrl(), requestModel)
      .pipe(map((res: any) => res.items));
  }

  findBiblioTitleOldEpc(searchRequest: SearchQueryRequestModel): Observable<any[]> {
    return this.http
      .post(getFindBiblioTitleOldEpcUrl(), searchRequest)
      .pipe(map((res: any) => res));
  }

  findBiblioHostItemOldEpc(searchRequest: SearchQueryRequestModel): Observable<any[]> {
    return this.http
      .post(getFindBiblioHostItemOldEpcUrl(), searchRequest)
      .pipe(map((res: any) => res));
  }

  biblioExportOldEpc(exportRequestModel: OldEpcBiblioExportRequestModel, fileName: string = null): Observable<any> {
    const httpOptions: object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'blob',
      observe: 'response'
    };

    return this.http
      .post(getBiblioExportOldEpcUrl(), exportRequestModel, httpOptions)
      .pipe(tap((response: any) => {
        const castResponse = response as HttpResponse<Blob>;
        FileSaver.saveAs(
          castResponse.body,
          !fileName ? this.extractFilename(castResponse.headers) : fileName
        );
      }));

  }

  extractFilename(headers: HttpHeaders): string {
    const header = headers.get('Content-Disposition');
    let filename = '';
    console.log(header)
    if (!StringUtils.isNullOrWhitespace(header)) {
      if (header && (header.indexOf('attachment') !== -1 || header.indexOf('inline') !== -1)) {
        const filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
        const matches = filenameRegex.exec(header);

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
    }

    return filename;
  }

}
