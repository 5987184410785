/* ### BASE URL ### */
import { StringUtils } from '../core-frontend-common/utils/string-utils';
import { PRODUCTION_BUILD, SYSTEM_NAME, PROD_BUILD_USE_HTTPS, DEV_BUILD_USE_HTTPS, DEVAPI_BUILD, LOCAL_BACKEND, LOCAL_AUTH } from './app.settings';
import {
  SYSTEM_NAME_ALFRED,
  SYSTEM_NAME_AUTH, SYSTEM_NAME_EMPTY,
  SYSTEM_NAME_EPC,
  SYSTEM_NAME_ETD, SYSTEM_NAME_HELPDESK,
  SYSTEM_NAME_ISBN,
  SYSTEM_NAME_LIBADMIN,
  SYSTEM_NAME_ROOMBOOK, SYSTEM_NAME_TOOLS, SYSTEM_NAME_UIK,
  SYSTEM_NAME_ZP
} from "./constants";

function getApiProtocol(): string {
  if (PRODUCTION_BUILD) {
      if (PROD_BUILD_USE_HTTPS) {
          return 'https';
      } else {
          return 'http';
      }
  }

  if (DEVAPI_BUILD) {
      if (DEV_BUILD_USE_HTTPS) {
          return 'https';
      } else {
          return 'http';
      }
  }

  return 'http';
}

function getClientProtocol(): string {
  if (PRODUCTION_BUILD) {
      if (PROD_BUILD_USE_HTTPS) {
          return 'https';
      } else {
          return 'http';
      }
  }

  if (DEVAPI_BUILD) {
      if (DEV_BUILD_USE_HTTPS) {
          return 'https';
      } else {
          return 'http';
      }
  }

  return 'http';
}

export const LOCAL_APP_PORTS = {
  [SYSTEM_NAME_EPC]: 0,
  [SYSTEM_NAME_ETD]: 3003,
  [SYSTEM_NAME_ISBN]: 3004,
  [SYSTEM_NAME_ROOMBOOK]: 3001,
  [SYSTEM_NAME_ZP]: 0,
  [SYSTEM_NAME_UIK]: 3002,
  [SYSTEM_NAME_HELPDESK]: 0,
  [SYSTEM_NAME_TOOLS]: 0,
  [SYSTEM_NAME_LIBADMIN]: 0,
  [SYSTEM_NAME_ALFRED]: 3003
};

export const LOCAL_HOST = 'localhost';         // local frontend host (default localhost)
export const LOCAL_FRONTEND_PORT = '3003';     // local frontend port
export const LOCAL_BACKEND_PORT = '51308';     // local API port

export const PROD_HOST = 'portal.tuke.sk';         // production hostname
export const PROD_HOST_PORT = '';                      // production host port (empty for default)
export const PROD_API_HOST = 'portal.tuke.sk';        // production API hostname
export const PROD_API_HOST_PORT = '';                  // production API host port (empty for default)
export const DEV_HOST = 'portal.dev.msweb.tuke.sk';      // development hostname
export const DEV_HOST_PORT = '';                       // development host port (empty for default)
export const DEV_API_HOST = 'portal.dev.msweb.tuke.sk';     // development API hostname
export const DEV_API_HOST_PORT = '';                   // development API host port (empty for default)

// local addresses (used for local development)
export const LOCAL_FRONTEND_URL = `${getClientProtocol()}://${LOCAL_HOST}:${LOCAL_FRONTEND_PORT}`;
const LOCAL_API_URL = `${getApiProtocol()}://${LOCAL_HOST}:${LOCAL_BACKEND_PORT}/api`;

// base URL for frontend (non-local)
export const BASE_FRONTEND_URL = `${getClientProtocol()}://${PRODUCTION_BUILD ?
    (PROD_HOST + (StringUtils.isNullOrEmpty(PROD_HOST_PORT) ? '' : (':' + PROD_HOST_PORT))) :
    (DEV_HOST + (StringUtils.isNullOrEmpty(DEV_HOST_PORT) ? '' : (':' + DEV_HOST_PORT)))}`;

export const LIBADMIN_URL = getBaseApiUrl(SYSTEM_NAME_LIBADMIN);

// constructs base URL for API
export function getBaseApiUrl(systemName?: string): string {
  if (PRODUCTION_BUILD) {
    return `${getApiProtocol()}://api${(systemName && systemName !== SYSTEM_NAME_EMPTY) ? ('.' + systemName) : ''}.${PROD_API_HOST}${
      StringUtils.isNullOrEmpty(PROD_API_HOST_PORT) ? '' : (':' + PROD_API_HOST_PORT)
    }/api`;
  } else if (LOCAL_BACKEND && !DEVAPI_BUILD) {
    return LOCAL_API_URL;
  }

  return `${getApiProtocol()}://api${(systemName && systemName !== SYSTEM_NAME_EMPTY) ? ('.' + systemName) : ''}.${DEV_API_HOST}${
    StringUtils.isNullOrEmpty(DEV_API_HOST_PORT) ? '' : (':' + DEV_API_HOST_PORT)
  }/api`;
}

export function getBaseFrontendUrl(systemName?: string): string {
    if (systemName === SYSTEM_NAME_AUTH) {
      return `${getClientProtocol()}://${PRODUCTION_BUILD ? PROD_HOST : DEV_HOST}${
        StringUtils.isNullOrEmpty(PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT) ? '' : (':' + (PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT))
      }`;
    }
    return `${getClientProtocol()}://${systemName}.${PRODUCTION_BUILD ? PROD_HOST : DEV_HOST}${
      StringUtils.isNullOrEmpty(PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT) ? '' : (':' + (PRODUCTION_BUILD ? PROD_HOST_PORT : DEV_HOST_PORT))
    }`;
}

// constructs base URL for API host without suffix
function getCurrentApiHost(): string {
    if (PRODUCTION_BUILD) {
        return `${getApiProtocol()}://${PROD_API_HOST}`;
    } else if (LOCAL_BACKEND) {
        return `${getApiProtocol()}://${LOCAL_HOST}:${LOCAL_BACKEND_PORT}`;
    }

    return `${getApiProtocol()}://${DEV_API_HOST}`;
}

// TODO temporary
export const BASE_URL = getBaseApiUrl(SYSTEM_NAME_EPC);
export const AUTH_URL = getBaseApiUrl(SYSTEM_NAME_EMPTY);

export const ADMIN_URL = getBaseApiUrl(SYSTEM_NAME_LIBADMIN);
export const CURRENT_API_HOST_URL = getCurrentApiHost();

const API_FACULTY_CODEBOOK = '/codebook/getFaculties';
const API_DEPARTMENT_CODEBOOK = '/codebook/getDepartments';

export function getAppConfigurationUrl() {
  return `${BASE_URL}/config/client`;
}

export function getSearchCollateFieldOfStudyUrl(phrase) {
  return `${BASE_URL}/codebook/searchCollateFieldOfStudy/${phrase}`;
}

export function getLanguagesUrl() {
  return `${BASE_URL}/codebook/getLanguages`;
}

/* ### USER URLS ### */
const API_GET_USER = '/user';
const API_GET_USER_HISTORICAL = '/user/getWithHistorical/';
const API_GET_USER_PHOTO = '/user/photo/';
const API_GET_SEARCHED_USER = '/user/getSearched';
const API_UPDATE_EXTERNAL_USER = '/user/updateExternalThesisUser/';
const API_SEARCH_USER_COLLATE = '/user/searchCollate/';

export function getFacultyCodebookUrl() {
  return BASE_URL + API_FACULTY_CODEBOOK;
}

export function getDepartmentCodebookUrl(facultyId) {
  return `${BASE_URL + API_DEPARTMENT_CODEBOOK}/${facultyId}`;
}

export function getHistoricalUserByIdUrl(id: number) {
  return BASE_URL + API_GET_USER_HISTORICAL + id;
}

export function getUserPhotoUrl(hash: string) {
  return BASE_URL + API_GET_USER_PHOTO + hash;
}

export function getSearchedUserUrl() {
  return BASE_URL + API_GET_SEARCHED_USER;
}

export function getUpdateExternalUserUrl(id) {
  return BASE_URL + API_UPDATE_EXTERNAL_USER + id;
}

export function getSearchUserCollateUrl(phrase) {
  return BASE_URL + API_SEARCH_USER_COLLATE + phrase;
}

export function getAllBiblioByFilterUrl() {
  return `${BASE_URL}/biblio/getAllByFilter`;
}

export function getBiblioById(id: number) {
  return `${BASE_URL}/biblio/getById/${id}`;
}

export function getBiblioByArchiveIdUrl(archiveId: number) {
  return `${BASE_URL}/biblio/getByArchiveId/${archiveId}`;
}

export function getAllMeetingsByFilterUrl() {
  return `${BASE_URL}/meeting/getAllByFilter`;
}

export function getMeetingById(id: number) {
  return `${BASE_URL}/meeting/getById/${id}`;
}

export function getAllPersonsByFilterUrl() {
  return `${BASE_URL}/person/getAllByFilter`;
}

export function getAllTukeAuthorsByFilterUrl() {
  return `${BASE_URL}/tukeAuthor/getAllByFilter`;
}

export function getPersonById(id: number) {
  return `${BASE_URL}/person/getById/${id}`;
}

export function getTicketStatusesConstantUrl() {
  return `${BASE_URL}/constant/ticketStatuses`;
}

export function getFormAttachmentTypesConstantUrl() {
  return `${BASE_URL}/constant/formAttachmentTypes`;
}

export function getPriorityTypeResourcesConstantUrl() {
  return `${BASE_URL}/constant/priorityTypeResources`;
}

export function getPatentPublishingSourcesUrl() {
  return `${BASE_URL}/constant/patentPublishingSources`;
}

export function getProjectInstitutionsUrl() {
  return `${BASE_URL}/constant/institutions`;
}

export function getAllBiblioCategoryCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllBiblioCategoryCodebook`;
}

export function getAllCitationCategoryCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllCitationCategoryCodebook/showInRegistrationForm`;
}

export function getAllCitationIndexCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllCitationIndexCodebook`;
}

export function getAllContentCharacteristicCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllContentCharacteristicCodebook`;
}

export function getAllCountryCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllCountryCodebook`;
}

export function getAllDocumentTypeCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllDocumentTypeCodebook`;
}

export function getAllLanguageCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllLanguageCodebook`;
}

export function getAllMediumCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllMediumCodebook`;
}

export function getAllPatentTypeCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllPatentTypeCodebook`;
}

export function getAllResearchAreaCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllResearchAreaCodebook`;
}

export function getAllBiblioFieldOfStudyCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllBiblioFieldOfStudyCodebook`;
}

export function getAllRoleCodebookUrl() {
  return `${BASE_URL}/codebook/GetAllRoleCodebook`;
}

export function getBiblioCategoryInGroupCodebookByGroupUrl(group: string) {
  return `${BASE_URL}/codebook/getAllBiblioCategoryCodebookWithFormGroups/${group}`;
}

export function getBiblioCategoryCodebookWithFormGroupsUrl() {
  return `${BASE_URL}/codebook/getAllBiblioCategoryCodebookWithFormGroups`;
}

export function getGrantCategoryTypesUrl() {
  return `${BASE_URL}/constant/grantCategoryTypes`;
}

export function getProjectResearchAreaUrl() {
  return `${BASE_URL}/codebook/getAllProjectResearchAreaCodebook`;
}

export function getAllResearchAndTechUrl() {
  return `${BASE_URL}/codebook/getAllResearchAndTechCodebook`;
}

export function getAllMatrixUrl() {
  return `${BASE_URL}/form/matrix/getAll`;
}

export function getMatrixByGroupUrl(group: string) {
  return `${BASE_URL}/form/matrix/getByGroup/${group}`;
}

export function getCreateRegistrationFormUrl() {
  return `${BASE_URL}/form/registration/create`;
}

export function getAllRegistrationFormByFilter() {
  return `${BASE_URL}/form/registration/getAllByFilter`;
}

export function getMatrixByCodeUrl(group: string, code: string) {
  return `${BASE_URL}/form/matrix/getByCode/${group}/${code}`;
}

export function getAllCitationMatrixUrl() {
  return `${BASE_URL}/form/citationMatrix/getAll`;
}

export function getCitationMatrixByGroupUrl(group: string) {
  return `${BASE_URL}/form/citationMatrix/getByGroup/${group}`;
}

export function getSearchParticipantsUrl() {
  return `${BASE_URL}/form/search/participant`;
}

export function getSearchedParticipantUrl() {
  return `${BASE_URL}/form/search/getSearchedParticipant`;
}

/*
* TICKET URL'S
* */

export function getAllTicketsByFilterUrl() {
  return `${BASE_URL}/ticket/getAllByFilter`;
}

export function getAllTicketsByArchiveIdUrl(archiveId: number) {
  return `${BASE_URL}/biblio/getIdByArchiveId/${archiveId}`;
}

export function getAllTicketsForOwnerByFilterUrl() {
  return `${BASE_URL}/ticket/getAllForOwnerByFilter`;
}

export function getAllTicketsForResponsibleByFilterUrl() {
  return `${BASE_URL}/ticket/getAllForResponsibleByFilter`;
}

export function getAllTicketsForAuthorByFilterUrl() {
  return `${BASE_URL}/ticket/getAllForAuthorByFilter`;
}

export function getTicketByIdUrl(id: number) {
  return `${BASE_URL}/ticket/get/${id}`;
}

export function getTicketByIdForOwnerUrl(id: number) {
  return `${BASE_URL}/ticket/getForOwner/${id}`;
}

export function getCreateTicketUrl() {
  return `${BASE_URL}/ticket/create`;
}

export function getUpdateTicketByOwnerUrl() {
  return `${BASE_URL}/ticket/updateByOwner`;
}

export function getUpdateTicketByResponsibleUrl() {
  return `${BASE_URL}/ticket/updateByResponsible`;
}

export function getDeleteTicketUrl(id: number) {
  return `${BASE_URL}/ticket/delete/${id}`;
}

export function getDeleteTicketWithCommentUrl() {
  return `${BASE_URL}/ticket/delete/withComment`;
}

export function getDeleteTicketByOwnerUrl(id: number) {
  return `${BASE_URL}/ticket/deleteByOwner/${id}`;
}

export function getTicketPriorityApprovementUrl() {
  return `${BASE_URL}/ticket/priorityApprovement`;
}

export function getTicketSetStatusUrl() {
  return `${BASE_URL}/ticket/setStatus`;
}

export function getTicketGoToUrl() {
  return `${BASE_URL}/ticket/goto`;
}

export function getTicketToContactUrl() {
  return `${BASE_URL}/ticket/toContact`;
}

export function getTicketContactEmailUrl() {
  return `${BASE_URL}/ticket/contactEmail`;
}

export function getTicketContactEmailBulkUrl() {
  return `${BASE_URL}/ticket/contactEmail/bulk`;
}

export function getUploadFormAttachmentUrl() {
  return `${BASE_URL}/file/upload/form/attachment`;
}

export function getDownloadFormAttachmentUrl(id: number) {
  return `${BASE_URL}/file/download/form/attachment/${id}`;
}

export function getDownloadFileUrl(id: number) {
  return `${BASE_URL}/file/download/${id}`;
}

export function getAllWorkflowUsersUrl() {
  return `${BASE_URL}/user/getAllWorkflowUsers`;
}

export function getSessionValidityCheckUrl() {
  return `${BASE_URL}/auth/validSession`;
}

export function getAllNotificationsForSystem(isForLoginScreen: boolean, systemName: string) {
  return LIBADMIN_URL + "/notification?isForLoginScreen=" + isForLoginScreen + "&application=" + systemName;
}

/* OLD EPC URLS */
export function getAllBiblioOldEpcUrl() {
  return `${BASE_URL}/oldEpc/biblio/getAll`;
}

export function getFindAuthorOldEpcUrl() {
  return `${BASE_URL}/oldEpc/biblio/findAuthor`;
}

export function getFindDepartmentOldEpcUrl() {
  return `${BASE_URL}/oldEpc/biblio/findDepartment`;
}

export function getFindBiblioTitleOldEpcUrl() {
  return `${BASE_URL}/oldEpc/biblio/findTitle`;
}

export function getFindBiblioHostItemOldEpcUrl() {
  return `${BASE_URL}/oldEpc/biblio/findHostItem`;
}

export function getBiblioExportOldEpcUrl() {
  return `${BASE_URL}/oldEpc/biblio/export`;
}

export function getFileInfoUrl(fileId: number) {
  return `${BASE_URL}/file/getInfo/${fileId}`;
}

export function getDeleteFileUrl(fileId: number) {
  return `${BASE_URL}/file/delete/${fileId}`;
}

export function getAllProjectsByFilterUrl() {
  return `${BASE_URL}/project/evidence/getAllByFilter`;
}

export function getAllProjectsForSupervisorByFilterUrl() {
  return `${BASE_URL}/project/evidence/getAllForSupervisorByFilter`;
}

export function getExportProjectsUrl() {
  return `${BASE_URL}/project/export`;
}

export function getSearchProjectsUrl() {
  return `${BASE_URL}/project/search`;
}

export function getProjectByIdUrl(id: number) {
  return `${BASE_URL}/project/getById/${id}`;
}

export function getAdminLockProjectUrl(id: number, isLocked: boolean) {
  return `${BASE_URL}/project/admin/lock/${id}/${isLocked}`;
}

export function getProjectEvidenceCreateUrl() {
  return `${BASE_URL}/project/evidence/create`;
}

export function getProjectEvidenceUpdateUrl() {
  return `${BASE_URL}/project/evidence/update`;
}

export function getProjectEvidenceDeleteUrl(id: number) {
  return `${BASE_URL}/project/evidence/delete/${id}`;
}

export function getTicketCommentUrl() {
  return `${BASE_URL}/ticket/setComment`;
}

export function getIsValidForCurrentUserUrl() {
  return `${BASE_URL}/person/isValidForCurrentUser`;
}

export function getCreateOrUpdatePersonUrl() {
  return `${BASE_URL}/person/createOrUpdate`;
}

export function getImportFromDwByIdUrl(id: number) {
  return `${BASE_URL}/ticket/importFromDw/id/${id}`;
}

export function getCheckOrcIdUrl(orcid: string) {
  return `${BASE_URL}/person/check/orcid/${orcid}`;
}

export function getDownloadDispatchNoteUrl(id: number) {
  return `${BASE_URL}/ticket/downloadDispachNote/${id}`;
}

export function getTukeAuthorEmploymentsByLoginUrl(login: string) {
  return `${BASE_URL}/tukeAuthor/getAllByLogin/${login}`;
}

export function getTukeAuthorChangeSearchedParticipantUrl() {
  return `${BASE_URL}/form/search/changeSearchedParticipant`;
}

export function getBiblioImportFromDwUrl(archiveId: number, save = false): string {
  return `${BASE_URL}/dwtools/biblio/importFromDw/${archiveId}/${save}`;
}

export function getBiblioFromDwUrl(archiveId: number): string {
  return `${BASE_URL}/dwtools/biblio/get/${archiveId}`;
}

export function getBiblioCrepcMetadataUrl(archiveId: number): string {
  return `${BASE_URL}/dwtools/biblio/queue/crepcMetadata/${archiveId}`;
}

export function getBiblioUpdateDatabaseUrl(): string {
  return `${BASE_URL}/dwtools/biblio/update/database`;
}

export function getBiblioUpdateQuartilUrl(): string {
  return `${BASE_URL}/dwtools/biblio/update/quartil`;
}

export function getBiblioDeleteQuartilsUrl(archiveId: number): string {
  return `${BASE_URL}/dwtools/biblio/delete/quartil/${archiveId}`;
}

export function getImportProjectVegaUrl(): string {
  return `${BASE_URL}/file/import/project/vega`;
}

export function getImportProjectKegaUrl(): string {
  return `${BASE_URL}/file/import/project/kega`;
}

export function getOpenAccessLicencesUrl(): string {
  return `${BASE_URL}/constant/openAccess/licences`;
}

export function getOpenAccessTypesUrl(): string {
  return `${BASE_URL}/constant/openAccess/types`;
}

export function getPersonsByRoleUrl(role: string) {
  return `${getBaseApiUrl(SYSTEM_NAME_AUTH)}/public/GetPersonsByRole?role=${role}`;
} 
