import { LanguageCodebookModel } from "../codebook/language-codebook.model";
import { CountryCodebookModel } from "../codebook/country-codebook.model";
import { BiblioCategoryCodebookModel } from "../codebook/biblio-category-codebook.model";
import { FormAuthorModel } from "./form-author.model";
import { FormHostItemMetadataModel } from "./form-host-item-metadata.model";
import { FormMeetingModel } from "./form-meeting.model";
import { FormOtherIdentifierMetadataModel } from "./form-other-identifier-metadata.model";
import { FormParticipantModel } from "./form-participant.model";
import { FormPublishingYearMetadataModel } from "./form-publishing-year-metadata.model";
import { FormKeywordModel } from "./form-keyword.model";
import { FormProjectModel } from "./form-project.model";
import { FormPatentModel } from "./form-patent.model";
import { ResearchAreaCodebookModel } from "../codebook/research-area-codebook.model";
import { BiblioFieldOfStudyCodebookModel } from "../codebook/biblio-field-of-study-codebook.model";
import { OpenAccessBiblioRequestModel } from "../open-access/open-access-biblio-request.model";

export class FormBiblioModel {
  id: number;
  group: string;
  isbn: string;
  issn: string;
  doi: string;
  languageId: number;
  language?: LanguageCodebookModel;
  countryCodeIsoId: number;
  countryCodeIso?: CountryCodebookModel;
  title: string;
  restOfTitle: string;
  issueNumber: string;
  placeOfPublishing: string;
  nameOfPublisher: string;
  pageCount: number;
  uri: string;
  citationIndexCode: string;
  biblioCategoryId: number;
  biblioCategory?: BiblioCategoryCodebookModel;
  yearOfPublishing?: number;
  abstract?: string;


  archiveId?: number;

  isReviewed?: boolean;
  researchAreaId?: number;

  participants: FormParticipantModel[];
  participantCount?: number;

  author: FormAuthorModel;
  authorId?: number;

  meeting: FormMeetingModel;
  meetingId?: number;

  hostItem: FormHostItemMetadataModel;
  hostItemId?: number;

  keywords: FormKeywordModel[];

  projects: FormProjectModel[];

  patent: FormPatentModel;

  biblioFieldOfStudyIds: number[] = [];
  researchAreaIds: number[] = [];

  researchAreas?: ResearchAreaCodebookModel[] = [];
  biblioFieldOfStudies?: BiblioFieldOfStudyCodebookModel[] = [];

  openAccessBiblio: OpenAccessBiblioRequestModel;

  static createEmptyFormBiblioModel(): FormBiblioModel {
    const biblio = new FormBiblioModel();
    biblio.group = "";
    biblio.isbn = "";
    biblio.issn = "";
    biblio.doi = "";
    biblio.abstract = "";

    biblio.languageId = 0;
    biblio.countryCodeIsoId = 0;
    biblio.title = "";
    biblio.restOfTitle = "";
    biblio.issueNumber = "";
    biblio.placeOfPublishing = "";
    biblio.nameOfPublisher = "";
    biblio.pageCount = 0;
    biblio.uri = "";
    biblio.citationIndexCode = "";
    biblio.biblioCategoryId = 0;
    biblio.yearOfPublishing = 0;
    biblio.isReviewed = false;
    biblio.researchAreaId = 0;
    biblio.author = new FormAuthorModel();
    biblio.meeting = FormMeetingModel.createEmptyMeeting();
    biblio.hostItem = FormHostItemMetadataModel.createEmptyFormHostItemModel();
    biblio.patent = FormPatentModel.createEmptyFormPatentModel();
    biblio.participants = [];
    biblio.keywords = [];
    biblio.projects = [];
    return biblio;
  }
}


