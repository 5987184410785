import { DISABLE_REGISTRATION_DATE } from "../app.settings";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppCommunicationService } from "../services/app-communication.service";

export function checkIsDisabledPageForDate(
  router: Router | null,
  notification: ToastrService | null,
  appCommunicationService: AppCommunicationService | null,
  autoblockAccess = false
): boolean {
  // const disableDate = DISABLE_REGISTRATION_DATE;
  // const today = new Date();
  // if (today.toLocaleDateString() >= disableDate.toLocaleDateString()) {
  //   if (autoblockAccess) {
  //     router.navigate(['/dashboard']);
  //     notification.info('Registrácia a úprava nových publikácií a citácií je z dôvodu zmien kategórií dočasne pozastavená.');
  //     appCommunicationService.loadingOff();
  //   }
  //   console.log('blocked registration')
  //   return true;
  // }
  return false;
}
