import { Component, OnInit, ViewChild } from "@angular/core";
import { BiblioDetailComponent } from "../../components/biblio-detail/biblio-detail.component";
import { BiblioCatalogActionsEnum } from "./types/biblio-catalog-actions.enum";
import { CitationComponent } from "../catalog/components/citation/citation.component";
import { BiblioCatalogSharedService, BiblioSharedData } from "./biblio-catalog-shared.service";
import { CatalogViewType } from "./types/catalog-view-type";
import { Subject } from "rxjs";
import { FacultyModel } from "../../models/faculty.model";
import { CODEBOOK_ITEMS, CodebooksService } from "../../services/codebooks.service";
import { BreadcrumbsService } from "../../components/breadcrumbs/breadcrumbs.service";
import { BreadcrumbModel } from "../../components/breadcrumbs/breadcrumb.model";

@Component({
  selector: 'smart-biblio-catalog-view',
  templateUrl: './biblio-catalog-view.component.html',
  styleUrls: ['./biblio-catalog-view.component.scss']
})
export class BiblioCatalogViewComponent implements OnInit {

  currentView: CatalogViewType = CatalogViewType.biblio;

  catalogViewTypes = CatalogViewType;

  dataChanged: Subject<any> = new Subject<any>();

  currentData: BiblioSharedData = new BiblioSharedData();

  faculties: FacultyModel[] = [];

  @ViewChild(BiblioDetailComponent, {static: false}) biblioDetail: BiblioDetailComponent;

  @ViewChild(CitationComponent, { static: false }) citationModal: CitationComponent;

  constructor(
    protected catalogDataService: BiblioCatalogSharedService,
    protected breadcrumbsService: BreadcrumbsService,
    protected codebookService: CodebooksService
  ) { }

  ngOnInit() {
    const breadcrumbs = this.breadcrumbsService.getFromStorage();

    if (breadcrumbs && breadcrumbs.length > 0) {
      const currentBreadcrumb = this.breadcrumbsService.getLast();
      if (currentBreadcrumb.breadcrumb) {
        console.log(currentBreadcrumb)
        this.catalogDataService.data = currentBreadcrumb.breadcrumb.data;
        this.doAction(currentBreadcrumb.breadcrumb.type, false);
      }
    } else {
      this.currentView = CatalogViewType.biblio;
    }

    this.breadcrumbsService
      .clickedBreadcrumb
      .subscribe(() => {
        setTimeout(() => {
          const currentBreadcrumb = this.breadcrumbsService.getLast();

          if (!currentBreadcrumb) {
            this.currentView = this.catalogViewTypes.biblio;
          } else {
            if (currentBreadcrumb.breadcrumb) {
              this.catalogDataService.data = currentBreadcrumb.breadcrumb.data;
              this.doAction(currentBreadcrumb.breadcrumb.type, false);
            }
          }
        }, 50);
      });

    this.catalogDataService
      .actionChange
      .subscribe((action: BiblioCatalogActionsEnum) => {
        this.doAction(action);
      });

    this.loadCodebooks();
  }

  async loadCodebooks() {
    const data = await this.codebookService.get(CODEBOOK_ITEMS.faculties);

    if (data && data.items) {
      this.faculties = data.items;
    }
  }

  doAction(action: BiblioCatalogActionsEnum, addBreadcrumb = true) {
    const biblio = this.catalogDataService.data.biblio;

    switch (action) {
      case BiblioCatalogActionsEnum.biblioDetail:
        this.biblioDetail.show(null, biblio.id, biblio);
        break;
      case BiblioCatalogActionsEnum.citationDetail:
        this.citationModal.show(biblio, null);
        break;
      case BiblioCatalogActionsEnum.clickedAuthor:
        if (this.catalogDataService.data.author !== this.currentData.author && this.currentView === CatalogViewType.authorDetail) {
          this.dataChanged.next(this.catalogDataService.data.author);
        }
        this.currentData.author = this.catalogDataService.data.author;
        if (addBreadcrumb) {
          this.addBreadcrumb(this.currentData.author.fullName, BiblioCatalogActionsEnum.clickedAuthor);
        }
        this.currentView = CatalogViewType.authorDetail;
        break;
      case BiblioCatalogActionsEnum.clickedKeyword:
        if (this.catalogDataService.data.keyword !== this.currentData.keyword && this.currentView === CatalogViewType.keywordDetail) {
          this.dataChanged.next(this.catalogDataService.data.keyword);
        }
        this.currentData.keyword = this.catalogDataService.data.keyword;
        if (addBreadcrumb) {
          this.addBreadcrumb(this.currentData.keyword.description, BiblioCatalogActionsEnum.clickedKeyword);
        }
        this.currentView = CatalogViewType.keywordDetail;
        break;
      case BiblioCatalogActionsEnum.clickedCitations:
        if (this.catalogDataService.data.citations !== this.currentData.citations && this.currentView === CatalogViewType.biblio) {
          this.dataChanged.next(this.catalogDataService.data.citations);
        }
        this.currentData.citations = this.catalogDataService.data.citations;
        this.currentData.biblio = this.catalogDataService.data.biblio;
        if (addBreadcrumb) {
          this.addBreadcrumb('(Cit) ' + this.catalogDataService.data.biblio.title, BiblioCatalogActionsEnum.clickedCitations);
        }
        this.currentView = CatalogViewType.citationsDetail;
        break;
    }
  }

  addBreadcrumb(title = '', type: BiblioCatalogActionsEnum) {
    const breadcrumb = BreadcrumbModel.createEmpty();
    breadcrumb.breadcrumb.title = title;
    breadcrumb.breadcrumb.data = Object.assign({}, this.currentData);
    breadcrumb.breadcrumb.type = type;

    this.breadcrumbsService
      .push(breadcrumb, true);
  }

}
