import { Component, OnInit } from "@angular/core";
import { DwToolsService } from "../../../services/dw-tools.service";
import { first } from "rxjs/operators";
import { checkDwToolsErrorResponse } from "../dw-tools-errors";
import { BaseEpc2Component } from "../base-epc2.component";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-epc2-dw-section",
  templateUrl: "./epc2-dw-section.component.html",
  styleUrls: ["./epc2-dw-section.component.scss"]
})
export class Epc2DwSectionComponent extends BaseEpc2Component implements OnInit {

  stateOptions = [
  ];

  save = this.stateOptions[1];

  constructor(
    private dwTools: DwToolsService,
    private i18n: I18nService
  ) {
    super();
  }

  ngOnInit() {

    this.generateStateOptions();
  }

  generateStateOptions() {
    this.stateOptions = [
      {
        name: this.i18n.getTranslation('Uložiť'),
        value: true
      },
      {
        name: this.i18n.getTranslation('Neuložiť'),
        value: false
      }
    ];

    this.save = this.stateOptions[1];
  }

  onSubmit(ac: number) {
    this.ac = ac;

    if (this.ac.toString().length !== 6) {
      return;
    }

    this.dwTools
      .biblioImportFromDw(this.ac, this.save.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.generateSuccessMessage();
        },
        error: (err) => {
          const message = checkDwToolsErrorResponse(err);

          if (message) {
            this.showingErrorMessage(message);
          }
        }
      });
  }

  generateSuccessMessage() {
    this.showingSuccessMessage("DW_TO_BIBLIO_MAP_SUCCESS");
  }

}
