import { TicketStatuses } from "../../constants";
import { generateTicketActions, TicketActions } from "./misc/ticket-actions";
import { TicketSimpleModel } from "../../models/ticket/ticket-simple.model";

export class TicketExtendedModel {
  ticket: TicketSimpleModel;
  currentStatus: number;
  nextStatus: number;
  backStatus: number[];
  actions: TicketActions;
}

export function getExtendedTicket(ticket: TicketSimpleModel): TicketExtendedModel {
  const t = new TicketExtendedModel();
  t.ticket = ticket;
  t.currentStatus = ticket.currentStatus;

  let ticketActions = new TicketActions();

  switch (ticket.currentStatus) {
    case TicketStatuses.sent_to_acceptance:
      t.nextStatus = TicketStatuses.in_acceptance;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, true, false, true, false, true, false, false, false, false);
      break;
    case TicketStatuses.in_acceptance:
      t.nextStatus = null;
      t.backStatus = [TicketStatuses.sent_to_acceptance];
      ticketActions = generateTicketActions(true, false, true, true, false, true, true, true, true, false, true, false, false, false, true);
      break;
    case TicketStatuses.to_scan:
      t.nextStatus = TicketStatuses.in_scan;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, true, false, true, false, false, false, false, false, false);
      break;
    case TicketStatuses.in_scan:
      t.nextStatus = TicketStatuses.scanned;
      t.backStatus = [TicketStatuses.to_scan];
      ticketActions = generateTicketActions(true, false, true, true, false, true, true, true, false, true);
      break;
    case TicketStatuses.to_conversion:
      t.nextStatus = TicketStatuses.in_conversion;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, true, false, true, false, false, false, false, false, false);
      break;
    case TicketStatuses.in_conversion:
      t.nextStatus = TicketStatuses.converted;
      t.backStatus = [TicketStatuses.to_conversion];
      ticketActions = generateTicketActions(true, false, true, true, false, true, true, true, false, true);
      break;
    case TicketStatuses.scanned:
      t.nextStatus = TicketStatuses.in_validation;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, true, false, true, false, false, false, false, false, false);
      break;
    case TicketStatuses.converted:
      t.nextStatus = TicketStatuses.in_validation;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, true, false, true, false, false, false, false, false, false);
      break;
    case TicketStatuses.in_validation:
      t.nextStatus = TicketStatuses.to_catalogize;
      t.backStatus = [TicketStatuses.scanned, TicketStatuses.converted];
      ticketActions = generateTicketActions(true, false, true, true, false, true, true, true, false, true);
      break;
    case TicketStatuses.to_catalogize:
      t.nextStatus = TicketStatuses.in_catalogization;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, true, false, true, false, false, false, false, false, false);
      break;
    case TicketStatuses.in_catalogization:
      t.nextStatus = TicketStatuses.sent_to_crepc;
      t.backStatus = [TicketStatuses.to_catalogize];
      ticketActions = generateTicketActions(true, false, true, true, false, true, true, true, false, true);
      break;
    case TicketStatuses.sent_to_crepc:
      t.nextStatus = TicketStatuses.in_dawinci_check;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, true, false, true, false, false, false, false, false, false);
      break;
    case TicketStatuses.imported_to_dawinci:
      t.nextStatus = TicketStatuses.in_dawinci_check;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, true, false, true, false, false, false, false, false, true);
      break;
    case TicketStatuses.in_dawinci_check:
      t.nextStatus = null;
      t.backStatus = [TicketStatuses.imported_to_dawinci, TicketStatuses.sent_to_crepc];
      ticketActions = generateTicketActions(true, false, true, true, false, true, true, true, false, true, false, false, true, true);
      break;
    case TicketStatuses.to_contact:
      t.nextStatus = TicketStatuses.in_contact;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, true, false, true, false, false, false, false, false, false);
      break;
    case TicketStatuses.in_contact:
      t.nextStatus = null;
      t.backStatus = [TicketStatuses.to_contact];
      ticketActions = generateTicketActions(true, false, true, true, false, true, false, false, false, true, true);
      break;
    case TicketStatuses.to_hand_over:
      t.nextStatus = TicketStatuses.in_hand_over_to_author;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, true, false, true, false, false, false, false, false, false, false, false, false, true);
      break;
    case TicketStatuses.in_hand_over_to_author:
      t.nextStatus = TicketStatuses.finished;
      t.backStatus = [TicketStatuses.to_hand_over];
      ticketActions = generateTicketActions(true, false, true, true, false, false, true, true, false, false, false, false, false, true);
      break;
    case TicketStatuses.finished:
      t.nextStatus = null;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, false, false, true, false, false, false, false, false, false, false, false, true, true);
      break;
    default:
      t.nextStatus = null;
      t.backStatus = null;
      ticketActions = generateTicketActions(true, false, false, true, false, false, false, false, false, false);
      break;
  }

  t.actions = ticketActions;

  return t;
}
