import { OpenAccessBiblioModel } from "./open-access-biblio.model";
import { FormProjectModel } from "../registration-form/form-project.model";

export class OpenAccessPayerModel {
  openAccessBiblioId: number;
  openAccessBiblio: OpenAccessBiblioModel;
  name: string;
  acpFees?: number;
  otherFees?: number;
  feeSourceInfo: string;
  projectId?: number;
  project: FormProjectModel;

  isValid?: boolean;
}
