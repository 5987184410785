import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { TicketSimpleModel } from "../../../models/ticket/ticket-simple.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TicketStatuses } from "../../../constants";
import { TicketAcceptanceModel } from "./ticket-acceptance.model";
import { UserService } from "../../../services/user.service";
import { UserModel } from "../../../models/user.model";
import { GotoTicketAcceptanceModel } from "./goto-ticket-acceptance.model";
import { SubmitTicketAcceptanceModel } from "./submit-ticket-acceptance.model";
import { TicketModalSubformComponent } from "../components/ticket-modal-subform/ticket-modal-subform.component";
import { TicketCommentRequestModel } from "../../../models/requests/ticket-comment-request.model";
import { TicketService } from "../../../services/ticket.service";
import { ToastrService } from "ngx-toastr";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";

export enum TicketAcceptanceModalTypes {
  acceptance,
  goTo,
  comment,
  basic,
  delete
}

@Component({
  selector: "smart-ticket-catalog-acceptance-modal",
  templateUrl: "./ticket-acceptance-modal.component.html",
  styleUrls: ["./ticket-acceptance-modal.component.scss"]
})
export class TicketAcceptanceModalComponent implements OnInit {
  ticketAcceptanceForm: FormGroup;

  ticket: TicketSimpleModel;

  modalTypes = TicketAcceptanceModalTypes;

  currentModalType: TicketAcceptanceModalTypes;

  forcedStatus = null;

  workflowUsers: UserModel[] = [];

  ticketStatuses = [
    { label: "acceptance", value: TicketStatuses.in_acceptance },
    { label: "scan", value: TicketStatuses.in_scan },
    { label: "conversion", value: TicketStatuses.in_conversion },
    { label: "validation", value: TicketStatuses.in_validation },
    { label: "cataloging", value: TicketStatuses.in_catalogization },
    { label: "handOver", value: TicketStatuses.in_hand_over_to_author }
  ];

  @ViewChild("staticModal", { static: false }) modal: ModalDirective;

  @Output() submitActionEvent: EventEmitter<SubmitTicketAcceptanceModel> = new EventEmitter<SubmitTicketAcceptanceModel>();

  @Output() submitGoToEvent: EventEmitter<GotoTicketAcceptanceModel> = new EventEmitter<GotoTicketAcceptanceModel>();

  @Output() submitCommentEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(TicketModalSubformComponent, { static: false }) ticketSubform: TicketModalSubformComponent;

  constructor(
    private formBuilder: FormBuilder,
    protected userService: UserService,
    protected ticketService: TicketService,
    protected toastr: ToastrService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
  }

  loadWorkflowUsers() {
    this.userService
      .getAllWorkflowUsers()
      .subscribe(v => {
        this.workflowUsers = [];
        this.workflowUsers = this.workflowUsers.concat(v);
      });
  }

  mapTicketStatus(ticketStatus: TicketStatuses): TicketStatuses {
    switch (ticketStatus) {
      case TicketStatuses.sent_to_acceptance:
        return TicketStatuses.in_acceptance;
      case TicketStatuses.in_acceptance:
        return TicketStatuses.in_acceptance;
      case TicketStatuses.to_scan:
        return TicketStatuses.in_scan;
      case TicketStatuses.in_scan:
        return TicketStatuses.in_scan;
      case TicketStatuses.to_conversion:
        return TicketStatuses.in_conversion;
      case TicketStatuses.in_conversion:
        return TicketStatuses.in_conversion;
      case TicketStatuses.in_validation:
        return TicketStatuses.in_validation;
      case TicketStatuses.to_catalogize:
        return TicketStatuses.in_catalogization;
      case TicketStatuses.in_catalogization:
        return TicketStatuses.in_catalogization;
      case TicketStatuses.to_hand_over:
        return TicketStatuses.in_hand_over_to_author;
      case TicketStatuses.in_hand_over_to_author:
        return TicketStatuses.in_hand_over_to_author;
    }
  }

  generateForm() {
    if (this.currentModalType === TicketAcceptanceModalTypes.goTo) {
      this.ticketAcceptanceForm = this.formBuilder.group({
        archiveId: [{ value: this.ticket.archiveId, disabled: true }],
        title: [{ value: this.ticket.title, disabled: true }],
        year: [{ value: this.ticket.yearOfPublishing, disabled: true }],
        categoryCode: [{ value: this.ticket.categoryCode, disabled: true }],
        authorFullName: [{ value: this.ticket.createdBy.name + " " + this.ticket.createdBy.surname, disabled: true }],
        status: [TicketStatuses.sent_to_acceptance, [Validators.required]],
        workflowUserId: ["", [Validators.required]],
        comment: [""]
      });
      return;
    } else {
      this.ticketAcceptanceForm = this.formBuilder.group({
        archiveId: [{ value: this.ticket.archiveId, disabled: true }],
        title: [{ value: this.ticket.title, disabled: true }],
        year: [{ value: this.ticket.yearOfPublishing, disabled: true }],
        categoryCode: [{ value: this.ticket.categoryCode, disabled: true }],
        authorFullName: [{ value: this.ticket.createdBy.name + " " + this.ticket.createdBy.surname, disabled: true }],
        comment: [""]
      });
    }
  }

  openModal(ticket: TicketSimpleModel, type: TicketAcceptanceModalTypes = TicketAcceptanceModalTypes.basic, forcedStatus: TicketStatuses = null) {
    this.ticket = { ...ticket };
    this.currentModalType = type;
    this.forcedStatus = forcedStatus;
    this.generateForm();
    this.modal.show();
  }

  openGoToModal(ticket: TicketSimpleModel) {
    this.ticket = ticket;
    this.loadWorkflowUsers();
    this.currentModalType = TicketAcceptanceModalTypes.goTo;
    this.generateForm();
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  submitAction() {

    if (this.currentModalType === TicketAcceptanceModalTypes.delete) {
      const req = new TicketCommentRequestModel();
      req.ticketId = this.ticket.id;
      req.comment = this.ticketAcceptanceForm.get("comment").value;

      if (!req.comment || req.comment.length === 0) {
        this.toastr.error("Komentár je povinný.");
        return;
      }
      this.ticketService
        .deleteTicketWithComment(req)
        .subscribe(() => {
          this.toastr.success(this.i18n.getTranslation("Tiket bol úspešne zmazaný"));
          this.close();
        }, () => {
          this.toastr.error(this.i18n.getTranslation("Tiket sa zmazať nepodarilo."));
        });
      this.submitCommentEvent.emit(true);
      return;
    }

    if (this.currentModalType === TicketAcceptanceModalTypes.comment) {
      const req = new TicketCommentRequestModel();
      req.ticketId = this.ticket.id;
      req.comment = this.ticketAcceptanceForm.get("comment").value;
      this.ticketService
        .ticketComment(req)
        .subscribe(v => {
          this.close();
          this.submitCommentEvent.emit(true);
        });
      return;
    }

    if (this.currentModalType === TicketAcceptanceModalTypes.goTo) {
      const user = this.ticketAcceptanceForm.get("workflowUserId").value;
      const goToTicket = GotoTicketAcceptanceModel.create();
      goToTicket.ticket = this.ticket;
      goToTicket.comment = this.ticketAcceptanceForm.get("comment").value;
      if (user && user.id !== 0) {
        const status = this.mapTicketStatus(this.ticketAcceptanceForm.get("status").value);
        goToTicket.workflowUserId = user.id;
        if (status) {
          goToTicket.status = status;
          this.submitGoToEvent.emit(goToTicket);
          this.close();
          return;
        }
      }
      return;
    }

    if (this.ticketAcceptanceForm.valid) {
      const accept = TicketAcceptanceModel.createEmptyModel();
      const submitTicket = new SubmitTicketAcceptanceModel();
      let subform;
      if (this.currentModalType === TicketAcceptanceModalTypes.acceptance) {
        subform = this.ticketSubform.getSubform();
      }
      Object.keys(accept)
        .forEach(key => {
          if (this.ticketAcceptanceForm.get(key) && this.ticketAcceptanceForm.get(key).value) {
            accept[key] = this.ticketAcceptanceForm.get(key).value;
          }
          if (!this.ticketAcceptanceForm.get(key) && !(this.currentModalType === TicketAcceptanceModalTypes.basic || this.currentModalType === TicketAcceptanceModalTypes.comment) && subform && subform.get(key) && subform.get(key).value) {
            accept[key] = subform.get(key).value;
          }
        });
      submitTicket.ticket = this.ticket;
      submitTicket.ticketAccept = accept;
      submitTicket.onlyComment = (this.currentModalType === TicketAcceptanceModalTypes.basic);
      submitTicket.forceStatus = this.forcedStatus;
      this.submitActionEvent.emit(submitTicket);
      this.close();
    }
  }

  closeAction() {
    this.close();
  }

}
