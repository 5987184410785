import { Component, OnInit, ViewChild } from "@angular/core";
import { NAV_TABS, NavTab, NavTabsComponent } from "./components/nav-tabs/nav-tabs.component";
import { BiblioPersonModel } from "../../models/biblio/biblio-person.model";
import { FilterComponent } from "./components/filter/filter.component";
import { BiblioTableComponent } from "./components/biblio-table/biblio-table.component";
import { PersonTableComponent } from "./components/person-table/person-table.component";
import { MeetingTableComponent } from "./components/meeting-table/meeting-table.component";
import { BiblioDetailComponent } from "../../components/biblio-detail/biblio-detail.component";
import { BiblioModel } from "../../models/biblio/biblio.model";
import { BreadcrumbsService } from "../../components/breadcrumbs/breadcrumbs.service";
import { FilterService } from "./components/filter/filter.service";

@Component({
  selector: "app-catalog",
  templateUrl: "./catalog.component.html",
  styleUrls: ["./catalog.component.scss"]
})
export class CatalogComponent implements OnInit {
  activeTab: NavTab = NAV_TABS[0];
  isPersonDetailActive = false;
  personDetail: BiblioPersonModel;
  filter: any;

  @ViewChild(FilterComponent, { static: false }) filterComponent: FilterComponent;
  @ViewChild(NavTabsComponent, { static: false }) navTabs: NavTabsComponent;
  @ViewChild(BiblioTableComponent, { static: false }) biblioTableComponent: BiblioTableComponent;
  @ViewChild(PersonTableComponent, { static: false }) personTableComponent: PersonTableComponent;
  @ViewChild(MeetingTableComponent, { static: false }) meetingTableComponent: MeetingTableComponent;
  @ViewChild(BiblioDetailComponent, { static: false }) biblioDetail: BiblioDetailComponent;

  constructor(
    protected breadcrumbsService: BreadcrumbsService,
    protected filterService: FilterService
  ) {

  }

  ngOnInit() {
    this.breadcrumbsService
      .breadcrumbsChanged
      .subscribe(() => {
        const currentBreadcrumb = this.breadcrumbsService.getLast();
        if (currentBreadcrumb && currentBreadcrumb.breadcrumb.filter) {
          this.filterService.setFilter(currentBreadcrumb.breadcrumb.filter);
        }
      });
  }

  onChangeNavTabs(tab: NavTab) {
    this.activeTab = tab;
    this.refreshData();
  }

  filterChanged(filter) {
    this.filter = filter;
    this.refreshData();
  }

  refreshData() {
    switch (this.activeTab.title) {
      case "biblio":
        setTimeout(() => {
          this.biblioTableComponent.getBiblio(this.filter);
        }, 200);
        return;
      case "person":
        setTimeout(() => {
          this.personTableComponent.getPersons(this.filter);
        }, 200);
        return;
      case "meeting":
        setTimeout(() => {
          this.meetingTableComponent.getMeetings(this.filter);
        }, 200);
        return;
    }
  }

  onPersonSelected(person: BiblioPersonModel) {
    this.onChangeNavTabs(NAV_TABS.filter(t => t.href === "person")[0]);
    this.navTabs.selectTab(this.navTabs.TABS.filter(t => t.href === "person")[0]);
    this.isPersonDetailActive = true;
    this.personDetail = person;
    this.filterComponent.changeFilter({
      text: person.fullName
    });
  }

  onBiblioDetail(biblio) {
    this.biblioDetail.show(null, biblio.id);
  }

  citationBiblioClicked(biblio: BiblioModel, fromModal = false) {
    if (biblio) {

      if (fromModal) {
        this.biblioDetail.close();
        this.biblioTableComponent.clickedCitation(
          biblio,
          fromModal ? () => this.onBiblioDetail(biblio) : null
        );
      } else {
        this.biblioTableComponent.clickedCitation(
          biblio,
          fromModal ? () => this.onBiblioDetail(biblio) : null
        );
      }
    }
  }

  downloadBiblioClicked(biblio: BiblioModel) {
    if (biblio) {
      this.biblioTableComponent.downloadClick();
    }
  }

}
