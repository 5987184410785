import { NgModule } from "@angular/core";
import { AuthorTukeEmploymentDialogComponent } from "./author-tuke-employment-dialog.component";
import { ModalModule } from "ngx-bootstrap";
import { CommonModule, DatePipe } from "@angular/common";
import { AccordionModule } from "primeng";
import { CustomPipesModule } from "../../../../pipes/customPipes.module";
import { I18nModule } from "../../../../../core-frontend-common/template/shared/i18n/i18n.module";

@NgModule({
  imports: [
    ModalModule,
    CommonModule,
    AccordionModule,
    CustomPipesModule,
    I18nModule
  ],
  exports: [
    AuthorTukeEmploymentDialogComponent
  ],
  declarations: [
    AuthorTukeEmploymentDialogComponent,
  ],
  providers: [
    DatePipe
  ]
})

export class AuthorTukeEmploymentDialogModule {}
