import { NgModule } from "@angular/core";
import { TicketEditModalComponent } from "./ticket-edit-modal.component";
import { ModalModule } from "ngx-bootstrap";
import { TicketRegistrationModule } from "../../ticket-registration/ticket-registration.module";
import { QuotesPageModule } from "../../quotes-page/quotes-page.module";
import { I18nModule } from "../../../../core-frontend-common/template/shared/i18n/i18n.module";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    ModalModule,
    TicketRegistrationModule,
    QuotesPageModule,
    I18nModule,
    CommonModule
  ],
  exports: [
    TicketEditModalComponent
  ],
  declarations: [
    TicketEditModalComponent
  ]
})

export class TicketEditModalModule {}
