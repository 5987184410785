import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { getUploadFormAttachmentUrl } from "../../../../app.urls";
import { FileService } from "../../../../services/file.service";
import { FileUpload } from "primeng";
import { MAX_FILESIZE_ATTACHMENT_UPLOAD_TICKET_REGISTRATION } from "../../../../constants";

@Component({
  selector: "smart-attachments",
  templateUrl: "./attachments.component.html",
  styleUrls: ["./attachments.component.scss"]
})
export class AttachmentsComponent implements OnInit {
  // size in bit
  MAX_FILE_SIZE = MAX_FILESIZE_ATTACHMENT_UPLOAD_TICKET_REGISTRATION;

  uploadedFiles: any[] = [];

  @Output() fileUploaded: EventEmitter<{ fileId: number, fileName: string }> = new EventEmitter<{ fileId: number; fileName: string }>();

  @Output() fileUploadError: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() fileChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() allowUpload: boolean;

  @ViewChild("upload", { static: false }) uploadEl: FileUpload;

  constructor(
    protected fileService: FileService
  ) {
  }

  ngOnInit() {
  }

  onRemovedFile(e) {
    this.fileChange.emit(false);
  }

  onSelectedFile(e) {
    this.uploadedFiles = [];

    if (e && e.currentFiles && e.currentFiles.length > 0) {
      this.uploadedFiles = e.currentFiles;
    } else {
      this.uploadedFiles = [];
    }
    this.fileChange.emit(true);
  }

  onUpload(e) {
  }

  async upload(): Promise<any> {
    this.uploadedFiles = [];
    if (typeof this.uploadEl.files === "object") {
      this.uploadedFiles = this.uploadEl.files;
    } else {
      // @ts-ignore
      for (const file of this.uploadEl.files) {
        this.uploadedFiles.push(file);
      }
    }

    if (!this.uploadedFiles || this.uploadedFiles.length === 0) {
      return;
    }

    try {
      const upload = await this.fileService.upload(this.getUploadUrl(), this.uploadedFiles[0]).toPromise();

      if (upload) {
        if (upload.status === 200) {
          const fileId = upload.body.result.id;
          const fileName = upload.body.result.fileName;
          this.fileUploaded.emit({ fileId, fileName });
          return Promise.resolve({ fileId, fileName });
        } else {
          this.fileUploadError.emit(true);
          return Promise.reject();
        }
      }
    } catch (e) {
      console.log(e);
      this.fileUploadError.emit(true);
      return Promise.reject();
    }


    // this.fileService.upload(this.getUploadUrl(), this.uploadedFiles[0])
    //   .subscribe(res => {
    //     console.log(res)
    //     if (res.status === 200) {
    //       const fileId = res.body.result.id;
    //       const fileName = res.body.result.fileName;
    //       this.fileUploaded.emit({fileId, fileName});
    //       return Promise.resolve({fileId, fileName});
    //     } else {
    //       this.fileUploadError.emit(true);
    //       return Promise.reject();
    //     }
    //   }, e => {
    //     return Promise.reject();
    //   });
  }

  hasFilesForUpload(): boolean {
    return this.uploadEl.files.length > 0;
  }

  getUploadUrl() {
    return getUploadFormAttachmentUrl();
  }

}
