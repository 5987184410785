import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  getAllCitationMatrixUrl,
  getAllMatrixUrl, getCitationMatrixByGroupUrl,
  getCreateRegistrationFormUrl,
  getMatrixByCodeUrl,
  getMatrixByGroupUrl, getSearchedParticipantUrl, getSearchParticipantsUrl
} from "../app.urls";
import { map } from 'rxjs/operators';
import { FormBiblioModel } from '../models/registration-form/form-biblio.model';
import { AuthenticationService } from '../../core-frontend-common/auth/authentication.service';
import { TicketFullModel } from "../models/ticket/ticket-full.model";

@Injectable()
export class RegistrationFormService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  getAllMatrix(): Observable<any[]> {
    return this.http
      .get(getAllMatrixUrl())
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getMatrixByGroup(group: string): Observable<any[]> {
    return this.http
      .get(getMatrixByGroupUrl(group))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getMatrixByCode(group: string, code: string): Observable<any[]> {
    return this.http
      .get(getMatrixByCodeUrl(group, code))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  createRegistration(formBiblio: FormBiblioModel): Observable<TicketFullModel> {
    return this.http
      .post(getCreateRegistrationFormUrl(), formBiblio)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  searchParticipants(search: any): Observable<any> {
    if (this.authenticationService.checkUser()) {
      return this.http.post(getSearchParticipantsUrl(), search)
        .pipe(map((res: any) => res.items));
    }
  }

  getSearchedParticipant(user: any): Observable<any> {
    if (this.authenticationService.checkUser()) {
      return this.http.post(getSearchedParticipantUrl(), user)
        .pipe(map((res: any) => res));
    }
  }

  getAllCitationMatrix() {
    return this.http
      .get(getAllCitationMatrixUrl())
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getCitationMatrixByGroup(group: string) {
    return this.http
      .get(getCitationMatrixByGroupUrl(group))
      .pipe(map((res: any) => {
        return res;
      }));
  }
}
