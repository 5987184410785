import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { REGISTRATION_FORM_GROUPS_2022, getCalendarLocale } from "../../../../constants";
import { CountryCodebookModel } from "../../../../models/codebook/country-codebook.model";
import { QuoteSectionBaseComponent } from "../quote-section-base.component";
import { CODEBOOK_ITEMS } from "../../../../services/codebooks.service";
import { FormGroup, Validators } from "@angular/forms";
import { capitalizeFirstLetterInString, checkCorrectDoiFormat } from "../../../../helper-functions";
import * as moment from "moment";
import { CitationFormElements } from "../../misc/citation-form-elements";
import { BiblioModel } from "../../../../models/biblio/biblio.model";
import { AutoComplete, Dropdown } from "primeng";

@Component({
  selector: "quoting-document-section",
  templateUrl: "./quoting-document-section.component.html",
  styleUrls: ["./quoting-document-section.component.scss"]
})
export class QuotingDocumentSectionComponent extends QuoteSectionBaseComponent implements OnInit {
  quoteForm: FormGroup;

  loadedMatrix = false;

  hasHostItem = false;
  hasMeeting = false;

  yesNoSplitButtonOptions = [];

  skLocaleCalendar = getCalendarLocale();

  quotingDocumentWizard = {
    hasAc: {
      visited: false,
      value: undefined,
      inputValue: ""
    },
    hasDoi: {
      visited: false,
      value: undefined,
      inputValue: ""
    }
  };

  visibleForm = false;

  formGroups = REGISTRATION_FORM_GROUPS_2022;

  selectedFormGroup;

  filteredCountries: CountryCodebookModel[] = [];

  catalogCountries: CountryCodebookModel[] = [];

  searchedQuotingDocuments: BiblioModel[] = [];

  formElements: CitationFormElements = new CitationFormElements();

  dateRange: [] | string = "";

  @Output() searchCrossrefByDoi: EventEmitter<string> = new EventEmitter<string>();

  @Output() searchedQuotingBiblio: EventEmitter<BiblioModel> = new EventEmitter<BiblioModel>();

  @Output() resetCrossrefData: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() resetQuotingDocument: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("quotingDocumentTitleEl", { static: false }) quotingDocumentTitleEl: AutoComplete;

  @ViewChild("documentTypeEl", { static: false }) documentTypeEl: Dropdown;

  ngOnInit() {
    this.generateQuotingDocumentOptions();

    this.initFormPart();

    this.initCodebooks();

    this.checkVisibilityOfForm();
  }

  async initCodebooks() {
    this.catalogCountries = await this.codebooksService.get(CODEBOOK_ITEMS.countries);
  }

  async initFormPart() {
    this.quoteForm = this.formBuilder.group({
      isbn: [""],
      issn: [""],
      eissn: [""],
      doi: [""],
      title: ["", [Validators.required]],
      yearOfPublishing: ["", [Validators.required]],
      pageCount: [""],

      hostItemTitle: [""],
      hostItemPageRange: [""],
      hostItemIssue: [""],
      hostItemVolume: [""],
      publisherName: [""],
      publisherAddress: [""],

      meetingName: [""],
      meetingCountry: [""],
      meetingCity: [""],
      meetingStartDatetime: [""],
      meetingEndDatetime: [""],

      wos: [""]
    });

    this.quoteForm.valueChanges
      .subscribe(() => {
        this.sectionValidityChanged();
      });

    return Promise.resolve(true);
  }

  generateQuotingDocumentOptions() {
    this.yesNoSplitButtonOptions.push({
      label: this.translationService.getTranslation("ÁNO"),
      value: true
    }, {
      label: this.translationService.getTranslation("NIE"),
      value: false
    });
  }

  quotingDocumentWizardChange(section: "ac" | "doi", value) {
    // this.resetQuotingDocumentWizard();
    this.resetForm();

    if (section === "ac") {
      this.resetQuotingDocumentDoiSectionWizard();
      this.quotingDocumentWizard.hasAc.visited = true;
      this.quotingDocumentWizard.hasAc.value = value;
    }

    if (!this.quotingDocumentWizard.hasAc.value) {
      this.resetQuotingDocument.emit(true);
    }

    if (section === "doi") {
      this.quotingDocumentWizard.hasDoi.visited = true;
      this.quotingDocumentWizard.hasDoi.value = value;

      if (!value) {
        // this.dataFromCrossRef = null;
        this.resetCrossrefData.emit(true);
        this.loadOthersData();
      }
    }

    this.sectionValidityChanged();

    this.checkVisibilityOfForm();
  }

  async loadOthersData() {
    this.resetForm();
  }

  checkIfIdentifiersAreValid(): boolean {
    let isValid = true;
    if (this.formElements.isbn.visible || this.formElements.issn.visible || this.formElements.eIssn.visible || this.formElements.doi.visible) {
      const isbn = this.quoteForm.get('isbn') ? this.quoteForm.get('isbn').value : null;
      const issn = this.quoteForm.get('issn') ? this.quoteForm.get('issn').value : null;
      const doi = this.quoteForm.get('doi') ? this.quoteForm.get('doi').value : null;
      const eIssn = this.quoteForm.get('eIssn') ? this.quoteForm.get('eIssn').value : null;

      if ((isbn && isbn.length > 0) || (issn && issn.length > 0) || (eIssn && eIssn.length > 0) || (doi && doi.length > 0)) {
        isValid = true;
      } else {
        isValid = false;
      }
    }
    return isValid;
  }

  checkVisibilityOfForm() {
    if (this.quotingDocumentWizard.hasAc.value && this.quotingDocument) {
      this.visibleForm = true;
      return;
    }

    if (this.quotingDocumentWizard.hasDoi.value && this.dataFromCrossRef && this.dataFromCrossRef.title) {
      this.visibleForm = true;
      return;
    }

    this.visibleForm = this.quotingDocumentWizard.hasDoi.visited &&
      this.quotingDocumentWizard.hasAc.visited &&
      !this.quotingDocumentWizard.hasDoi.value &&
      !this.quotingDocumentWizard.hasAc.value;
  }

  selectFormGroup(formGroup) {
    this.selectedFormGroup = formGroup.title;

    this.loadedMatrix = false;

    this.registrationFormService
      .getCitationMatrixByGroup(this.selectedFormGroup)
      .subscribe(properties => {
        if (properties && properties.length > 0) {
          properties.forEach(property => {
            let prop: string = property.propertyName;
            prop = prop.charAt(0).toLowerCase() + prop.slice(1);
            this.formElements[prop] = {
              visible: property.isVisible,
              required: property.isRequired
            };
            if (property.isVisible) {
              if (prop.toLowerCase().indexOf("hostitem") !== -1) {
                this.hasHostItem = true;
              }
              if (prop.toLowerCase().indexOf("meeting") !== -1) {
                this.hasMeeting = true;
              }
            }
            if (property.isRequired && this.quoteForm.get(prop)) {
              if (prop === "yearOfPublishing") {
                this.quoteForm.get(prop).setValidators([Validators.required, Validators.min(1980), Validators.max(new Date().getFullYear() + 1)]);
              } else {
                this.quoteForm.get(prop).setValidators([Validators.required]);
              }
            }
          });

          this.loadedMatrix = true;
        }
      });
  }

  resetQuotingDocumentDoiSectionWizard() {
    this.quotingDocumentWizard.hasDoi.visited = false;
    this.quotingDocumentWizard.hasDoi.value = undefined;
  }

  async searchQuotingDocumentByTitle(event) {
    const text = event.query;
    if (text && text.length > 2) {

      const queryDesigner = {
        "search": [],
        "page": 1,
        "pageSize": 10,
        "orderBy": [
          {
            "field": "CreatedDatetime",
            "order": "DESC"
          },
          {
            "field": "Id",
            "order": "DESC"
          }
        ]
      };

      queryDesigner["search"].push({
        "fields": [],
        "value": ""
      });
      queryDesigner["search"][0]["fields"] = ["title"];
      queryDesigner["search"][0]["value"] = text;

      const res = await this.biblioService
        .getAllByFilter(queryDesigner)
        .toPromise();

      if (res && res.items) {
        this.searchedQuotingDocuments = res.items;
      }

    }
  }

  selectQuotingDocumentByTitle(biblio: BiblioModel) {
    this.searchedQuotingBiblio.emit(biblio);
  }

  async searchQuotingDocumentByDoi() {
    const res = checkCorrectDoiFormat(this.quotingDocumentWizard.hasDoi.inputValue);

    if (res.valid) {

      if (res.value !== this.quotingDocumentWizard.hasDoi.inputValue) {
        this.quotingDocumentWizard.hasDoi.inputValue = res.value;
      }

      this.searchCrossrefByDoi.emit(res.value);
    }
  }

  fullFillDataFromEditedCitation() {
    if (this.editedCitation && this.isEditing) {

      // first step - if is publication in our evidence
      if (this.editedCitation.sourceId) {

        this.quotingDocumentWizardChange("ac", true);

        setTimeout(() => {
          this.quotingDocumentTitleEl.inputFieldValue = this.editedCitation.title;
        }, 50);

      } else {

        this.quotingDocumentWizardChange("ac", false);

        setTimeout(() => {
          this.quotingDocumentWizardChange("doi", !!this.editedCitation.doi);

          if (this.editedCitation.doi) {
            this.quotingDocumentWizard.hasDoi.inputValue = this.editedCitation.doi;
            this.visibleForm = true;
          }
        }, 20);

        if (this.editedCitation.group) {
          const group = this.formGroups.find(g => g.title === this.editedCitation.group);

          if (group) {
            this.selectFormGroup(group);

            setTimeout(() => {
              this.documentTypeEl.value = group;
              this.documentTypeEl.selectedOption = { label: group.title, value: group };
            }, 150);
          }
        }

        setTimeout(() => {
          Object.keys(this.editedCitation)
            .forEach(citationKey => {

              if (this.quoteForm && this.quoteForm.get(citationKey)) {
                this.quoteForm.get(citationKey).patchValue(this.editedCitation[citationKey]);
              }

            });

          this.checkProblematicElements();
        }, 150);

      }

    }
  }

  checkProblematicElements() {

    if (this.editedCitation && this.editedCitation.yearOfPublishing) {
      this.quoteForm.get("yearOfPublishing").patchValue(moment(this.editedCitation.yearOfPublishing, "YYYY").toDate());
    }

    if (this.editedCitation && this.editedCitation.meetingCountry) {
      setTimeout(() => {
        const country = this.catalogCountries.find(c => c.description === this.editedCitation.meetingCountry);
        this.quoteForm.get("meetingCountry").patchValue(country);
      }, 250);
    }

    if (this.editedCitation && this.editedCitation.meetingStartDatetime && this.editedCitation.meetingEndDatetime) {
      this.dateRange = [];
      // @ts-ignore
      this.dateRange.push(moment(this.editedCitation.meetingStartDatetime).toDate());
      // @ts-ignore
      this.dateRange.push(moment(this.editedCitation.meetingEndDatetime).toDate());
    }
  }

  changeQuotingDocument(e: Event) {
    e.preventDefault();

    this.quotingDocument = null;
    this.searchedQuotingDocuments = [];
    this.quotingDocumentTitleEl.inputFieldValue = "";
    this.quotingDocumentTitleEl.focusInput();
    this.sectionValidityChanged();
  }

  lockAllInputsChanged() {
    super.lockAllInputsChanged();

    if (this.allInputsLocked) {
      setTimeout(() => {
        this.quoteForm.disable();
      }, 1000);
    }
  }

  editedCitationChanged() {
    super.editedCitationChanged();

    this.fullFillDataFromEditedCitation();
  }

  crossRefDataChanged() {
    super.crossRefDataChanged();

    this.checkVisibilityOfForm();

    if (this.dataFromCrossRef) {
      this.fullFillDataFromCrossRef();
    }
  }

  quotingDocumentChanged() {
    super.quotingDocumentChanged();

    this.checkVisibilityOfForm();

    if (this.quotingDocument) {
      this.fullFillDataFromQuotingDocument();
    }
  }

  resetForm() {
    this.quoteForm.reset();

    this.quoteForm.enable();
  }

  fullFillDataFromCrossRef() {
    if (!this.dataFromCrossRef) {
      return;
    }

    this.resetForm();

    this.quoteForm.controls["doi"].patchValue(this.quotingDocumentWizard.hasDoi.inputValue);

    if (this.dataFromCrossRef.title && this.dataFromCrossRef.title.length > 0) {
      this.quoteForm.controls["title"].patchValue(capitalizeFirstLetterInString(this.dataFromCrossRef.title[0]));
    }

    if (this.dataFromCrossRef["publisher-location"]) {
      this.quoteForm.controls["publisherAddress"].patchValue(this.dataFromCrossRef["publisher-location"]);
    }

    if (this.dataFromCrossRef["published-online"]) {
      const published = this.dataFromCrossRef["published-online"]["date-parts"];
      if (published && published.length > 0) {
        const year = moment(published[0][0], "YYYY").toDate();
        this.quoteForm.controls["yearOfPublishing"].patchValue(year);
      }
    } else if (this.dataFromCrossRef["published-print"]) {
      const published = this.dataFromCrossRef["published-print"]["date-parts"];
      if (published && published.length > 0) {
        const year = moment(published[0][0], "YYYY").toDate();
        this.quoteForm.controls["yearOfPublishing"].patchValue(year);
      }
    }

    if (this.dataFromCrossRef.ISBN && this.dataFromCrossRef.ISBN.length > 0) {
      this.quoteForm.controls["isbn"].patchValue(this.dataFromCrossRef.ISBN[0]);
    }

    if (this.dataFromCrossRef["issn-type"] && this.dataFromCrossRef["issn-type"].length > 0) {

      const printIssn = this.dataFromCrossRef["issn-type"].find(t => t.type === "print");
      let issn = "";
      if (printIssn) {
        issn = printIssn.value;
      } else {
        const eIssn = this.dataFromCrossRef["issn-type"].find(t => t.type === "electronic");
        if (eIssn) {
          issn = eIssn.value;
        }
      }
      this.quoteForm.controls["issn"].patchValue(issn);
    }

    if (this.dataFromCrossRef["container-title"] && this.dataFromCrossRef["container-title"].length > 0) {
      this.quoteForm.controls["hostItemTitle"].patchValue(this.dataFromCrossRef["container-title"][0]);
    }

    if (this.dataFromCrossRef["short-container-title"] &&
      (!this.dataFromCrossRef["container-title"] || this.dataFromCrossRef["container-title"].length === 0)) {
      this.quoteForm.controls["hostItemTitle"].patchValue(this.dataFromCrossRef["short-container-title"][0]);
    }

    if (this.dataFromCrossRef.volume && this.dataFromCrossRef.volume.length > 0) {
      this.quoteForm.controls["hostItemVolume"].patchValue(Number.parseInt(this.dataFromCrossRef.volume, 0));
    }

    if (this.dataFromCrossRef.issue && this.dataFromCrossRef.issue.length > 0) {
      this.quoteForm.controls["hostItemIssue"].patchValue(Number.parseInt(this.dataFromCrossRef.issue, 0));
    }

    if (this.dataFromCrossRef.page && this.dataFromCrossRef.page.length > 0) {
      this.quoteForm.controls["pageCount"].patchValue(Number.parseInt(this.dataFromCrossRef.page, 0));
      this.quoteForm.controls["hostItemPageRange"].patchValue(Number.parseInt(this.dataFromCrossRef.page, 0));
    }

    if (this.dataFromCrossRef.publisher && this.dataFromCrossRef.publisher.length > 0) {
      this.quoteForm.controls["publisherName"].patchValue(this.dataFromCrossRef.publisher);
    }
  }

  fullFillDataFromQuotingDocument() {
    if (!this.quotingDocument) {
      return;
    }

    this.resetForm();

    const elementsToDisable = [];

    Object.keys(this.quotingDocument).forEach(key => {
      const exists = this.quoteForm.get(key);

      if (key === "yearOfPublishing") {
        const year = moment(this.quotingDocument.yearOfPublishing, "YYYY").toDate();
        if (year) {
          this.quoteForm.controls["yearOfPublishing"].patchValue(year);
          elementsToDisable.push("yearOfPublishing");
        }
      } else {
        if (this.quotingDocument[key] && exists) {
          exists.patchValue(this.quotingDocument[key]);
          elementsToDisable.push(key);
        }
      }
    });

    if (this.quotingDocument.hostItem && this.quotingDocument.hostItem.title) {
      this.quoteForm.controls["hostItemTitle"].patchValue(this.quotingDocument.hostItem.title);
      elementsToDisable.push("hostItemTitle");
    }

    if (this.quotingDocument.hostItem && this.quotingDocument.hostItem.pageRange) {
      this.quoteForm.controls["hostItemPageRange"].patchValue(this.quotingDocument.hostItem.pageRange);
      elementsToDisable.push("hostItemPageRange");
    }

    if (this.quotingDocument.hostItem && this.quotingDocument.hostItem.issueNumber) {
      this.quoteForm.controls["hostItemIssue"].patchValue(this.quotingDocument.hostItem.issueNumber);
      elementsToDisable.push("hostItemIssue");
    }

    if (this.quotingDocument.hostItem && this.quotingDocument.hostItem.volume) {
      this.quoteForm.controls["hostItemVolume"].patchValue(this.quotingDocument.hostItem.volume);
      elementsToDisable.push("hostItemVolume");
    }

    if (this.quotingDocument.hostItem && this.quotingDocument.hostItem.nameOfPublisher) {
      this.quoteForm.controls["publisherName"].patchValue(this.quotingDocument.hostItem.nameOfPublisher);
      elementsToDisable.push("publisherName");
    }

    if (this.quotingDocument.hostItem && this.quotingDocument.hostItem.placeOfPublishing) {
      this.quoteForm.controls["publisherAddress"].patchValue(this.quotingDocument.hostItem.placeOfPublishing);
      elementsToDisable.push("publisherAddress");
    }

    if (this.quotingDocument.meeting && this.quotingDocument.meeting.name) {
      this.quoteForm.controls["meetingName"].patchValue(this.quotingDocument.meeting.name);
      elementsToDisable.push("meetingName");
    }

    if (this.quotingDocument.meeting && this.quotingDocument.meeting.location) {
      this.quoteForm.controls["meetingCity"].patchValue(this.quotingDocument.meeting.location);
      elementsToDisable.push("meetingCity");
    }

    if (this.quotingDocument.meeting && this.quotingDocument.meeting.startDate) {
      this.quoteForm.controls["meetingStartDatetime"].patchValue(this.quotingDocument.meeting.startDate);
      elementsToDisable.push("meetingStartDatetime");
    }

    if (this.quotingDocument.meeting && this.quotingDocument.meeting.endDate) {
      this.quoteForm.controls["meetingEndDatetime"].patchValue(this.quotingDocument.meeting.endDate);
      elementsToDisable.push("meetingEndDatetime");
    }

    if (!this.quotingDocument.isbn && this.quotingDocument.hostItem && this.quotingDocument.hostItem.isbn) {
      this.quoteForm.controls["isbn"].patchValue(this.quotingDocument.hostItem.isbn);
      elementsToDisable.push("isbn");
    }

    if (!this.quotingDocument.issn && this.quotingDocument.hostItem && this.quotingDocument.hostItem.issn) {
      this.quoteForm.controls["issn"].patchValue(this.quotingDocument.hostItem.issn);
      elementsToDisable.push("issn");
    }

    setTimeout(() => {
      if (elementsToDisable && elementsToDisable.length > 0) {
        elementsToDisable.forEach(el => this.quoteForm.controls[el].disable());
      }
    }, 50);
  }

  changedYearOfPublishing() {
    console.log(this.quoteForm.value);

  }

  filterAutocompleteMethod(event, type: "country") {
    if (event.query.length === 0) {

      if (type === "country") {
        this.filteredCountries = [...this.catalogCountries];
      }

      return;
    }


    const text = event.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    if (type === "country") {

      this.filteredCountries = [];

      for (let i = 0; i < this.catalogCountries.length; i++) {
        const country = this.catalogCountries[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        if (country.indexOf(text) !== -1) {
          this.filteredCountries.push(this.catalogCountries[i]);
        }
      }

    }
  }

  changedDateRange(v) {
    if (this.dateRange && this.dateRange.length === 2) {
      if (this.dateRange[0]) {
        this.quoteForm.get("meetingStartDatetime").patchValue(moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss"));
      }
      if (this.dateRange[1]) {
        this.quoteForm.get("meetingEndDatetime").patchValue(moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"));
      } else {
        this.quoteForm.get("meetingStartDatetime").patchValue("");
        this.quoteForm.get("meetingEndDatetime").patchValue("");
      }
    } else {
      this.quoteForm.get("meetingStartDatetime").patchValue("");
      this.quoteForm.get("meetingEndDatetime").patchValue("");
    }
  }

  sectionValidityChanged() {
    super.sectionValidityChanged();

    const form = {};

    Object.assign(form, this.quoteForm.value);

    // @ts-ignore
    if (form && form.meetingCountry) {
      // @ts-ignore
      form.meetingCountry = form.meetingCountry.description;
    }

    if (this.selectedFormGroup) {
      form["group"] = this.selectedFormGroup;
    }

    if (!this.quoteForm.valid) {
      this.isValidSection = false;
    } else {
      // check if is only form without any data
      if (!this.dataFromCrossRef && !this.quotingDocument && !this.quotingDocumentWizard.hasDoi.value && this.quotingDocumentWizard.hasDoi.visited) {
        if (this.selectedFormGroup) {
          this.isValidSection = true;
        } else {
          this.isValidSection = false;
        }
      } else {

        // if (this.isEditing && !this.quotingDocument && !this.dataFromCrossRef) {
        //   this.isValidSection = false;
        // } else {
        this.isValidSection = true;
        // }
      }
    }

    // for admin
    if (this.allInputsLocked && this.isEditing) {
      this.isValidSection = true;
    }

    const validIdentifiers = this.checkIfIdentifiersAreValid();

    if (!validIdentifiers) {
      this.isValidSection = false;
    }

    this.validityChanged.emit({ valid: this.isValidSection, data: form });
  }

}
