import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { ProjectService } from "../../services/project.service";
import { ProjectModel } from "../../models/project/project.model";
import { ProjectFormComponent } from "./project-form/project-form.component";
import { ProjectInstitutionModel } from "../../models/project-institution.model";
import { CONSTANT_ITEMS, ConstantsService } from "../../services/constants.service";
import { BaseDropdownModel } from "../../models/base-dropdown.model";
import { generateYearsFilter } from "../../misc/years-filter";
import { FileService } from "../../services/file.service";
import { getExportProjectsUrl } from "../../app.urls";
import { AppCommunicationService } from "../../services/app-communication.service";
import { Router } from "@angular/router";
import { ProjectsInfoModalComponent } from "./projects-info-modal/projects-info-modal.component";
import { UserRolePipe } from "../../../core-frontend-common/pipes/user-role.pipe";
import { UserRoles } from "../../constants";
import { first, flatMap } from "rxjs/operators";
import { CodebooksService } from "../../services/codebooks.service";
import { sortByPriority } from "../../misc/sort-by-priority";
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";
import {
  createSubQuery,
  createSubQueryForMoreObjectsAndFields,
  createSubQueryFromObject
} from "../../../core-frontend-common/misc/query-designer/query-designer-functions";
import { queryFilterTypes, queryOperators } from "../../../core-frontend-common/misc/query-designer/query-designer";

@Component({
  selector: "smart-projets-management-view",
  templateUrl: "./projets-management-view.component.html",
  styleUrls: ["./projets-management-view.component.scss"]
})
export class ProjetsManagementViewComponent implements OnInit {

  selectedElement: any;

  currentFilter = {
    search: "",
    institution: null,
    faculty: null,
    year: null,
    research: null,
    foreign: null,
    category: null,
    type: null,
    priority: false,
    onlyNonLocked: false,
    myProjectsOnly: false,
    isCommercialContract: false
  };

  defaultFilter = {
    search: "",
    institution: null,
    faculty: null,
    year: null,
    research: null,
    foreign: null,
    category: null,
    type: null,
    priority: false,
    onlyNonLocked: false,
    myProjectsOnly: false,
    isCommercialContract: false
  };

  institutions: ProjectInstitutionModel[] = [];

  faculties = [];

  yearsFilter: BaseDropdownModel[] = [];

  researchFilter: BaseDropdownModel[] = [];

  foreignProjectsFilter: BaseDropdownModel[] = [];

  projects: ProjectModel[] = [];

  checkedProjects: ProjectModel[] = [];

  tableData = {
    totalItems: 0,
    page: 1,
    pageSize: 10,
    loading: false
  };

  loadedProject: ProjectModel;

  editCreatePageVisible = false;

  showActions = false;

  hasSuperadminRights = false;

  disabledForm = false;

  showFacultyFilter = false;

  @ViewChild(ProjectFormComponent, { static: false }) projectDialog: ProjectFormComponent;

  @ViewChild(ProjectsInfoModalComponent, { static: false }) projectInfoModal: ProjectsInfoModalComponent;

  constructor(
    protected projectService: ProjectService,
    private constantService: ConstantsService,
    private codebooksService: CodebooksService,
    private fileService: FileService,
    private appCommunicationService: AppCommunicationService,
    private router: Router,
    private userRole: UserRolePipe,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.loadConstants();

    this.getFaculties();

    this.generateFilters();

    this.yearsFilter = generateYearsFilter(2010);

    this.loadUserRights();
  }

  async loadConstants() {
    const res = await this.constantService.get(CONSTANT_ITEMS.projectInstitutions);

    if (res) {
      const data = sortByPriority(res);
      this.institutions = data;
    }
  }

  generateFilters() {
    this.researchFilter = [];
    this.foreignProjectsFilter = [];

    this.researchFilter.push({
      item_text: this.i18n.getTranslation("Domáce a zahraničné projekty"),
      item_id: null
    }, {
      item_text: this.i18n.getTranslation("Domáce projekty"),
      item_id: true
    }, {
      item_text: this.i18n.getTranslation("Zahraničné projekty"),
      item_id: false
    });

    this.foreignProjectsFilter.push({
      item_text: this.i18n.getTranslation("Výskumné a nevýskumné projekty"),
      item_id: null
    }, {
      item_text: this.i18n.getTranslation("Výskumné projekty"),
      item_id: true
    }, {
      item_text: this.i18n.getTranslation("Nevýskumné projekty"),
      item_id: false
    });
  }

  getFaculties() {
    this.codebooksService
      .getFaculties()
      .pipe(first())
      .subscribe(val => {
        this.faculties = val["items"];
      });
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(event: KeyboardEvent) {
    if (!event || !event.key) {
      return;
    }

    if (event.key.toLowerCase() === "enter") {
      if (this.selectedElement) {
        this.loadProjects();
      }
    }
    if (event.key.toLowerCase() === "escape") {
      if (this.selectedElement) {

        if (this.currentFilter.search === this.defaultFilter.search) {
          this.resetFilter();
        } else {
          this.currentFilter.search = "";
        }
      }
    }
  }

  loadUserRights() {
    this.hasSuperadminRights = false;
    if (this.userRole.transform([UserRoles.EpcProjectAdmin, UserRoles.Superadmin, UserRoles.EpcWorkflowAdmin, UserRoles.EpcWorkflow])) {
      this.hasSuperadminRights = true;
    }
  }

  onFocus(event) {
    if (event) {
      this.selectedElement = event.target;
    } else {
      this.selectedElement = null;
    }
  }

  onFocusOut() {
    this.selectedElement = null;
  }

  filterChanged() {
    console.log(this.currentFilter);
    if (this.currentFilter.institution && this.currentFilter.institution["code"] === "TUKE") {
      this.showFacultyFilter = true;
    } else {
      this.showFacultyFilter = false;
    }
  }

  resetFilter() {
    Object.assign(this.currentFilter, this.defaultFilter);
    this.loadProjects();
  }

  loadProjects() {
    this.checkedProjects = [];

    this.tableData.loading = true;

    const queryDesigner = this.generateQuery();

    let api;

    this.showActions = false;

    if (this.currentFilter.myProjectsOnly) {
      api = this.projectService.getAllForSupervisorByFilter(queryDesigner);
      this.showActions = true;
    } else {
      api = this.projectService.getAllByFilter(queryDesigner);
    }

    if (this.hasSuperadminRights) {
      this.showActions = true;
    }

    api.subscribe(v => {
      this.projects = v.items;
      this.tableData.totalItems = v.totalItems;
      this.tableData.loading = false;
    }, e => {
      console.log(e);
      this.tableData.loading = false;
    });
  }

  async export() {
    const queryDesigner = this.generateQuery();

    this.appCommunicationService.loadingOn(this.i18n.getTranslation("Sťahujem súbor, počkajte prosím."));

    const res = await this.fileService.downloadPost(getExportProjectsUrl(), queryDesigner, "projects-export-" + Date.now()).toPromise();

    if (res) {
      this.appCommunicationService.loadingOff();
    }
  }

  generateQuery(): any {
    const query = {
      search: [],
      isLocked: {},
      institutionName: {},
      createdById: {},
      isCommercialContract: {},
      isDomestic: {},
      isResearch: {}
    };

    const queryDesigner = {
      search: [],
      where: {
        operatorType: "AND",
        operands: []
      },
      page: this.tableData.page,
      pageSize: this.tableData.pageSize,
      orderBy: [
        {
          field: "CreatedDatetime",
          order: "DESC"
        }
      ]
    };

    if (this.currentFilter && this.currentFilter.onlyNonLocked) {
      query.isLocked = createSubQueryFromObject(queryOperators.OR, false, "isLocked", queryFilterTypes.EQUAL);
    }

    if (this.currentFilter && this.currentFilter.year) {
      query["year"] = createSubQueryForMoreObjectsAndFields(queryOperators.AND,
        [
          {
            obj: this.currentFilter.year,
            field: "startYear",
            filterType: queryFilterTypes.LessThanOrEqual
          }, {
          obj: this.currentFilter.year,
          field: "endYear",
          filterType: queryFilterTypes.GreaterThanOrEqual
        }], "item_id");
    }

    if (this.currentFilter && this.currentFilter.institution) {
      query["institutionName"] = createSubQueryFromObject(queryOperators.OR, this.currentFilter.institution["description"], "institutionName", queryFilterTypes.Contains);

      if (this.currentFilter.institution && this.currentFilter.institution['code'].toLowerCase() === 'tuke') {
        if (this.currentFilter.faculty) {
          query['departmentIdAsString'] = createSubQueryFromObject(queryOperators.OR, this.currentFilter.faculty["facultyId"].toString().substring(0, 3), "departmentIdAsString", queryFilterTypes.StartsWith);
        } else {
          delete query['departmentIdAsString'];
        }
      } else {
        delete query['departmentIdAsString'];
      }
    }

    if (this.currentFilter && this.currentFilter.isCommercialContract) {
      query.isCommercialContract = createSubQueryFromObject(queryOperators.OR, this.currentFilter.isCommercialContract, "isCommercialContract", queryFilterTypes.EQUAL);
    }

    if (this.currentFilter && this.currentFilter.research) {
      query.isResearch = createSubQuery(queryOperators.OR, [this.currentFilter.research], "isResearch", queryFilterTypes.EQUAL, 'item_id');
    }

    if (this.currentFilter && this.currentFilter.foreign) {
      query.isDomestic = createSubQuery(queryOperators.OR, [this.currentFilter.foreign], "isDomestic", queryFilterTypes.EQUAL, 'item_id');
    }

    Object.keys(query).forEach(queryItem => {
      if (Object.keys(query[queryItem]).length > 0 && queryItem !== "search") {
        queryDesigner.where.operands.push(query[queryItem]);
      }
    });

    if (this.currentFilter && this.currentFilter.search && this.currentFilter.search.length > 0) {

      const stringArray = this.currentFilter.search.split(/(\s+)/).filter(function(e) {
        return e.trim().length > 0;
      });
      for (let i = 0; i < stringArray.length; i++) {
        queryDesigner.search.push({
          fields: [],
          value: ""
        });

        queryDesigner.search[i].fields = ["name", "grantNumber", "agencyCode", "agencyName"];

        queryDesigner.search[i].value = stringArray[i];
      }
    }

    return queryDesigner;
  }

  setPage(pageInfo) {
    if (!pageInfo) {
      return;
    }
    this.tableData.page = (pageInfo.first + pageInfo.rows) / this.tableData.pageSize;

    this.loadProjects();
  }

  addAction() {

    this.loadedProject = new ProjectModel();

    this.editCreatePageVisible = true;
  }

  async editProjectAction(p: ProjectModel, disabledForm = true) {
    this.disabledForm = disabledForm;

    this.loadedProject = await this.projectService
      .getById(p.id).toPromise();

    if (this.loadedProject) {

      this.editCreatePageVisible = true;

    }
  }

  projectDeletedAction(e) {
    if (e) {
      this.projectViewClosed();
    }
  }

  projectViewClosed() {
    this.editCreatePageVisible = false;
    this.loadProjects();
  }

  clickedImportProject() {
    this.router.navigate(["/projects-management/import"]);
  }

  clickedInfoButton() {
    this.projectInfoModal.openModal();
  }

}
