import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core-frontend-common/template/core/core.module';
import { CustomModule } from './+app-custom/custom.module';
import { AuthGuard } from './core-frontend-common/auth/guards/auth.guard';
import { AuthenticationService } from './core-frontend-common/auth/authentication.service';
import { UserService } from './+app-custom/services/user.service';
import { GeneralService } from './+app-custom/services/general.service';
import { AppRedirectService } from './core-frontend-common/auth/app-redirect.service';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RoleGuard } from './core-frontend-common/auth/guards/role.guard';
import { SharedModule } from './core-frontend-common/template/shared/shared.module';
import { ReportBugDialogModule } from './+app-custom/components/report-bug-dialog/report-bug-dialog.module';
import { AUTH_INTERCEPTOR_PROVIDER } from './core-frontend-common/auth/interceptors/auth-interceptor.provider';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ConfirmDialogModule, MessageService } from "primeng";
import { LayoutModule } from "./core-frontend-common/template/shared/layout/layout.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpConfigInterceptor } from "./+app-custom/interceptors/httpconfig.interceptor";
import { I18nService } from "./core-frontend-common/template/shared/i18n";
import { CommonModule } from "./core-frontend-common/common.module";
import { FloatNumberFormatDirective } from "./+app-custom/directives/floatNumberFormat.directive";
import { FocusDirective } from "./+app-custom/directives/focus.directive";
import { OverlayDialogModule } from "./+app-custom/components/overlay-dialog/overlay-dialog.module";
import { CustomPreloadingStrategy } from "./custom-preloading-strategy";
import { CodebooksService } from "./+app-custom/services/codebooks.service";
import { PreloadingStrategy } from "@angular/router";
import { I18nModule } from "./core-frontend-common/template/shared/i18n/i18n.module";
import { AppThemeModule } from "./+app-custom/theme/app-theme.module";

@NgModule({
  declarations: [
    AppComponent,
    FloatNumberFormatDirective,
    FocusDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    CustomModule,
    SharedModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    ReportBugDialogModule,
    LayoutModule,
    ConfirmDialogModule,
    CommonModule,
    OverlayDialogModule,
    I18nModule.forRoot(),
    AppThemeModule
  ],
  providers: [
    AUTH_INTERCEPTOR_PROVIDER,
    AuthGuard,
    RoleGuard,
    AuthenticationService,
    UserService,
    CookieService,
    GeneralService,
    AppRedirectService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: PreloadingStrategy, useClass: CustomPreloadingStrategy, deps: [CodebooksService]}
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}
