import { Pipe, PipeTransform } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import * as hashSum from "hash-sum";
import { hasRequiredField } from "../misc/has-required-field";

@Pipe({
  name: "requiredElement",
  pure: false
})
export class RequiredElementPipe implements PipeTransform {
  cachedControlHash: string = null;
  cachedResult: boolean = null;

  constructor() {
  }

  transform(control: AbstractControl): boolean {
    const newHashOfControl = hashSum(control);

    let res: boolean;

    if (newHashOfControl !== this.cachedControlHash) {
      const nowHasRequiredField = hasRequiredField(control);

      this.cachedControlHash = hashSum(control);
      this.cachedResult = nowHasRequiredField;

      res = nowHasRequiredField;
    } else {
      res = this.cachedResult;
    }

    return res;
  }

}
