import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { TicketService } from "../../../services/ticket.service";
import { ToastrService } from "ngx-toastr";
import { CodebooksService } from "../../../services/codebooks.service";
import { FormBuilder } from "@angular/forms";
import { ConfirmationService } from "primeng";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";
import { CrossRefService } from "../../../services/cross-ref.service";
import { CrossrefMetadataModel } from "../../../models/crossref/crossref-metadata.model";
import { RegistrationFormService } from "../../../services/registration-form.service";
import { BiblioService } from "../../../services/biblio.service";
import { BiblioModel } from "../../../models/biblio/biblio.model";
import { FormCitationModel } from "../../../models/registration-form/form-citation.model";

@Component({
  selector: 'quote-base-section',
  template: 'Quote Base section'
})

export class QuoteSectionBaseComponent implements OnChanges {
  isValidSection = false;

  @Input() archiveId: number;

  @Input() isEditing = false;

  @Input() citedPublication: BiblioModel;

  @Input() quotingDocument: BiblioModel;

  @Input() dataFromCrossRef: CrossrefMetadataModel;

  @Input() editedCitation: FormCitationModel;

  @Input() allInputsLocked: boolean = false;

  @Output() validityChanged: EventEmitter<{valid: boolean, data: any}> = new EventEmitter<{valid: boolean, data: any}>();

  constructor(
    protected ticketService: TicketService,
    protected notification: ToastrService,
    protected codebooksService: CodebooksService,
    protected formBuilder: FormBuilder,
    protected confirmationService: ConfirmationService,
    protected translationService: I18nService,
    protected crossRef: CrossRefService,
    protected registrationFormService: RegistrationFormService,
    protected biblioService: BiblioService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['dataFromCrossRef']) {
      this.crossRefDataChanged();
    }

    if (changes && changes['quotingDocument']) {
      this.quotingDocumentChanged();
    }

    if (changes && changes['citedPublication']) {
      this.citedPublicationChanged();
    }

    if (changes && changes['editedCitation']) {
      this.editedCitationChanged();
    }

    if (changes && changes['allInputsLocked']) {
      this.lockAllInputsChanged();
    }
  }

  lockAllInputsChanged(){}

  crossRefDataChanged() {}

  quotingDocumentChanged() {}

  citedPublicationChanged() {}

  sectionValidityChanged() {}

  editedCitationChanged() {}

}

