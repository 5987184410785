import { NgModule } from "@angular/core";
import { TicketCatalogComponent } from "./ticket-catalog.component";
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { CommonSharedPipesModule } from "../../../core-frontend-common/pipes/common-shared-pipes.module";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";
import {
  CheckboxModule,
  DropdownModule,
  EditorModule,
  FieldsetModule,
  InputTextareaModule,
  InputTextModule,
  MultiSelectModule,
  TableModule
} from "primeng";
import { GeneralFilterComponent } from "./components/general-filter/general-filter.component";
import { CustomPipesModule } from "../../pipes/customPipes.module";
import { ItemTableRowModule } from "./components/item-table-row/item-table-row.module";
import { ItemsFiltersComponent } from "./components/items-filters/items-filters.component";
import { BiblioDetailModule } from "../../components/biblio-detail/biblio-detail.module";
import { TicketRowexpansionModule } from "./components/ticket-rowexpansion/ticket-rowexpansion.module";
import { TicketAcceptanceModalComponent } from "./ticket-acceptance-modal/ticket-acceptance-modal.component";
import { TicketMultiItemsActionModalComponent } from "./ticket-multi-items-action-modal/ticket-multi-items-action-modal.component";
import { TicketContactEmailModalComponent } from "./ticket-contact-email-modal/ticket-contact-email-modal.component";
import { TicketPriorityApprovementModalModule } from "./ticket-priority-approvement-modal/ticket-priority-approvement-modal.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule, TooltipModule } from "ngx-bootstrap";
import { TicketModalSubformComponent } from "./components/ticket-modal-subform/ticket-modal-subform.component";
import { QuotesPageModule } from "../quotes-page/quotes-page.module";
import { InlineLoaderModule } from "../../../core-frontend-common/components/inline-loader/inline-loader.module";
import { TicketEditModalModule } from "./ticket-edit-modal/ticket-edit-modal.module";
import { TicketImportFromDwModalComponent } from "./ticket-import-from-dw-modal/ticket-import-from-dw-modal.component";

@NgModule({
  declarations: [
    TicketCatalogComponent,
    GeneralFilterComponent,
    ItemsFiltersComponent,
    TicketAcceptanceModalComponent,
    TicketMultiItemsActionModalComponent,
    TicketContactEmailModalComponent,
    TicketModalSubformComponent,
    TicketImportFromDwModalComponent
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    CommonSharedPipesModule,
    I18nModule,
    FieldsetModule,
    TableModule,
    CustomPipesModule,
    ItemTableRowModule,
    BiblioDetailModule,
    TicketRowexpansionModule,
    TicketPriorityApprovementModalModule,
    DropdownModule,
    InputTextModule,
    FormsModule,
    CheckboxModule,
    TooltipModule,
    ModalModule,
    ReactiveFormsModule,
    QuotesPageModule,
    InlineLoaderModule,
    TicketEditModalModule,
    InputTextareaModule,
    EditorModule,
    MultiSelectModule
  ]
})

export class TicketCatalogModule {}
