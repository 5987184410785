import { EventEmitter, Input, Output } from "@angular/core";
import { DwRecordSimpleModel } from "../../models/dwtools/dw-record-simple.model";

export class BaseEpc2Component {

  dwRecord: DwRecordSimpleModel;

  showError = false;

  showSuccess = false;

  message = "";

  ac;

  @Output() loadedDwRecord: EventEmitter<DwRecordSimpleModel> = new EventEmitter<DwRecordSimpleModel>();

  @Input() set setDwRecord(dwRecord: DwRecordSimpleModel) {
    if (!dwRecord) {
      return;
    }
    this.dwRecord = dwRecord;
    if (dwRecord && dwRecord.archiveId) {
      this.ac = dwRecord.archiveId;
    }
  }

  async pasteValue() {
    const text = await navigator.clipboard.readText();
    this.ac = Number.parseInt(text, 0);
  }

  resetMessages() {
    this.showSuccess = false;
    this.showError = false;
    this.message = "";
  }

  onSubmit(ac: number) {
  }

  showingErrorMessage(message: string) {
    this.resetMessages();
    this.showError = true;
    this.message = message;

    setTimeout(() => {
      this.resetMessages();
    }, 10000);
  }

  showingSuccessMessage(message: string) {
    this.resetMessages();
    this.showSuccess = true;
    this.message = message;

    setTimeout(() => {
      this.resetMessages();
    }, 10000);
  }

}
