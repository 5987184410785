import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BiblioModel } from "../../../../models/biblio/biblio.model";
import { BiblioDetailComponent } from "../../../../components/biblio-detail/biblio-detail.component";
import { BiblioCatalogActionsEnum } from "../../types/biblio-catalog-actions.enum";
import { PublishingModel } from "../../../../models/biblio/publishing.model";
import { FormKeywordModel } from "../../../../models/registration-form/form-keyword.model";
import { ProjectModel } from "../../../../models/biblio/project.model";
import { MeetingModel } from "../../../../models/biblio/meeting.model";
import { CitationModel } from "../../../../models/biblio/citation.model";
import { BiblioCatalogSharedService } from "../../biblio-catalog-shared.service";
import { ToastrService } from "ngx-toastr";
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-biblio-item",
  templateUrl: "./biblio-item.component.html",
  styleUrls: ["./biblio-item.component.scss"]
})
export class BiblioItemComponent implements OnInit {

  @Input() biblio: BiblioModel;

  @ViewChild(BiblioDetailComponent, {static: false}) biblioDetail: BiblioDetailComponent;

  constructor(
    protected catalogDataService: BiblioCatalogSharedService,
    protected toastr: ToastrService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
  }

  openBiblioDetail() {
    this.catalogDataService.openBiblioDetail(this.biblio);
  }

  openCitationBiblio() {
    this.catalogDataService.openCitationDetail(this.biblio);
  }

  clickedAuthor(publisher: PublishingModel) {
    this.catalogDataService.openAuthor(this.biblio, publisher);
  }

  clickedKeyword(keyword: FormKeywordModel) {
    this.catalogDataService.openKeyword(keyword);
  }

  clickedProject(project: ProjectModel) {
    this.catalogDataService.openProject(project);
  }

  clickedMeeting(meeting: MeetingModel) {
    this.catalogDataService.openMeeting(meeting);
  }

  clickedCitationsCount(citations: CitationModel[]) {
    if (citations.length === 0) {
      this.toastr.warning(this.i18n.getTranslation('Žiadne publikácie necitovali túto publikáciu.'));
    } else {
      this.catalogDataService.openCitations(citations, this.biblio);
    }
  }

}
