import { Component, OnInit } from "@angular/core";
import { QuoteSectionBaseComponent } from "../quote-section-base.component";
import { FormParticipantModel } from "../../../../models/registration-form/form-participant.model";

@Component({
  selector: "authors-section",
  templateUrl: "./authors-section.component.html",
  styleUrls: ["./authors-section.component.scss"]
})
export class AuthorsSectionComponent extends QuoteSectionBaseComponent implements OnInit {

  participants: FormParticipantModel[] = [new FormParticipantModel()];

  maxParticipantsCount = 3;

  ngOnInit() {
  }

  onSubmittedParticipant(participant: FormParticipantModel, index: number, fromLoadedData = false) {
    participant["submitted"] = true;
    if (participant.isExternal && !participant.name && !participant.surname && participant.fullName) {
      const names = participant.fullName.split(" ");
      if (names && names.length === 2) {
        participant.name = names[0];
        participant.surname = names[1];
      }
    }
    this.participants[index] = participant;

    if (index + 1 !== this.maxParticipantsCount && !fromLoadedData) {
      this.participants.push(new FormParticipantModel());
    }

    this.sectionValidityChanged();
  }

  onDeleteParticipant(index) {
    this.participants.splice(index, 1);
    this.participants.push(new FormParticipantModel());
    this.sectionValidityChanged();
  }

  showParticipantsRemovalDialog() {
    this.confirmationService.confirm({
      message: this.translationService.getTranslation("Boli zadaní niekoľkí participanti. Prajete si ich zmazať?"),
      header: this.translationService.getTranslation("Potvrdenie"),
      acceptLabel: this.translationService.getTranslation("Áno"),
      rejectLabel: this.translationService.getTranslation("Nie"),
      accept: () => {
        this.participants = [];
        this.participants.push(new FormParticipantModel());

        setTimeout(() => {
          this.sectionValidityChanged();
        }, 300);
      }
    });
  }

  crossRefDataChanged() {
    super.crossRefDataChanged();

    if (this.editedCitation && this.isEditing) {
      return;
    }

    if (!this.dataFromCrossRef) {
      this.participants = [];
      this.participants.push(new FormParticipantModel());
      this.sectionValidityChanged();
      return;
    }

    if (this.dataFromCrossRef && this.dataFromCrossRef.author && this.dataFromCrossRef.author.length > 0) {
      this.participants = [];
      this.dataFromCrossRef.author.forEach(author => {
        if (author.family && author.given) {
          const participant = new FormParticipantModel();
          participant.surname = author.family;
          participant.name = author.given;

          if (author.ORCID) {
            participant.orcid = author.ORCID;
          }

          this.participants.push(participant);
        }
      });

      setTimeout(() => {
        this.sectionValidityChanged();
      }, 300);
    }
  }

  editedCitationChanged() {
    super.editedCitationChanged();

    if (this.editedCitation && this.editedCitation.persons) {

      this.participants = [];
      let idx = 1;
      this.editedCitation.persons.forEach(participant => {
        const p = new FormParticipantModel();
        Object.assign(p, participant);
        p.orderNumber = idx;
        p.fullName = `${participant.name} ${participant.surname}`;
        this.onSubmittedParticipant(p, idx - 1, true);
        idx++;
      });
    }

    setTimeout(() => {
      this.sectionValidityChanged();
    }, 300);
  }

  quotingDocumentChanged() {
    super.quotingDocumentChanged();

    if (this.editedCitation && this.isEditing) {
      return;
    }

    if (!this.quotingDocument) {
      this.participants = [];
      this.participants.push(new FormParticipantModel());
      this.sectionValidityChanged();
      return;
    }

    if (this.quotingDocument && this.quotingDocument.publishings) {
      this.participants = [];
    }

    if (this.quotingDocument && this.quotingDocument.publishings) {
      const participant = new FormParticipantModel();
      Object.assign(participant, this.quotingDocument.publishings);
      participant.orderNumber = 1;
      this.onSubmittedParticipant(participant, 0, true);
    }

    if (this.quotingDocument && this.quotingDocument.publishings) {
      let idx = 1;
      this.quotingDocument.publishings.forEach(participant => {
        const p = new FormParticipantModel();
        Object.assign(p, participant);
        p.orderNumber = idx;
        this.onSubmittedParticipant(p, idx - 1, true);
        idx++;
      });
    }

    setTimeout(() => {
      this.sectionValidityChanged();
    }, 300);
  }

  sectionValidityChanged() {
    super.sectionValidityChanged();

    if (this.participants && this.participants.length > 0 && (this.participants[0].name || this.participants[0].fullName) && this.participants[0]["submitted"]) {
      this.validityChanged.emit({ valid: true, data: this.participants });
    } else {
      this.validityChanged.emit({ valid: false, data: this.participants });
    }
  }
}
