import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import {
  getFormAttachmentTypesConstantUrl,
  getPatentPublishingSourcesUrl,
  getPriorityTypeResourcesConstantUrl, getProjectInstitutionsUrl,
  getTicketStatusesConstantUrl
} from "../app.urls";

export enum CONSTANT_ITEMS {
  ticketStatuses = "ticketStatuses",
  formAttachmentTypes = "formAttachmentTypes",
  priorityTypeResources = "priorityTypeResources",
  patentPublishingSources = "patentPublishingSources",
  projectInstitutions = "projectInstitutions"
}

@Injectable({
  providedIn: "root"
})
export class ConstantsService {
  protected storageKey = "constantItems";

  preferencesChanged: Subject<any> = new Subject<any>();

  protected values: { [key: string]: any } = {};

  constructor(
    private _http: HttpClient
  ) {
  }

  getTicketStatuses(): Observable<any[]> {
    return this._http.get(getTicketStatusesConstantUrl()).pipe(map((res: any) => res.result));
  }

  getFormAttachmentTypes(): Observable<any[]> {
    return this._http.get(getFormAttachmentTypesConstantUrl()).pipe(map((res: any) => res.result));
  }

  getPriorityTypeResources(): Observable<any[]> {
    return this._http.get(getPriorityTypeResourcesConstantUrl()).pipe(map((res: any) => res.result));
  }

  getPatentPublishingSources(): Observable<any[]> {
    return this._http.get(getPatentPublishingSourcesUrl()).pipe(map((res: any) => res.result));
  }

  getProjectInstitutions(): Observable<any[]> {
    return this._http.get(getProjectInstitutionsUrl()).pipe(map((res: any) => res.result));
  }

  async get(key: string, defaultValue = undefined) {
    if (typeof this.values[key] !== "undefined") {
      return this.values[key];
    }

    let items = [];

    switch (key) {
      case CONSTANT_ITEMS.ticketStatuses:
        items = await this.getTicketStatuses().toPromise();
        break;
      case CONSTANT_ITEMS.formAttachmentTypes:
        items = await this.getFormAttachmentTypes().toPromise();
        break;
      case CONSTANT_ITEMS.priorityTypeResources:
        items = await this.getPriorityTypeResources().toPromise();
        break;
      case CONSTANT_ITEMS.patentPublishingSources:
        items = await this.getPatentPublishingSources().toPromise();
        break;
      case CONSTANT_ITEMS.projectInstitutions:
        items = await this.getProjectInstitutions().toPromise();
        break;
    }

    this.values[key] = items;
    return items;
  }

  set(key: string, value: any, save = true) {
    this.values[key] = value;
    if (save) {
      this.saveToLocalStorage();
    }
    this.preferencesChanged.next({ key, value });
  }

  saveToLocalStorage() {
    window.localStorage.setItem(this.storageKey, JSON.stringify(this.values));
  }
}
