import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PDFDocumentProxy, PdfViewerComponent as Ng2PdfViewer } from 'ng2-pdf-viewer';
import { PDF_TYPES } from './pdf-types.enum';
import { PdfService } from './pdf.service';
import * as FileSaver from "file-saver";
import { first } from "rxjs/operators";

@Component({
  selector: 'smart-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  pdfData = {
    title: '',
    type: null,
    url: '',
  };

  options = {
    src: new ArrayBuffer(0),
    pdfProxy: null,
    zoom: 1,
    totalPages: 0,
    currentPage: 1,
    loadingPercentage: 0,
    searchText: '',
  };

  showDownload = false;

  loading = true;

  @ViewChild('pdfViewModal', { static: false }) pdfViewModal: ModalDirective;
  @ViewChild(Ng2PdfViewer, { static: false }) pdfComponent: Ng2PdfViewer;
  @Output() onCloseModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private pdfService: PdfService) {}

  ngOnInit() {}

  show(title: string, type: PDF_TYPES, url: any, showDownload = false) {
    this.pdfData.title = title;
    this.pdfData.type = type;
    this.pdfData.url = url;

    this.pdfService.downloadPdf(url, type).subscribe(pdf => {
      this.options.src = pdf;
    });

    this.showDownload = showDownload;

    this.pdfViewModal.show();
  }

  close() {
    if (this.options.pdfProxy) {
      (<PDFDocumentProxy>this.options.pdfProxy).destroy();
    }
    this.resetSettings();
    this.pdfViewModal.hide();
  }

  downloadPdf() {
    this.pdfService.downloadFile(this.pdfData.url, this.pdfData.title)
      .pipe(first())
      .subscribe();
  }

  onPdfLoaded(pdfProxy: PDFDocumentProxy) {
    this.options.pdfProxy = pdfProxy;
    this.options.totalPages = pdfProxy.numPages;
    this.loading = false;
  }

  onPageRendered() {
    $('.pdfViewer').removeClass('removePageBorders');
  }

  nextPage() {
    if (this.options.currentPage < this.options.totalPages) {
      this.options.currentPage++;
    }
  }

  prevPage() {
    if (this.options.currentPage > 1) {
      this.options.currentPage--;
    }
  }

  firstPage() {
    this.options.currentPage = 1;
  }

  lastPage() {
    this.options.currentPage = this.options.totalPages;
  }

  zoomIn() {
    this.options.zoom += 0.1;
  }

  zoomOut() {
    this.options.zoom -= 0.1;
  }

  searchByText() {
    this.pdfComponent.pdfFindController.executeCommand('find', {
      caseSensitive: false,
      findPrevious: undefined,
      highlightAll: true,
      phraseSearch: true,
      query: this.options.searchText,
    });
  }

  checkLoadingComplete() {
    this.loading = this.options.currentPage !== this.options.totalPages;
    this.options.loadingPercentage = Math.ceil((this.options.currentPage * 100) / this.options.totalPages);
  }

  resetSettings() {
    this.pdfData = {
      title: '',
      type: null,
      url: '',
    };
    this.options = {
      src: new ArrayBuffer(0),
      pdfProxy: null,
      zoom: 1,
      totalPages: 0,
      currentPage: 1,
      loadingPercentage: 0,
      searchText: '',
    };
    this.loading = true;
  }
}
