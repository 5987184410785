import * as moment from 'moment';

export function formatTimeToDatetime(time) {
  return moment(time, 'HH:mm').format('YYYY-MM-DD HH:mm:ss');
}

export function formatDateToDatetime(date) {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function capitalizeFirstLetterInString(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function getPercentageOfUppercaseLettersInString(text: string): number {
  let textLength = 0;
  let uppercaseLettersCount = 0;
  let index = 0;

  while (index <= text.length) {
    const character = text.charAt(index);
    // @ts-ignore
    if (!isNaN(character * 1)) {
      console.log('character is numeric');
    } else {
      if (character === character.toUpperCase()) {
        uppercaseLettersCount++;
      }
      textLength++;
    }
    index++;
  }

  return (uppercaseLettersCount / textLength) * 100;
}

export function deleteSpecialCharactersFromString(text: string): string {
  const specialCharacters = [
    '<jats:p>',
    '</jats:p>'
  ];

  specialCharacters.forEach(char => {
    text = text.replace(char, '');
  });

  return text;
}

export function checkCorrectDoiFormat(doi: string): {valid: boolean, value: string} {
  if (!doi) {
    return {valid: false, value: doi};
  }

  // check validity of doi
  let match = false;

  console.log(doi)

  let insertedDoiValue = doi.replace(" ", "");

  const isHttp = insertedDoiValue.includes("http");

  let matchedPattern;

  let correctDoi = "";

  const allRegex: RegExp[] = [
    new RegExp("10.\\d{4,9}/[-._;()/:a-zA-Z0-9]+$"),
    new RegExp("10.1002/[^\\s]+$"),
    new RegExp("10.\\d{4}/\\d+-\\d+X?(\\d+)\\d+<[\\d\\w]+:[\\d\\w]*>\\d+.\\d+.\\w+;\\d$"),
    new RegExp("10.1021/\\w\\w\\d+$"),
    new RegExp("10.1207/[\\w\\d]+\&\\d+_\\d+$")
  ];

  for (let i = 0; i < allRegex.length; i++) {
    const test = allRegex[i].exec(insertedDoiValue);
    if (test && test.length > 0) {
      matchedPattern = allRegex[i];
      correctDoi = test[0];
      match = true;
    }
  }

  if (match && correctDoi && correctDoi.length > 0 && isHttp) {
    insertedDoiValue = correctDoi;
  }

  return {valid: match, value: insertedDoiValue};
}
