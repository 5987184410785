import { NgModule } from "@angular/core";
import { TicketPriorityApprovementModalComponent } from "./ticket-priority-approvement-modal.component";
import { ModalModule } from "ngx-bootstrap";
import { CommonModule } from "@angular/common";
import { I18nModule } from "../../../../core-frontend-common/template/shared/i18n/i18n.module";
import { TicketService } from "../../../services/ticket.service";
import { InputTextModule } from "primeng";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    ModalModule,
    CommonModule,
    I18nModule,
    InputTextModule,
    FormsModule
  ],
  declarations: [
    TicketPriorityApprovementModalComponent
  ],
  exports: [
    TicketPriorityApprovementModalComponent
  ],
  providers: [
    TicketService
  ]
})

export class TicketPriorityApprovementModalModule {}
