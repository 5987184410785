import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";

@Component({
  selector: 'smart-ticket-error-check-modal',
  templateUrl: './ticket-error-check-modal.component.html',
  styleUrls: ['./ticket-error-check-modal.component.scss']
})
export class TicketErrorCheckModalComponent implements OnInit {
  errors: string[] = [];

  @ViewChild("staticModal", { static: false }) modal: ModalDirective;

  constructor() { }

  ngOnInit() {
  }

  show(
    errors: string[]
  ) {
    this.errors = errors;
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

}
