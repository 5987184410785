import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { TicketSimpleModel } from "../../../../models/ticket/ticket-simple.model";
import { TicketService } from "../../../../services/ticket.service";
import { Router } from "@angular/router";
import { TicketActions, TicketActionsEnum } from "../../misc/ticket-actions";
import { copyTextToClipboard } from "../../../../misc/copy-text-to-clipboard";
import { ToastrService } from "ngx-toastr";
import { FormAttachmentTypes, PriorityTypes } from "../../../../constants";
import { TableColumn, TicketTableColumnNames } from "../../../../misc/table-responsive";
import { getCurrentStatus, getCurrentStatusForPedagogue } from "../../misc/get-current-status";
import { UserRolePipe } from "../../../../../core-frontend-common/pipes/user-role.pipe";
import { TicketAttachmentPhysicalDocument } from "../../misc/ticket-attachment-physical-document";
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";
import { AppCommunicationService } from "../../../../services/app-communication.service";

@Component({
  selector: "tr[table-body]",
  templateUrl: "./item-table-row.component.html",
  styleUrls: ["./item-table-row.component.scss"]
})
export class ItemTableRowComponent implements OnInit, OnChanges {
  ITEM_ACTIONS = TicketActionsEnum;

  formAttachments = FormAttachmentTypes;

  ticketTableColumnNames = TicketTableColumnNames;

  priorityTypes = PriorityTypes;

  hasComment = false;

  @Input() ticket: TicketSimpleModel;

  @Output() checked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() showEditButton = false;

  @Input() actions: TicketActions;

  @Output() onButtonAction: EventEmitter<TicketActionsEnum> = new EventEmitter<TicketActionsEnum>();

  @Output() ticketPriorityAction: EventEmitter<TicketSimpleModel> = new EventEmitter<TicketSimpleModel>();

  @Output() onInfoButtonAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() viewType: "admin" | "pedagogue" = "pedagogue";

  @Input() showForAllItemsTable = false;

  @Input() pRowToggler: any;

  @Input() showOnlyInfoButton = false;

  @Input() canShowAlsoImportDwButton = false;

  @Input() columnsToShow: TableColumn[];

  constructor(
    protected ticketService: TicketService,
    protected router: Router,
    protected messageService: ToastrService,
    protected userRole: UserRolePipe,
    protected translateService: I18nService,
    protected appCommunicationService: AppCommunicationService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.ticket && changes.ticket.currentValue) {
      this.ticket = changes.ticket.currentValue;
      this.checkComment();
    }

  }

  checkComment() {
    this.ticket.lifecycles.forEach(lifecycle => {
      if (lifecycle.comment && lifecycle.comment.trim().length > 0) {
        this.hasComment = true;
      }
    });
  }

  checkboxChanged(e) {
    this.ticket.isChecked = e.checked;
    this.checked.emit(this.ticket.isChecked);
  }

  action(e: Event, action: TicketActionsEnum) {
    e.preventDefault();

    // Pedagogue has another edit button action - redirected directly to form
    if (this.viewType === "pedagogue" && action === TicketActionsEnum.edit) {
      if (this.ticket.type === 'biblio') {
        this.router.navigate([`/form-edit/${this.ticket.id}`]);
      } else if (this.ticket.type === 'citation') {
        this.router.navigate([`/quote-edit/${this.ticket.id}`]);
      }
      return;
    }

    if (action === TicketActionsEnum.info) {
      this.onInfoButtonAction.emit(true);
    } else {
      this.onButtonAction.emit(action);
    }
  }

  copyEmailBtnClick(email: string) {
    copyTextToClipboard(email);
    this.messageService.success(this.translateService.getTranslation("E-Mail skopírovany do schránky."));
  }

  copyText(text: string | number) {
    const txt = text.toString();
    copyTextToClipboard(txt);
  }

  emailBtnClick(email: string) {
    window.location.assign(`mailto:${email}`);
  }

  quoteBtnClick() {
    // TODO
    this.router.navigate([`quotes/${this.ticket.archiveId}`]);
  }

  getCurrentStatusForPedagogue(status: number): string {
    return getCurrentStatusForPedagogue(status);
  }

  getCurrentStatus(ticket: TicketSimpleModel): string {
    return getCurrentStatus(ticket);
  }

  clickedPriority(ticket: TicketSimpleModel) {
    if (this.userRole.transform(['SUPERADMIN'])) {
      this.ticketPriorityAction.emit(ticket);
    }
  }

  isPhysicalDocument(attachment): boolean {
    if (!attachment) return false;
    return TicketAttachmentPhysicalDocument(attachment, this.formAttachments);
  }

  downloadDispatchNoteClicked() {
    this.appCommunicationService.loadingOn(this.translateService.getTranslation('Sťahujem sprievodku.'));

    this.ticketService.downloadDispatchNote(this.ticket.id)
      .subscribe(() => {
        this.appCommunicationService.loadingOff();
        this.messageService.success(this.translateService.getTranslation("Súbor úspešne stiahnutý."));
      }, err => {
        this.appCommunicationService.loadingOff();
        this.messageService.error(this.translateService.getTranslation('Súbor sa stiahnuť nepodarilo.'));
      });
  }

}
