import { CitationCategoryModel } from "../../../../models/codebook/citation-category.model";
import { CitationDatabaseType } from "../../../../types/citation-database.type";

export class CitationCategorySectionType {
  valid: boolean;
  data: {
    category: CitationCategoryModel;
    database: CitationDatabaseType;
  };

  static createEmpty(): CitationCategorySectionType {
    const c = new CitationCategorySectionType();
    c.valid = false;
    // @ts-ignore
    c.data = {};
    c.data.category = CitationCategoryModel.createEmpty();
    c.data.database = null;
    return c;
  }
}
