export enum BiblioCatalogActionsEnum {
  biblioDetail,
  citationDetail,
  clickedAuthor,
  clickedKeyword,
  clickedMeeting,
  clickedProject,
  clickedCitations,
  resetAll
}
