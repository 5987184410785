import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  getBiblioCrepcMetadataUrl,
  getBiblioDeleteQuartilsUrl,
  getBiblioFromDwUrl,
  getBiblioImportFromDwUrl,
  getBiblioUpdateDatabaseUrl,
  getBiblioUpdateQuartilUrl
} from "../app.urls";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DwRecordSimpleModel } from "../models/dwtools/dw-record-simple.model";
import { DwRecordCrudDatabaseRequest } from "../models/dwtools/dw-record-crud-database-request";
import { PRODUCTION_BUILD } from "../app.settings";
import { DwArticleIdentifier } from "../models/dwtools/dw-article-identifier";

@Injectable()
export class DwToolsService {

  constructor(
    private http: HttpClient
  ) {
  }

  biblioImportFromDw(archiveId: number, save = false): Observable<any> {
    return this.http
      .get(getBiblioImportFromDwUrl(archiveId, save))
      .pipe(map(res => res));
  }

  getFromDw(archiveId: number): Observable<DwRecordSimpleModel> {
    return this.http
      .get(getBiblioFromDwUrl(archiveId))
      .pipe(map((res: any) => res.result as DwRecordSimpleModel));
  }

  biblioCrepcMetadata(archiveId: number): Observable<any> {
    return this.http
      .get(getBiblioCrepcMetadataUrl(archiveId))
      .pipe(map((res: any) => res));
  }

  biblioUpdateDatabase(dwRecord: DwRecordCrudDatabaseRequest): Observable<DwRecordSimpleModel> {
    return this.http
      .post(getBiblioUpdateDatabaseUrl(), dwRecord)
      .pipe(map((res: any) => res.result as DwRecordSimpleModel));
  }

  biblioUpdateQuartil(dwRecord: DwRecordCrudDatabaseRequest): Observable<DwRecordSimpleModel> {
    return this.http
      .post(getBiblioUpdateQuartilUrl(), dwRecord)
      .pipe(map((res: any) => res.result as DwRecordSimpleModel));
  }

  biblioDeleteQuartils(archiveId: number): Observable<any> {
    return this.http
      .get(getBiblioDeleteQuartilsUrl(archiveId))
      .pipe(map((res: any) => res as any));
  }

  searchArticleIdentifier(articleIdentifier: string): Observable<DwArticleIdentifier[]> {
    const url = PRODUCTION_BUILD ? 'https://sctool.lib.tuke.sk/check?article=' + articleIdentifier : 'http://devscrappertool.lib.tuke.sk/check?article=' + articleIdentifier;

    return this.http
      .get(url)
      .pipe(map((res: any) => res as any));
  }

}
