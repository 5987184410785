import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { BiblioCategoryCodebookModel } from "../models/codebook/biblio-category-codebook.model";
import {
  getAllBiblioCategoryCodebookUrl, getAllBiblioFieldOfStudyCodebookUrl,
  getAllCitationCategoryCodebookUrl,
  getAllCitationIndexCodebookUrl,
  getAllContentCharacteristicCodebookUrl,
  getAllCountryCodebookUrl,
  getAllDocumentTypeCodebookUrl,
  getAllLanguageCodebookUrl,
  getAllMediumCodebookUrl,
  getAllPatentTypeCodebookUrl, getAllResearchAndTechUrl,
  getAllResearchAreaCodebookUrl,
  getAllRoleCodebookUrl, getBiblioCategoryCodebookWithFormGroupsUrl,
  getBiblioCategoryInGroupCodebookByGroupUrl,
  getDepartmentCodebookUrl,
  getFacultyCodebookUrl, getGrantCategoryTypesUrl, getProjectResearchAreaUrl
} from "../app.urls";
import { debounceTime, map, shareReplay, tap } from "rxjs/operators";
import { CitationCategoryModel } from "../models/codebook/citation-category.model";
import { CitationIndexModel } from "../models/codebook/citation-index.model";
import { ContentCharacteristicCodebookModel } from "../models/codebook/content-characteristic-codebook.model";
import { CountryCodebookModel } from "../models/codebook/country-codebook.model";
import { DocumentTypeCodebookModel } from "../models/codebook/document-type-codebook.model";
import { LanguageCodebookModel } from "../models/codebook/language-codebook.model";
import { MediumCodebookModel } from "../models/codebook/medium-codebook.model";
import { PatentTypeCodebook } from "../models/codebook/patent-type.codebook";
import { ResearchAreaCodebookModel } from "../models/codebook/research-area-codebook.model";
import { RoleCodebookModel } from "../models/codebook/role-codebook.model";
import { Router } from "@angular/router";
import { BiblioCategoryCodebookWithFormGroupModel } from "../models/codebook/biblio-category-codebook-with-formgroup.model";
import { GrantCategoryTypeCodebookModel } from "../models/codebook/grant-category-type-codebook.model";
import { BiblioFieldOfStudyCodebookModel } from "../models/codebook/biblio-field-of-study-codebook.model";
import { ProjectResearchAreaCodebookModel } from "../models/codebook/project-research-area-codebook.model";
import { ResearchAndTechCodebookModel } from "../models/codebook/research-and-tech-codebook.model";

export enum CODEBOOK_ITEMS {
  faculties = "faculties",
  departments = "departments",
  biblioCategories = "biblioCategories",
  biblioCategoriesWithFormGroups = "biblioCategoriesWithFormGroups",
  citationCategories = "citationCategories",
  citationIndexes = "citationIndexes",
  contentCharacteristics = "contentCharacteristics",
  countries = "countries",
  documentTypes = "documentTypes",
  languages = "languages",
  mediums = "mediums",
  patentTypes = "patentTypes",
  researchAreas = "researchAreas",
  fieldOfStudy = "fieldOfStudy",
  roles = "roles",
  grantCategoryTypes = "grantCategoryTypes",
  researchAndTech = 'researchAndTech',
  projectResearchArea = 'projectResearchArea'
}

@Injectable({
  providedIn: "root"
})
export class CodebooksService {
  protected storageKey = "codebookItems";

  // Cache for each observable
  private cache: { [key: string]: Observable<any> } = {};

  preferencesChanged: Subject<any> = new Subject<any>();

  protected values: { [key: string]: any } = {};

  constructor(
    private _http: HttpClient,
    private router: Router
  ) {
  }

  private getFromCache<T>(key: string, fetcher: Observable<T>): Observable<T> {
    if (!this.cache[key]) {
      this.cache[key] = fetcher.pipe(
        shareReplay(1)
      );
    }
    return this.cache[key];
  }

  getFaculties(): Observable<any[]> {
    const url = getFacultyCodebookUrl();
    return this.getFromCache(url, this._http.get<any[]>(url));
  }

  getDepartments(facultyId: number): Observable<any[]> {
    return this._http.get(getDepartmentCodebookUrl(facultyId)).pipe(map((res: any) => res.items));
  }

  // For dynamic requests that depend on parameters, generate a unique key for caching
  // getDepartmentDetails(departmentId: number): Observable<any> {
  //   const url = `${getDepartmentDetailsUrl()}/${departmentConfig}`;
  //   return this.getFromCache(url, this.http.get<any>(url));
  // }

  getAllBiblioCategoryCodebook(): Observable<BiblioCategoryCodebookModel[]> {
    const url = getAllBiblioCategoryCodebookUrl();
    return this.getFromCache(url, this._http.get<any[]>(url));
  }

  getAllCitationCategoryCodebook(): Observable<CitationCategoryModel[]> {
    const url = getAllCitationCategoryCodebookUrl();
    return this.getFromCache(url, this._http.get<any[]>(url));
  }

  getAllCitationIndexCodebook(): Observable<CitationIndexModel[]> {
    return this._http.get(getAllCitationIndexCodebookUrl()).pipe(map((res: any) => res));
  }

  getAllContentCharacteristicCodebook(): Observable<ContentCharacteristicCodebookModel[]> {
    return this._http.get(getAllContentCharacteristicCodebookUrl()).pipe(map((res: any) => res));
  }

  getAllCountryCodebook(): Observable<CountryCodebookModel[]> {
    return this._http.get(getAllCountryCodebookUrl()).pipe(map((res: any) => res));
  }

  getAllDocumentTypeCodebook(): Observable<DocumentTypeCodebookModel[]> {
    return this._http.get(getAllDocumentTypeCodebookUrl()).pipe(map((res: any) => res));
  }

  getAllLanguageCodebook(): Observable<LanguageCodebookModel[]> {
    return this._http
      .get(getAllLanguageCodebookUrl())
      .pipe(
        map((res: any) => res)
      );
  }

  getAllMediumCodebook(): Observable<MediumCodebookModel[]> {
    return this._http.get(getAllMediumCodebookUrl()).pipe(map((res: any) => res));
  }

  getAllPatentTypeCodebook(): Observable<PatentTypeCodebook[]> {
    return this._http.get(getAllPatentTypeCodebookUrl()).pipe(map((res: any) => res));
  }

  getAllResearchAreaCodebook(): Observable<ResearchAreaCodebookModel[]> {
    return this._http.get(getAllResearchAreaCodebookUrl()).pipe(map((res: any) => res));
  }

  getAllFieldOfStudyCodebook(): Observable<BiblioFieldOfStudyCodebookModel[]> {
    return this._http.get(getAllBiblioFieldOfStudyCodebookUrl()).pipe(map((res: any) => res));
  }

  getAllRoleCodebook(): Observable<RoleCodebookModel[]> {
    return this._http.get(getAllRoleCodebookUrl()).pipe(map((res: any) => res));
  }

  getBiblioCategoryInGroupCodebookByGroup(code: string): Observable<BiblioCategoryCodebookModel[]> {
    return this._http.get(getBiblioCategoryInGroupCodebookByGroupUrl(code)).pipe(map((res: any) => res));
  }

  getBiblioCategoryCodebookWithFormGroups(): Observable<BiblioCategoryCodebookWithFormGroupModel[]> {
    return this._http.get(getBiblioCategoryCodebookWithFormGroupsUrl()).pipe(map((res: any) => res));
  }

  getGrantCategoryTypes(): Observable<GrantCategoryTypeCodebookModel[]> {
    return this._http.get(getGrantCategoryTypesUrl()).pipe(map((res: any) => res.result));
  }

  getProjectResearchArea(): Observable<ProjectResearchAreaCodebookModel[]> {
    return this._http.get(getProjectResearchAreaUrl()).pipe((res: any) => res);
  }

  getResearchAndTech(): Observable<ResearchAndTechCodebookModel[]> {
    return this._http.get(getAllResearchAndTechUrl()).pipe((res: any) => res);
  }

  async get(key: string, defaultValue = undefined) {
    if (typeof this.values[key] !== "undefined") {
      return this.values[key];
    }

    let items = [];

    switch (key) {
      case CODEBOOK_ITEMS.faculties:
        items = await this.getFaculties().toPromise();
        break;
      case CODEBOOK_ITEMS.biblioCategories:
        items = await this.getAllBiblioCategoryCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.biblioCategoriesWithFormGroups:
        items = await this.getBiblioCategoryCodebookWithFormGroups().toPromise();
        break;
      case CODEBOOK_ITEMS.citationCategories:
        items = await this.getAllCitationCategoryCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.citationIndexes:
        items = await this.getAllCitationIndexCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.contentCharacteristics:
        items = await this.getAllContentCharacteristicCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.countries:
        items = await this.getAllCountryCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.documentTypes:
        items = await this.getAllDocumentTypeCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.languages:
        items = await this.getAllLanguageCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.mediums:
        items = await this.getAllMediumCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.patentTypes:
        items = await this.getAllPatentTypeCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.researchAreas:
        items = await this.getAllResearchAreaCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.fieldOfStudy:
        items = await this.getAllFieldOfStudyCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.roles:
        items = await this.getAllRoleCodebook().toPromise();
        break;
      case CODEBOOK_ITEMS.grantCategoryTypes:
        items = await this.getGrantCategoryTypes().toPromise();
        break;
      case CODEBOOK_ITEMS.researchAndTech:
        items = await this.getResearchAndTech().toPromise();
        break;
      case CODEBOOK_ITEMS.projectResearchArea:
        items = await this.getProjectResearchArea().toPromise();
        break;
    }

    this.values[key] = items;
    return items;
  }

  set(key: string, value: any, save = true) {
    this.values[key] = value;
    if (save) {
      this.saveToLocalStorage();
    }
    this.preferencesChanged.next({ key, value });
  }

  saveToLocalStorage() {
    window.localStorage.setItem(this.storageKey, JSON.stringify(this.values));
  }
}
