import { TicketStatuses } from "../../../constants";
import { TicketSimpleModel } from "../../../models/ticket/ticket-simple.model";

export function getCurrentStatusForPedagogue(status: number): string {
  if (status < TicketStatuses.sent_to_acceptance) {
    return 'Koncept';
  }
  if (status === TicketStatuses.sent_to_acceptance) {
    return 'Podaná';
  } else {
    return 'Katalogizácia';
  }
}

export function getCurrentStatus(ticket: TicketSimpleModel): string {
  const lastLifecycle = ticket.lifecycles[ticket.lifecycles.length - 1];
  let person = '';
  if (lastLifecycle && lastLifecycle.createdBy) {
    person = ` (${lastLifecycle.createdBy.surname})`;
  }
  switch (ticket.currentStatus) {
    case TicketStatuses.created:
      return "Koncept";
    case TicketStatuses.updated_by_pedagogue:
      return "Koncept";
    case TicketStatuses.to_rework:
      return "Na prepracovanie";
    case TicketStatuses.sent_to_acceptance:
      return "Príjem";
    case TicketStatuses.in_acceptance:
      return "Príjem" + person;
    case TicketStatuses.to_scan:
      return "Sken";
    case TicketStatuses.in_scan:
      return "Sken" + person;
    case TicketStatuses.to_conversion:
      return "Konverzia";
    case TicketStatuses.in_conversion:
      return "Konverzia" + person;
    case TicketStatuses.scanned:
      return "Kontrola";
    case TicketStatuses.converted:
      return "Kontrola";
    case TicketStatuses.in_validation:
      return "Kontrola" + person;
    case TicketStatuses.to_catalogize:
      return "Katalogizácia";
    case TicketStatuses.in_catalogization:
      return "Katalogizácia" + person;
    case TicketStatuses.sent_to_crepc:
      return "Import DAWINCI";
    case TicketStatuses.imported_to_dawinci:
      return "Import DAWINCI";
    case TicketStatuses.in_dawinci_check:
      return "Import DAWINCI" + person;
    case TicketStatuses.to_hand_over:
      return "Výdaj";
    case TicketStatuses.in_hand_over_to_author:
      return "Výdaj" + person;
    case TicketStatuses.to_contact:
      return "Kontakt";
    case TicketStatuses.in_contact:
      return "Kontakt" + person;
    case TicketStatuses.finished:
      return "Dokončené";
  }
  return " - ";
}
