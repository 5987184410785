import { Component, OnInit, ViewChild } from "@angular/core";
import { GeneralService } from "../../services/general.service";
import { NotificationModel } from "../../components/notification/notification.model";
import { TileModel } from "../../components/tile/tile.model";
import { AppCommunicationService } from "../../services/app-communication.service";
import { UserModel } from "../../models/user.model";
import { UserRoles, YOUTUBE_URLS } from "../../constants";
import { PersonsService } from "../../services/persons.service";
import { UserPublicationDataDialogComponent } from "../../components/user-publication-data-dialog/user-publication-data-dialog.component";
import { PersonValidityModel } from "../../models/person-validity.model";
import { PdfViewerComponent } from "../../../core-frontend-common/components/pdf-viewer/pdf-viewer.component";
import { PDF_TYPES } from "../../../core-frontend-common/components/pdf-viewer/pdf-types.enum";
import { DISABLE_REGISTRATION_DATE } from "../../app.settings";
import { checkIsDisabledPageForDate } from "../../misc/check-disabled-page-for-date";
import { FileService } from "../../services/file.service";
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";

export enum pdfTypes {
  'vyhlaska' = 'vyhlaska',
  'smernica' = 'smernica',
  'kategorieEPC' = 'kategorieEPC',
  'metodika' = 'metodika',
  'zakon131' = 'zakon131',
  'prilohaKmetodike' = 'prilohaKmetodike',
  'vedeckeDatabazy' = 'vedeckeDatabazy',
  'epcDoktorandi' = 'epcDoktorandi'
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  user: UserModel;

  notifications: NotificationModel[] = [];

  quickActions: TileModel[] = [];

  personValidity: PersonValidityModel;

  pdfTypes = pdfTypes;

  youtubeUrls = YOUTUBE_URLS;

  disabledRegistration = {
    date: DISABLE_REGISTRATION_DATE,
    isDisabled: checkIsDisabledPageForDate(null, null, null, false)
  };

  @ViewChild(UserPublicationDataDialogComponent, {static: false}) userPublicationDataDialogComponent: UserPublicationDataDialogComponent;

  @ViewChild(PdfViewerComponent, {static: false}) pdfViewer: PdfViewerComponent;

  constructor(
    private generalService: GeneralService,
    protected personsService: PersonsService,
    protected file: FileService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.loadUser();

    this.loadNotifications();

    this.generateQuickActions();

    this.checkIfUserIsAllowedToSystemEpc();
  }

  async checkIfUserIsAllowedToSystemEpc() {
    const valid = await this.personsService.isValidForCurrentUser().toPromise();

    if (valid) {
      this.personValidity = valid;

      if (valid && !valid.isValid) {
        this.showPublisherDialog(valid);
      }
    }
  }

  showPublisherDialog(person: PersonValidityModel) {
   this.userPublicationDataDialogComponent.show(person, true);
  }

  loadUser() {
    this.user = AppCommunicationService.getCurrentUser().user;
  }

  loadNotifications() {
    this.generalService
      .getNotifications()
      .subscribe(val => this.notifications = val);
  }

  generateQuickActions() {
    this.quickActions.push(
      {
        id: 1,
        title: this.i18n.getTranslation('Registrácia publikácie'),
        route: "/form",
        imageUrl: "/assets/img/custom/registration.png",
        roles: [...UserRoles.All]
      },
      {
        id: 2,
        title: this.i18n.getTranslation('Registrácia citácie'),
        route: "/quotes",
        imageUrl: "/assets/img/custom/quote.png",
        roles: [...UserRoles.All]
      },
      {
        id: 3,
        title: this.i18n.getTranslation('Moje žiadosti'),
        route: "/my-publications",
        imageUrl: "/assets/img/custom/myThesis.png",
        roles: [...UserRoles.All]
      },
      {
        id: 4,
        title: this.i18n.getTranslation('Výstupy'),
        route: "/exports",
        imageUrl: "/assets/img/custom/exports.png",
        roles: [...UserRoles.All]
      },
      {
        id: 5,
        title: this.i18n.getTranslation('Katalóg tiketov'),
        route: "/ticket",
        imageUrl: "/assets/img/custom/tickets.png",
        roles: [UserRoles.Superadmin, UserRoles.EpcWorkflow, UserRoles.EpcWorkflowAdmin]
      },
      {
        id: 6,
        title: this.i18n.getTranslation('Katalóg publikácií'),
        route: "/catalog",
        imageUrl: "/assets/img/custom/catalog.png",
        roles: [UserRoles.Superadmin, UserRoles.EpcWorkflow]
      }
    );
  }

  openPdf(pdfType: pdfTypes, showDownload = false) {
    this.pdfViewer.show(
      this.i18n.getTranslation('Vyhláška'),
      PDF_TYPES.local,
      `assets/pdf/${pdfType}.pdf`,
      showDownload
    );
  }

  downloadPdf(pdfType: pdfTypes, fileExtension: 'pdf' | 'xlsx' = 'pdf') {
    this.file
      .download(`assets/pdf/${pdfType}.${fileExtension}`, `${pdfType}.${fileExtension}`, fileExtension)
      .subscribe(v => console.log(v));
  }

  openPdfUrl(pdfType: pdfTypes) {
    switch (pdfType) {
      case pdfTypes.kategorieEPC:
        window.open('assets/pdf/kategorieEPC.pdf', '_blank');
        break;
      case pdfTypes.metodika:
        window.open('http://cms.crepc.sk/Data/Sites/1/pdf/metodickepokyny/2023/metodika_2023_230405.pdf ', '_blank');
        break;
      case pdfTypes.vyhlaska:
        window.open('http://cms.crepc.sk/Data/Sites/1/pdf/metodickepokyny/2022/vyhlaska397-2020.pdf', '_blank');
        break;
      case pdfTypes.zakon131:
        window.open('https://www.slov-lex.sk/static/pdf/2002/131/ZZ_2002_131_20200425.pdf', '_blank');
        break;
      case pdfTypes.vedeckeDatabazy:
        window.open('assets/pdf/vedecke_databazy_2022.pptx', '_blank');
        break;
      case pdfTypes.epcDoktorandi:
        window.open('assets/pdf/EPC_doktorandi.pptx', '_blank');
        break;
    }
  }
}
