import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { RowexpansionBaseComponent } from "../rowexpansion-base.component";
import { BiblioModel } from "../../../../../models/biblio/biblio.model";
import { reorderPublishingsBiblio } from "../../../../../misc/reorder-publishings-biblio";

@Component({
  selector: "smart-rowexpansion-citation",
  templateUrl: "./rowexpansion-citation.component.html",
  styleUrls: ["./rowexpansion-citation.component.scss", "../rowexpansion.style.scss"]
})
export class RowexpansionCitationComponent extends RowexpansionBaseComponent implements OnInit {

  quotingBiblio: BiblioModel = new BiblioModel();

  sourceBiblio: BiblioModel = new BiblioModel();

  @Output() showAllCitationsForBiblioIdEvent: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
    this.loadCitation();

    this.fulfillLifecycles();

    this.getFileInfo();
  }

  async loadCitation() {
    if (this.fullTicket.formCitation) {
      const biblioRes = await this.biblioService
        .getById(this.fullTicket.formCitation.biblioId)
        .toPromise();

      if (biblioRes) {
        this.quotingBiblio = reorderPublishingsBiblio(biblioRes);
      }

      if (this.fullTicket.formCitation.sourceId) {
        const citedPublicationRes = await this.biblioService
          .getById(this.fullTicket.formCitation.sourceId)
          .toPromise();

        if (citedPublicationRes) {
          this.sourceBiblio = citedPublicationRes;
        }
      }
    }
  }

  showAllCitationsForBiblioId(biblioId: number) {
    this.showAllCitationsForBiblioIdEvent.emit(biblioId);
  }

}
