export class DwDatabaseCrudRequest {
  field?: string;
  name: string;
  code: string;
  orderNumber?: number;
  isNew: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  quartil?: string;

  constructor(isCreating = false) {
    if (isCreating) {
      this.orderNumber = 0;
    }
    this.isDelete = false;
    this.isUpdate = false;
    this.isNew = false;
  }
}
