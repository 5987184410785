import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { QuoteSectionBaseComponent } from "../quote-section-base.component";
import { FormAttachmentTypes, FormAttachmentTypesArray } from "../../../../constants";
import { AttachmentsComponent } from "../../../ticket-registration/components/attachments/attachments.component";
import { TicketFullModel } from "../../../../models/ticket/ticket-full.model";
import { Dropdown } from "primeng";
import { FormCitationModel } from "../../../../models/registration-form/form-citation.model";
import { CitationDatabaseType } from "../../../../types/citation-database.type";

@Component({
  selector: "attachments-section",
  templateUrl: "./attachments-section.component.html",
  styleUrls: ["./attachments-section.component.scss"]
})
export class AttachmentsSectionComponent extends QuoteSectionBaseComponent implements OnInit, OnChanges {
  uri = "";

  maxFileSizeForUploadMB = 200;

  currentFormAttachmentType: number;

  formAttachments = FormAttachmentTypes;

  formAttachmentsData = FormAttachmentTypesArray;

  citationDatabase = CitationDatabaseType;

  isWosOrScopus = false;

  isWos = false;
  isScopus = false;

  isValidWosScopusUrl = true;

  @ViewChild(AttachmentsComponent, { static: false }) uploadComponent: AttachmentsComponent;

  @Input() editedTicketCitation: TicketFullModel;

  @Input() citationForm: FormCitationModel = new FormCitationModel();

  @ViewChild("attachmentTypeEl", { static: false }) attachmentTypeEl: Dropdown;

  ngOnInit() {
    this.checkWosOrScopusDatabase();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes && changes["citationForm"]) {
      this.checkWosOrScopusDatabase();
    }
  }

  checkWosOrScopusDatabase() {
    if (this.citationForm) {
      this.isWos = this.citationForm.databaseType === CitationDatabaseType.wos;
      this.isScopus = this.citationForm.databaseType === CitationDatabaseType.scopus;

      this.isWosOrScopus = this.isWos || this.isScopus;

      let attachmentType = this.formAttachments.without_attachment;

      if (this.isWosOrScopus) {
        attachmentType = this.formAttachments.url_link;
        this.isValidWosScopusUrl = false;
      }

      const attach = this.formAttachmentsData.find(a => a.value === attachmentType);

      if (attach) {
        this.onFormAttachmentTypeChange(null, attach.value);

        this.fixDropdownValue(attach);
      }
    }
  }

  onFormAttachmentTypeChange(e = null, valueDirectly = null) {
    if (e !== null || valueDirectly !== null) {
      this.currentFormAttachmentType = e ? e.value.value : valueDirectly;
    }

    this.sectionValidityChanged();
  }

  formAttachmentInputChanged(text: string) {

    if (this.isValidWosScopusUrl || !this.isWosOrScopus) {
      this.uri = text;
      this.sectionValidityChanged();
    }

    this.isValidWosScopusUrl = false;

    if (this.isWos) {
      if (text.indexOf('https://www.webofscience.com/') !== -1) {
        this.isValidWosScopusUrl = true;
      }
    }

    if (this.isScopus) {
      if (text.indexOf('https://www.scopus.com/') !== -1) {
        this.isValidWosScopusUrl = true;
      }
    }

    if (this.isValidWosScopusUrl) {
      this.uri = text;
    } else {
      this.uri = '';
    }
    this.sectionValidityChanged();
  }

  fileChanged(isAdded: boolean) {
    this.sectionValidityChanged();
  }

  fileUploaded(fileInfo: { fileId: number; fileName: string }) {
    if (fileInfo) {
      this.notification.success(this.translationService.getTranslation("Súbor bol úspešne nahraný do systému."));
    }
  }

  fileUploadError(e: boolean) {
    if (e) {
      this.notification.error(this.translationService.getTranslation("Nastala chyba počas nahrávania súboru."));
    }
  }

  fixDropdownValue(attachment: any) {
    if (this.attachmentTypeEl) {
      this.attachmentTypeEl.selectedOption = { value: attachment, label: attachment.name };
    }
  }

  quotingDocumentChanged() {
    super.quotingDocumentChanged();

    if (this.quotingDocument && this.quotingDocument.id && !this.isWosOrScopus) {
      const attachment = this.formAttachmentsData.find(a => a.value === this.formAttachments.without_attachment);

      if (attachment) {
        this.currentFormAttachmentType = attachment.value;
        this.fixDropdownValue(attachment);
      }
    }

    this.sectionValidityChanged();
  }

  editedCitationChanged() {
    super.editedCitationChanged();

    if (this.editedTicketCitation && this.isEditing && !this.isWosOrScopus) {
      this.uri = this.editedCitation.uri;

      const attachment = this.formAttachmentsData.find(a => a.value === this.editedTicketCitation.attachmentType);

      if (attachment) {
        this.currentFormAttachmentType = this.editedTicketCitation.attachmentType;

        this.fixDropdownValue(attachment);
      }
    }

    this.sectionValidityChanged();
  }

  crossRefDataChanged() {
    super.crossRefDataChanged();

    if (this.dataFromCrossRef && this.dataFromCrossRef.URL) {
      this.uri = this.dataFromCrossRef.URL;
    }
  }

  sectionValidityChanged() {
    super.sectionValidityChanged();

    if (!this.currentFormAttachmentType) {
      return;
    }

    if (this.currentFormAttachmentType === this.formAttachments.url_link) {
      if (this.uri && this.uri.length > 0) {
        this.validityChanged.emit({ valid: true, data: { type: this.currentFormAttachmentType, uri: this.uri } });
        return;
      } else {
        this.validityChanged.emit({ valid: false, data: { type: this.currentFormAttachmentType, uri: this.uri } });
        return;
      }
    }

    if (this.currentFormAttachmentType === this.formAttachments.file) {
      setTimeout(() => {
        if (!this.uploadComponent || !this.uploadComponent.hasFilesForUpload()) {
          this.validityChanged.emit({ valid: false, data: { type: this.currentFormAttachmentType, uri: this.uri } });
          return;
        } else {
          this.validityChanged.emit({
            valid: true,
            data: { type: this.currentFormAttachmentType, uri: this.uri, file: this.uploadComponent.uploadedFiles }
          });
          return;
        }
      }, 50);
    }

    this.validityChanged.emit({ valid: true, data: { type: this.currentFormAttachmentType, uri: this.uri } });
    return;
  }
}
