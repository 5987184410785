import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appUppercaseCheck]',
})
export class UppercaseCheckDirective {

  @Output() uppercasedTextDetected: EventEmitter<any> = new EventEmitter<any>();
  @Input() inputValue: string;

  constructor() {
  }

  @HostListener('change')
  onChange() {
    if (this.inputValue.length >= 3) {
      const res = this.checkWholeTextUppercase(this.inputValue);
      if (res) {
        this.uppercasedTextDetected.emit(100);
      }
    }
  }

  checkWholeTextUppercase(text: string): boolean {
    if (!text || text.length === 0) return false;
    const numUpper = text.length - text.replace(/[A-Z]/g, '').length;
    if (numUpper / text.length > 0.3) {
      return true;
    }
    return false;
  }

}
