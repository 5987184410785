import { Component, EventEmitter, OnInit, Output } from '@angular/core';

export const NAV_TABS = [
  {
    title: 'biblio',
    href: 'biblio',
    icon: 'fa-book'
  },
  {
    title: 'person',
    href: 'person',
    icon: 'fa-user'
  },
  {
    title: 'meeting',
    href: 'meeting',
    icon: 'fa-users'
  }
];

export interface NavTab {
  title: string;
  href: string;
  icon: string;
}

@Component({
  selector: 'smart-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements OnInit {
  TABS = NAV_TABS;
  activeTab = this.TABS[0];

  @Output() changeNav: EventEmitter<NavTab> = new EventEmitter<NavTab>();
  constructor() { }

  ngOnInit() {
  }

  selectTab(tab: NavTab) {
    this.activeTab = tab;
    this.changeNav.emit(this.activeTab);
  }

}
