import * as moment from "moment";
import { BaseDropdownModel } from "../models/base-dropdown.model";


export function generateYearsFilter(yearFrom: number): BaseDropdownModel[] {
  let initYear = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
  const endYear = moment(`${yearFrom}-01-01 00:00:00`);

  const filter: BaseDropdownModel[] = [];

  while (moment(initYear).format("YYYY") > moment(endYear).format("YYYY")) {
    filter.push({
      item_id: moment(initYear).format("YYYY"),
      item_text: moment(initYear).format("YYYY")
    });

    initYear = moment(initYear).subtract(1, "years").format("YYYY-MM-DD HH:mm:ss");
  }

  return filter;
}
