import { NgModule } from "@angular/core";
import { AttachmentsComponent } from "./attachments.component";
import { FileUploadModule } from "primeng";
import { I18nModule } from "../../../../../core-frontend-common/template/shared/i18n/i18n.module";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    FileUploadModule,
    I18nModule,
    CommonModule
  ],
  exports: [
    AttachmentsComponent
  ],
  declarations: [
    AttachmentsComponent
  ]
})

export class AttachmentsModule {}
