import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { TicketSimpleModel } from "../../../models/ticket/ticket-simple.model";
import { TicketService } from "../../../services/ticket.service";
import { PriorityTypes, PriorityTypesArray } from "../../../constants";
import { TicketPriorityRequestModel } from "../../../models/requests/ticket-priority-request.model";
import { ToastrService } from "ngx-toastr";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "ticket-catalog-priority-approvement",
  templateUrl: "./ticket-priority-approvement-modal.component.html",
  styleUrls: ["./ticket-priority-approvement-modal.component.scss"]
})
export class TicketPriorityApprovementModalComponent implements OnInit {
  comment = '';

  currentPriority;

  ticket: TicketSimpleModel;

  priorityTypesArray = PriorityTypesArray;

  afterClose: () => void = null;

  @ViewChild("staticModal", { static: false }) modal: ModalDirective;

  constructor(
    protected ticketService: TicketService,
    protected toastr: ToastrService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
  }

  openModal(
    ticket: TicketSimpleModel,
    afterClose: () => void = null
  ) {
    this.afterClose = afterClose;
    this.ticket = ticket;
    if (this.ticket.priorityType) {
      this.currentPriority = this.priorityTypesArray.find(p => p.value === this.ticket.priorityType);
    }
    this.comment = this.ticket.priorityComment;
    this.modal.show();
  }

  approvePriority(approve: true | false = false) {
    const request = new TicketPriorityRequestModel();
    request.ticketId = this.ticket.id;
    request.isApproved = approve;
    request.comment = this.comment;
    this.ticketService
      .priorityApprovement(request)
      .subscribe(v => {
        if (v && v.success) {
          this.toastr.success(this.i18n.getTranslation('Prioritné spracovanie úspešne schválené.'));
        } else {
          this.toastr.error(this.i18n.getTranslation('Prioritné spracovanie sa schváliť nepodarilo.'));
        }
        this.close();
      }, error => {
        this.toastr.error(this.i18n.getTranslation('Prioritné spracovanie sa schváliť nepodarilo.'));
        this.close();
      });
  }

  close() {
    if (this.afterClose) {
      this.afterClose();
    }
    this.modal.hide();
  }

}
