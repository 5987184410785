import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CrossrefMetadataModel } from "../models/crossref/crossref-metadata.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CrossRefService {

  BASE_URL = 'https://api.crossref.org/v1/works';

  MAIL_TO_PARAM = '?mailto:is@helpdesk.tuke.sk';

  constructor(
    protected http: HttpClient
  ) { }

  getByDoi(doi: string): Observable<CrossrefMetadataModel> {
    return this.http
      .get(`${this.BASE_URL}/${doi}${this.MAIL_TO_PARAM}`)
      .pipe(map((res: any) => {
        if (res && res.message) {
          return res.message as CrossrefMetadataModel;
        }
      }));
  }
}
