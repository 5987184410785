import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getAllMeetingsByFilterUrl } from '../app.urls';
import { map } from 'rxjs/operators';
import { MeetingModel } from "../models/biblio/meeting.model";

@Injectable({
  providedIn: 'root'
})
export class MeetingsService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getAllByFilter(filter: any): Observable<{totalItems: number, items: MeetingModel[]}> {
    return this.http
      .post(getAllMeetingsByFilterUrl(), filter)
      .pipe(map((res: any) => {
        return {
          totalItems: res.totalItems,
          items: res.items as MeetingModel[]
        };
      }));
  }}
