import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BASE_URL } from "../../app.urls";
import { map } from "rxjs/operators";

@Injectable()
export class UserSearchService {

  constructor(
    private http: HttpClient
  ) {
  }

  searchUser(search: any): Observable<any> {
    return this.http
      .post(`${BASE_URL}/user/searchFilter`, search)
      .pipe(map((res: any) => res));
  }

  getSearchedUser(user: any): Observable<any> {
    return this.http
      .post(`${BASE_URL}/user/getSearched`, user)
      .pipe(map((res: any) => res));
  }

}
