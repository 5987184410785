import { Component, OnInit } from "@angular/core";
import { RowexpansionBaseComponent } from "../rowexpansion-base.component";

@Component({
  selector: "smart-rowexpansion-biblio",
  templateUrl: "./rowexpansion-biblio.component.html",
  styleUrls: ["./rowexpansion-biblio.component.scss", "../rowexpansion.style.scss"]
})
export class RowexpansionBiblioComponent extends RowexpansionBaseComponent implements OnInit {

  ngOnInit() {
    this.fulfillLifecycles();

    this.getFileInfo();
  }

}
