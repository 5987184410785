import {NgModule} from '@angular/core';
import {BaseOverlayDialogComponent} from './base-overlay-dialog/base-overlay-dialog';
import {OverlayDialogRootComponent} from './overlay-dialog-root';
import {CommonModule} from '@angular/common';
import {MatButtonModule, MatCheckboxModule, MatIconModule} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ModalModule} from "ngx-bootstrap/modal";
import { TutorialVideosDialogComponent } from './tutorial-videos-dialog/tutorial-videos-dialog.component';
import { OverlayService } from "./overlay-service";
import { YoutubePlayerModule } from "../youtube-player/youtube-player.module";

@NgModule({
  declarations: [
    BaseOverlayDialogComponent,
    OverlayDialogRootComponent,
    TutorialVideosDialogComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    ModalModule,
    YoutubePlayerModule
  ],
  exports: [
    OverlayDialogRootComponent,
    BaseOverlayDialogComponent,
  ],
  entryComponents: [
    BaseOverlayDialogComponent,
    TutorialVideosDialogComponent
  ],
  providers: [
    OverlayService
  ]
})

export class OverlayDialogModule {
}
