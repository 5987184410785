import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProjectModel } from "../../../../models/project/project.model";

export class GrantPriceModel {
  projectId?: number;
  project?: ProjectModel;
  year: number;
  regularPrice: number;
  capitalPrice: number;

  sum?: number;

  static createNew(): GrantPriceModel {
    const c = new GrantPriceModel();
    c.year = 0;
    c.regularPrice = 0;
    c.capitalPrice = 0;
    c.sum = 0;
    return c;
  }
}

@Component({
  selector: "smart-grant-prices-subform",
  templateUrl: "./grant-prices-subform.component.html",
  styleUrls: ["./grant-prices-subform.component.scss"]
})
export class GrantPricesSubformComponent implements OnInit {

  data: GrantPriceModel[] = [];

  data2: GrantPriceModel[] = [...this.data];

  disabledActions = false;

  @Input() set setGrantPrices(grantPrices: GrantPriceModel[]) {
    this.data = grantPrices;

    this.data.forEach(s => {
      s['generatedId'] = Math.random();
      this.changedPrice(s);
    });

    this.data2 = [...this.data];
  }

  @Input() set setDisabledActions(disabled: boolean) {
    this.disabledActions = disabled;
  }

  @Output() changedGrantPrices: EventEmitter<GrantPriceModel[]> = new EventEmitter<GrantPriceModel[]>();

  constructor() {
  }

  ngOnInit() {
  }

  onRowEditInit(grant: GrantPriceModel) {
    if (!grant.year) return;
  }

  onRowEditSave(grant: GrantPriceModel) {
    this.changedData();
  }

  onRowDelete(grant: GrantPriceModel, index: number) {
    this.data.splice(index, 1);
    this.changedData();
  }

  addNewGrant(dt) {
    const i = GrantPriceModel.createNew();
    i['generatedId'] = Math.random();
    i.year = new Date().getFullYear();
    this.data.push(i);
    setTimeout(() => {
      const item = this.data[this.data.length - 1];
      this.onRowEditInit(item);
      dt.initRowEdit(item);
    }, 50);
  }

  changedPrice(grant: GrantPriceModel) {
    grant.sum = grant.regularPrice + grant.capitalPrice;
  }

  changedData() {
    this.changedGrantPrices.emit(this.data);
  }
}
