import { NgModule } from "@angular/core";
import { ItemTableRowComponent } from "./item-table-row.component";
import { CustomPipesModule } from "../../../../pipes/customPipes.module";
import { CommonModule } from "@angular/common";
import { CheckboxModule, TableModule } from "primeng";
import { TooltipModule } from "ngx-bootstrap";
import { FormsModule } from "@angular/forms";
import { I18nModule } from "../../../../../core-frontend-common/template/shared/i18n/i18n.module";
import { CommonSharedPipesModule } from "../../../../../core-frontend-common/pipes/common-shared-pipes.module";

@NgModule({
  imports: [
    CustomPipesModule,
    CommonModule,
    CheckboxModule,
    TooltipModule,
    FormsModule,
    I18nModule,
    CommonSharedPipesModule,
    TableModule
  ],
  exports: [
    ItemTableRowComponent
  ],
  declarations: [
    ItemTableRowComponent
  ]
})

export class ItemTableRowModule {}
