import { Component, Input, OnInit } from "@angular/core";
import { CitationModel } from "../../../../models/biblio/citation.model";

@Component({
  selector: 'smart-citation-item',
  templateUrl: './citation-item.component.html',
  styleUrls: ['./citation-item.component.scss']
})
export class CitationItemComponent implements OnInit {

  @Input() citation: CitationModel;

  constructor() { }

  ngOnInit() {
  }

}
