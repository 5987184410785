import { TicketSimpleModel } from "../../../models/ticket/ticket-simple.model";
import { TicketStatuses } from "../../../constants";

export class GotoTicketAcceptanceModel {
  ticket: TicketSimpleModel;
  status: TicketStatuses;
  workflowUserId: number;
  comment: string;

  static create(): GotoTicketAcceptanceModel {
    const t = new GotoTicketAcceptanceModel();
    t.ticket = new TicketSimpleModel();
    t.status = null;
    t.workflowUserId = null;
    t.comment = '';
    return t;
  }
}
