import { Component, OnInit } from "@angular/core";

@Component({
  selector: "smart-projects-info-modal",
  templateUrl: "./projects-info-modal.component.html",
  styleUrls: ["./projects-info-modal.component.scss"]
})
export class ProjectsInfoModalComponent implements OnInit {

  displayModal: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  openModal() {
    this.displayModal = true;
  }

  closeModal() {
    this.displayModal = false;
  }

}
