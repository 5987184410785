import { FormCitationPersonModel } from "./form-citation-person.model";
import { CitationDatabaseType } from "../../types/citation-database.type";

export class FormCitationModel {
  archiveId?: number;
  databaseType: CitationDatabaseType;
  /* citation category */
  categoryId?: number;
  /* registration form group */
  group?: string;
  isbn: string;
  issn: string;
  eissn: string;
  doi: string;
  title: string;
  yearOfPublishing?: number;
  pageCount?: number | string;
  wos: string;
  hostItemTitle: string;
  hostItemPageRange: string;
  hostItemIssue: string;
  hostItemVolume: string;
  meetingStartDatetime?: Date;
  meetingEndDatetime?: Date;
  meetingName: string;
  meetingCountry: string;
  meetingCity: string;
  publisherName?: string;
  publisherAddress?: string;
  biblioId: number;
  sourceId?: number;
  persons: FormCitationPersonModel[];
  uri: string;
}
