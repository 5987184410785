export class OldEpcBiblioRequestModel {
  searchPhrase: string;
  searchOption: string;
  facultyCode: string;
  departmentId: string;
  publishingYearFrom: number;
  publishingYearTo: number;
  reportingYearFrom: number;
  reportingYearTo: number;
  categoryOption: number;
  grantCategory: string;
  biblioCategoryCode: string;
  showParticipationPercentage: boolean;
  showCitations: boolean;
}
