import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { TicketSimpleModel } from "../../models/ticket/ticket-simple.model";
import { TicketService } from "../../services/ticket.service";
import { TicketStatuses } from "../../constants";
import { TicketActions, TicketActionsEnum } from "../ticket-catalog/misc/ticket-actions";
import {
  getTableColumnsByPriorityAndResolution,
  TableColumn,
  TableColumnsPriority,
  TicketTableColumnNames
} from "../../misc/table-responsive";
import { BtnFilterItemModel } from "../../components/btn-group-filter/btn-filter-item.model";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ConfirmationService } from "primeng";
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";
import { ToastrService } from "ngx-toastr";
import { BsModalService } from "ngx-bootstrap/modal";
import { TicketPriorityApprovementModalComponent } from "../ticket-catalog/ticket-priority-approvement-modal/ticket-priority-approvement-modal.component";
import {
  createSubQueryFromMultipleObjects,
  createSubQueryFromObject
} from "../../../core-frontend-common/misc/query-designer/query-designer-functions";
import { queryFilterTypes, queryOperators } from "../../../core-frontend-common/misc/query-designer/query-designer";

export enum BtnGroupsEnum {
  concept,
  sent,
  processed
}

@Component({
  selector: "smart-my-publications",
  templateUrl: "./my-publications.component.html",
  styleUrls: ["./my-publications.component.scss"]
})
export class MyPublicationsComponent implements OnInit, AfterViewInit {
  btnGroupFilterItems: BtnFilterItemModel[] = [];

  filters = {
    search: "",
    type: BtnGroupsEnum.concept,
    yearFrom: "",
    yearTo: ""
  };

  currentYear = new Date().getFullYear();

  tickets: TicketSimpleModel[] = [];

  ticketActions: TicketActions = new TicketActions();

  pTableColNames = TicketTableColumnNames;

  pTableAllCols: TableColumn[] = [
    {
      name: TicketTableColumnNames.checkbox,
      priority: TableColumnsPriority.not_visible
    },
    {
      name: TicketTableColumnNames.ac,
      priority: TableColumnsPriority.priority_6
    },
    {
      name: TicketTableColumnNames.category,
      priority: TableColumnsPriority.priority_4
    },
    {
      name: TicketTableColumnNames.status,
      priority: TableColumnsPriority.priority_5
    },
    {
      name: TicketTableColumnNames.year,
      priority: TableColumnsPriority.priority_4
    },
    {
      name: TicketTableColumnNames.info,
      priority: TableColumnsPriority.priority_1
    },
    {
      name: TicketTableColumnNames.contact,
      priority: TableColumnsPriority.priority_2
    },
    {
      name: TicketTableColumnNames.actions,
      priority: TableColumnsPriority.priority_1
    }
  ];

  notVisibleCols: TableColumn[] = [];

  pTableColsToShow: TableColumn[] = [];

  pTable = {
    colNumber: 0,
    expandedRows: {},
    loading: false,
    totalItems: 0,
    page: 1,
    pageSize: 20
  };

  checkedTickets: TicketSimpleModel[] = [];

  @ViewChild("filterInput", { static: false }) filterInput: ElementRef;

  @ViewChild(TicketPriorityApprovementModalComponent, { static: false }) ticketPriority: TicketPriorityApprovementModalComponent;

  constructor(
    protected ticketService: TicketService,
    private confirmationService: ConfirmationService,
    private translationService: I18nService,
    protected notification: ToastrService,
  ) {
  }

  ngOnInit() {
    this.generateFilters();

    this.loadTicketActions();

    this.onResize();

    console.log(this.translationService.currentLanguage)
  }

  ngAfterViewInit() {
    fromEvent(this.filterInput.nativeElement, "keyup")
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.loadData();
      });
  }

  onResize() {
    this.pTableColsToShow = getTableColumnsByPriorityAndResolution(this.pTableAllCols);
    this.notVisibleCols = this.pTableAllCols.filter(c => !this.pTableColsToShow.includes(c));
    this.pTable.colNumber = this.pTableColsToShow.length;
  }

  setPage(pageInfo) {
    this.pTable.page = (pageInfo.first + pageInfo.rows) / this.pTable.pageSize;
    this.loadData();
  }

  ticketPriorityApprovementAction(ticket: TicketSimpleModel) {
    this.ticketPriority.openModal(ticket);
  }

  generateFilters() {
    this.btnGroupFilterItems.push({
        title: this.translationService.getTranslation('Koncept'),
        id: BtnGroupsEnum.concept,
        color: "rgb(87, 136, 156)"
      },
      {
        title: this.translationService.getTranslation('Odoslané'),
        id: BtnGroupsEnum.sent,
        color: "rgb(176, 155, 91)"
      },
      {
        title: this.translationService.getTranslation('Spracované'),
        id: BtnGroupsEnum.processed,
        color: "rgb(146, 162, 168)"
      });
  }

  loadTicketActions() {
    this.ticketActions = new TicketActions();
    this.ticketActions.info = true;
    if (this.filters.type === BtnGroupsEnum.concept) {
      this.ticketActions.delete = true;
      this.ticketActions.edit = true;
    } else if (this.filters.type === BtnGroupsEnum.processed) {
      // TODO
      this.ticketActions.quote = true;
    } else if (this.filters.type === BtnGroupsEnum.sent) {
      this.ticketActions.downloadDispatchNote = true;
    }
  }

  loadData() {
    this.pTable.loading = true;

    const query = {
      search: [],
      currentStatus: {},
      yearOfPublishing: {}
    };

    const parsedYears = {
      from: new Date(this.filters.yearFrom).getFullYear(),
      to: new Date(this.filters.yearTo).getFullYear()
    };

    const queryDesigner = {
      "search": [],
      "where": {
        "operatorType": "AND",
        "operands": []
      },
      "page": this.pTable.page,
      "pageSize": this.pTable.pageSize,
      "orderBy": [
        {
          "field": "CreatedDatetime",
          "order": "DESC"
        },
        {
          "field": "Id",
          "order": "DESC"
        }
      ]
    };

    if (this.filters.type === BtnGroupsEnum.concept) {
      query.currentStatus = createSubQueryFromObject(
        queryOperators.OR, TicketStatuses.sent_to_acceptance, "currentStatus", queryFilterTypes.LESSTHAN
      );
    } else if (this.filters.type === BtnGroupsEnum.sent) {
      query.currentStatus = createSubQueryFromMultipleObjects(queryOperators.AND,
        [
          {
            obj: TicketStatuses.sent_to_acceptance,
            filterType: queryFilterTypes.GreaterThanOrEqual
          },
          {
            obj: TicketStatuses.finished,
            filterType: queryFilterTypes.LESSTHAN
          }
        ]
        , "currentStatus", null);
    } else if (this.filters.type === BtnGroupsEnum.processed) {
      query.currentStatus = createSubQueryFromObject(
        queryOperators.AND, TicketStatuses.finished, "currentStatus", queryFilterTypes.EQUAL
      );
    }

    if (parsedYears.from && parsedYears.to) {
      query.yearOfPublishing = createSubQueryFromMultipleObjects(
        queryOperators.AND, [
          {
            obj: parsedYears.from,
            filterType: queryFilterTypes.GreaterThanOrEqual
          },
          {
            obj: parsedYears.to,
            filterType: queryFilterTypes.LessThanOrEqual
          }
        ], "formBiblio.yearOfPublishing"
      );
    } else if (parsedYears.from) {
      query.yearOfPublishing = createSubQueryFromObject(
        queryOperators.AND, parsedYears.from, "formBiblio.yearOfPublishing", queryFilterTypes.GreaterThanOrEqual
      );
    } else if (parsedYears.to) {
      query.yearOfPublishing = createSubQueryFromObject(
        queryOperators.AND, parsedYears.to, "formBiblio.yearOfPublishing", queryFilterTypes.LessThanOrEqual
      );
    }

    Object.keys(query)
      .forEach(queryItem => {
        if (Object.keys(query[queryItem]).length > 0 && queryItem !== "search") {
          queryDesigner["where"]["operands"].push(query[queryItem]);
        }
      });

    if (this.filters.search.length > 0) {
      const stringArray = this.filters.search.split(/(\s+)/).filter(function(e) {
        return e.trim().length > 0;
      });
      for (let i = 0; i < stringArray.length; i++) {
        queryDesigner["search"].push({
          "fields": [],
          "value": ""
        });

        const isNumber = Number.parseInt(stringArray[i], 0);

        if (isNumber) {
          queryDesigner["search"][i]["fields"] = ["formBiblio.archiveId", "formCitation.archiveId"];
        } else {
          queryDesigner["search"][i]["fields"] = ["formBiblio.title", "createdBy.surname", "formCitation.title"];
        }

        queryDesigner["search"][i]["value"] = stringArray[i];
      }
    }

    if (queryDesigner.where.operands.length === 0) {
      delete queryDesigner.where;
    }

    this.ticketService
      .getAllTicketsForOwnerByFilter(queryDesigner)
      .subscribe(v => {
        this.pTable.totalItems = v.totalItems;
        if (v && v.items) {
          this.tickets = v.items;
        }
        this.pTable.loading = false;
      }, e => {
        console.log(e);
        this.pTable.loading = false;
      });
  }

  refreshData(refresh = false) {
    if (refresh) {
      this.loadData();
    }
  }

  btnGroupFilterChanged(id: number) {
    if (id === this.filters.type) {
      return;
    }
    this.filters.type = id;
    if (this.filters.type === BtnGroupsEnum.sent) {
      this.pTableAllCols.find(c => c.name === TicketTableColumnNames.status).priority = TableColumnsPriority.priority_3;
    } else {
      this.pTableAllCols.find(c => c.name === TicketTableColumnNames.status).priority = TableColumnsPriority.not_visible;
    }
    this.onResize();
    this.loadTicketActions();
    this.loadData();
  }

  checkedTicketItemChange(ticket: TicketSimpleModel, isChecked: boolean) {
    if (this.checkedTickets.length === 0) {
      this.checkedTickets.push(ticket);
      return;
    }

    const itemIndex = this.checkedTickets.indexOf(ticket);

    if (itemIndex !== -1 && !isChecked) {
      this.checkedTickets.splice(itemIndex, 1);
      return;
    }

    if ((itemIndex === -1 || !itemIndex) && isChecked) {
      this.checkedTickets.push(ticket);
      return;
    }
  }

  filterChange(e: Event) {
    e.preventDefault();
    this.loadData();
  }

  downloadClicked(e: Event) {
    e.preventDefault();
  }

  onTicketButtonAction(action: TicketActionsEnum, ticket: TicketSimpleModel) {
    if (action === TicketActionsEnum.delete) {
      this.deleteClicked(ticket);
    }
  }

  deleteClicked(ticket: TicketSimpleModel) {
    this.confirmationService.confirm({
      message: this.translationService.getTranslation(
        `Skutočne si prajete zmazať zaregistrovanú ${ticket.type === "biblio" ? "publikáciu" : "citáciu"}? Táto akcia je nenávratná.`
      ),
      header: this.translationService.getTranslation(`Zmazanie zaregistrovanej ${ticket.type === "biblio" ? "publikácie" : "citácie"}`),
      acceptLabel: this.translationService.getTranslation("Zmazať"),
      rejectButtonStyleClass: "btn danger-btn",
      rejectLabel: this.translationService.getTranslation("Ponechať"),
      rejectVisible: false,
      accept: () => {
        this.ticketService
          .deleteTicketByOwner(ticket.id)
          .subscribe(v => {
            this.loadData();
            this.notification.success(this.translationService.getTranslation(`${ticket.type === "biblio" ? "Publikácia" : "Citácia"} bola úspešne zmazaná.`));
          }, err => {
            console.log(err);
            this.notification.error(this.translationService.getTranslation(`${ticket.type === "biblio" ? "Publikáciu" : "Citáciu"} sa nepodarilo zmazať.`));
          });
      }
    });
  }

}
