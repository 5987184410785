import { NgModule } from "@angular/core";
import { YoutubePlayerComponent } from "./youtube-player.component";
import { CustomPipesModule } from "../../pipes/customPipes.module";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    CustomPipesModule,
    CommonModule
  ],
  exports: [
    YoutubePlayerComponent
  ],
  declarations: [
    YoutubePlayerComponent
  ]
})

export class YoutubePlayerModule {}
