import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserModel } from '../models/user.model';
import {
  getAllWorkflowUsersUrl,
  getHistoricalUserByIdUrl,
  getSearchedUserUrl,
  getSearchUserCollateUrl, getTukeAuthorChangeSearchedParticipantUrl, getTukeAuthorEmploymentsByLoginUrl,
  getUpdateExternalUserUrl,
  getUserPhotoUrl, 
  getPersonsByRoleUrl
} from "../app.urls";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { FormChangeAuthorParticipantRequestModel } from "../models/form-change-author-participant-request.model";
import { TicketFullModel } from "../models/ticket/ticket-full.model";
import { UserByRoleModel } from '../models/user-by-role.model';

@Injectable()
export class UserService {

  constructor(
    private http: HttpClient
  ) {}

  getUserById(id: number): Observable<UserModel> {
      return this.http.post(getHistoricalUserByIdUrl(id), '').pipe(map(res => res as UserModel));
  }

  getPhotoForUser(hash): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'blob',
    };
      return this.http.get(getUserPhotoUrl(hash), httpOptions).pipe(map((res: any) => res.blob));
  }

  getSearched(user): Observable<any> {
      return this.http.post(getSearchedUserUrl(), user);
  }

  searchUser(phrase: string): Observable<any> {
      return this.http.get(getSearchUserCollateUrl(phrase));
  }

  updateExternalThesisUser(userData): Observable<any> {
      return this.http.put(getUpdateExternalUserUrl(userData.id), userData);
  }

  getAllWorkflowUsers(): Observable<UserModel[]> {
    return this.http.get(getAllWorkflowUsersUrl()).pipe(map((res: any) => res.items as UserModel[]));
  }

  getTukeAuthorEmploymentsByLogin(login: string): Observable<any[]> {
    return this.http.get(getTukeAuthorEmploymentsByLoginUrl(login)).pipe(map((res: any) => res.items as any[]));
  }

  changeSearchedParticipant(participant: FormChangeAuthorParticipantRequestModel): Observable<TicketFullModel> {
    return this.http.post(getTukeAuthorChangeSearchedParticipantUrl(), participant).pipe(map((res: any) => res));
  }

  getPersonsByRole(role: string): Observable<UserByRoleModel[]> {
    return this.http.get(getPersonsByRoleUrl(role)).pipe(map((res: any) => res as UserByRoleModel[]));
  }

  
}
