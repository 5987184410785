import { TicketStatuses, UserRoles } from "../../../constants";

export enum ticketSectionsEnum {
  acceptance,
  scan,
  conversion,
  validation,
  cataloging,
  importDawinci,
  contact,
  hand_over,
  allRecords,
  allRecordsAdmin
}

export const ticketTabs: TicketSection[] = [
  {
    name: 'acceptance',
    section: ticketSectionsEnum.acceptance,
    in_data_param: TicketStatuses.in_acceptance,
    to_data_param: TicketStatuses.sent_to_acceptance,
    allowedRoles: UserRoles.All
  },
  {
    name: 'scan',
    section: ticketSectionsEnum.scan,
    in_data_param: TicketStatuses.in_scan,
    to_data_param: TicketStatuses.to_scan,
    allowedRoles: UserRoles.All
  },
  {
    name: 'conversion',
    section: ticketSectionsEnum.conversion,
    in_data_param: TicketStatuses.in_conversion,
    to_data_param: TicketStatuses.to_conversion,
    allowedRoles: UserRoles.All
  },
  {
    name: 'validation',
    section: ticketSectionsEnum.validation,
    in_data_param: TicketStatuses.in_validation,
    to_data_param: [TicketStatuses.scanned, TicketStatuses.converted],
    allowedRoles: [UserRoles.Superadmin, UserRoles.EpcWorkflowAdmin]
  },
  {
    name: 'cataloging',
    section: ticketSectionsEnum.cataloging,
    in_data_param: TicketStatuses.in_catalogization,
    to_data_param: TicketStatuses.to_catalogize,
    allowedRoles: UserRoles.All
  },
  {
    name: 'importDawinci',
    section: ticketSectionsEnum.importDawinci,
    in_data_param: TicketStatuses.in_dawinci_check,
    to_data_param: [TicketStatuses.sent_to_crepc, TicketStatuses.imported_to_dawinci],
    allowedRoles: UserRoles.All
  },
  {
    name: 'contact',
    section: ticketSectionsEnum.contact,
    in_data_param: TicketStatuses.in_contact,
    to_data_param: TicketStatuses.to_contact,
    allowedRoles: [UserRoles.Superadmin, UserRoles.EpcWorkflowAdmin]
  },
  {
    name: 'handOver',
    section: ticketSectionsEnum.hand_over,
    in_data_param: TicketStatuses.in_hand_over_to_author,
    to_data_param: TicketStatuses.to_hand_over,
    allowedRoles: UserRoles.All
  },
  {
    name: 'allRecords',
    section: ticketSectionsEnum.allRecords,
    allowedRoles: UserRoles.All
  },
  {
    name: 'allRecordsAdmin',
    section: ticketSectionsEnum.allRecordsAdmin,
    allowedRoles: UserRoles.All
  }
];

export class TicketSection {
  name: string;
  section: ticketSectionsEnum;
  in_data_param?: number;
  to_data_param?: number | number[];
  allowedRoles: readonly string[];
}
