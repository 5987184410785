import { NgModule } from "@angular/core";
import { DwToolsViewComponent } from "./dw-tools-view.component";
import { AccordionModule, AutoCompleteModule, DropdownModule, InputTextModule, SelectButtonModule, TabViewModule } from "primeng";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";
import { Epc2DwSectionComponent } from './epc2-dw-section/epc2-dw-section.component';
import { FormsModule } from "@angular/forms";
import { DwToolsService } from "../../services/dw-tools.service";
import { CommonModule } from "@angular/common";
import { Epc2AddDatabasesComponent } from './epc2-add-databases/epc2-add-databases.component';
import { SearchACComponent } from './search-ac/search-ac.component';
import { DatabaseFormComponent } from './database-form/database-form.component';
import { QuartilUpdateSectionComponent } from './quartil-update-section/quartil-update-section.component';
import { QuartilEditorComponent } from './quartil-editor/quartil-editor.component';
import { UserHistorySectionComponent } from './user-history-section/user-history-section.component';
import { CustomPipesModule } from "../../pipes/customPipes.module";
import { ArticleIdentifiersSectionComponent } from './article-identifiers-section/article-identifiers-section.component';
import { CrepcUpdateSectionComponent } from './crepc-update-section/crepc-update-section.component';

@NgModule({
  declarations: [
    DwToolsViewComponent,
    Epc2DwSectionComponent,
    Epc2AddDatabasesComponent,
    SearchACComponent,
    DatabaseFormComponent,
    QuartilUpdateSectionComponent,
    QuartilEditorComponent,
    UserHistorySectionComponent,
    ArticleIdentifiersSectionComponent,
    CrepcUpdateSectionComponent
  ],
  imports: [
    TabViewModule,
    I18nModule,
    InputTextModule,
    FormsModule,
    CommonModule,
    SelectButtonModule,
    DropdownModule,
    AccordionModule,
    CustomPipesModule,
    AutoCompleteModule
  ],
  exports: [
    DwToolsViewComponent
  ],
  providers: [
    DwToolsService
  ]
})

export class DwToolsViewModule {}
