import { NgModule } from '@angular/core';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { BreadcrumbsService } from './breadcrumbs.service';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap';
import { I18nModule } from '../../../core-frontend-common/template/shared/i18n/i18n.module';
import { UtilsModule } from '../../../core-frontend-common/template/shared/utils/utils.module';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
  ],
  exports: [
    BreadcrumbsComponent,
  ],
  imports: [
    CommonModule,
    UtilsModule,
    I18nModule,
    TooltipModule,
  ],
  providers: [
    BreadcrumbsService,
  ],
})

export class BreadcrumbsModule {
}
