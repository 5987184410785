import { Injectable } from '@angular/core';
import { BreadcrumbModel } from './breadcrumb.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  public breadcrumbs: BreadcrumbModel[] = [];

  public breadcrumbsChanged: Subject<boolean> = new Subject<boolean>();

  public clickedBreadcrumb: Subject<boolean> = new Subject<boolean>();

  public BREADCRUMB_STORAGE_KEY = 'breadcrumbs';

  public BREADCRUMB_MAX_ITEMS = 10;

  constructor() { }

  public push(breadcrumb: BreadcrumbModel, save = true): BreadcrumbModel[] {
    if (!breadcrumb.id) {
      breadcrumb.id = this.generateRandomNumber();
    }
    this.breadcrumbs.push(breadcrumb);
    if (this.breadcrumbs.length > this.BREADCRUMB_MAX_ITEMS) {
      this.breadcrumbs.splice(0, 1);
    }
    if (save) {
      this.saveToStorage();
    }
    this.breadcrumbsChanged.next();
    return this.breadcrumbs;
  }

  getLast(): BreadcrumbModel {
    if (this.breadcrumbs.length > 0) {
      return this.breadcrumbs[this.breadcrumbs.length - 1];
    }
    return null;
  }

  public setActiveBreadcrumbById(id: number, save = true): BreadcrumbModel[] {
    const index = this.breadcrumbs.findIndex(b => b.id === id);
    if ((index + 1 <= this.breadcrumbs.length)) {
      this.breadcrumbs.splice(index + 1, this.breadcrumbs.length - (index + 1));
      if (save) {
        this.saveToStorage();
      }
    }
    setTimeout(() => {
      this.clickedBreadcrumb.next(true);
    }, 50);

    return this.breadcrumbs;
  }

  public removeLast(save = true): BreadcrumbModel[] {
    this.breadcrumbs.pop();
    if (save) {
      this.saveToStorage();
    }
    this.breadcrumbsChanged.next();
    return this.breadcrumbs;
  }

  public removeById(id: number, save = true): BreadcrumbModel[] {
    const index = this.breadcrumbs.findIndex(i => i.id === id);
    if (index && index !== -1) {
      this.breadcrumbs = this.breadcrumbs.splice(this.breadcrumbs.findIndex(e => e.id === id), 1);
    }
    if (save) {
      this.saveToStorage();
    }
    this.breadcrumbsChanged.next();
    return this.breadcrumbs;
  }

  public removeAll(save = true): BreadcrumbModel[] {
    this.breadcrumbs = [];
    if (save) {
      this.saveToStorage();
    }
    this.breadcrumbsChanged.next();
    this.clickedBreadcrumb.next(false);
    return this.breadcrumbs;
  }

  public getById(id: number): BreadcrumbModel | null {
    const index = this.breadcrumbs.findIndex(i => i.id === id);
    if (index && index !== -1) {
      return this.breadcrumbs[index];
    }
    return null;
  }

  public saveToStorage() {
    const exists = localStorage.getItem(this.BREADCRUMB_STORAGE_KEY);
    if (exists) {
      localStorage.removeItem(this.BREADCRUMB_STORAGE_KEY);
    }
    localStorage.setItem(this.BREADCRUMB_STORAGE_KEY, JSON.stringify(this.breadcrumbs));
  }

  public getFromStorage() {
    const items = localStorage.getItem(this.BREADCRUMB_STORAGE_KEY);
    if (items) {
      this.breadcrumbs = JSON.parse(items);
      this.breadcrumbsChanged.next();
      return this.breadcrumbs;
    }
    this.breadcrumbs = [];
    return this.breadcrumbs;
  }

  generateRandomNumber(): number {
    const ids = [];
    this.breadcrumbs.forEach(b => ids.push(b.id));
    const random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
    if (ids.indexOf(random) === -1) {
      return random;
    } else {
      return this.generateRandomNumber();
    }
  }
}
