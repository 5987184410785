import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbModel } from './breadcrumb.model';
import { BreadcrumbsService } from './breadcrumbs.service';

@Component({
  selector: 'smart-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  activeBreadcrumb: BreadcrumbModel;

  breadcrumbs: BreadcrumbModel[] = [];

  constructor(
    protected breadcrumbsService: BreadcrumbsService,
  ) {
  }

  ngOnInit() {
    this.breadcrumbs = this.breadcrumbsService.breadcrumbs;

    this.breadcrumbsService
      .breadcrumbsChanged
      .subscribe(() => {
        this.breadcrumbs = this.breadcrumbsService.breadcrumbs;
      });
  }

  goBack(e: Event) {
    e.preventDefault();

    if (this.breadcrumbs.length > 1) {
      this.breadcrumbsService
        .setActiveBreadcrumbById(this.breadcrumbs[this.breadcrumbs.length - 2].id);
    } else {
      this.removeAllBreadcrumbs(e);
    }
  }

  clickBreadCrumb(breadcrumb: BreadcrumbModel) {
    this.activeBreadcrumb = breadcrumb;
    this.breadcrumbsService.setActiveBreadcrumbById(breadcrumb.id);
  }

  removeAllBreadcrumbs(event: Event) {
    event.preventDefault();
    this.breadcrumbsService.removeAll();
  }

}
