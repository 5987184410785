import { DwDatabaseModel } from "./dw-database.model";

export class DwRecordSimpleModel {
  objectId: number;
  crepcId: number;
  title: string;
  restOfTitle: string;
  yearOfPublishing: number;
  archiveId: number;
  databases: DwDatabaseModel[];
  quartil?: string;

  hostItemTitle?: string;
  issn?: string;
}
