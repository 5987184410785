import { Component, Input, OnInit } from "@angular/core";
import { BiblioModel } from "../../../models/biblio/biblio.model";
import { Subject } from "rxjs";
import { BiblioCatalogSharedService } from "../biblio-catalog-shared.service";
import { CitationModel } from "../../../models/biblio/citation.model";

@Component({
  selector: 'smart-citations-detail',
  templateUrl: './citations-detail.component.html',
  styleUrls: ['./citations-detail.component.scss']
})
export class CitationsDetailComponent implements OnInit {

  citedBiblio: BiblioModel;

  citations: CitationModel[] = [];

  @Input() dataChanged: Subject<any> = new Subject<any>();

  constructor(
    protected catalogDataService: BiblioCatalogSharedService
  ) { }

  ngOnInit() {
    this.citations = this.catalogDataService.data.citations;
    this.citedBiblio = this.catalogDataService.data.biblio;
  }

}
