import { Pipe, PipeTransform } from '@angular/core';
import { BiblioCategoryCodebookModel } from "../models/codebook/biblio-category-codebook.model";
import { CODEBOOK_ITEMS, CodebooksService } from "../services/codebooks.service";

@Pipe({
  name: 'biblioCategory',
  pure: false
})
export class BiblioCategoryPipe implements PipeTransform {

  biblioCategories: BiblioCategoryCodebookModel[] = [];

  constructor(
    protected codebooks: CodebooksService
  ) {
    this.loadData();
  }

  async loadData() {
    this.biblioCategories = await this.codebooks.get(CODEBOOK_ITEMS.biblioCategories);
  }

  transform(category: number | string, type: 'id' | 'code' = 'id'): any {
    if (this.biblioCategories && category) {
      if (type === 'id') {
        return this.biblioCategories.find(c => c.id === category).code;
      }

      if (type === 'code') {
        const item = this.biblioCategories.find(c => c.code === category);

        if (item && item.description) {
          return item.description;
        }
      }
    }
    return ' - ';
  }

}
