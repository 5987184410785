import { NgModule } from "@angular/core";
import { BiblioCatalogViewComponent } from "./biblio-catalog-view.component";
import { BiblioListComponent } from './biblio-list/biblio-list.component';
import { MeetingListComponent } from './meeting-list/meeting-list.component';
import { PersonListComponent } from './person-list/person-list.component';
import { BiblioItemComponent } from './components/biblio-item/biblio-item.component';
import { PersonItemComponent } from './components/person-item/person-item.component';
import { MeetingItemComponent } from './components/meeting-item/meeting-item.component';
import { CommonModule } from "@angular/common";
import { PaginatorModule } from "primeng";
import { BiblioDetailModule } from "../../components/biblio-detail/biblio-detail.module";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";
import { TooltipModule } from "ngx-bootstrap";
import { CitationModule } from "../catalog/components/citation/citation.module";
import { AuthorDetailComponent } from './author-detail/author-detail.component';
import { CatalogFilterComponent } from './components/catalog-filter/catalog-filter.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { KeywordDetailComponent } from "./keyword-detail/keyword-detail.component";
import { BreadcrumbsModule } from "../../components/breadcrumbs/breadcrumbs.module";
import { CitationsDetailComponent } from './citations-detail/citations-detail.component';
import { CitationsListComponent } from './citations-list/citations-list.component';
import { CitationItemComponent } from './components/citation-item/citation-item.component';
import { CommonSharedPipesModule } from "../../../core-frontend-common/pipes/common-shared-pipes.module";

@NgModule({
  imports: [
    CommonModule,
    PaginatorModule,
    BiblioDetailModule,
    I18nModule,
    TooltipModule,
    CitationModule,
    NgMultiSelectDropDownModule,
    BreadcrumbsModule,
    CommonSharedPipesModule
  ],
  declarations: [
    BiblioCatalogViewComponent,
    BiblioListComponent,
    MeetingListComponent,
    PersonListComponent,
    BiblioItemComponent,
    PersonItemComponent,
    MeetingItemComponent,
    AuthorDetailComponent,
    CatalogFilterComponent,
    KeywordDetailComponent,
    CitationsDetailComponent,
    CitationsListComponent,
    CitationItemComponent
  ]
})

export class BiblioCatalogViewModule {}
