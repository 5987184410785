import { ProjectModel } from "./project.model";
import { UserModel } from "../user.model";

export class ProjectParticipantModel {
  id?: number;
  login: string;
  name: string;
  surname: string;
  title: string;
  titleAfter: string;
  institutionName: string;
  institutionCode: string;
  departmentName: string;
  departmentCode: string;
  departmentIdAsString: string;
  isSupervisor: boolean;
  projectId: number;
  project: ProjectModel;

  isExternal?: boolean;

  static createFromUserModel(user: UserModel): ProjectParticipantModel {
    const participant = new ProjectParticipantModel();
    participant.login = user.login;
    participant.name = user.name;
    participant.surname = user.surname;
    participant.title = user.title;
    participant.titleAfter = user.titleAfter;
    return participant;
  }
}
