/*
* functions for table responsivity
* priority is number from 1 - 6; 1 for highest priority to display, 6 the lowest
*
* */

export enum TableColumnsPriority {
  not_visible,
  priority_1,
  priority_2,
  priority_3,
  priority_4,
  priority_5,
  priority_6
}

export class TableColumn {
  name: TicketTableColumnNames;
  priority: TableColumnsPriority;
}

export function getTableColumnsByPriorityAndResolution(columns: TableColumn[]): TableColumn[] {
  const maxPriority: TableColumnsPriority = getMaxPriorityForCurrentResolution();

  return columns.filter(c => c.priority <= maxPriority && c.priority !== TableColumnsPriority.not_visible);
}

export function getMaxPriorityForCurrentResolution(): TableColumnsPriority {
  const width = window.window.innerWidth;

  // default set max priority
  let priority = TableColumnsPriority.priority_6;

  if (width >= 1120) {
    priority = TableColumnsPriority.priority_6;
  } else if (width >= 960) {
    priority = TableColumnsPriority.priority_5;
  } else if (width >= 800) {
    priority = TableColumnsPriority.priority_5;
  } else if (width >= 640) {
    priority = TableColumnsPriority.priority_4;
  } else if (width >= 480) {
    priority = TableColumnsPriority.priority_3;
  } else if (width > 320) {
    priority = TableColumnsPriority.priority_3;
  } else if (width <= 320) {
    priority = TableColumnsPriority.priority_3;
  }
  return priority;
}

export enum TicketTableColumnNames {
  checkbox,
  ac,
  category,
  status,
  year,
  info,
  contact,
  actions,
  citedCount
}
