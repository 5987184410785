import { CountryCodebookModel } from "../codebook/country-codebook.model";
import { BiblioCategoryCodebookModel } from "../codebook/biblio-category-codebook.model";
import { LanguageCodebookModel } from "../codebook/language-codebook.model";
import { MediumCodebookModel } from "../codebook/medium-codebook.model";
import { MeetingModel } from "./meeting.model";
import { EditionModel } from "./edition.model";
import { PatentModel } from "./patent.model";
import { HostItemModel } from "./host-item.model";
import { KeywordModel } from "./keyword.model";
import { PublishingModel } from "./publishing.model";
import { CitationModel } from "./citation.model";
import { DuplicityModel } from "./duplicity.model";
import { ProjectModel } from "./project.model";
import { DatabaseModel } from "./database.model";
import { BiblioPersonModel } from "./biblio-person.model";

export class BiblioModel {
  id: number;
  objectId: number;
  recordLabel: string;
  dwId: string;
  recordLastUpdateDatetime: Date;
  group: string;
  isbn: string;
  issn: string;
  eissn: string;
  doi: string;
  crepcId: number;
  originCatalogInstitution: string;
  title: string;
  restOfTitle: string;
  abstract: string;
  responsiblePersonsString: string;
  issueOrderNumber: string;
  placeOfPublishing: string;
  nameOfPublisher: string;
  yearOfPublishing: number;
  yearOfReporting?: number;
  pageCount: string;
  uri: string;
  archiveId?: number;
  citationDatabaseCode: string;
  impactFactor?: number;
  isPublished?: boolean;
  isReviewed?: boolean;
  participantCount?: number;
  isCrepcVerified?: boolean;
  comment: string;
  institutionName: string;
  institutionRole: string;
  countryCodeIso: CountryCodebookModel;
  biblioCategory: BiblioCategoryCodebookModel;
  language: LanguageCodebookModel;
  medium: MediumCodebookModel;
  meeting: MeetingModel;
  edition: EditionModel;
  patent: PatentModel;
  hostItem: HostItemModel;
  keywords: KeywordModel[];
  publishings: PublishingModel[];
  citations: CitationModel[];
  duplicities: DuplicityModel[];
  projects: ProjectModel[];
  databases: DatabaseModel[];

  selected?: boolean;
}
