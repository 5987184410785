import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormProjectModel } from "../../../../models/registration-form/form-project.model";
import { FormElements } from "../../../ticket-catalog/misc/form-elements";
import { ProjectService } from "../../../../services/project.service";
import { AutoComplete } from "primeng";

export enum states {
  "empty",
  "searching",
  "searched"
}

export enum searchByProjectTypes {
  "searchByProjectName" = "searchByProjectName",
  "searchByGrantNumber" = "searchByGrantNumber"
}

@Component({
  selector: "smart-project-section",
  templateUrl: "./project-section.component.html",
  styleUrls: ["./project-section.component.scss"]
})
export class ProjectSectionComponent implements OnInit {
  states = states;

  currentState: states = states.empty;

  searchByProjectTypes = searchByProjectTypes;

  searchByTypes: any[] = [
    {
      title: searchByProjectTypes.searchByProjectName
    }, {
      title: searchByProjectTypes.searchByGrantNumber
    }];

  searchBy = this.searchByTypes[0];

  isValid = false;

  searchedProjects: FormProjectModel[] = [];

  requiredKeys = [];

  @Input() project: FormProjectModel = new FormProjectModel();

  @Input() projectsCount: number;

  @Input() currentProjectIndex: number;

  @Input() formElements: FormElements;

  @Output() changeProject: EventEmitter<{ valid: boolean, data: FormProjectModel }> = new EventEmitter<{ valid: boolean, data: FormProjectModel }>();

  @Output() addProjectEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() removeProject: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("projectNameEl", { static: false }) projectNameEl: AutoComplete;
  @ViewChild("grantNumberEl", { static: false }) grantNumberEl: AutoComplete;

  constructor(
    protected projectService: ProjectService
  ) {
  }

  ngOnInit() {
    this.loadRequiredKeys();
  }

  loadRequiredKeys() {
    const hostItemFormElements = {};

    const keys = [];

    Object.assign(hostItemFormElements, this.formElements);

    Object.keys(hostItemFormElements)
      .forEach(key => {
        if (key.indexOf("project") === -1) {
          delete hostItemFormElements[key];
        } else {
          if (hostItemFormElements[key].required) {
            keys.push(key);
          }
        }
      });

    keys.forEach((key: string) => {
      let truncated = key.replace("project.", "");
      truncated = truncated.charAt(0).toLowerCase() + truncated.substring(1, key.length - 1);
      this.requiredKeys.push(truncated);
    });

    setTimeout(() => {
      this.change();
    }, 1000);
  }

  change() {

    this.isValid = true;

    const err = this.requiredKeys
      .find(key => !this.project[key]);

    if (err) {
      this.isValid = false;
    }

    if (this.isValid && this.project.id) {
      this.currentState = this.states.searched;
    }

    this.checkIfIsProjectFromCrossref(this.project);

    this.changeProject.emit({ valid: this.isValid, data: this.project });
    return;
  }

  async checkIfIsProjectFromCrossref(project: FormProjectModel) {
    if (!project.id && project.grantNumber && project.orderNumber) {
      const projectRes = await this.projectService
        .searchProject({
          grantNumber: project.grantNumber.slice(0, 49)
        }).toPromise();

      if (projectRes && projectRes.totalItems === 1) {
        this.project = projectRes.items[0];
        this.refreshAutocompleteElements();
        this.change();
      } else {
        this.removeProject.emit(true);
      }
    }
  }

  selectProject(project: FormProjectModel) {
    Object.assign(this.project, project);
    this.refreshAutocompleteElements();
    this.change();
  }

  refreshAutocompleteElements() {
    if (this.grantNumberEl.inputFieldValue !== this.project.grantNumber && this.project.grantNumber) {
      this.grantNumberEl.inputFieldValue = this.project.grantNumber;
    }
  }

  searchProject(event, type: searchByProjectTypes) {
    let searchType;
    if (type === searchByProjectTypes.searchByGrantNumber) {
      searchType = {
        grantNumber: event.query.slice(0, 49)
      };
    } else {
      searchType = {
        name: event.query
      };
    }

    this.projectService
      .searchProject(searchType)
      .subscribe(val => {
        this.searchedProjects = val.items;
      });
  }

  getHostItem(): FormProjectModel {
    return this.project;
  }

  isValidHostItem() {
    return this.isValid;
  }

  addAnotherProject(e: Event) {
    e.preventDefault();
    if (this.isValid) {
      this.addProjectEvent.emit(true);
    }
  }

  removeThisProject(e: Event) {
    e.preventDefault();
    this.removeProject.emit(true);
  }


  closeProjectSearching(e: Event) {
    e.preventDefault();
    this.removeProject.emit(true);
  }
}

