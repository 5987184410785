import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BiblioModel } from '../../../../../../models/biblio/biblio.model';
import { BiblioService } from '../../../../../../services/biblio.service';

@Component({
  selector: 'smart-biblio-item',
  templateUrl: './biblio-item.component.html',
  styleUrls: ['./biblio-item.component.scss']
})
export class BiblioItemComponent implements OnInit {

  @Output() clickedDetailBiblio = new EventEmitter<any>();

  @Input() biblio: BiblioModel;

  constructor(
    protected biblioService: BiblioService
  ) { }

  ngOnInit() {
  }

  clickDetailBiblio(biblio) {
    this.clickedDetailBiblio.emit(biblio);
  }

}
