import { Component, OnInit } from "@angular/core";
import { MeetingsService } from "../../../../services/meetings.service";
import { FilterService, FilterToShow } from "../filter/filter.service";
import { MeetingModel } from "../../../../models/biblio/meeting.model";
import { createSubQuery } from "../../../../../core-frontend-common/misc/query-designer/query-designer-functions";
import { queryFilterTypes, queryOperators } from "../../../../../core-frontend-common/misc/query-designer/query-designer";

@Component({
  selector: 'smart-meeting-table',
  templateUrl: './meeting-table.component.html',
  styleUrls: ['./meeting-table.component.scss']
})
export class MeetingTableComponent implements OnInit {
  data: MeetingModel[] = [];
  personsFilter: any;
  datatableSettings = {
    loading: false,
    paginator: true,
    pageSize: 20,
    totalRecords: 0,
    lazy: true,
    colNumber: 4,
    page: 1
  };
  collapsed = false;

  constructor(
    protected meetingsService: MeetingsService,
    protected filterService: FilterService
  ) { }

  ngOnInit() {
    this.onResize(window.innerWidth, true);

    setTimeout(() => {
      this.filterService
        .filterToShow
        .next(new FilterToShow(true, true, false, false, false));
    }, 200);
  }

  onResize(event, onLoad = false) {
    if (onLoad) {
      this.getWindowWidth(event);
    } else {
      this.getWindowWidth(event.target.innerWidth);
    }
  }

  getWindowWidth(width) {
    if (width >= 1120) {
      this.datatableSettings.colNumber = 4;
    } else if (width >= 960) {
      this.datatableSettings.colNumber = 4;
    } else if (width >= 800) {
      this.datatableSettings.colNumber = 4;
    } else if (width >= 640) {
      this.datatableSettings.colNumber = 4;
    } else if (width >= 480) {
      this.datatableSettings.colNumber = 4;
    } else if (width > 320) {
      this.datatableSettings.colNumber = 4;
    } else if (width <= 320) {
      this.datatableSettings.colNumber = 4;
    }
  }

  getMeetings(dataFilter = null) {
    this.datatableSettings.loading = true;

    let filter;

    if (dataFilter) {
      filter = this.fulfillFilter(dataFilter);
      this.personsFilter = dataFilter;
      this.datatableSettings.pageSize = dataFilter.pageSize.value;
    } else {
      return;
    }

    if (!filter) {
      return;
    }

    this.meetingsService
      .getAllByFilter(filter)
      .subscribe(val => {
        this.datatableSettings.totalRecords = val.totalItems as number;
        this.data = val.items;
        this.datatableSettings.loading = false;
      }, err => {
        this.datatableSettings.loading = false;
      });
  }

  fulfillFilter(filter: any) {
    const query = {
      search: [],
      year: {}
    };
    const queryDesigner = {
      search: [],
      where: {
        operatorType: 'AND',
        operands: [],
      },
      page: this.datatableSettings.page,
      pageSize: filter.pageSize.value,
      orderBy: [
        {
          field: 'CreatedDatetime',
          order: 'DESC',
        },
        {
          field: 'Id',
          order: 'DESC',
        },
      ],
    };

    let hasOperands = false;

    if (filter) {
      if (filter && filter.text && filter.text.length > 0) {
        const stringArray = filter.text.split(/(\s+)/).filter(function(e) {
          return e.trim().length > 0;
        });
        for (let i = 0; i < stringArray.length; i++) {
          queryDesigner.search.push({
            fields: [],
            value: '',
          });
          queryDesigner.search[i].fields = ['fullName'];
          queryDesigner.search[i].value = stringArray[i];
        }
      } else {
        delete queryDesigner.search;
      }

      if (filter && filter.year && filter.year.length > 0) {
        query.year = createSubQuery(queryOperators.OR, filter.year, 'meetingDateString', queryFilterTypes.Contains, 'item_id');
        hasOperands = true;
      } else {
        delete query.year;
      }

      Object.keys(query)
        .forEach(queryItem => {
          if (Object.keys(query[queryItem]).length > 0 && queryItem !== 'search') {
            queryDesigner.where.operands.push(query[queryItem]);
          }

          if (!hasOperands) {
            delete queryDesigner.where;
          }
        });

      return queryDesigner;
    }
    return queryDesigner;
  }

  setPage(pageInfo) {
    if (this.personsFilter && this.datatableSettings.pageSize) {
      this.datatableSettings.page = (pageInfo.first + pageInfo.rows) / this.datatableSettings.pageSize;
      this.getMeetings();
      this.scrollToTop();
    }
  }

  scrollToTop() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  collapseAll() {
    this.collapsed = !this.collapsed;
  }

}
