import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  getAdminLockProjectUrl,
  getAllProjectsByFilterUrl, getAllProjectsForSupervisorByFilterUrl, getExportProjectsUrl,
  getProjectByIdUrl, getProjectEvidenceCreateUrl, getProjectEvidenceDeleteUrl, getProjectEvidenceUpdateUrl,
  getSearchProjectsUrl
} from "../app.urls";
import { map } from "rxjs/operators";
import { FormProjectModel } from "../models/registration-form/form-project.model";
import { ProjectSearchRequestModel } from "../models/search/project-search-request.model";
import { ProjectModel } from "../models/project/project.model";

@Injectable({
  providedIn: "root"
})
export class ProjectService {

  constructor(
    private http: HttpClient
  ) {
  }

  searchProject(filter: ProjectSearchRequestModel): Observable<{ totalItems: number, items: FormProjectModel[] }> {
    return this.http
      .post(getSearchProjectsUrl(), filter)
      .pipe(map((res: any) => {
        return {
          totalItems: res.result.totalItems,
          items: res.result.items as FormProjectModel[]
        };
      }));
  }

  getAllByFilter(filter: ProjectSearchRequestModel): Observable<{ totalItems: number, items: ProjectModel[] }> {
    return this.http
      .post(getAllProjectsByFilterUrl(), filter)
      .pipe(map((res: any) => {
        return {
          totalItems: res.result.totalItems,
          items: res.result.items as ProjectModel[]
        };
      }));
  }

  getAllForSupervisorByFilter(filter: ProjectSearchRequestModel): Observable<{ totalItems: number, items: ProjectModel[] }> {
    return this.http
      .post(getAllProjectsForSupervisorByFilterUrl(), filter)
      .pipe(map((res: any) => {
        return {
          totalItems: res.result.totalItems,
          items: res.result.items as ProjectModel[]
        };
      }));
  }

  getById(id: number): Observable<ProjectModel> {
    return this.http
      .get(getProjectByIdUrl(id))
      .pipe(map((res: any) => {
        return res.result as ProjectModel;
      }));
  }

  adminLockProject(id: number, isLocked: boolean): Observable<any> {
    return this.http
      .get(getAdminLockProjectUrl(id, isLocked))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  projectCreate(request): Observable<any> {
    return this.http
      .post(getProjectEvidenceCreateUrl(), request)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  projectUpdate(request): Observable<any> {
    return this.http
      .post(getProjectEvidenceUpdateUrl(), request)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  projectDelete(id: number): Observable<any> {
    return this.http
      .delete(getProjectEvidenceDeleteUrl(id))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  exportProjects(filter: ProjectSearchRequestModel): Observable<any> {
    return this.http
      .post(getExportProjectsUrl(), filter)
      .pipe(map((res: any) => res));
  }
}
