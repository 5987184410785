import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProjectModel } from "../../../models/project/project.model";
import { ProjectService } from "../../../services/project.service";
import { CONSTANT_ITEMS, ConstantsService } from "../../../services/constants.service";
import { ProjectInstitutionModel } from "../../../models/project-institution.model";
import { ProjectParticipantModel } from "../../../models/project/project-participant.model";
import { CODEBOOK_ITEMS, CodebooksService } from "../../../services/codebooks.service";
import { ToastrService } from "ngx-toastr";
import { AppCommunicationService } from "../../../services/app-communication.service";
import { deleteSpecialChars } from "../../../misc/delete-special-chars";
import { GrantPriceModel } from "./grant-prices-subform/grant-prices-subform.component";
import { ResearchAndTechCodebookModel } from "../../../models/codebook/research-and-tech-codebook.model";
import { ProjectResearchAreaCodebookModel } from "../../../models/codebook/project-research-area-codebook.model";
import * as moment from "moment";
import { getCalendarLocale } from "../../../constants";
import { sortByPriority } from "../../../misc/sort-by-priority";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-project-form-page",
  templateUrl: "./project-form-page.component.html",
  styleUrls: ["./project-form-page.component.scss"]
})
export class ProjectFormPageComponent implements OnInit {

  skLocaleCalendar = getCalendarLocale();

  projectForm: FormGroup;

  loadedProject: ProjectModel = new ProjectModel();

  institutions: ProjectInstitutionModel[] = [];

  filteredInstitutions: ProjectInstitutionModel[] = [];

  selectedInstitution: ProjectInstitutionModel;

  keywords: string[] = [];

  participants: ProjectParticipantModel[] = [new ProjectParticipantModel()];

  showDepartments = false;

  disabledForm = false;

  researchAndTech: ResearchAndTechCodebookModel[] = [];

  filteredResearchAndTech: ResearchAndTechCodebookModel[] = [];

  selectedResearchAndTech: ResearchAndTechCodebookModel;

  projectResearchAreas: ProjectResearchAreaCodebookModel[] = [];

  filteredProjectResearchAreas: ProjectResearchAreaCodebookModel[] = [];

  selectedProjectResearchArea: ProjectResearchAreaCodebookModel;

  @Output() wasDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() set setDisabledForm(disabled: boolean) {
    this.disabledForm = disabled;
  }

  @Input() set setProject(proj: ProjectModel) {
    this.loadedProject = proj;
  }

  constructor(
    private formBuilder: FormBuilder,
    protected projectService: ProjectService,
    protected toastr: ToastrService,
    protected constantService: ConstantsService,
    private codebookService: CodebooksService,
    private appCommunicationService: AppCommunicationService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.loadData();
  }

  async loadInstitutions() {
    const institutions = await this.constantService.get(CONSTANT_ITEMS.projectInstitutions);

    if (institutions) {
      this.institutions = sortByPriority(institutions);

      if (this.loadedProject.institutionCode) {
        this.selectedInstitution = this.institutions.find(i => i.code.toLowerCase() === this.loadedProject.institutionCode.toLowerCase());
      }
    }
  }

  async loadCodebooks() {
    const researchAndTech = await this.codebookService.get(CODEBOOK_ITEMS.researchAndTech);

    if (researchAndTech) {
      this.researchAndTech = researchAndTech;
    }

    const projectResearchArea = await this.codebookService.get(CODEBOOK_ITEMS.projectResearchArea);

    if (projectResearchArea) {
      this.projectResearchAreas = projectResearchArea;
    }
  }

  clearSelectedValues() {
    this.keywords = [];
  }

  disableForm() {
    this.projectForm.disable();
  }

  enableForm() {
    this.projectForm.enable();
  }

  generateForm() {
    this.loadedProject.name = deleteSpecialChars(this.loadedProject.name);

    this.projectForm = this.formBuilder.group({
      agencyCode: [this.loadedProject.agencyCode || "", [Validators.required]],
      agencyName: [this.loadedProject.agencyName || "", [Validators.required]],
      grantNumber: [this.loadedProject.grantNumber || "", [Validators.required, Validators.maxLength(50)]],
      isLocked: [this.loadedProject.isLocked || false, [Validators.required]],
      name: [this.loadedProject.name || "", [Validators.required]],
      nameEn: [this.loadedProject.nameEn || ""],
      programName: [this.loadedProject.programName || ""],
      startYear: [this.loadedProject.startYear || "", [Validators.min(1980), Validators.max(2100)]],
      endYear: [this.loadedProject.endYear || "", [Validators.min(1980), Validators.max(2100)]],
      institutionName: [this.loadedProject.institutionName || ""],
      institutionCode: [this.loadedProject.institutionCode || ""],
      departmentName: [this.loadedProject.departmentName || ""],
      grantPrice: [this.loadedProject.grantPrice || 0, [Validators.min(0)]],
      keywordsAsString: [this.loadedProject.keywordsAsString || ""],
      acronym: [this.loadedProject.acronym || ""],
      annotation: [this.loadedProject.annotation || ""],
      annotationEn: [this.loadedProject.annotationEn || ""],
      isCommercialContract: [this.loadedProject.isCommercialContract !== null ? this.loadedProject.isCommercialContract : null],
      isDomestic: [this.loadedProject.isDomestic !== null ? this.loadedProject.isDomestic : null],
      isResearch: [this.loadedProject.isResearch !== null ? this.loadedProject.isResearch : null],
      institutionIdNumber: [this.loadedProject.institutionIdNumber || ''],
      grantSignatureDate: [this.loadedProject.grantSignatureDate ? moment(this.loadedProject.grantSignatureDate).toDate() : null],
      achievementDescription: [this.loadedProject.achievementDescription || ''],
      publishingUrlLink: [this.loadedProject.publishingUrlLink || ''],
      contractRegisterUrlLink: [this.loadedProject.contractRegisterUrlLink || ''],
      participationType: [this.loadedProject.participationType || ''],
      researchAndTechId: [this.loadedProject.researchAndTechId || ''],
      projectResearchAreaId: [this.loadedProject.projectResearchAreaId || '']
    });

    if (this.loadedProject.keywordsAsString) {
      this.keywords = this.loadedProject.keywordsAsString.split(",");
    }

    if (this.loadedProject.participants) {
      this.participants = this.loadedProject.participants;
      this.participants.push(new ProjectParticipantModel());
    }

    if (this.loadedProject && this.loadedProject.projectResearchArea) {
      this.selectedProjectResearchArea = this.loadedProject.projectResearchArea;
      this.projectForm.get('projectResearchAreaId').patchValue(this.selectedProjectResearchArea.id);
    }

    if (this.loadedProject && this.loadedProject.researchAndTech) {
      this.selectedResearchAndTech = this.loadedProject.researchAndTech;
      this.projectForm.get('researchAndTechId').patchValue(this.selectedResearchAndTech.id);
    }

    if (this.loadedProject.isLocked) {
      this.projectForm.get("name").disable();
      this.projectForm.get("agencyCode").disable();
      this.projectForm.get("agencyName").disable();
      this.projectForm.get("grantNumber").disable();
    } else {
      this.projectForm.get("name").enable();
      this.projectForm.get("agencyCode").enable();
      this.projectForm.get("agencyName").enable();
      this.projectForm.get("grantNumber").enable();
    }

    if (this.disabledForm) {
      this.disableForm();
    } else {
      this.enableForm();

      if (!this.showDepartments) {
        this.projectForm.get('departmentName').disable();
      }

    }

    setTimeout(() => {
      this.projectForm.updateValueAndValidity();
    }, 100);
  }

  loadData() {

    this.clearSelectedValues();

    this.generateForm();

    this.autocompleteElCleared();

    this.loadInstitutions();

    this.loadCodebooks();

  }

  changedGrandPrices(grantPrices: GrantPriceModel[]) {
    grantPrices.forEach(price => price.projectId = this.loadedProject.id);
    this.loadedProject.grantPrices = [...grantPrices];
  }

  close() {
    this.closed.emit(true);
  }

  async submitAction() {
    await this.send();
  }

  closeAction() {
    this.close();
  }

  async deleteAction() {
    const res = await this.projectService
      .projectDelete(this.loadedProject.id).toPromise();

    if (!res) {
      console.log(res);
      this.toastr.error(this.i18n.getTranslation("Nastala chyba."));
      return;
    }

    if (res) {
      this.wasDeleted.emit(true);
      this.toastr.success(this.i18n.getTranslation("Projekt bol úspešne zmazaný."));
      this.close();
    }
  }

  async send() {
    const isEditing = (this.loadedProject && this.loadedProject.id !== null && this.loadedProject.id > 0);

    this.appCommunicationService.loadingOn();

    const data = new ProjectModel();
    let res;

    let finalParticipants: ProjectParticipantModel[] = [];

    if (this.participants && this.participants.length > 0) {

      finalParticipants = this.participants.filter(p => p.surname && p.surname.length > 0);

    }

    finalParticipants.forEach((p, index) => {
      if (this.loadedProject && this.loadedProject.id) {
        p.projectId = this.loadedProject.id;
      }
      delete p["isExternal"];
      if (index === 0) {
        p.isSupervisor = true;

        if (this.selectedInstitution && this.selectedInstitution.code && this.selectedInstitution.code.toLowerCase() === "tuke") {
          this.projectForm.get("departmentName").patchValue(p.departmentName);
        }
      }
    });

    if (isEditing) {
      Object.assign(data, this.loadedProject);
      Object.assign(data, this.projectForm.value);
    } else {
      Object.assign(data, this.projectForm.value);
    }

    data.participants = finalParticipants;

    if (this.selectedInstitution) {
      data.institutionCode = this.selectedInstitution.code;
      data.institutionName = this.selectedInstitution.description;
    }

    if (data.grantSignatureDate) {
      data.grantSignatureDate = moment(data.grantSignatureDate).set('hours', new Date().getHours()).set('minutes', new Date().getMinutes()).toDate();
    }

    if (isEditing) {
      res = await this.projectService
        .projectUpdate(data)
        .toPromise();
    } else {
      res = await this.projectService
        .projectCreate(data)
        .toPromise();
    }

    if (!res) {
      console.log(res);
      this.toastr.error(this.i18n.getTranslation("Nastala chyba."));
      this.appCommunicationService.loadingOff();
      return;
    }

    this.toastr.success(this.i18n.getTranslation("Projekt bol úspešne uložený / upravený."));
    this.appCommunicationService.loadingOff();
    this.close();
  }

  autocompleteElCleared(type: 'institution' | 'researchAndTech' | 'projectResearch' = 'institution') {
    if (type === "institution") {
      this.filteredInstitutions = [...this.institutions];

      this.projectForm.patchValue({
        institutionName: "",
        institutionCode: ""
      });
    } else if (type === 'researchAndTech') {
      this.filteredResearchAndTech = [...this.researchAndTech];

      this.projectForm.patchValue({
        researchAndTechId: ""
      });
    } else if (type === 'projectResearch') {
      this.filteredProjectResearchAreas = [...this.projectResearchAreas];

      this.projectForm.patchValue({
        projectResearchAreaId: ""
      });
    }
  }

  autocompleteElComplete(event, type: 'institution' | 'researchAndTech' | 'projectResearch' = 'institution') {
    const text = event.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    if (type === "institution") {
      this.filteredInstitutions = [];
      for (let i = 0; i < this.institutions.length; i++) {
        const inst = this.institutions[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        if (inst.indexOf(text) !== -1) {
          this.filteredInstitutions.push(this.institutions[i]);
        }
      }
    } else if (type === 'researchAndTech') {
      this.filteredResearchAndTech = [];
      for (let i = 0; i < this.researchAndTech.length; i++) {
        const inst = this.researchAndTech[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        if (inst.indexOf(text) !== -1) {
          this.filteredResearchAndTech.push(this.researchAndTech[i]);
        }
      }
    } else if (type === 'projectResearch') {
      this.filteredProjectResearchAreas = [];
      for (let i = 0; i < this.projectResearchAreas.length; i++) {
        const inst = this.projectResearchAreas[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        if (inst.indexOf(text) !== -1) {
          this.filteredProjectResearchAreas.push(this.projectResearchAreas[i]);
        }
      }
    }
  }

  async onSelectAutocompleteItem(type: 'institution' | 'researchAndTech' | 'projectResearch' = 'institution') {
    if (type === 'institution') {
      if (!this.selectedInstitution) {
        return;
      }

      this.projectForm.patchValue({
        institutionName: this.selectedInstitution.description,
        institutionCode: this.selectedInstitution.code
      });

      if (this.selectedInstitution.code.toLowerCase() === "tuke") {
        this.showDepartments = false;
      } else {
        this.showDepartments = true;
      }

      if (this.showDepartments) {
        this.projectForm.get('departmentName').enable();
      } else {
        this.projectForm.get('departmentName').disable();
      }
    } else if (type === 'researchAndTech') {
      this.projectForm.patchValue({'researchAndTechId': this.selectedResearchAndTech.id});
    } else if (type === 'projectResearch') {
      this.projectForm.patchValue({'projectResearchAreaId': this.selectedProjectResearchArea.id});
    }
  }

  keywordsChanged(words: string[]) {
    if (!words) {
      return;
    }

    let w = [];

    words.forEach(word => {
      const splitted = word.split(/[\\;,]+/);
      w = [...w, ...splitted];
    });

    if (w && w.length === 0) {
      w = null;
    }

    const keywordsAsString = w.toString();

    this.projectForm.controls["keywordsAsString"].patchValue(keywordsAsString);

    this.keywords = w;
  }

  checkValidity() {

  }

  onDeleteParticipant(index: number) {
    this.participants.splice(index, 1);
    this.findEmptyParticipantAndPushNewOne();
  }

  onSubmittedParticipant(participant, index: number) {
    this.participants[index] = participant;
    this.findEmptyParticipantAndPushNewOne();
  }

  onEditParticipant(index: number) {
    this.findEmptyParticipantAndPushNewOne();
  }

  findEmptyParticipantAndPushNewOne() {
    const findEmpty = this.participants.find(p => !p.surname || p.surname.length === 0);

    if (!findEmpty) {
      this.participants.push(new ProjectParticipantModel());
    }
  }

  markParticipantAsFirstAuthor(participant: ProjectParticipantModel, currentIndex: number) {
    this.participants.splice(currentIndex, 1);
    this.participants.unshift(participant);
  }

  copyValueFromTo(from: string, to: string) {
    this.projectForm.get(to).patchValue(this.projectForm.get(from).value);
  }
}
