import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import * as FileSaver from "file-saver";
import { isStringNullOrEmpty } from "../misc/utils";
import { getDeleteFileUrl, getFileInfoUrl, getUploadFormAttachmentUrl } from "../app.urls";
import * as _ from 'lodash';
import { FileMetadataModel } from "../models/biblio/file-metadata.model";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    protected httpClient: HttpClient
  ) { }

  download(url: string, fileName?: string, type: 'pdf' | 'xlsx' = 'pdf'): Observable<Blob> {
    const httpOptions: object = {
      headers: new HttpHeaders({}),
      responseType: 'blob',
      observe: 'response'
    };

    if (type === 'xlsx') {
      httpOptions['headers'].set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    return this.httpClient.get(url, httpOptions)
      .pipe(tap((response: any) => {
        let castResponse;
        castResponse = response as HttpResponse<Blob>;
        if (type === 'xlsx') {
          const blob = new Blob([castResponse.body], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const file = new File([blob], fileName, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          castResponse.body = file;
        }
        FileSaver.saveAs(
          castResponse.body,
          isStringNullOrEmpty(fileName) ? this.extractFilename(castResponse.headers) : fileName
        );
      }));
  }

  getInfo(fileMetadataId): Observable<FileMetadataModel> {
    return this.httpClient.get(
      getFileInfoUrl(fileMetadataId)
    ).pipe(map((res: any) => res.result as FileMetadataModel));
  }

  deleteFile(fileMetadataId): Observable<boolean> {
    return this.httpClient.get(
      getDeleteFileUrl(fileMetadataId)
    ).pipe(map((res: any) => res.result));
  }

  downloadPost(url: string, data: any, fileName?: string): Observable<Blob> {
    const httpOptions: object = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'blob',
      observe: 'response'
    };

    return this.httpClient.post(url, data, httpOptions)
      .pipe(tap((response: any) => {
        const castResponse = response as HttpResponse<Blob>;
        FileSaver.saveAs(
          castResponse.body,
          isStringNullOrEmpty(fileName) ? this.extractFilename(castResponse.headers) : fileName
        );
      }));
  }

  downloadApi(fileMetaId: number, fileName?: string): Observable<Blob> {
    const httpOptions: object = {
      headers: new HttpHeaders({}),
      responseType: 'blob',
      observe: 'response'
    };

    return this.httpClient.get(getUploadFormAttachmentUrl(), httpOptions)
      .pipe(tap((response: any) => {
        const castResponse = response as HttpResponse<Blob>;
        FileSaver.saveAs(
          castResponse.body,
          isStringNullOrEmpty(fileName) ? this.extractFilename(castResponse.headers) : fileName
        );
      }));
  }

// When subscribing to observable, check event.type
// if it is HttpEventType.UploadProgress, then the event reports upload progress
// else if event is instance of HttpResponse, then upload has finished
//
// If error handler is triggered it indicates that something went wrong
  upload(url: string, file: File): Observable<any> {
    const formData = new FormData();
    console.log(file)
    formData.append('uploadFile', file, file.name ? file.name : '');

    const httpOptions: object = {
      reportProgress: false,
      observe: 'response',
    };

    return this.httpClient.post(url, formData, httpOptions);
  }

  uploadMultiple(route: string, files: File[]): Observable<HttpEvent<any>> {
    const formData = new FormData();

    _.forEach(files, (file: File) => {
      formData.append('uploadFile', file, file.name);
    });

    const httpOptions: object = {
      params: new HttpParams(),
      headers: new HttpHeaders({}),
      reportProgress: true
    };
    const request = new HttpRequest('POST', getUploadFormAttachmentUrl(), formData, httpOptions);

    return this.httpClient.request(request);
  }

  extractFilename(headers: HttpHeaders): string {
    const header = headers.get('Content-Disposition');
    let filename = '';

    if (!isStringNullOrEmpty(header)) {
      if (header && (header.indexOf('attachment') !== -1 || header.indexOf('inline') !== -1)) {
        const filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
        const matches = filenameRegex.exec(header);

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
    }

    return filename;
  }
}
