import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { TicketSection, ticketSectionsEnum, ticketTabs } from "./misc/ticket-sections";
import { TicketService } from "../../services/ticket.service";
import { FILTER_ACTIONS } from "./components/general-filter/general-filter.component";
import { ToastrService } from "ngx-toastr";
import { TicketSimpleModel } from "../../models/ticket/ticket-simple.model";
import { TicketActions, TicketActionsEnum } from "./misc/ticket-actions";
import { getExtendedTicket } from "./ticket-mapper";
import { TicketAcceptanceModalComponent, TicketAcceptanceModalTypes } from "./ticket-acceptance-modal/ticket-acceptance-modal.component";
import { handOverTypes, PriorityTypes, TicketStatuses } from "../../constants";
import { TicketStatusUpdateRequestModel } from "../../models/requests/ticket-status-update-request.model";
import { TicketEditModalComponent } from "./ticket-edit-modal/ticket-edit-modal.component";
import { ConfirmationService } from "primeng";
import { TicketGotoRequestModel } from "../../models/requests/ticket-goto-request.model";
import { GotoTicketAcceptanceModel } from "./ticket-acceptance-modal/goto-ticket-acceptance.model";
import { SubmitTicketAcceptanceModel } from "./ticket-acceptance-modal/submit-ticket-acceptance.model";
import { isArray } from "rxjs/internal-compatibility";
import { AppCommunicationService } from "../../services/app-communication.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  TicketMultiItemsActionModalComponent,
  TicketMultiItemsActions
} from "./ticket-multi-items-action-modal/ticket-multi-items-action-modal.component";
import { TicketContactEmailModalComponent } from "./ticket-contact-email-modal/ticket-contact-email-modal.component";
import {
  getTableColumnsByPriorityAndResolution,
  TableColumn,
  TableColumnsPriority,
  TicketTableColumnNames
} from "../../misc/table-responsive";
import { BiblioDetailComponent } from "../../components/biblio-detail/biblio-detail.component";
import { TicketPriorityApprovementModalComponent } from "./ticket-priority-approvement-modal/ticket-priority-approvement-modal.component";
import { map } from "rxjs/operators";
import { TicketImportFromDwModalComponent } from "./ticket-import-from-dw-modal/ticket-import-from-dw-modal.component";
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";
import {
  createSubQuery,
  createSubQueryForMoreObjectsAndFields,
  createSubQueryFromMultipleObjects,
  createSubQueryFromObject
} from "src/app/core-frontend-common/misc/query-designer/query-designer-functions";
import { queryFilterTypes, queryOperators } from "../../../core-frontend-common/misc/query-designer/query-designer";

export enum tablesEnum {
  toTable,
  inTable,
}

export const MAX_ARCHIVE_ID_NUMBER = 2147483647;

@Component({
  selector: "smart-ticket-catalog",
  templateUrl: "./ticket-catalog.component.html",
  styleUrls: ["./ticket-catalog.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TicketCatalogComponent implements OnInit {
  tablesEnum = tablesEnum;

  activeTab: TicketSection = ticketTabs[0];

  ticketTabs = ticketTabs;

  pTableGeneralSettings = {
    pageSize: 5,
    loading: false
  };

  toTableSettings = {
    pageSize: 0,
    page: 0,
    totalItems: 0,
    loading: false,
    searchCitationByBiblioId: null
  };

  inTableSettings = {
    pageSize: 5,
    page: 0,
    totalItems: 0,
    loading: false
  };

  toTableFilter = {};

  inTableFilter = {};

  checkedToTableTickets: TicketSimpleModel[] = [];

  checkedInTableTickets: TicketSimpleModel[] = [];

  ticketSections = ticketSectionsEnum;

  inTableData: TicketSimpleModel[] = [];

  toTableData: TicketSimpleModel[] = [];

  data: TicketSimpleModel[] = [];

  ticketActions: TicketActions = new TicketActions();

  pTableColNames = TicketTableColumnNames;

  pTableAllCols: TableColumn[] = [
    {
      name: TicketTableColumnNames.checkbox,
      priority: TableColumnsPriority.priority_6
    },
    {
      name: TicketTableColumnNames.ac,
      priority: TableColumnsPriority.priority_2
    },
    {
      name: TicketTableColumnNames.category,
      priority: TableColumnsPriority.priority_4
    },
    {
      name: TicketTableColumnNames.year,
      priority: TableColumnsPriority.priority_6
    },
    {
      name: TicketTableColumnNames.info,
      priority: TableColumnsPriority.priority_1
    },
    {
      name: TicketTableColumnNames.contact,
      priority: TableColumnsPriority.priority_4
    },
    {
      name: TicketTableColumnNames.actions,
      priority: TableColumnsPriority.priority_1
    }
  ];

  pTableColsToShow: TableColumn[] = [];

  notVisibleCols: TableColumn[] = [];

  pTable = {
    colNumber: 0,
    expandedRows: {}
  };

  forcedAC = '';

  tryOpenApprovementAutomatically = false;

  showingTicketsByBiblioIdOnly = false;

  @ViewChild(TicketAcceptanceModalComponent, { static: false }) ticketAcceptanceModal: TicketAcceptanceModalComponent;

  @ViewChild(TicketEditModalComponent, { static: false }) ticketEditModalComponent: TicketEditModalComponent;

  @ViewChild(TicketMultiItemsActionModalComponent, { static: false }) ticketMultiItemsActionModalComponent: TicketMultiItemsActionModalComponent;

  @ViewChild(TicketContactEmailModalComponent, { static: false }) ticketContactEmailModalComponent: TicketContactEmailModalComponent;

  @ViewChild(BiblioDetailComponent, { static: false }) biblioDetailComponent: BiblioDetailComponent;

  @ViewChild(TicketPriorityApprovementModalComponent, { static: false }) ticketPriority: TicketPriorityApprovementModalComponent;

  @ViewChild(TicketImportFromDwModalComponent, { static: false }) importFromDwModalComponent: TicketImportFromDwModalComponent;

  constructor(
    protected ticketService: TicketService,
    protected toastrService: ToastrService,
    protected confirmationService: ConfirmationService,
    protected appCommunicationService: AppCommunicationService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.checkRouteParams();

    this.onResize();
  }

  onResize() {
    this.pTableColsToShow = getTableColumnsByPriorityAndResolution(this.pTableAllCols);
    this.notVisibleCols = this.pTableAllCols.filter(c => !this.pTableColsToShow.includes(c));
    this.pTable.colNumber = this.pTableColsToShow.length;
  }

  checkRouteParams() {
    this.activatedRoute
      .queryParams
      .subscribe(v => {
        if (v && v["tab"]) {
          const tab = this.ticketTabs.find(t => t.section == Number.parseInt(v["tab"], 0));
          this.selectTab(tab, false);
        }
        if (v && v["ac"]) {
          this.forcedAC = v['ac'];

          if (v["priority"] === 'true') {
            this.tryOpenApprovementAutomatically = true;
          }

          setTimeout(() => {
            this.forcedAC = '';
            this.router.navigate(['.'], {relativeTo: this.activatedRoute, queryParams: {ac: null, priority: null}, queryParamsHandling: 'merge'});
          }, 4000);
        }
      });
  }

  itemsFilterChanged(filter: any, status: TicketStatuses | number[], tableType: tablesEnum) {
    if (tableType === tablesEnum.toTable) {
      this.toTableFilter = filter;
      this.refreshToTableData(this.toTableFilter, true);
    } else {
      this.inTableFilter = filter;
      this.refreshInTableData(this.inTableFilter);
    }
  }

  setPage(pageInfo, table: tablesEnum) {
    if (!pageInfo) {
      return;
    }

    if (!this.toTableSettings.pageSize) return;

    if (table === tablesEnum.toTable) {
      // this.toTableSettings.page = ((pageInfo.first) + pageInfo.rows) / this.toTableSettings.pageSize;
      this.toTableSettings.page = pageInfo.first;
      this.refreshToTableData(this.toTableFilter);
    } else {
      // this.inTableSettings.page = ((pageInfo.first) + pageInfo.rows) / this.inTableSettings.pageSize;
      this.inTableSettings.page = pageInfo.first;
      this.refreshInTableData(this.inTableFilter);
    }
  }

  /* ## LOAD DATA FOR "IN" TABLE ## */
  refreshInTableData(filter: any = null) {
    this.pTableGeneralSettings.loading = true;

    const query = {
      search: [],
      currentStatus: {},
      type: {}
    };

    if (filter) {
      Object.keys(filter).forEach(key => {
        query[key] = {};
      });
    }

    this.checkedInTableTickets = [];

    if (this.activeTab.in_data_param && this.activeTab.section !== ticketSectionsEnum.allRecords) {
      query.currentStatus = createSubQueryFromObject("OR",
        this.activeTab.in_data_param,
        "currentStatus",
        queryFilterTypes.EQUAL
      );
    }

    if (filter && filter.type && filter.type.value) {
      query.type = createSubQueryFromObject("OR",
        filter.type.value,
        "type",
        queryFilterTypes.EQUAL);
    }

    const queryDesigner = {
      search: [],
      where: {
        operatorType: "AND",
        operands: []
      },
      page: (this.inTableSettings.page + this.inTableSettings.pageSize) / this.inTableSettings.pageSize,
      pageSize: this.inTableSettings.pageSize,
      orderBy: [
        {
          field: "OrderPriority",
          order: "DESC"
        },
        {
          field: "CreatedDatetime",
          order: "ASC"
        }
      ]
    };

    Object.keys(query).forEach(queryItem => {
      if (Object.keys(query[queryItem]).length > 0 && queryItem !== "search") {
        queryDesigner["where"]["operands"].push(query[queryItem]);
      }
    });

    this.ticketService
      .getAllTicketsForResponsibleByFilter(queryDesigner)
      .subscribe(
        v => {
          const data = v.items;
          this.inTableSettings.totalItems = v.totalItems;
          if (this.activeTab.in_data_param) {
            this.inTableData = data.filter(t => t.currentStatus === this.activeTab.in_data_param);
          }
          this.pTableGeneralSettings.loading = false;
        },
        error => {
          console.log(error);
          this.inTableData = [];
          this.toastrService.error(this.i18n.getTranslation("Nastala chyba."));
          this.pTableGeneralSettings.loading = false;
        }
      );
  }

  /* ## LOAD DATA FOR "TO" TABLE ## */
  refreshToTableData(toTableFilter: any = null, resetPage = false): Promise<TicketSimpleModel[]> {
    this.pTableGeneralSettings.loading = true;

    this.showingTicketsByBiblioIdOnly = false;

    const query = {
      search: [],
      currentStatus: {},
      categoryCode: {},
      faculty: {},
      type: {},
      priority: {},
      isWaitingPriority: {},
      handOverType: {},
      yearOfPublishing: {},
      isDeleted: {}
    };

    const filter = this.toTableFilter;

    if (toTableFilter && toTableFilter['pageSize']) {
      this.toTableSettings.pageSize = toTableFilter['pageSize']['code'];
    }

    if (filter) {
      Object.keys(filter).forEach(key => {
        query[key] = {};
      });
    }

    this.checkedToTableTickets = [];

    if (filter && filter["category"] && filter["category"].length > 0) {

      const obj = filter["category"].map(c => {
        return {
          obj: c,
          filterType: queryFilterTypes.EQUAL
        };
      });

      query.categoryCode = createSubQueryFromMultipleObjects(queryOperators.OR,
        obj,
        "formBiblio.biblioCategoryId", "id");
    }

    if (filter && filter["faculty"] && filter["faculty"]) {
      query.faculty = createSubQueryFromObject("OR",
        filter["faculty"].facultyCode,
        "createdBy.facultyCode",
        queryFilterTypes.EQUAL
      );
    }

    if (filter && filter["type"] && filter["type"].value) {
      query.type = createSubQueryFromObject("OR",
        filter["type"].value,
        "type",
        queryFilterTypes.EQUAL);
    }

    if (filter && filter["priority"]) {
      query.priority = createSubQueryFromObject("OR",
        PriorityTypes.no_priority,
        "priorityType",
        queryFilterTypes.GREATERTHAN);
    }

    if (filter && filter["priorityWaiting"]) {
      query.isWaitingPriority = createSubQueryFromObject("OR",
        null,
        "isPrioritized",
        queryFilterTypes.IsNull, true);
    }

    if (filter && filter["handOverType"]) {
      query.handOverType = createSubQueryFromObject("OR",
        handOverTypes.sending_by_post,
        "handOverType",
        queryFilterTypes.EQUAL, false);
    }

    const showIsDeleted = this.activeTab.section === ticketSectionsEnum.allRecordsAdmin;
    const showOnlyIsDeleted = filter && filter['isDeleted'] && showIsDeleted;

    const deletedObjFilter = [
      {
        obj: false,
        filterType: queryFilterTypes.EQUAL,
        field: 'isDeleted'
      }
    ];

    if (showIsDeleted) {
      deletedObjFilter.push({
        obj: true,
        filterType: queryFilterTypes.EQUAL,
        field: 'isDeleted'
      });
    }

    query.isDeleted = createSubQueryForMoreObjectsAndFields(queryOperators.OR, deletedObjFilter);

    if (showOnlyIsDeleted) {
      query.isDeleted = createSubQueryFromObject("OR",
        true,
        "isDeleted",
        queryFilterTypes.EQUAL);
    }

    if (filter && filter["yearOfPublishing"]) {
      query.yearOfPublishing = createSubQueryForMoreObjectsAndFields(queryOperators.OR,
        [
          {
          obj: filter["yearOfPublishing"].value,
          field: "formBiblio.yearOfPublishing",
          filterType: queryFilterTypes.EQUAL
        }, {
          obj: filter["yearOfPublishing"].value,
          field: "formCitation.yearOfPublishing",
          filterType: queryFilterTypes.EQUAL
        }]);
    }

    if (this.toTableFilter['searchCitationByBiblioId']) {
      query['ticket.biblio.archiveId'] = createSubQueryFromObject(queryOperators.OR,
        this.toTableFilter['searchCitationByBiblioId'],
        'biblioId',
        queryFilterTypes.EQUAL);

      this.showingTicketsByBiblioIdOnly = true;
    }

    if (this.activeTab.section !== ticketSectionsEnum.allRecords && this.activeTab.section !== ticketSectionsEnum.allRecordsAdmin) {
      if (this.activeTab.to_data_param) {
        if (isArray(this.activeTab.to_data_param) && this.activeTab.to_data_param.length > 1) {
          query.currentStatus = createSubQuery(
            queryOperators.OR,
            [this.activeTab.in_data_param, this.activeTab.to_data_param[0], this.activeTab.to_data_param[1]],
            "currentStatus",
            queryFilterTypes.EQUAL
          );
        } else {
          query.currentStatus = createSubQuery(
            queryOperators.OR,
            [this.activeTab.to_data_param],
            "currentStatus",
            queryFilterTypes.EQUAL
          );
        }
      }
    } else {

      if (this.activeTab.section === ticketSectionsEnum.allRecords && toTableFilter && toTableFilter['unfinishedTicketsOnly']) {
        query.currentStatus = createSubQueryForMoreObjectsAndFields(queryOperators.AND,
          [
            {
              obj: TicketStatuses.sent_to_acceptance,
              field: "currentStatus",
              filterType: queryFilterTypes.GreaterThanOrEqual
            }, {
            obj: TicketStatuses.finished,
            field: "currentStatus",
            filterType: queryFilterTypes.LESSTHAN
          }]);
      } else if (this.activeTab.section === ticketSectionsEnum.allRecords) {
        query.currentStatus = createSubQueryFromObject("OR",
          TicketStatuses.sent_to_acceptance,
          "currentStatus",
          queryFilterTypes.GreaterThanOrEqual);
      } else {
        query.currentStatus = createSubQueryFromObject("OR",
          TicketStatuses.created,
          "currentStatus",
          queryFilterTypes.GreaterThanOrEqual);
      }

    }

    if (resetPage) {
      this.toTableSettings.page = 0;
    }

    const queryDesigner = {
      search: [],
      where: {
        operatorType: "AND",
        operands: []
      },
      page: (this.toTableSettings.page + this.toTableSettings.pageSize) / this.toTableSettings.pageSize,
      pageSize: this.toTableSettings.pageSize,
      orderBy: [
        {
          field: "OrderPriority",
          order: "DESC"
        },
        {
          field: "CreatedDatetime",
          order: "ASC"
        }
      ]
    };

    Object.keys(query).forEach(queryItem => {
      if (Object.keys(query[queryItem]).length > 0 && queryItem !== "search") {
        queryDesigner["where"]["operands"].push(query[queryItem]);
      }
    });

    if (!queryDesigner.page) {
      queryDesigner.page = 1;
    }

    if (filter && filter["search"] && filter["search"].length > 0) {

      const stringArray = filter["search"].split(/(\s+)/).filter(function(e) {
        return e.trim().length > 0;
      });
      for (let i = 0; i < stringArray.length; i++) {
        queryDesigner["search"].push({
          "fields": [],
          "value": ""
        });

        const isNumber = /^\d+$/.test(stringArray[i]);

        if (isNumber && (Number.parseInt(stringArray[i], 0) < MAX_ARCHIVE_ID_NUMBER)) {
          queryDesigner["search"][i]["fields"] = ["formBiblio.archiveId", "formCitation.archiveId"];
        } else {
          queryDesigner["search"][i]["fields"] = [
            "formBiblio.title",
            "createdBy.surname",
            "formCitation.title",
            "formBiblio.hostItem.isbn",
            "formBiblio.hostItem.issn",
            "formBiblio.hostItem.eissn",
            "formBiblio.isbn",
            "formBiblio.issn",
            "formBiblio.eissn",
            "formCitation.isbn",
            "formCitation.issn",
            "formCitation.eissn"
          ];
        }

        queryDesigner["search"][i]["value"] = stringArray[i];
      }
    }

    return this.ticketService
      .getAllTicketsByFilter(queryDesigner)
      .toPromise()
      .then(
        v => {
          const data = v.items;
          this.toTableSettings.totalItems = v.totalItems;
          if (isArray(this.activeTab.to_data_param)) {
            this.toTableData = [];
            this.activeTab.to_data_param.forEach(p => {
              const d = data.filter(t => t.currentStatus === p);
              this.toTableData = [...this.toTableData, ...d];
            });
          } else if (this.activeTab.to_data_param) {
            this.toTableData = data.filter(t => t.currentStatus === this.activeTab.to_data_param);
          }
          if (this.activeTab.section === ticketSectionsEnum.allRecords || this.activeTab.section === ticketSectionsEnum.allRecordsAdmin) {
            this.toTableData = data;
          }
          this.pTableGeneralSettings.loading = false;

          this.checkAutomaticallyOpenApprovement(this.toTableData);

          return Promise.resolve(this.toTableData);
        },
        error => {
          console.log(error);
          this.toTableData = [];
          this.toastrService.error("Nastala chyba.");
          this.pTableGeneralSettings.loading = false;
          return Promise.reject();
        }
      );
  }

  checkAutomaticallyOpenApprovement(data: TicketSimpleModel[]) {
    if (!this.tryOpenApprovementAutomatically) {
      return;
    }

    if (data && data.length === 1 && data[0].priorityType !== PriorityTypes.no_priority) {
      this.ticketPriorityApprovementAction(data[0]);
      this.tryOpenApprovementAutomatically = false;
    }
  }

  async showAllCitationsForBiblioId(archiveId: number) {
    this.toTableFilter['searchCitationByBiblioId'] = archiveId;

    await this.refreshToTableData();
  }

  checkIfTicketsDontBelongsToSomePerson(ticketIds: number[]): Promise<TicketSimpleModel[]> {
    const filter = {
      id: {}
    };

    if (ticketIds && ticketIds.length === 0) {
      return;
    }

    filter.id = createSubQuery(queryOperators.OR,
      ticketIds,
      "id",
      queryFilterTypes.EQUAL);

    const queryDesigner = {
      search: [],
      where: {
        operatorType: "AND",
        operands: []
      },
      page: 1,
      pageSize: 100,
      orderBy: [
        {
          field: "Id",
          order: "ASC"
        }
      ]
    };

    Object.keys(filter).forEach(queryItem => {
      if (Object.keys(filter[queryItem]).length > 0 && queryItem !== "search") {
        queryDesigner["where"]["operands"].push(filter[queryItem]);
      }
    });

    return this.ticketService
      .getAllTicketsByFilter(queryDesigner)
      .pipe(map(res => res.items))
      .toPromise();
  }

  /* ## LOAD DATA GENERAL FUNCTION ## */
  loadData(filter: any = null, onlyOneTable: tablesEnum = null) {
    if (!onlyOneTable && this.activeTab.section !== ticketSectionsEnum.allRecords) {
      this.clearData();
      this.refreshToTableData();
      this.refreshInTableData();
      return;
    }

    if (onlyOneTable === tablesEnum.inTable) {
      this.refreshInTableData();
    } else {
      this.refreshToTableData();
    }

  }

  /* ## CLEARS TABLE DATA ## */
  clearData() {
    this.toTableData = [];
    this.inTableData = [];
    this.checkedInTableTickets = [];
    this.checkedToTableTickets = [];
  }

  /* ## SELECT TAB FUNCTION ## */
  selectTab(tab: TicketSection, loadData = true) {
    if (tab === this.activeTab) {
      return;
    }
    this.activeTab = tab;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: this.activeTab.section },
      queryParamsHandling: "merge",
      skipLocationChange: false
    });

    if (loadData) {
      this.toTableSettings.page = 0;
      this.inTableSettings.page = 0;
      this.loadData();
    }
  }

  /* ## GENERAL FILTERS CHANGE FUNCTION - FILTERS FOR "IN" TABLE - MULTI-ACTIONS ## */
  generalFilterChange(action: FILTER_ACTIONS) {
    switch (action) {
      case FILTER_ACTIONS.refresh:
        this.loadData(false);
        this.toastrService.info(this.i18n.getTranslation("Dáta obnovené."));
        break;
      case FILTER_ACTIONS.print:
        this.openMultiItemsActionModal(this.checkedInTableTickets, TicketMultiItemsActions.print, this.activeTab.section);
        break;
      case FILTER_ACTIONS.submit:
        this.openMultiItemsActionModal(this.checkedInTableTickets, TicketMultiItemsActions.submit, this.activeTab.section);
        break;
      case FILTER_ACTIONS.delete:
        this.openMultiItemsActionModal(this.checkedInTableTickets, TicketMultiItemsActions.delete, this.activeTab.section);
        break;
      case FILTER_ACTIONS.contact:
        this.openMultiContactUserActionModal(this.checkedInTableTickets);
        break;
    }
  }

  /* ## TICKET ACTIONS FUNCTION ## */
  ticketAction(action: TicketActionsEnum, ticket: TicketSimpleModel) {
    switch (action) {
      case TicketActionsEnum.up:
        this.moveTicketUp(ticket);
        break;
      case TicketActionsEnum.down:
        this.moveTicketDown(ticket);
        break;
      case TicketActionsEnum.check:
        this.checkTicket(ticket);
        break;
      case TicketActionsEnum.edit:
        this.editTicket(ticket);
        break;
      case TicketActionsEnum.comment:
        this.commentTicket(ticket);
        break;
      case TicketActionsEnum.left:
        this.moveTicketLeft(ticket);
        break;
      case TicketActionsEnum.contact:
        this.contactTicket(ticket);
        break;
      case TicketActionsEnum.goTo:
        this.goToTicket(ticket);
        break;
      case TicketActionsEnum.contactUser:
        this.contactUser(ticket);
        break;
      case TicketActionsEnum.importDw:
        this.importFromDawinci(ticket);
        break;
      case TicketActionsEnum.biblio:
        this.showBiblio(ticket);
        break;
      case TicketActionsEnum.delete:
        this.deleteTicket(ticket);
        break;
    }
  }

  ticketPriorityApprovementAction(ticket: TicketSimpleModel) {
    this.ticketPriority.openModal(ticket, () => {
      this.refreshToTableData(this.toTableFilter);
    });
  }

  showBiblio(ticket: TicketSimpleModel) {
    if (!ticket.biblioId) {
      this.toastrService.error(this.i18n.getTranslation("Biblio záznam pre daný tiket ešte neexistuje."));
      return;
    }
    this.biblioDetailComponent
      .show(null, ticket.biblioId, null, ticket.type === 'citation' ? ticket.citationId : null);
  }

  importFromDawinci(ticket: TicketSimpleModel) {
    this.importFromDawinciAfterDialogFn(ticket);
  }

  importFromDawinciAfterDialogFn(ticket) {
    this.appCommunicationService.loadingOn();

    this.ticketService
      .importFromDwById(ticket.id)
      .subscribe(v => {
        this.appCommunicationService.loadingOff();
        this.toastrService.success(this.i18n.getTranslation("Údaje úspešne naimportované z Dawinci."));
        this.refreshInTableData(this.inTableFilter);
      }, error => {
        console.log(error);
        this.appCommunicationService.loadingOff();
        this.toastrService.error(this.i18n.getTranslation("Nastala chyba pri pokuse o import dát z Dawinci."));
      });
  }


  contactUser(ticket: TicketSimpleModel) {
    this.ticketContactEmailModalComponent.show([ticket], false, this.activeTab.section === ticketSectionsEnum.contact);
  }

  deleteTicket(ticket: TicketSimpleModel) {
    this.ticketAcceptanceModal.openModal(ticket, TicketAcceptanceModalTypes.delete);
  }

  goToTicket(ticket: TicketSimpleModel) {
    this.ticketAcceptanceModal.openGoToModal(ticket);
  }

  contactTicket(ticket: TicketSimpleModel) {
    this.ticketAcceptanceModal.openModal(ticket, TicketAcceptanceModalTypes.basic, TicketStatuses.to_contact);
  }

  editTicket(ticket: TicketSimpleModel) {
    if (ticket.currentStatus === TicketStatuses.sent_to_acceptance) {
      this.ticketEditModalComponent.show(ticket, true);
      return;
    } else if (ticket.currentStatus === TicketStatuses.in_acceptance) {
      this.ticketEditModalComponent.show(ticket, false);
      return;
    } else if (ticket.currentStatus === TicketStatuses.in_validation) {
      this.ticketEditModalComponent.show(ticket, false);
      return;
    } else if (ticket.currentStatus === TicketStatuses.in_catalogization) {
      this.ticketEditModalComponent.show(ticket, false);
      return;
    } else if (ticket.currentStatus === TicketStatuses.in_dawinci_check) {
      this.ticketEditModalComponent.show(ticket, true);
      return;
    }
    this.ticketEditModalComponent.show(ticket, false);
  }

  checkTicket(ticket: TicketSimpleModel) {

    if (ticket.currentStatus === TicketStatuses.in_dawinci_check) {
      if (!ticket.dwImportedDatetime) {
        this.toastrService.warning(this.i18n.getTranslation("Záznam ešte nebol importovaný z DW."));
        return;
      }
    }

    if (ticket.currentStatus === TicketStatuses.in_acceptance || ticket.currentStatus === TicketStatuses.in_dawinci_check) {
      this.showTicketAcceptanceModal(ticket, TicketAcceptanceModalTypes.acceptance);
      return;
    }
    this.showTicketAcceptanceModal(ticket, TicketAcceptanceModalTypes.basic);
    return;
  }

  moveTicketUp(ticket: TicketSimpleModel) {
    const extendedTicket = getExtendedTicket(ticket);

    this.checkIfTicketsDontBelongsToSomePerson([ticket.id])
      .then((tickets: TicketSimpleModel[]) => {
        if (tickets.find(t => t.id === ticket.id)) {
          if (extendedTicket && extendedTicket.nextStatus) {
            const t = new TicketStatusUpdateRequestModel();
            t.status = extendedTicket.nextStatus;
            t.ticketId = ticket.id;
            this.changeTicketStatus(t);
          }
        } else {
          this.toastrService.info(this.i18n.getTranslation("Zdá sa že zvolený tiket už niekto rieši."));
        }
      }).catch(e => {
      console.log(e);
      this.toastrService.info(this.i18n.getTranslation("Zdá sa že zvolený tiket už niekto rieši."));
    });
  }

  moveTicketDown(ticket: TicketSimpleModel) {
    const extendedTicket = getExtendedTicket(ticket);

    if (extendedTicket && extendedTicket.backStatus) {
      let b = 0;

      if (extendedTicket.backStatus.length === 1) {
        b = extendedTicket.backStatus[0];
      } else {

        // there are only two ticket-catalog types with two back statuses - in_validation and in_dawinci_check
        // we need to recognize which we should choose
        const lastStatus = ticket.lifecycles[ticket.lifecycles.length - 1];

        if (lastStatus) {
          const status = lastStatus.status;

          if (status === TicketStatuses.in_dawinci_check) {

            const prevLastStatus = ticket.lifecycles[ticket.lifecycles.length - 2];

            if (prevLastStatus) {

              if (prevLastStatus.status === TicketStatuses.imported_to_dawinci) {
                b = TicketStatuses.imported_to_dawinci;
              } else if (prevLastStatus.status === TicketStatuses.sent_to_crepc) {
                b = TicketStatuses.sent_to_crepc;
              }

            } else {
              const prevStatus = ticket.lifecycles.find(l => l.status === TicketStatuses.imported_to_dawinci);

              if (prevStatus) {
                b = TicketStatuses.imported_to_dawinci;
              } else {
                b = TicketStatuses.sent_to_crepc;
              }
            }

          } else if (status === TicketStatuses.in_validation) {

            const prevLastStatus = ticket.lifecycles[ticket.lifecycles.length - 2];

            if (prevLastStatus) {

              if (prevLastStatus.status === TicketStatuses.converted) {
                b = TicketStatuses.converted;
              } else if (prevLastStatus.status === TicketStatuses.scanned) {
                b = TicketStatuses.scanned;
              }

            } else {
              const prevStatus = ticket.lifecycles.find(l => l.status === TicketStatuses.converted);

              if (prevStatus) {
                b = TicketStatuses.converted;
              } else {
                b = TicketStatuses.scanned;
              }
            }

          }
        }
      }

      const t = new TicketStatusUpdateRequestModel();
      t.status = b;
      t.ticketId = ticket.id;
      this.changeTicketStatus(t);
    }
  }

  moveTicketLeft(ticket: TicketSimpleModel) {
    const extendedTicket = getExtendedTicket(ticket);

    if (extendedTicket && extendedTicket.backStatus) {
      this.confirmationService.confirm({
        message: this.i18n.getTranslation("Skutočne si prajete posunúť publikáciu v stave späť?"),
        acceptLabel: this.i18n.getTranslation("Potvrdiť"),
        rejectLabel: this.i18n.getTranslation("Zrušiť"),
        header: this.i18n.getTranslation("Potvrdenie"),
        accept: () => {
          const t = new TicketStatusUpdateRequestModel();
          t.status = extendedTicket.backStatus[0];
          t.ticketId = ticket.id;
          this.changeTicketStatus(t);
        }
      });
    }
  }

  commentTicket(ticket: TicketSimpleModel) {
    this.showTicketAcceptanceModal(ticket, TicketAcceptanceModalTypes.comment);
  }

  showTicketAcceptanceModal(ticket: TicketSimpleModel, type: TicketAcceptanceModalTypes) {
    this.ticketAcceptanceModal.openModal(ticket, type);
  }

  refreshData(refresh = false, table: tablesEnum) {
    if (refresh) {
      this.loadData(null, table);
    }
  }

  onSubmitTicketGoTo(res: GotoTicketAcceptanceModel) {
    const ticketUpdate = new TicketGotoRequestModel();
    ticketUpdate.comment = res.comment;
    ticketUpdate.ticketId = res.ticket.id;
    ticketUpdate.status = res.status;
    if (res.workflowUserId) {
      ticketUpdate.responsibleId = res.workflowUserId;
    } else {
      delete ticketUpdate.responsibleId;
    }
    this.goTo(ticketUpdate);
    this.ticketAcceptanceModal.close();
  }

  onSubmitCommentModal(val) {
    if (val) {
      this.toastrService.success(this.i18n.getTranslation("Komentár úspešne uložený."));
      this.loadData();
    }
  }

  onSubmitTicketAcceptance(res: SubmitTicketAcceptanceModel) {
    const ticketUpdate = new TicketStatusUpdateRequestModel();
    ticketUpdate.comment = res.ticketAccept.comment;
    ticketUpdate.ticketId = res.ticket.id;

    if (res.forceStatus) {
      ticketUpdate.status = res.forceStatus;

      if (res.forceStatus === TicketStatuses.to_contact) {
        this.toContact(ticketUpdate);
        return;
      }
    } else if (!res.onlyComment) {
      ticketUpdate.attachmentType = res.ticketAccept.attachmentType;

      if (res.ticket.type === "biblio") {

        ticketUpdate.handOverType = res.ticketAccept.handOverType;

        if (res.ticket.currentStatus === TicketStatuses.in_acceptance) {

          if (res.ticketAccept.processingType === "scan") {
            ticketUpdate.status = TicketStatuses.to_scan;
          } else {
            ticketUpdate.status = TicketStatuses.to_conversion;
          }

        } else if (res.ticket.currentStatus === TicketStatuses.in_dawinci_check) {
          if (res.ticket.handOverType === handOverTypes.picking_up_in_uk) {
            ticketUpdate.status = TicketStatuses.to_hand_over;
          } else if (res.ticket.handOverType === handOverTypes.sending_by_post) {
            ticketUpdate.status = TicketStatuses.finished;
          } else {

            if (res.ticketAccept.processingType) {
              if (res.ticketAccept.processingType === "scan") {
                ticketUpdate.status = TicketStatuses.to_hand_over;
              } else {
                ticketUpdate.status = TicketStatuses.finished;
              }
            }
          }
        }

      } else {

        if (res.ticket.currentStatus === TicketStatuses.in_acceptance) {
          ticketUpdate.status = TicketStatuses.to_catalogize;
        }

        if (res.ticket.currentStatus === TicketStatuses.in_dawinci_check) {
          if (res.ticket.handOverType === handOverTypes.picking_up_in_uk) {
            ticketUpdate.status = TicketStatuses.to_hand_over;
          } else if (res.ticket.handOverType === handOverTypes.sending_by_post) {
            ticketUpdate.status = TicketStatuses.finished;
          } else {

            if (res.ticketAccept.processingType) {
              if (res.ticketAccept.processingType === "scan") {
                ticketUpdate.status = TicketStatuses.to_hand_over;
              } else {
                ticketUpdate.status = TicketStatuses.finished;
              }
            }
          }
        }

      }

    } else {
      const ticketExtended = getExtendedTicket(res.ticket);

      if (ticketExtended.nextStatus) {
        ticketUpdate.status = ticketExtended.nextStatus;
      }
    }

    this.changeTicketStatus(ticketUpdate);
  }

  goTo(ticketReq: TicketGotoRequestModel) {
    this.ticketService.getGoto(ticketReq).subscribe(
      v => {
        this.ticketStatusChangeSuccessNotification();
        this.loadData(null, tablesEnum.inTable);
      },
      error => {
        console.log(error);
        this.ticketStatusChangeErrorNotification();
      }
    );
  }

  toContact(ticketReq: TicketStatusUpdateRequestModel) {
    this.ticketService.toContact(ticketReq).subscribe(
      v => {
        this.ticketStatusChangeSuccessNotification();
        this.loadData(null, tablesEnum.inTable);
      },
      error => {
        console.log(error);
        this.ticketStatusChangeErrorNotification();
      }
    );
  }

  changeTicketStatus(ticketUpdateRes: TicketStatusUpdateRequestModel) {
    this.ticketService.setStatus(ticketUpdateRes).subscribe(
      v => {
        this.ticketStatusChangeSuccessNotification();
        this.loadData();
      },
      error => {
        console.log(error);
        this.ticketStatusChangeErrorNotification();
      }
    );
  }

  getTicketActions(ticket: TicketSimpleModel): TicketActions {
    const extendedTicket = getExtendedTicket(ticket);
    return extendedTicket.actions;
  }

  findBiggestValueInArrayObject(data: any[], attribute: string): any {
    data.reduce((prev, current) => {
      return prev[attribute] > current[attribute] ? prev : current;
    });
  }

  checkedTicketItemChange(ticket: TicketSimpleModel, isChecked: boolean, tableType: tablesEnum) {
    const checkedTickets = tableType === tablesEnum.toTable ? this.checkedToTableTickets : this.checkedInTableTickets;

    if (checkedTickets.length === 0) {
      checkedTickets.push(ticket);
      return;
    }

    const itemIndex = checkedTickets.indexOf(ticket);

    if (itemIndex !== -1 && !isChecked) {
      checkedTickets.splice(itemIndex, 1);
      return;
    }

    if ((itemIndex === -1 || !itemIndex) && isChecked) {
      checkedTickets.push(ticket);
      return;
    }
  }

  async resetShowingTicketsByBiblioOnly() {
    this.toTableFilter['searchCitationByBiblioId'] = null;

    await this.refreshToTableData();
  }

  submitCheckedTickets(tableType: tablesEnum) {
    const checkedTickets = (tableType === tablesEnum.toTable ? this.checkedToTableTickets : this.checkedInTableTickets);

    if (tableType === tablesEnum.toTable) {
      this.openMultiItemsActionModal(checkedTickets, TicketMultiItemsActions.moveUp, this.activeTab.section);
    } else if (tableType === tablesEnum.inTable) {
      this.openMultiItemsActionModal(checkedTickets, TicketMultiItemsActions.moveDown, this.activeTab.section);
    }
  }

  selectAllTickets(tableType: tablesEnum) {
    if (tableType === tablesEnum.toTable) {
      this.toTableData.forEach(t => t.isChecked = true);
      this.checkedToTableTickets = [...this.toTableData];
    } else if (tableType === tablesEnum.inTable) {
      this.inTableData.forEach(t => t.isChecked = true);
      this.checkedInTableTickets = [...this.inTableData];
    }
  }

  deselectAllTickets(tableType: tablesEnum) {
    if (tableType === tablesEnum.toTable) {
      this.checkedToTableTickets = [];
      this.toTableData.forEach(t => t.isChecked = false);
    } else if (tableType === tablesEnum.inTable) {
      this.checkedInTableTickets = [];
      this.inTableData.forEach(t => t.isChecked = false);
    }
  }

  openMultiContactUserActionModal(items: TicketSimpleModel[]) {
    this.ticketContactEmailModalComponent.show(items, true);
  }

  openMultiItemsActionModal(items: TicketSimpleModel[], action: TicketMultiItemsActions, section: ticketSectionsEnum) {
    this.ticketMultiItemsActionModalComponent.show(items, action, section);
  }

  onFinishedMultiItemsAction(v) {
    if (v) {
      this.loadData();
    }
  }

  onFinishedContactUserAction(v) {
    if (v) {
      this.loadData();
    }
  }

  ticketStatusChangeSuccessNotification() {
    this.toastrService.success(this.i18n.getTranslation("Zmena prebehla úspešne"));
  }

  ticketStatusChangeErrorNotification() {
    this.toastrService.error(this.i18n.getTranslation("Zmena prebehla neúspešne"));
  }
}
