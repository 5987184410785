import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProjectModel } from "../../../models/project/project.model";
import { ModalDirective } from "ngx-bootstrap";
import { ProjectService } from "../../../services/project.service";
import { CONSTANT_ITEMS, ConstantsService } from "../../../services/constants.service";
import { ProjectInstitutionModel } from "../../../models/project-institution.model";
import { ProjectParticipantModel } from "../../../models/project/project-participant.model";
import { CodebooksService } from "../../../services/codebooks.service";
import { ToastrService } from "ngx-toastr";
import { AppCommunicationService } from "../../../services/app-communication.service";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-project-form",
  templateUrl: "./project-form.component.html",
  styleUrls: ["./project-form.component.scss"]
})
export class ProjectFormComponent implements OnInit {

  projectForm: FormGroup;

  loadedProject: ProjectModel = new ProjectModel();

  institutions: ProjectInstitutionModel[] = [];

  filteredInstitutions: ProjectInstitutionModel[] = [];

  selectedInstitution: ProjectInstitutionModel;

  keywords: string[] = [];

  participants: ProjectParticipantModel[] = [new ProjectParticipantModel()];

  doAfterEnd: () => void;

  showDepartments = false;

  @ViewChild("staticModal", { static: false }) modal: ModalDirective;

  @Output() wasDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    protected projectService: ProjectService,
    protected toastr: ToastrService,
    protected constantService: ConstantsService,
    private codebookService: CodebooksService,
    private appCommunicationService: AppCommunicationService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {

  }

  async loadInstitutions() {
    const institutions = await this.constantService.get(CONSTANT_ITEMS.projectInstitutions);

    if (institutions) {
      this.institutions = institutions;

      if (this.loadedProject.institutionCode) {
        this.selectedInstitution = this.institutions.find(i => i.code.toLowerCase() === this.loadedProject.institutionCode.toLowerCase());
      }
    }
  }

  clearSelectedValues() {
    this.keywords = [];
  }

  generateForm() {
    this.projectForm = this.formBuilder.group({
      agencyCode: [this.loadedProject.agencyCode || "", [Validators.required]],
      agencyName: [this.loadedProject.agencyName || "", [Validators.required]],
      grantNumber: [this.loadedProject.grantNumber || "", [Validators.required]],
      isLocked: [this.loadedProject.isLocked || false, [Validators.required]],
      name: [this.loadedProject.name || "", [Validators.required]],
      programName: [this.loadedProject.programName || ""],
      startYear: [this.loadedProject.startYear || ""],
      endYear: [this.loadedProject.endYear || ""],
      institutionName: [this.loadedProject.institutionName || ""],
      institutionCode: [this.loadedProject.institutionCode || ""],
      departmentName: [this.loadedProject.departmentName || ""],
      grantPrice: [this.loadedProject.grantPrice || 0],
      keywordsAsString: [this.loadedProject.keywordsAsString || ""],
      acronym: [this.loadedProject.acronym || ""],
      annotation: [this.loadedProject.annotation || ""]
    });

    if (this.loadedProject.keywordsAsString) {
      this.keywords = this.loadedProject.keywordsAsString.split(",");
    }

    if (this.loadedProject.participants) {
      this.participants = this.loadedProject.participants;
      this.participants.push(new ProjectParticipantModel());
    }

  }

  openModal(
    project: ProjectModel = null,
    doAfterEnd: () => void = null
  ) {

    if (project) {
      this.loadedProject = project;
    }

    this.doAfterEnd = doAfterEnd;

    this.clearSelectedValues();

    this.generateForm();

    this.institutionElCleared();

    this.loadInstitutions();

    setTimeout(() => {
      this.modal.show();
    }, 100);
  }

  close(skipAfterEndFn = false) {
    if (this.doAfterEnd && !skipAfterEndFn) {
      this.doAfterEnd();
    }
    this.modal.hide();
  }

  async submitAction() {
    await this.send();
  }

  closeAction() {
    this.close(true);
  }

  async deleteAction() {
    const res = await this.projectService
      .projectDelete(this.loadedProject.id).toPromise();

    if (!res) {
      console.log(res);
      this.toastr.error(this.i18n.getTranslation('Nastala chyba.'));
      return;
    }

    if (res) {
      this.wasDeleted.emit(true);
      this.toastr.success(this.i18n.getTranslation("Projekt bol úspešne zmazaný."));
      this.close();
    }
  }

  async send() {
    const isEditing = (this.loadedProject && this.loadedProject.id !== null && this.loadedProject.id > 0);

    this.appCommunicationService.loadingOn();

    const data = new ProjectModel();
    let res;

    let finalParticipants: ProjectParticipantModel[] = [];

    if (this.participants && this.participants.length > 0) {

      finalParticipants = this.participants.filter(p => p.surname && p.surname.length > 0);

    }

    finalParticipants.forEach((p, index) => {
      if (this.loadedProject && this.loadedProject.id) {
        p.projectId = this.loadedProject.id;
      }
      delete p["isExternal"];
      if (index === 0) {
        p.isSupervisor = true;

        if (this.selectedInstitution.code.toLowerCase() === "tuke") {
          this.projectForm.get("departmentName").patchValue(p.departmentName);
        }
      }
    });

    if (isEditing) {
      Object.assign(data, this.loadedProject);
      Object.assign(data, this.projectForm.value);
    } else {
      Object.assign(data, this.projectForm.value);
    }

    data.participants = finalParticipants;

    if (this.selectedInstitution) {
      data.institutionCode = this.selectedInstitution.code;
      data.institutionName = this.selectedInstitution.description;
    }

    if (isEditing) {
      res = await this.projectService
        .projectUpdate(data)
        .toPromise();
    } else {
      res = await this.projectService
        .projectCreate(data)
        .toPromise();
    }

    if (!res) {
      console.log(res);
      this.toastr.error(this.i18n.getTranslation("Nastala chyba."));
      this.appCommunicationService.loadingOff();
      return;
    }

    this.toastr.success(this.i18n.getTranslation("Projekt bol úspešne uložený / upravený."));
    this.appCommunicationService.loadingOff();
    this.close();
  }

  institutionElCleared() {
    this.filteredInstitutions = [...this.institutions];

    this.projectForm.patchValue({
      institutionName: "",
      institutionCode: ""
    });
  }

  institutionElAutocomplete(event) {

    this.filteredInstitutions = [...this.institutions];

    if (!event || event.query.length === 0) {
      return;
    }

    const text = event.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    this.filteredInstitutions = [];

    for (let i = 0; i < this.institutions.length; i++) {
      const inst = this.institutions[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

      if (inst.indexOf(text) !== -1) {
        this.filteredInstitutions.push(this.institutions[i]);
      }
    }
  }

  async onSelectInstitution() {
    if (!this.selectedInstitution) {
      return;
    }

    this.projectForm.patchValue({
      institutionName: this.selectedInstitution.description,
      institutionCode: this.selectedInstitution.code
    });

    if (this.selectedInstitution.code.toLowerCase() === "tuke") {
      this.showDepartments = false;
    } else {
      this.showDepartments = true;
    }
  }

  keywordsChanged(words: string[]) {
    if (!words) {
      return;
    }

    let w = [];

    words.forEach(word => {
      const splitted = word.split(/[\\;,]+/);
      w = [...w, ...splitted];
    });

    if (w && w.length === 0) {
      w = null;
    }

    const keywordsAsString = w.toString();

    this.projectForm.controls["keywordsAsString"].patchValue(keywordsAsString);

    this.keywords = w;
  }

  checkValidity() {

  }

  onDeleteParticipant(index: number) {
    this.participants.splice(index, 1);
    this.findEmptyParticipantAndPushNewOne();
  }

  onSubmittedParticipant(participant, index: number) {
    this.participants[index] = participant;
    this.findEmptyParticipantAndPushNewOne();
  }

  onEditParticipant(index: number) {
    this.findEmptyParticipantAndPushNewOne();
  }

  findEmptyParticipantAndPushNewOne() {
    const findEmpty = this.participants.find(p => !p.surname || p.surname.length === 0);

    if (!findEmpty) {
      this.participants.push(new ProjectParticipantModel());
    }
  }

  markParticipantAsFirstAuthor(participant: ProjectParticipantModel, currentIndex: number) {
    this.participants.splice(currentIndex, 1);
    this.participants.unshift(participant);
  }

}
