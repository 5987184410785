export class FormMeetingModel {
  id: number;
  name: string;
  location: string;
  meetingStartDate?: Date | string;
  meetingEndDate?: Date | string;
  partNumber?: number;

  static createEmptyMeeting() {
    const m = new FormMeetingModel();
    m.name = '';
    m.location = '';
    m.meetingStartDate = '';
    m.meetingEndDate = '';
    m.partNumber = 0;
    return m;
  }
}
