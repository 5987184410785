import { Component, OnInit } from "@angular/core";
import { DwToolsService } from "../../../services/dw-tools.service";
import { first } from "rxjs/operators";
import { DwRecordSimpleModel } from "../../../models/dwtools/dw-record-simple.model";
import { BaseEpc2Component } from "../base-epc2.component";
import { checkDwToolsErrorResponse } from "../dw-tools-errors";
import { DwDatabaseModel } from "../../../models/dwtools/dw-database.model";
import { DwRecordCrudDatabaseRequest } from "../../../models/dwtools/dw-record-crud-database-request";
import { DwDatabaseCrudRequest } from "../../../models/dwtools/dw-database-crud-request";
import { AppCommunicationService } from "../../../services/app-communication.service";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";
import { SearchedIdentifierOutput } from "../article-identifiers-section/article-identifiers-section.component";
import { DwDatabasesEnum } from "../dw-databases";

@Component({
  selector: "smart-epc2-add-databases",
  templateUrl: "./epc2-add-databases.component.html",
  styleUrls: ["./epc2-add-databases.component.scss"]
})
export class Epc2AddDatabasesComponent extends BaseEpc2Component implements OnInit {

  dwRecord: DwRecordSimpleModel;

  editingDatabases = false;

  editingIdentifiers = false;

  selectedDatabase: DwDatabaseModel;

  autoSearchIdentifierOptions = [];

  autoSearchIdentifier: any;

  publicationTitle = "";

  changes = {
    toDelete: [],
    toEdit: [],
    toAdd: []
  };

  constructor(
    private dwTools: DwToolsService,
    private appCommunication: AppCommunicationService,
    private toastr: ToastrService,
    private confirm: ConfirmationService,
    private i18n: I18nService
  ) {
    super();
  }

  ngOnInit() {

    this.generateAutoSearchIdentifierOptions();

  }

  generateAutoSearchIdentifierOptions() {
    this.autoSearchIdentifierOptions = [
      {
        name: this.i18n.getTranslation('Automaticky vyhľadať identifikátor'),
        value: true
      },
      {
        name: this.i18n.getTranslation('Nevyhľadávať'),
        value: false
      }
    ];

    this.autoSearchIdentifier = this.autoSearchIdentifierOptions[1];
  }


  async pasteValue() {
    const text = await navigator.clipboard.readText();
    this.ac = Number.parseInt(text, 0);
  }

  getBiblioFromDw(ac: number) {
    this.ac = ac;

    this.publicationTitle = "";

    if (!this.ac) {
      return;
    }

    this.dwTools
      .getFromDw(this.ac)
      .pipe(first())
      .subscribe({
        next: (value) => {
          if (value && value.title && this.autoSearchIdentifier.value) {
            this.publicationTitle = value.title;
          }
          this.dwRecord = value;
          this.resetToChanges();
          this.loadedDwRecord.emit(this.dwRecord);
        },
        error: (err) => {
          const message = checkDwToolsErrorResponse(err);

          if (message) {
            this.showingErrorMessage(message);
          }
        }
      });
  }

  resetEditModes() {
    this.editingDatabases = false;
    this.editingIdentifiers = false;
    this.selectedDatabase = null;
  }

  resetToChanges() {
    this.changes.toEdit = [];
    this.changes.toAdd = [];
    this.changes.toDelete = [];
  }

  toggleEditDatabases() {
    this.editingDatabases = !this.editingDatabases;
  }

  toggleEditIdentifiers() {
    this.editingIdentifiers = !this.editingIdentifiers;
  }

  toggleDeleteDatabase(database: DwDatabaseModel) {
    database["wasEdited"] = true;
    this.changes.toDelete.push(database);
    this.resetEditModes();
  }

  toggleEditDatabase(database: DwDatabaseModel) {
    this.selectedDatabase = database;
  }

  addNewDatabase() {
    this.selectedDatabase = new DwDatabaseModel();
  }

  closedDatabaseForm(closed: boolean) {
    if (closed) {
      this.resetEditModes();
    }
  }

  submittedDatabaseForm(database: DwDatabaseModel) {
    if (database && database.orderNumber) {
      if (this.selectedDatabase) {
        this.selectedDatabase["wasEdited"] = true;
      }
      this.changes.toEdit.push(database);
    } else {
      this.changes.toAdd.push(database);
    }
    this.resetEditModes();
  }

  deleteFromChanges(location: DwDatabaseModel[], item: DwDatabaseModel) {
    if (location && location.length > 0) {
      const index = location.findIndex(l => l.name === item.name && l.code === item.code && l.field === item.field);

      if (index !== -1) {
        location.splice(index, 1);
      }
    }
  }

  async submitAndImport() {

    const res = await this.onSubmit();

    if (res) {

      this.appCommunication.loadingOn(this.i18n.getTranslation('Prebieha import.'));

      this.dwTools
        .biblioImportFromDw(this.ac, true)
        .pipe(first())
        .subscribe({
          next: (val) => {
            this.generateSuccessMessage();

            this.appCommunication.loadingOff();
          },
          error: (err) => {
            const message = checkDwToolsErrorResponse(err);

            if (message) {
              this.showingErrorMessage(message);
            }

            this.appCommunication.loadingOff();
          }
        });
    }

  }

  async onSubmit(): Promise<boolean> {
    this.appCommunication.loadingOn();

    const request = new DwRecordCrudDatabaseRequest();
    request.archiveId = this.dwRecord.archiveId;
    request.objectId = this.dwRecord.objectId;

    if (this.changes && this.changes.toAdd.length > 0) {
      this.changes.toAdd.forEach(item => {
        const i = new DwDatabaseCrudRequest();
        i.isNew = true;
        i.field = item.field;
        i.code = item.code;
        i.name = item.name;
        request.databases.push(i);
      });
    }

    if (this.changes && this.changes.toEdit.length > 0) {
      this.changes.toEdit.forEach(item => {
        const i = new DwDatabaseCrudRequest();
        i.isUpdate = true;
        i.field = item.field;
        i.code = item.code;
        i.name = item.name;
        i.orderNumber = item.orderNumber;
        request.databases.push(i);
      });
    }

    if (this.changes && this.changes.toDelete.length > 0) {
      this.changes.toDelete.forEach(item => {
        const i = new DwDatabaseCrudRequest();
        i.isDelete = true;
        i.field = item.field;
        i.code = item.code;
        i.name = item.name;
        i.orderNumber = item.orderNumber;
        request.databases.push(i);
      });
    }

    if (!request.databases || request.databases.length === 0) {
      this.appCommunication.loadingOff();
      return Promise.resolve(false);
    }

    return new Promise((resolve, reject) => {
      this.dwTools
        .biblioUpdateDatabase(request)
        .pipe(first())
        .subscribe({
          next: (value) => {
            this.toastr.success(this.i18n.getTranslation('Operácia bola úspešná'), this.i18n.getTranslation('Úspech'));
            this.appCommunication.loadingOff();
            this.resetEditModes();
            this.resetMessages();
            this.resetToChanges();

            if (value) {
              this.dwRecord = value;
            }

            resolve(true);
          },
          error: (err) => {
            console.log(err);
            this.toastr.error(this.i18n.getTranslation('Operácia sa nepodarila'), this.i18n.getTranslation('Chyba'));
            this.appCommunication.loadingOff();

            reject(err);
          }
        });
    });
  }

  generateSuccessMessage() {
    this.showingSuccessMessage("DW_TO_BIBLIO_MAP_SUCCESS");
  }

  deleteQuartils() {
    this.confirm
      .confirm({
        header: this.i18n.getTranslation('Premazanie kvartilov'),
        message: this.i18n.getTranslation('Skutočne si prajete vymazať kvartily? Touto operáciou bude vymazané pole 977$q.'),
        accept: () => {
          this.appCommunication.loadingOn();

          this.dwTools
            .biblioDeleteQuartils(this.ac)
            .pipe(first())
            .subscribe({
              next: () => {
                this.getBiblioFromDw(this.ac);
                this.toastr.success(this.i18n.getTranslation("Premazanie kvartilov prebehlo v poriadku."), this.i18n.getTranslation('Úspech'));
                this.appCommunication.loadingOff();
              },
              error: err => {
                console.log(err);
                this.toastr.error(this.i18n.getTranslation("Premazanie kvartilov neúspešné."), this.i18n.getTranslation('Chyba'));
                this.appCommunication.loadingOff();
              }
            });
        },
        acceptLabel: this.i18n.getTranslation('Áno'),
        rejectLabel: this.i18n.getTranslation('Nie')
      });
  }

  autoSearchedIdentifiers(identifiers: SearchedIdentifierOutput) {
    // if (identifiers.wosid) {
    //   this.addNewDatabase();
    //   this.selectedDatabase.code = identifiers.wosid;
    //   this.selectedDatabase.code = "WOS:";
    //   this.selectedDatabase.name = DwDatabasesEnum.WOS;
    // }
    //
    // if (identifiers.scopusid) {
    //   this.addNewDatabase();
    //   this.selectedDatabase.code = identifiers.scopusid;
    //   this.selectedDatabase.code = "";
    //   this.selectedDatabase.name = DwDatabasesEnum.SCOPUS;
    // }
    //
    // if (identifiers.cccid) {
    //   this.addNewDatabase();
    //   this.selectedDatabase.code = identifiers.cccid;
    //   this.selectedDatabase.code = "CCC:";
    //   this.selectedDatabase.name = DwDatabasesEnum.CCC;
    // }
  }

}
