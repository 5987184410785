import { Component, Input, OnInit } from "@angular/core";
import { BiblioCatalogSharedService } from "../biblio-catalog-shared.service";
import { BiblioService } from "../../../services/biblio.service";
import { FilterItem, getQueryDesignerFilter } from "../misc/query-designer";
import { FormKeywordModel } from "../../../models/registration-form/form-keyword.model";
import { BiblioModel } from "../../../models/biblio/biblio.model";
import { Subject } from "rxjs";
import { queryFilterTypes } from "../../../../core-frontend-common/misc/query-designer/query-designer";

@Component({
  selector: 'smart-keyword-detail',
  templateUrl: './keyword-detail.component.html',
  styleUrls: ['./keyword-detail.component.scss']
})
export class KeywordDetailComponent implements OnInit {
  queryDesigner: any;

  biblioData: BiblioModel[] = [];

  keyword: FormKeywordModel;

  @Input() dataChanged: Subject<any> = new Subject<any>();

  constructor(
    protected catalogDataService: BiblioCatalogSharedService,
    protected biblioService: BiblioService
  ) {
    this.queryDesigner = this.getQueryDesigner();
  }

  ngOnInit() {
    setTimeout(() => {
      this.dataChanged.subscribe(() => {
        this.queryDesigner = this.getQueryDesigner();
      });
    }, 500);
  }

  getQueryDesigner(): any {
    this.keyword = this.catalogDataService.data.keyword;

    const filterItem: FilterItem = {
      queryParam: 'keywords',
      queryParamValue: 'keywords.description',
      queryParamData: [this.keyword.description],
      filterType: queryFilterTypes.Contains
    };

    return getQueryDesignerFilter(
      [filterItem],
      null,
      1,
      10,
      null
    );
  }

}
