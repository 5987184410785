import { ComponentRef, Injectable, Type } from "@angular/core";
import { OverlayDialogRootComponent } from "./overlay-dialog-root";
import { OverlayDialog } from "./overlay-dialog";
import { Subject } from "rxjs";

export class OverlayDataStructure {
  image: string;
  icon: string;
  text: string;
  swipe: {
    text: string;
    action: () => {};
  };
  button: {
    text: string;
    action: () => {}
  };
}

@Injectable()
export class OverlayService {

  protected dialogRoot: OverlayDialogRootComponent;

  protected currentDialog: OverlayDialog;

  protected operating: boolean;

  public data: Subject<any> = new Subject<any>();

  constructor(
  ) {}

  public registerOverlayComponent(component: OverlayDialogRootComponent) {

    if (this.dialogRoot) {
      throw new Error("Overlay dialog root is already registered!");
    }
    this.dialogRoot = component;

    this.operating = false;

  }

  public getCurrentDialog(): OverlayDialog {
    return this.currentDialog;
  }

  public isShown(): boolean {
    return !!this.currentDialog;
  }

  forceOperating(val: boolean) {
    this.operating = val;
  }

  public isOperating(): boolean {
    return this.operating;
  }

  public hide(): Promise<void> {
    if (this.currentDialog) {

      // if (this.operating) {
      //   return Promise.reject('Another dialog is operating right now.');
      // }
      const dur = this.currentDialog.getHideDuration();
      this.operating = true;
      this.currentDialog.hide();

      let resolveFn;

      setTimeout(
        () => {
          this.dialogRoot.removeComponent();
          this.currentDialog = null;
          this.operating = false;
          setTimeout(
            () => {
              if (resolveFn) {
                resolveFn();
              }
            },
            0
          );
        },
        (dur + 10)
      );


      return new Promise<void>(
        (resolve, reject) => {
          resolveFn = resolve;
        }
      );
    }

    return Promise.resolve();
  }

  public async show<T extends OverlayDialog>(dialogType: Type<T>): Promise<{ componentRef: ComponentRef<T>, finished: Promise<void> }> {

    if (!this.dialogRoot) {
      throw new Error("Cannot show dialog, no OverlayDialogRoot has been attached.");
    }

    if (this.operating) {
      throw new Error("Another dialog is operating right now.");
    }

    await this.hide();

    this.operating = true;

    const componentRef = this.dialogRoot.showComponent<T>(dialogType);
    const component = componentRef.instance;
    const dur = component.getShowDuration();

    this.currentDialog = component;


    const finishedPromise = new Promise<void>(
      (resolve, reject) => {
        setTimeout(
          () => {
            this.operating = false;
            resolve();
          },
          (dur + 10)
        );
      }
    );

    setTimeout(
      () => {
        component.show();
      },
      0
    );

    return {
      componentRef,
      finished: finishedPromise
    };

  }

  getOverlayDialogRoot() {
    return this.dialogRoot;
  }

}
