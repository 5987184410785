import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { PersonValidityModel } from "../../models/person-validity.model";
import { PersonsService } from "../../services/persons.service";
import { PersonCreateUpdateRequestModel } from "../../models/person-create-update.request.model";
import { ToastrService } from "ngx-toastr";
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";
import { Router } from "@angular/router";
import { PdfViewerComponent } from "../../../core-frontend-common/components/pdf-viewer/pdf-viewer.component";
import { PDF_TYPES } from "../../../core-frontend-common/components/pdf-viewer/pdf-types.enum";
import { AppCommunicationService } from "../../services/app-communication.service";
import { SystemUserModel } from "../../models/system-user.model";
import { UserRolePipe } from "../../../core-frontend-common/pipes/user-role.pipe";
import { removeDiacriticsFromString } from "../../misc/remove-diacritics-from-string";
import { setCaretPosition } from "../../misc/set-caret-position";
import { ResearchAreaCodebookModel } from "../../models/codebook/research-area-codebook.model";
import { BiblioFieldOfStudyCodebookModel } from "../../models/codebook/biblio-field-of-study-codebook.model";
import { CODEBOOK_ITEMS, CodebooksService } from "../../services/codebooks.service";
import { ErrorCodes } from "../../constants";

@Component({
  selector: "smart-user-publication-data-dialog",
  templateUrl: "./user-publication-data-dialog.component.html",
  styleUrls: ["./user-publication-data-dialog.component.scss"]
})
export class UserPublicationDataDialogComponent implements OnInit {

  person: PersonCreateUpdateRequestModel = new PersonCreateUpdateRequestModel();

  iUnderstand = false;

  allowClose = false;

  checkedUser = false;

  verifiedOrcid = "";

  selectedGender: {
    code: string;
    value: string
  };

  genders = [];

  researchAreas: ResearchAreaCodebookModel[] = [];
  fieldOfStudies: BiblioFieldOfStudyCodebookModel[] = [];
  filteredResearchAreas: ResearchAreaCodebookModel[] = [];
  filteredFieldOfStudies: BiblioFieldOfStudyCodebookModel[] = [];
  selectedResearchArea: ResearchAreaCodebookModel = new ResearchAreaCodebookModel();
  selectedFieldOfStudy: BiblioFieldOfStudyCodebookModel = new BiblioFieldOfStudyCodebookModel();

  @ViewChild("userPublicationDataDialog", { static: true }) userPublicationDataDialog: ModalDirective;

  @ViewChild(PdfViewerComponent, { static: false }) pdfViewer: PdfViewerComponent;

  constructor(
    protected personsService: PersonsService,
    protected toastr: ToastrService,
    protected translateService: I18nService,
    protected router: Router,
    protected appCommunicationService: AppCommunicationService,
    protected userRole: UserRolePipe,
    protected codebookService: CodebooksService
  ) {
  }

  ngOnInit() {
    this.loadCodebooks();

    this.generateGenders();
  }

  generateGenders() {
    this.genders = [
      {
        code: "M",
        value: this.translateService.getTranslation("Muž")
      },
      {
        code: "Z",
        value: this.translateService.getTranslation("Žena")
      }
    ];
  }

  async loadCodebooks() {
    this.researchAreas = await this.codebookService.get(CODEBOOK_ITEMS.researchAreas);
    this.fieldOfStudies = await this.codebookService.get(CODEBOOK_ITEMS.fieldOfStudy);
  }

  focusedOrcIdInput(e) {
    if (e && !this.person.orcid || this.person.orcid.length === 0) {
      setTimeout(() => {
        setCaretPosition("orcId", 0);
      }, 70);
    }
  }

  show(person: PersonValidityModel, allowClose = false, forceOpen = false) {
    this.allowClose = allowClose;

    Object.assign(this.person, person);

    if (this.genders && this.person.gender) {
      this.selectedGender = this.genders.find(g => g.code.toLowerCase() === this.person.gender.toLowerCase());
    }

    this.userPublicationDataDialog.config.ignoreBackdropClick = true;
    this.userPublicationDataDialog.config.backdrop = "static";
    this.userPublicationDataDialog.config.keyboard = false;
    this.userPublicationDataDialog.show();

    setTimeout(() => {
      if (this.researchAreas && this.person.researchAreaId) {
        const researchArea = this.researchAreas.find(r => r.id === this.person.researchAreaId);

        if (researchArea) {
          this.selectedResearchArea = researchArea;
        }
      }

      if (this.fieldOfStudies && this.person.biblioFieldOfStudyId) {
        const fieldOfStudy = this.fieldOfStudies.find(r => r.id === this.person.biblioFieldOfStudyId);

        if (fieldOfStudy) {
          this.selectedFieldOfStudy = fieldOfStudy;
        }
      }
    }, 2000);
  }

  showManual(type: "orcid" | "publonsid") {
    this.pdfViewer.show(
      type.toUpperCase(),
      PDF_TYPES.local,
      `assets/pdf/${type}.pdf`
    );
  }

  onSubmit() {
    if (this.person.orcid && this.person.publonsId && this.person.birthYear && this.person.researchAreaId && this.person.biblioFieldOfStudyId && this.selectedGender) {

      const correctPublonsId = this.checkPublonsId(this.person.publonsId);

      if (!correctPublonsId) {
        this.toastr.error(this.translateService.getTranslation("Zadané Publons ID je v nesprávnom formáte."));
        return;
      }

      const create = new PersonCreateUpdateRequestModel();
      create.publonsId = this.person.publonsId;
      create.orcid = this.checkIfOrcIdIsInCorrectFormatAndFixIt(this.person.orcid);
      create.birthYear = this.person.birthYear;
      create.biblioFieldOfStudyId = this.person.biblioFieldOfStudyId;
      create.researchAreaId = this.person.researchAreaId;
      create.login = this.person.login;
      create.gender = this.selectedGender.code;
      create.id = this.person.id;
      create.name = this.person.name;
      create.surname = this.person.surname;

      this.appCommunicationService.loadingOn(this.translateService.getTranslation("Ukladám."));

      this.personsService
        .createOrUpdatePerson(create)
        .subscribe(v => {

          if (v) {
            this.toastr.success(this.translateService.getTranslation("Údaje boli úspešne uložené."));
            this.userPublicationDataDialog.hide();
          }
          this.appCommunicationService.loadingOff();
        }, e => {
          console.log(e);

          this.appCommunicationService.loadingOff();

          if (e && e.error && e.error.errors && e.error.errors.length > 0) {

            if (e.error.errors.indexOf(ErrorCodes.AnotherUsersOrcIdOrPublons) !== -1) {

              this.toastr.error(this.translateService.getTranslation("Zadali ste ORCID alebo Publons ID inej osoby. Prosíme aby ste zadali svoje ORCID a Publons ID."));

              return;

            }

          }

          this.toastr.error(this.translateService.getTranslation("Údaje sa nepodarilo uložiť."));
        });

    }
  }

  checkPublonsId(publonsId: string): boolean {
    const regex = new RegExp(/[A-Z]{1,3}-\d{4}-(19|20)\d\d/g);
    return regex.test(publonsId);
  }

  checkIfOrcIdIsInCorrectFormatAndFixIt(orcid: string): string {
    orcid = orcid.replace(/-/g, "");
    orcid = orcid.match(/.{1,4}/g).join("-");
    return orcid;
  }

  closeClicked() {
    if (this.allowClose) {
      this.userPublicationDataDialog.hide();
    } else {
      this.router.navigate(["/dashboard"]);
      this.userPublicationDataDialog.hide();
    }
  }

  checkOrcId() {
    const withoutDash = this.person.orcid.replace(new RegExp("-", "g"), "");

    if (withoutDash && withoutDash.length !== 16) {
      return;
    }

    const orcidArray = withoutDash.match(/.{1,4}/g);

    const orcid = orcidArray.join("-");

    this.appCommunicationService.loadingOn(this.translateService.getTranslation("Načítavam údaje."));

    if (orcid) {
      this.personsService
        .checkOrcId(orcid)
        .subscribe(v => {
          if (v && v.result) {
            this.checkIfItIsUsersData(v.result);
          }

          if (v && v.errors && v.errors.length > 0) {
            this.toastr.error(this.translateService.getTranslation("Zadané ORCID neexistuje."));
            this.checkedUser = false;
          }

          this.appCommunicationService.loadingOff();
        }, error => {
          if (error && error.error && error.error.errors.length > 0) {
            this.toastr.error(this.translateService.getTranslation("Zadané ORCID neexistuje."));
            this.checkedUser = false;
          }
          this.appCommunicationService.loadingOff();
        });
    }
  }

  changedOrcId() {
    if (this.checkedUser) {
      this.checkedUser = false;
      this.toastr.warning(this.translateService.getTranslation("Nastala zmena hodnoty ORCID po overení. Prosím, overte ORCID opäť pre verifikovanie údajov."));
    }
  }

  checkIfItIsUsersData(data) {
    const user = AppCommunicationService.getCurrentUser().user as SystemUserModel;

    if (user.rolesAsString.includes("SUPERADMIN")) {
      if (data.publonsId) {
        this.person.publonsId = data.publonsId;
      }
      this.verifiedOrcid = this.person.orcid;
      this.checkedUser = true;

      return;
    }

    let userFullnameLocal = '';
    let userFullnameData = '';
    if (user.name) {
      userFullnameLocal += removeDiacriticsFromString(user.name);
    }
    if (user.surname) {
      userFullnameLocal += removeDiacriticsFromString(user.surname);
    }

    userFullnameLocal = userFullnameLocal.trim().replace(/\s/g, "");

    console.log(data)

    console.log(userFullnameLocal)
    if (data) {
      if (data.name) {
        userFullnameData += removeDiacriticsFromString(data.name);
      }
      if (data.surname) {
        userFullnameData += removeDiacriticsFromString(data.surname);
      }

      userFullnameData = userFullnameData.trim().replace(/\s/g, "");
      console.log(userFullnameData)

      if (userFullnameLocal === userFullnameData) {
        if (data.publonsId) {
          this.person.publonsId = data.publonsId;
        }
        this.checkedUser = true;
      } else {
        this.toastr.error(this.translateService.getTranslation("Nepodarilo sa overiť ORCID. Uistite sa, že v ORCID systéme máte zverejnené vaše meno a priezvisko rovnaké ako máte zadané v systéme."));
      }

    } else {
      this.toastr.error(this.translateService.getTranslation("Zadané ORCID nepatrí aktuálne prihlásenému užívateľovi. Zadajte iné."));
      this.checkedUser = false;
    }
  }

  filterAutocompleteMethod(event, type: "researchArea" | "fieldOfStudy") {
    if (event.query.length === 0) {
      if (type === "researchArea") {
        this.filteredResearchAreas = [...this.researchAreas];
      } else if (type === "fieldOfStudy") {
        this.filteredFieldOfStudies = [...this.fieldOfStudies];
      }
      return;
    }

    const text = event.query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    if (type === "researchArea") {

      this.filteredResearchAreas = [];

      for (let i = 0; i < this.researchAreas.length; i++) {
        const lang = this.researchAreas[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        if (lang.indexOf(text) !== -1) {
          this.filteredResearchAreas.push(this.researchAreas[i]);
        }
      }

    } else if (type === "fieldOfStudy") {

      this.filteredFieldOfStudies = [];

      for (let i = 0; i < this.fieldOfStudies.length; i++) {
        const country = this.fieldOfStudies[i].description.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        if (country.indexOf(text) !== -1) {
          this.filteredFieldOfStudies.push(this.fieldOfStudies[i]);
        }
      }

    }
  }

  filterResearchAreaCleared(event) {
    this.filteredResearchAreas = this.researchAreas;
  }

  filterFieldOfStudyCleared(event) {
    this.filteredFieldOfStudies = this.fieldOfStudies;
  }

  selectedAutocompleteValue(value, type: "researchArea" | "fieldOfStudy") {
    if (!value) {
      return;
    }

    if (type === "researchArea") {
      this.person.researchAreaId = value.id;
    }

    if (type === "fieldOfStudy") {
      this.person.biblioFieldOfStudyId = value.id;
    }
  }

}
