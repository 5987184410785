import { Component, OnInit, ViewChild } from "@angular/core";
import { QuoteSectionBaseComponent } from "../quote-section-base.component";
import { CODEBOOK_ITEMS } from "../../../../services/codebooks.service";
import { CitationCategoryModel } from "../../../../models/codebook/citation-category.model";
import { CitationDatabases } from "../../../../constants";
import { Dropdown } from "primeng";
import { CitationDatabaseType } from "../../../../types/citation-database.type";
import { CitationCategorySectionType } from "./citation-category-section.type";

@Component({
  selector: "citation-category-section",
  templateUrl: "./citation-category-section.component.html",
  styleUrls: ["./citation-category-section.component.scss"]
})
export class CitationCategorySectionComponent extends QuoteSectionBaseComponent implements OnInit {
  citationDatabases: { id: CitationDatabaseType, title: CitationDatabaseType }[] = [];

  citationDatabase: CitationDatabaseType;

  citationDatabaseTypes = CitationDatabaseType;

  citationCategoryData: CitationCategoryModel[] = [];

  citationCategory: CitationCategoryModel;

  @ViewChild("citationCategoryEl", { static: false }) citationCategoryEl: Dropdown;

  @ViewChild("citationDatabaseEl", { static: false }) citationDatabaseEl: Dropdown;

  ngOnInit() {
    this.generateCitationCategoryData();

    this.generateCitationDatabasesData();
  }

  editedCitationChanged() {
    super.editedCitationChanged();

    if (this.editedCitation && this.isEditing) {

      if (this.editedCitation.databaseType) {
        this.citationDatabase = this.editedCitation.databaseType;

        if (this.citationDatabase) {
          this.citationDatabaseEl.selectedOption = { label: this.citationDatabase, value: { id: this.citationDatabase, title: this.citationDatabase }};
        }

        if (this.citationDatabase === CitationDatabaseType.wos || this.citationDatabase === CitationDatabaseType.scopus) {
          this.generateCitationCategoryData(true);
        }
      }

      if (this.editedCitation.categoryId) {
        setTimeout(() => {

          const category = this.citationCategoryData.find(c => c.id === this.editedCitation.categoryId);

          if (category) {
            this.citationCategory = category;

            this.citationCategoryEl.selectedOption = { label: this.citationCategory.description, value: this.citationCategory };
          }

        }, 50);
      }

      setTimeout(() => {
        this.sectionValidityChanged();
      }, 150);
    }
  }

  async generateCitationCategoryData(forWos = false) {
    const categories = await this.codebooksService
      .get(CODEBOOK_ITEMS.citationCategories);

    if (categories) {
      this.citationCategoryData = categories;

      if (forWos) {
        this.citationCategoryData = categories.filter(c => Number.parseInt(c.categoryGroupCode, 0) === 1);
      } else {
        this.citationCategoryData = categories.filter(c => Number.parseInt(c.categoryGroupCode, 0) > 1);
      }

      if (this.citationCategoryData && this.citationCategoryData.length === 1) {
        this.citationCategoryChange(this.citationCategoryData[0], true);
      }
    }
  }

  generateCitationDatabasesData() {
    CitationDatabases.forEach(citationDatabase => {
      this.citationDatabases.push({
        id: citationDatabase,
        title: citationDatabase
      });
    });
  }

  citationDatabaseChange(e) {
    this.citationDatabase = e.value.title;
    this.citationCategoryEl.selectedOption = {};
    this.citationCategory = null;
    this.generateCitationCategoryData(
      this.citationDatabase === CitationDatabaseType.wos || this.citationDatabase === CitationDatabaseType.scopus
    );
    this.sectionValidityChanged();
    setTimeout(() => {
      this.citationCategoryEl.show();
    }, 20);
  }

  // STEP 2 - choose citationcategory
  citationCategoryChange(e, directlyValue = false) {
    if (directlyValue) {
      this.citationCategory = e;

      setTimeout(() => {
        this.citationCategoryEl.selectedOption = { label: this.citationCategory.description, value: this.citationCategory };
      }, 50);
    } else {
      this.citationCategory = e.value;
    }
    this.sectionValidityChanged();
  }

  sectionValidityChanged() {
    super.sectionValidityChanged();

    const sectionData: CitationCategorySectionType = CitationCategorySectionType.createEmpty();

    sectionData.valid = false;
    sectionData.data.category = this.citationCategory;
    sectionData.data.database = this.citationDatabase;

    if (this.citationCategory && this.citationCategory.id && this.citationDatabase) {
      sectionData.valid = true;
    }

    this.validityChanged.emit(sectionData);
  }
}
