import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { I18nService } from "../../../../../core-frontend-common/template/shared/i18n";
import { TicketService } from "../../../../services/ticket.service";
import { first } from "rxjs/operators";
import { OpenAccessPayerModel } from "../../../../models/open-access/open-access-payer.model";
import { BaseConstantModel } from "../../../../models/base-constant.model";
import { OpenAccessBiblioModel } from "../../../../models/open-access/open-access-biblio.model";
import { OpenAccessBiblioRequestModel } from "../../../../models/open-access/open-access-biblio-request.model";
import { SelectItem } from "primeng";

@Component({
  selector: "smart-open-access-section",
  templateUrl: "./open-access-section.component.html",
  styleUrls: ["./open-access-section.component.scss"]
})
export class OpenAccessSectionComponent implements OnInit {

  openAccess: OpenAccessBiblioModel = new OpenAccessBiblioModel();

  licences: BaseConstantModel[] = [];

  selectedLicence: BaseConstantModel;

  payers: OpenAccessPayerModel[] = [];

  selectButtonOptions: SelectItem[] = [];

  @Input() set setOpenAccess(openAccessBiblio: OpenAccessBiblioRequestModel | null) {
    if (openAccessBiblio) {
      this.openAccess = openAccessBiblio;

      this.openAccess.payers.forEach(p => p.isValid = true);

      this.payers = this.openAccess.payers;

      const licence = this.licences.find(l => l.value.toLowerCase().trim() === openAccessBiblio.licenceCode.toLowerCase().trim());
      if (licence) {
        this.selectedLicence = licence;
      }

      this.emitData();
    }
  }

  @Output() dataChanged: EventEmitter<OpenAccessBiblioRequestModel> = new EventEmitter<OpenAccessBiblioRequestModel>();

  constructor(
    private translationService: I18nService,
    private ticketService: TicketService
  ) {
  }

  ngOnInit() {

    this.getOpenAccessLicences();
  }

  getOpenAccessLicences() {
    this.ticketService
      .getOpenAccessLicences()
      .pipe(first())
      .subscribe({
        next: value => this.licences = value
      });
  }

  licenceChange(e) {
    // e.value.value;
    this.openAccess.licenceCode = e.value.value;

    if (this.openAccess && this.openAccess.licenceCode) {
      this.addPayer();
    }

    this.emitData();
  }

  addPayer() {
    this.payers.push(new OpenAccessPayerModel());
    this.emitData();
  }

  deletePayer(payer: OpenAccessPayerModel, index: number) {
    this.payers.splice(index, 1);
    this.emitData();
  }

  changedPayer(payer: OpenAccessPayerModel, index: number) {
    this.payers[index] = payer;
    this.emitData();
  }

  getOpenAccessSectionData(): OpenAccessBiblioRequestModel {
    const openAccessRequest: OpenAccessBiblioRequestModel = new OpenAccessBiblioRequestModel();

    if (this.selectedLicence) {
      openAccessRequest.licenceCode = this.selectedLicence.value;
    }

    if (this.openAccess.id) {
      openAccessRequest.id = this.openAccess.id;
    }

    const hasSomeInvalidPayer = this.payers.find(p => !p.isValid);

    if (hasSomeInvalidPayer) {
      openAccessRequest.isValid = false;
    } else {
      openAccessRequest.payers = this.payers;
      openAccessRequest.isValid = true;
    }

    if (!this.selectedLicence) {
      openAccessRequest.payers = [];
      openAccessRequest.isValid = true;
    }

    return openAccessRequest;
  }

  emitData() {
    this.dataChanged.emit(this.getOpenAccessSectionData());
  }
}
