import { Component, OnInit } from "@angular/core";
import { PersonsService } from "../../../services/persons.service";
import { getQueryDesignerFilter, SearchItem } from "../../biblio-catalog-view/misc/query-designer";
import { first } from "rxjs/operators";
import { UserService } from "../../../services/user.service";
import { BiblioPersonModel } from "../../../models/biblio/biblio-person.model";
import { TukeAuthorRequestModel } from "../../../models/tuke-author-request.model";

@Component({
  selector: "smart-user-history-section",
  templateUrl: "./user-history-section.component.html",
  styleUrls: ["./user-history-section.component.scss"]
})
export class UserHistorySectionComponent implements OnInit {

  input = "";

  data: TukeAuthorRequestModel[] = [];

  isSearchedUser = false;

  userData: any[] = [];

  constructor(
    private personsService: PersonsService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
  }

  onFilterUsers(event) {
    this.isSearchedUser = false;
    this.searchParticipant(event.query);
  }

  searchParticipant(text: string) {
    const names = text.split(' ');

    const surname = names[0];
    let name = '';

    if (names && names.length > 1) {
      name = names[1];
    }

    const search = new SearchItem();
    search.searchValue = surname;
    search.searchParams = ["login", "surname"];

    const query = getQueryDesignerFilter([], search);

    if (name && name.length > 0) {
      const s = {
        fields: ['login', 'name'],
        value: name
      };
      query.search.push(s);
    }

    this.personsService
      .getAllTukeAuthorsByFilter(query)
      .pipe(first())
      .subscribe(val => {
        if (val === null) {
          val = [];
        }
        this.data = val;
      });
  }

  search() {
    const search = new SearchItem();
    search.searchValue = this.input;
    search.searchParams = ["login", "surname"];

    const query = getQueryDesignerFilter([], search);

    this.personsService
      .getAllTukeAuthorsByFilter(query)
      .pipe(first())
      .subscribe({
        next: value => {
          this.data = value;
        }
      });
  }

  onSelectOption(person: BiblioPersonModel) {
    if (!person || !person.login) return;

    this.userService
      .getTukeAuthorEmploymentsByLogin(person.login)
      .pipe(first())
      .subscribe({
        next: value => {
          this.userData = value;
          this.isSearchedUser = true;
        }
      });
  }
}
