export class BasePersonModel {
  id: number;
  fullName: string;
  name: string;
  surname: string;
  login: string;
  publonsId: string;
  orcid: string;
  gender: string;
  biblioFieldOfStudyId: number;
  researchAreaId: number;
}
