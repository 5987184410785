import { Component, OnInit } from "@angular/core";
import { DwRecordSimpleModel } from "../../models/dwtools/dw-record-simple.model";

@Component({
  selector: "smart-dw-tools-view",
  templateUrl: "./dw-tools-view.component.html",
  styleUrls: ["./dw-tools-view.component.scss"]
})
export class DwToolsViewComponent implements OnInit {

  dwRecord: DwRecordSimpleModel;

  tabs = {
    epc2dw: 0,
    epc2database: 1,
    quartil: 2,
    userHistory: 3,
    articleIdentifiers: 4,
    crepcUpdate: 5,
  };

  activeTabIndex = this.tabs.epc2dw;

  constructor() {
  }

  ngOnInit() {
  }

  storeDwRecord(dwRecord: DwRecordSimpleModel) {
    this.dwRecord = dwRecord;
  }
}
