import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormOtherIdentifierMetadataModel } from '../../../../models/registration-form/form-other-identifier-metadata.model';

@Component({
  selector: 'smart-other-identifier',
  templateUrl: './other-identifier.component.html',
  styleUrls: ['./other-identifier.component.scss'],
})
export class OtherIdentifierComponent implements OnInit {
  isSubmitted = false;

  isValid = false;

  @Input() identifier: FormOtherIdentifierMetadataModel;

  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() addIdentifierEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
    if (this.identifier.id) {
      this.isSubmitted = true;
      this.isValid = true;
    }
  }

  checkValidity() {
    if (this.identifier &&
      this.identifier.otherIdentifier &&
      this.identifier.otherIdentifier.length > 0 &&
      this.identifier.otherIdentifierSource &&
      this.identifier.otherIdentifierSource.length > 0
    ) {
      this.isValid = true;
      return;
    }
    this.isValid = false;
    return;
  }

  submit() {
    this.isSubmitted = true;
  }

  delete() {
    this.deleteEvent.emit(this.identifier);
  }

  addIdentifier() {
    this.addIdentifierEvent.emit(true);
  }

}
