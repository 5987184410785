import { Pipe, PipeTransform } from "@angular/core";
import { TableColumn } from "../misc/table-responsive";

@Pipe({
  name: "tableShowColumn"
})
export class TableShowColumnPipe implements PipeTransform {

  transform(allColumns: TableColumn[], columnName): boolean {
    if (!allColumns || (columnName === null || columnName === undefined)) {
      return false;
    }
    const find = allColumns.find(c => c.name === columnName);
    if (find) {
      return true;
    }
    return false;
  }

}
