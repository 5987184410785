export class TukeAuthorRequestModel {
  login: string;
  name: string;
  surname: string;
  birthname: string;
  gender: string;
  facultyId?: number;
  facultyIdAsString: string;
  facultyCode: string;
  facultyName: string;
  departmentId?: number;
  departmentIdAsString: string;
  departmentCode: string;
  departmentName: string;
  publonsId: string;
  orcid: string;
}
