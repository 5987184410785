import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/core-frontend-common/auth/authentication.service';

@Injectable()
export class AppCommunicationService {
  loadingAnimation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingAnimationText: Subject<string> = new Subject<string>();

  static decryptData(data) {
    return data;
  }

  static getCurrentUser() {
    return AuthenticationService.getCurrentUser();
  }

  constructor(
  ) {}

  loadingOn(text = null) {
    if (text) {
      this.loadingAnimationText.next(text);
    }
    this.loadingAnimation.next(true);
  }

  loadingOff() {
    this.loadingAnimation.next(false);
    this.loadingAnimationText.next(null);
  }
}
