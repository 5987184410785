export class FormProjectModel {
  id?: number;
  name: string;
  agencyCode: string;
  grantNumber: string;
  agencyName: string;
  orderNumber: number;

  static createEmptyModel(): FormProjectModel {
    const p = new FormProjectModel();
    p.name = '';
    p.agencyCode = '';
    p.agencyName = '';
    p.grantNumber = '';
    p.orderNumber = 1;
    return p;
  }
}
