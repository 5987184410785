import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormPatentModel } from "../../../../models/registration-form/form-patent.model";
import { FormElements } from "../../../ticket-catalog/misc/form-elements";
import { getCalendarLocale } from "../../../../constants";
import { PatentTypeCodebook } from "../../../../models/codebook/patent-type.codebook";
import { CODEBOOK_ITEMS, CodebooksService } from "../../../../services/codebooks.service";
import { Dropdown } from "primeng";

@Component({
  selector: "smart-patent-section",
  templateUrl: "./patent-section.component.html",
  styleUrls: ["./patent-section.component.scss"]
})
export class PatentSectionComponent implements OnInit {
  isValid = false;

  showPatent = true;

  skLocaleCalendar = getCalendarLocale();

  requiredKeys = [];

  patentTypes: PatentTypeCodebook[] = [];

  @Input() patent: FormPatentModel = new FormPatentModel();

  @Input() isAGJ = false;

  @Input() formElements: FormElements;

  @Output() changePatent: EventEmitter<{ valid: boolean, data: FormPatentModel }> = new EventEmitter<{ valid: boolean, data: FormPatentModel }>();

  @ViewChild('patentType', {static: false}) patentType: Dropdown;

  constructor(
    protected codebooksService: CodebooksService
  ) {
  }

  ngOnInit() {
   this.loadData();
  }

  async loadData() {
    const loaded = await this.loadCodebooks();

    this.loadRequiredKeys();

    if (loaded) {
      if (this.patent && this.patent.id) {
        this.loadPatentData();
      }
    }
  }

  loadPatentData() {
    if (this.patent.patentTypeId) {
      const p = this.patentTypes.find(patent => patent.id === this.patent.patentTypeId);

      if (p) {
        setTimeout(() => {
          this.patentType.selectedOption = { label: p.description, value: p };
        }, 100);
      }
    }
  }

  async loadCodebooks(): Promise<boolean> {
    this.patentTypes = await this.codebooksService.get(CODEBOOK_ITEMS.patentTypes);

    return Promise.resolve(true);
  }

  loadRequiredKeys() {
    const hostItemFormElements = {};

    const keys = [];

    this.showPatent = false;

    Object.assign(hostItemFormElements, this.formElements);

    Object.keys(hostItemFormElements)
      .forEach(key => {
        if (key.indexOf("patent") === -1) {
          delete hostItemFormElements[key];
        } else {
          if (hostItemFormElements[key].visible) {
            this.showPatent = true;
          }
          if (hostItemFormElements[key].required) {
            keys.push(key);
          }
        }
      });

    keys.forEach((key: string) => {
      let truncated = key.replace("patent.", "");
      truncated = truncated.charAt(0).toLowerCase() + truncated.substring(1, key.length - 1);
      if (!(this.isAGJ && truncated === 'publisher')) {
        this.requiredKeys.push(truncated);
      }
    });

    setTimeout(() => {
      this.change();
    }, 1000);
  }

  change() {
    this.isValid = true;

    const err = this.requiredKeys
      .find(key => {
        return !this.patent[key];
      });

    if (err && this.showPatent) {
      this.isValid = false;
    }

    this.changePatent.emit({valid: this.isValid, data: this.patent});
    return;
  }

  changedPatentType(patentType) {
    this.patent.patentTypeId = patentType.value.id;
    this.change();
  }

}
