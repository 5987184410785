import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UserService } from "../../services/user.service";
import { AppCommunicationService } from "../../services/app-communication.service";
import { CodebooksService } from "../../services/codebooks.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormParticipantModel } from "../../models/registration-form/form-participant.model";
import { RegistrationFormService } from "../../services/registration-form.service";
import { AutoComplete, Dropdown } from "primeng";
import { parseTitlesToUser } from "../../misc/academic-titles";
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "app-biblio-person-search-mk2",
  templateUrl: "./biblio-person-search-mk2.component.html",
  styleUrls: ["./biblio-person-search-mk2.component.scss"]
})
export class BiblioPersonSearchComponentMk2 implements OnInit, OnChanges {
  data = [];

  states = {
    opened: true,
    searching: true,
    fromTuke: true,
    notFromTuke: false,
    submitted: false
  };

  isFormValid = false;

  userForm: FormGroup;

  isEditing = false;

  temporaryCrossrefUser = new FormParticipantModel();

  participantSearchTypes = {
    startsWith: "StartsWith",
    contains: "Contains"
  };

  participantSearchTypesData = [];

  currentParticipantSearchType = this.participantSearchTypes.contains;

  @Output() submittedParticipant: EventEmitter<any> = new EventEmitter<any>();

  @Output() onDeleteParticipant: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onEditParticipant: EventEmitter<any> = new EventEmitter<any>();

  @Input() selectedUser: FormParticipantModel;

  @ViewChild("searchInput", { static: false }) searchInput: AutoComplete;

  @ViewChild("currentParticipantSearchTypeEl", { static: false }) currentParticipantSearchTypeEl: Dropdown;

  @Input() maxPercentage = 0;

  @Input() isAlreadySubmitted = false;

  @Input() position: number;

  @Input() disabledMoveUp = false;

  @Input() disabledMoveDown = false;

  @Input() withoutPercentage = false;

  @Output() moveUp: EventEmitter<any> = new EventEmitter<any>();

  @Output() moveDown: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private userService: UserService,
    private appCommunicationService: AppCommunicationService,
    private codebooksService: CodebooksService,
    private formBuilder: FormBuilder,
    private registrationFormService: RegistrationFormService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      name: [""],
      percent: [""]
    });

    this.userForm.valueChanges.subscribe(v => {
      this.formChange();
    });

    this.states.submitted = this.isAlreadySubmitted;

    if (this.states.submitted) {
      this.disableInputs();
    }

    setTimeout(() => {
      // check if form was already submitted
      if (this.isAlreadySubmitted) {
        this.userForm.get("name").patchValue(this.selectedUser.fullName);
        this.userForm.get("percent").patchValue(this.selectedUser.participationPercentage);
        if (this.selectedUser.id === 0 || !this.selectedUser.id) {
          this.states.notFromTuke = true;
        }

        this.isFormValid = true;
      }
    }, 100);

    if (
      this.selectedUser.name &&
      this.selectedUser.surname &&
      !this.selectedUser.id
    ) {
      this.temporaryCrossrefUser = this.selectedUser;
    }

    this.prefillCurrentParticipantSearchType();

  }

  generateParticipantSearchTypes() {
    this.participantSearchTypesData = [
      {
        label: this.i18n.getTranslation("Začína na.."),
        value: this.participantSearchTypes.startsWith
      },
      {
        label: this.i18n.getTranslation("Obsahuje"),
        value: this.participantSearchTypes.contains
      }
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    // was change detect, maybe changed order of participants and is change on first position so change role to author
    if (changes) {
      if (this.states.submitted) {
        this.isFormValid = true;
      }
    }
  }

  prefillCurrentParticipantSearchType() {
    if (this.temporaryCrossrefUser) {
      this.currentParticipantSearchType = this.participantSearchTypes.startsWith;
    } else {
      this.currentParticipantSearchType = this.participantSearchTypes.contains;
    }

    setTimeout(() => {
      if (this.currentParticipantSearchTypeEl) {
        this.currentParticipantSearchTypeEl.selectedOption = this.participantSearchTypesData.find(p => p.value === this.currentParticipantSearchType);
      }
    }, 100);
  }

  changedParticipantSearchType(val) {
    if (!val) {
      return;
    }

    this.currentParticipantSearchType = val.value.value;

    const inputVal = this.searchInput.value;

    if (inputVal && inputVal.length >= 1) {
      this.searchParticipant(inputVal);
    }
  }

  onFilterUsers(event) {
    this.searchParticipant(event.query);
  }

  searchParticipant(text: string, name: string = null) {
    const t = text.replace(" ", "_");

    const searchBody = {
      phrase: t
    };

    if (this.currentParticipantSearchType) {
      searchBody["filterType"] = this.currentParticipantSearchType;
    }

    this.registrationFormService.searchParticipants(searchBody).subscribe(val => {
      if (val === null) {
        val = [];
      }
      val.push({
        id: 0,
        fullName: this.i18n.getTranslation("Nenašli ste čo ste hľadali?"),
        name: "",
        surname: "",
        titleAfter: "",
        workCenterCode: "",
        facultyCode: "",
        userId: 0
      });
      this.data = val;
    });
  }

  searchUserClick(e: Event) {
    e.preventDefault();
    this.searchParticipant(this.selectedUser.surname);
    setTimeout(() => {
      this.searchInput.overlayVisible = true;
    }, 50);
  }

  async onSelectOption() {
    if (this.selectedUser.id === 0 && this.selectedUser.userId === 0) {
      this.userNotFromTuke();
      this.userForm.get("name").enable();
      this.selectedUser.participationPercentage = this.maxPercentage;
      return;
    }

    this.appCommunicationService.loadingOn(this.i18n.getTranslation("Načítavam dáta."));

    await this.registrationFormService.getSearchedParticipant(this.selectedUser).toPromise();

    this.userFromTuke();
    this.selectedUser.participationPercentage = this.maxPercentage;
    this.states.searching = false;
    this.appCommunicationService.loadingOff();
  }

  onSubmitParticipant() {
    if (this.states.submitted) {
      this.editParticipant();
      return;
    }

    if (!this.userForm.valid || !this.hasCorrectValues()) {
      return;
    }

    if (!this.selectedUser.name && !this.selectedUser.surname && this.selectedUser.fullName) {
      this.selectedUser = parseTitlesToUser(this.selectedUser);
    }

    this.isEditing = false;
    this.submittedParticipant.emit(this.selectedUser);
    this.states.submitted = true;
    this.disableInputs();
  }

  disableInputs() {
    this.userForm.get("name").disable();
    this.userForm.get("percent").disable();
  }

  hasCorrectValues(): boolean {
    const name = this.userForm.get("name").value;
    const percent = this.userForm.get("percent").value;

    if ((!name || name.length === 0)) {
      return false;
    }

    if (this.withoutPercentage) {
      return true;
    }

    if (!percent || percent.length === 0) {
      return false;
    }

    return true;
  }

  editParticipant() {
    this.states.searching = false;
    this.states.opened = true;
    this.states.submitted = false;
    this.onEditParticipant.emit(this.selectedUser);
    this.userForm.get("percent").patchValue("");
    this.userForm.get("percent").enable();
    this.isEditing = true;
  }

  onCloseButton() {
    this.states.opened = true;
    this.states.searching = true;
    this.states.fromTuke = true;
    this.onDeleteParticipant.emit(true);
    this.states.submitted = false;
  }

  userFromTuke() {
    this.states.opened = true;
    this.states.fromTuke = true;
    this.states.notFromTuke = false;
    this.states.searching = true;
    this.userForm.get("name").disable();
  }

  userNotFromTuke() {
    this.selectedUser = new FormParticipantModel();
    this.selectedUser.name = this.temporaryCrossrefUser.name;
    this.selectedUser.surname = this.temporaryCrossrefUser.surname;
    this.selectedUser.isExternal = true;
    if (this.temporaryCrossrefUser.name && this.temporaryCrossrefUser.surname) {
      this.selectedUser.fullName = `${this.temporaryCrossrefUser.name} ${this.temporaryCrossrefUser.surname}`;
    }
    this.states.opened = true;
    this.states.notFromTuke = true;
    this.states.searching = false;
    this.states.fromTuke = false;
  }

  checkMaxPercentageValidity(e: KeyboardEvent) {
    const target = e.target as HTMLInputElement;
    if (Number.parseInt(target.value, 0) > this.maxPercentage) {
      target.value = this.maxPercentage.toString();
    }

    if (Number.parseInt(target.value, 0) <= 0) {
      target.value = "1";
    }
  }

  selectPercentage(percentage: number) {
    this.selectedUser.participationPercentage = percentage;
    this.userForm.get("percent").patchValue(percentage);
    this.formChange();
  }

  formChange() {
    if (
      this.selectedUser.fullName
    ) {
      if (this.withoutPercentage) {
        this.isFormValid = true;
      } else if (this.selectedUser.participationPercentage > 0 &&
        this.selectedUser.participationPercentage <= this.maxPercentage) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    } else {
      this.isFormValid = false;
    }
  }

  moveUpClick(e: Event) {
    e.preventDefault();
    if (this.disabledMoveUp) {
      return;
    }
    this.moveUp.emit(this.selectedUser);
  }

  moveDownClick(e: Event) {
    e.preventDefault();
    if (this.disabledMoveDown) {
      return;
    }
    this.moveDown.emit(this.selectedUser);
  }
}
