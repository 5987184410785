import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { TicketSimpleModel } from "../../../models/ticket/ticket-simple.model";
import { getExtendedTicket } from "../ticket-mapper";
import { AppCommunicationService } from "../../../services/app-communication.service";
import { TicketStatusUpdateRequestModel } from "../../../models/requests/ticket-status-update-request.model";
import { TicketService } from "../../../services/ticket.service";
import { ticketSectionsEnum } from "../misc/ticket-sections";
import { TicketModalSubformComponent } from "../components/ticket-modal-subform/ticket-modal-subform.component";
import { FormAttachmentTypes, handOverTypes, TicketStatuses } from "../../../constants";
import { ToastrService } from "ngx-toastr";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";
import { TicketAttachmentPhysicalDocument } from "../misc/ticket-attachment-physical-document";

export enum TicketMultiItemsActions {
  submit = 'submit',
  print = 'print',
  moveUp = 'moveUp',
  moveDown = 'moveDown',
  delete = 'delete'
}

@Component({
  selector: 'smart-ticket-catalog-multi-items-action-modal',
  templateUrl: './ticket-multi-items-action-modal.component.html',
  styleUrls: ['./ticket-multi-items-action-modal.component.scss']
})
export class TicketMultiItemsActionModalComponent implements OnInit {
  currentTicketSection: ticketSectionsEnum;

  ticketSections = ticketSectionsEnum;

  tickets: TicketSimpleModel[] = [];

  conversionTickets: TicketSimpleModel[] = [];
  scanTickets: TicketSimpleModel[] = [];

  actionType: TicketMultiItemsActions;

  ticketMultiItemsActions = TicketMultiItemsActions;

  successCount = 0;

  errorCount = 0;

  isFinished = false;

  isProcessing = false;

  @ViewChild("staticModal", { static: false }) modal: ModalDirective;

  @Output() onFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(TicketModalSubformComponent, {static: false}) ticketSubform: TicketModalSubformComponent;

  constructor(
    protected appCommunicationService: AppCommunicationService,
    protected ticketService: TicketService,
    protected toastr: ToastrService,
    private i18n: I18nService
  ) { }

  ngOnInit() {
  }

  clear() {
    this.successCount = 0;
    this.errorCount = 0;
    this.isFinished = false;
    this.isProcessing = false;
  }

  show(tickets: TicketSimpleModel[], action: TicketMultiItemsActions, section: ticketSectionsEnum) {
    this.tickets = tickets;

    this.scanTickets = this.tickets.filter(t => TicketAttachmentPhysicalDocument(t.attachmentType, FormAttachmentTypes));
    this.conversionTickets = this.tickets.filter(t => !TicketAttachmentPhysicalDocument(t.attachmentType, FormAttachmentTypes));

    this.actionType = action;
    this.currentTicketSection = section;
    this.modal.show();
  }

  submit() {
    switch (this.actionType) {
      case TicketMultiItemsActions.moveDown: this.moveTicketsUpOrDown(); break;
      case TicketMultiItemsActions.moveUp: this.moveTicketsUpOrDown(); break;
      case TicketMultiItemsActions.submit: this.submitTicketAction(); break;
      case TicketMultiItemsActions.delete: this.deleteTicketAction(); break;
    }
  }

  close() {
    this.modal.hide();
    if (this.isFinished) {
      this.onFinished.emit(true);
    }
    this.clear();
  }

  deleteTicketAction() {
    if (this.actionType !== TicketMultiItemsActions.delete) {
      return;
    }

    if (this.tickets.length > 0) {

      const subform = this.ticketSubform.getSubformValue();

      const comment = subform['comment'];

      if (!comment || comment.length === 0) {
        this.toastr.error(this.i18n.getTranslation('Komentár je pri odstráňovaní povinný.'));
        return;
      }

      this.isProcessing = true;

      this.tickets.forEach(t => {

       this.ticketService
         .deleteTicket(t.id)
         .subscribe(v => {
           this.isProcessing = false;
           this.isFinished = true;
           this.close();
         });
      });
    }
  }

  submitTicketAction() {
    if (this.actionType !== TicketMultiItemsActions.submit) {
      return;
    }

    if (this.tickets.length > 0) {

      this.isProcessing = true;

      this.tickets.forEach(t => {

        const ticketUpdate = new TicketStatusUpdateRequestModel();
        ticketUpdate.ticketId = t.id;
        ticketUpdate.comment = t.comment;

        // if is in ACCEPTANCE > goes to scan or conversion
        if (this.currentTicketSection === ticketSectionsEnum.acceptance) {

          const subform = this.ticketSubform.getSubformValue();

          if (this.scanTickets.length === 0 || this.conversionTickets.length === 0) {

            ticketUpdate.handOverType = subform['handOverType'];
            ticketUpdate.attachmentType = subform['attachmentType'];
            if (subform['processingType'] === "scan") {
              ticketUpdate.status = TicketStatuses.to_scan;
            } else {
              ticketUpdate.status = TicketStatuses.to_conversion;
            }

          } else {

            const isInScanTickets = this.scanTickets.find(ticket => ticket.id === t.id);

            if (isInScanTickets) {
              ticketUpdate.handOverType = subform['handOverType'];
              ticketUpdate.attachmentType = subform['attachmentType'];
              ticketUpdate.status = TicketStatuses.to_scan;
            } else {
              ticketUpdate.handOverType = handOverTypes.sending_by_post;
              ticketUpdate.attachmentType = t.attachmentType;
              ticketUpdate.status = TicketStatuses.to_conversion;
            }

          }

          if (t.type === 'citation') {
            ticketUpdate.status = TicketStatuses.to_catalogize;
          }
        } else {

          const ticketExtended = getExtendedTicket(t);

          if (ticketExtended.nextStatus) {
            ticketUpdate.status = ticketExtended.nextStatus;
          }
        }

        this.changeTicketStatus(t, ticketUpdate);

      });
    }
  }

  moveTicketsUpOrDown() {
    if (this.actionType !== TicketMultiItemsActions.moveUp && this.actionType !== TicketMultiItemsActions.moveDown) {
      return;
    }

    const extendedTicket = getExtendedTicket(this.tickets[0]);

    const nextStatus = (this.actionType === TicketMultiItemsActions.moveUp ? extendedTicket.nextStatus : extendedTicket.backStatus[0]);

    if (this.tickets.length > 0 && nextStatus) {

      this.isProcessing = true;

      this.tickets.forEach(t => {

        const ticket = new TicketStatusUpdateRequestModel();
        ticket.status = nextStatus;
        ticket.ticketId = t.id;
        ticket.comment = t.comment;

        this.changeTicketStatus(t, ticket);

      });

    }
  }

  changeTicketStatus(ticket: TicketSimpleModel, ticketUpdateRes: TicketStatusUpdateRequestModel) {
    this.ticketService.setStatus(ticketUpdateRes)
      .subscribe(
      v => {
        ticket['result'] = true;
        this.successCount++;
        this.checkFinishedAction();
      },
      error => {
        ticket['result'] = false;
        this.errorCount++;
        console.log(error);
        this.checkFinishedAction();
      }
    );
  }

  checkFinishedAction() {
    if (this.successCount + this.errorCount === this.tickets.length) {
      this.isFinished = true;
      this.isProcessing = false;
      this.close();
    } else {
      this.isFinished = false;
    }
  }

}
