import { FormBiblioModel } from "../registration-form/form-biblio.model";
import { FormCitationModel } from "../registration-form/form-citation.model";
import { TicketRequestType } from "../../types/ticket-request.type";

export class TicketCreateRequestModel {
  type: TicketRequestType;
  formBiblio: FormBiblioModel;
  formCitation: FormCitationModel;
  sendToAcceptance: boolean;
  priorityType?: number;
  priorityComment?: string;
  attachmentType?: number;
  fileMetadataId?: number;
  comment: string;
  id?: number;
}
