export class CitationCategoryModel {
  categoryGroupCode: string;
  code: string;
  description: string;
  id?: number;
  isCrepcValid: boolean;
  isValid: boolean;
  priority: number;
  showInFilter: boolean;
  showInRegistrationForm: boolean;

  static createEmpty(): CitationCategoryModel {
    const c = new CitationCategoryModel();
    c.code = '';
    c.description = '';
    return c;
  }
}
