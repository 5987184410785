import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

const LOCAL_STORAGE_USER_FILTER = 'USER_FILTER';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  filter: Subject<any> = new Subject<any>();
  filterToShow: BehaviorSubject<FilterToShow> = new BehaviorSubject<FilterToShow>(new FilterToShow(false, false, false, false, false));
  currentFilter: any;

  constructor() { }

  saveToLocalStorage(filter, filterToShow) {
    localStorage.removeItem(LOCAL_STORAGE_USER_FILTER);
    localStorage.setItem(LOCAL_STORAGE_USER_FILTER, JSON.stringify({filter, filterToShow}));
  }

  loadFromLocalStorage() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_FILTER));
  }

  public getCurrentFilter(): any {
    return this.currentFilter;
  }

  public setFilter(filter: any) {
    this.currentFilter = filter;
    this.filter.next(filter);

  }
}

export class FilterToShow {
  filterToShow = {
    search: false,
    year: false,
    biblioCategory: false,
    faculty: false,
    department: false
  };

  constructor(
    search = false,
    year = false,
    biblioCategory = false,
    faculty = false,
    department = false
  ) {
    this.filterToShow.search = search;
    this.filterToShow.year = year;
    this.filterToShow.biblioCategory = biblioCategory;
    this.filterToShow.faculty = faculty;
    this.filterToShow.department = department;
  }
}
