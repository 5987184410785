import { CrFunderModel } from "./cr-funder.model";
import { CrClinicalTrialNumberModel } from "./cr-clinical-trial-number.model";
import { CrContributorModel } from "./cr-contributor.model";
import { CrDateModel } from "./cr-date.model";
import { CrPartialDateModel } from "./cr-partial-date.model";
import { CrAssertionModel } from "./cr-assertion.model";
import { CrLicenseModel } from "./cr-license.model";
import { CrResourceLinkModel } from "./cr-resource-link.model";
import { CrReferenceModel } from "./cr-reference.model";
import { CrIssnWithTypeModel } from "./cr-issn-with-type.model";
import { CrContentDomainModel } from "./cr-content-domain.model";
import { CrRelationModel } from "./cr-relation.model";
import { CrReviewModel } from "./cr-review.model";
import { CrUpdateModel } from "./cr-update.model";

export class CrossrefMetadataModel {
  'publisher': string;
  'title': string[];
  'original-title'?: string[];
  'short-title'?: string[];
  'abstract'?: string;
  'reference-count': number;
  'references-count': number;
  'is-referenced-by-count': number;
  'source': string;
  'prefix': string;
  'DOI': string;
  'URL': string;
  'member': string;
  'type': string;
  'created': CrDateModel;
  'deposited': CrDateModel;
  'indexed': CrDateModel;
  'issued': CrPartialDateModel;
  'posted'?: CrPartialDateModel;
  'accepted'?: CrPartialDateModel;
  'subtitle'?: string[];
  'container-title'?: string[];
  'short-container-title'?: string[];
  'group-title'?: string;
  'issue'?: string;
  'volume'?: string;
  'page'?: string;
  'article-number'?: string;
  'published-print'?: CrPartialDateModel;
  'published-online'?: CrPartialDateModel;
  'subject'?: string[];
  'ISSN'?: string[];
  'issn-type'?: CrIssnWithTypeModel[];
  'ISBN'?: string[];
  'license'?: CrLicenseModel[];
  'funder'?: CrFunderModel[];
  'assertion'?: CrAssertionModel[];
  'author'?: CrContributorModel[];
  'editor'?: CrContributorModel[];
  'chair'?: CrContributorModel[];
  'translator'?: CrContributorModel[];
  'update-to'?: CrUpdateModel[];
  'update-policy'?: string;
  'link'?: CrResourceLinkModel[];
  'clinical-trial-number'?: CrClinicalTrialNumberModel[];
  'alternative-id'?: string;
  'reference'?: CrReferenceModel[];
  'content-domain'?: CrContentDomainModel;
  'relation'?: CrRelationModel;
  'review'?: CrReviewModel;
  'publisher-location'?: string;
  'language'?: string;
}
