import { NgModule } from "@angular/core";
import { TicketRegistrationComponent } from "./ticket-registration.component";
import { UserPublicationDataDialogModule } from "../../components/user-publication-data-dialog/user-publication-data-dialog.module";
import { CommonModule } from "@angular/common";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  AccordionModule,
  AutoCompleteModule,
  ButtonModule, CalendarModule, CheckboxModule,
  ChipsModule,
  DropdownModule,
  InputTextModule,
  SelectButtonModule
} from "primeng";
import { CustomPipesModule } from "../../pipes/customPipes.module";
import { AttachmentsModule } from "./components/attachments/attachments.module";
import { FilesModule } from "./components/files/files.module";
import { ProjectSectionComponent } from "./components/project-section/project-section.component";
import { PatentSectionComponent } from "./components/patent-section/patent-section.component";
import { MeetingComponent } from "./components/meeting/meeting.component";
import { HostItemComponent } from "./components/host-item/host-item.component";
import { ModalModule, TooltipModule } from "ngx-bootstrap";
import { MultiValChooserComponent } from "./components/multi-val-chooser/multi-val-chooser.component";
import { BiblioPersonSearchMk2Module } from "../../components/biblio-person-search-mk2/biblio-person-search-mk2.module";
import { BiblioIdentifiersComponent } from "./components/biblio-identifiers/biblio-identifiers.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { TicketErrorCheckModalComponent } from "./ticket-error-check-modal/ticket-error-check-modal.component";
import { OpenAccessSectionComponent } from './components/open-access-section/open-access-section.component';
import { OpenAccessPayerComponent } from './components/open-access-section/open-access-payer/open-access-payer.component';
import { OpenAccessSourceDocumentComponent } from './components/open-access-source-document/open-access-source-document.component';
import { CustomDatepickerModule } from "../../components/custom-datepicker/custom-datepicker.module";
import { ProjectAdministratorsListDialogComponent } from './components/project-administrators-list-dialog/project-administrators-list-dialog.component';

@NgModule({
  imports: [
    UserPublicationDataDialogModule,
    CommonModule,
    I18nModule,
    FormsModule,
    DropdownModule,
    CustomPipesModule,
    AccordionModule,
    AttachmentsModule,
    FilesModule,
    InputTextModule,
    ReactiveFormsModule,
    ChipsModule,
    TooltipModule,
    AutoCompleteModule,
    ButtonModule,
    SelectButtonModule,
    CheckboxModule,
    DragDropModule,
    BiblioPersonSearchMk2Module,
    CalendarModule,
    ModalModule,
    CustomDatepickerModule
  ],
  exports: [
    TicketRegistrationComponent
  ],
  declarations: [
    TicketRegistrationComponent,
    ProjectSectionComponent,
    PatentSectionComponent,
    MeetingComponent,
    HostItemComponent,
    MultiValChooserComponent,
    BiblioIdentifiersComponent,
    TicketErrorCheckModalComponent,
    OpenAccessSectionComponent,
    OpenAccessPayerComponent,
    OpenAccessSourceDocumentComponent,
    ProjectAdministratorsListDialogComponent
  ]
})

export class TicketRegistrationModule {}
