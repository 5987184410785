import { Component, OnInit, ViewChild } from "@angular/core";
import { OldEpcService } from "../../services/old-epc.service";
import { OldEpcBiblioRequestModel } from "../../models/old-epc/old-epc-biblio-request.model";
import { SearchQueryRequestModel } from "../../models/search-query-request.model";
import {
  OLD_EPC_BIBLIO_EXPORT,
  OLD_EPC_BIBLIO_EXPORT_ENUM,
  OLD_EPC_CATEGORIES,
  OLD_EPC_SEARCH_OPTIONS,
  OLD_EPC_SEARCH_OPTIONS_ENUM,
  OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES,
  WHOLE_FACULTY_OLD_EPC, getCalendarLocale
} from "../../constants";
import { Dropdown } from "primeng";
import { ToastrService } from "ngx-toastr";
import { CODEBOOK_ITEMS, CodebooksService } from "../../services/codebooks.service";
import { OldEpcBiblioExportRequestModel } from "../../models/old-epc/old-epc-biblio-export-request.model";
import { GrantCategoryTypeCodebookModel } from "../../models/codebook/grant-category-type-codebook.model";
import { BiblioCategoryCodebookModel } from "../../models/codebook/biblio-category-codebook.model";
import {
  getTableColumnsByPriorityAndResolution,
  TableColumn,
  TableColumnsPriority,
  TicketTableColumnNames
} from "../../misc/table-responsive";
import { ActivatedRoute } from "@angular/router";
import { AppCommunicationService } from "../../services/app-communication.service";
import { AuthenticationService } from "../../../core-frontend-common/auth/authentication.service";
import { PRODUCTION_BUILD } from "../../app.settings";
import { I18nService } from "../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-exports-view",
  templateUrl: "./exports-view.component.html",
  styleUrls: ["./exports-view.component.scss"]
})
export class ExportsViewComponent implements OnInit {
  MAX_YEAR_RANGE_WORKPLACE = 2;

  skLocaleCalendar = getCalendarLocale();

  searchQuery: OldEpcBiblioRequestModel = new OldEpcBiblioRequestModel();

  exportBiblioQuery: OldEpcBiblioExportRequestModel = new OldEpcBiblioExportRequestModel();

  searchOptionsData = OLD_EPC_SEARCH_OPTIONS;

  categoriesOptionsData = [];

  grantCategoriesData = [];

  biblioCategoriesData = [];

  exportsData = [];

  searchedDataSuggestions = [];

  inputSearchPlaceholder = "";

  tableData = [];

  faculties = [];

  departments = [];

  selectedDepartmentId = "";

  selectedFacultyCode = "";

  tableLoading = false;

  oldEpcCategoriesEnum = OLD_EPC_CATEGORIES;

  oldEpcExportsEnum = OLD_EPC_BIBLIO_EXPORT_ENUM;

  showCitations = false;

  showPercentAuthors = false;

  isAvailableForExport = false;

  availableSections = {
    search: "search",
    export: "export"
  };

  pTable = {
    colNumber: 0,
    expandedRows: {},
    loading: false,
    totalItems: 0,
    page: 1,
    pageSize: 100
  };

  pTableColNames = TicketTableColumnNames;

  pTableAllCols: TableColumn[] = [
    {
      name: TicketTableColumnNames.ac,
      priority: TableColumnsPriority.priority_5
    },
    {
      name: TicketTableColumnNames.category,
      priority: TableColumnsPriority.priority_4
    },
    {
      name: TicketTableColumnNames.year,
      priority: TableColumnsPriority.priority_6
    },
    {
      name: TicketTableColumnNames.info,
      priority: TableColumnsPriority.priority_1
    },
    {
      name: TicketTableColumnNames.citedCount,
      priority: TableColumnsPriority.priority_1
    }
  ];

  notVisibleCols: TableColumn[] = [];

  pTableColsToShow: TableColumn[] = [];

  currentVisibleSection = this.availableSections.search;

  wasSearchedWithCitations = false;
  wasSearchedWithPercent = false;

  showOnlyPersonExports = false;

  isLoggedUser = false;

  isValidForm = false;

  dateRangeInvalid = false;

  @ViewChild("searchOptions", { static: false }) searchOptionsEl: Dropdown;
  @ViewChild("categoryOptionEl", { static: false }) categoryOptionEl: Dropdown;
  @ViewChild("biblioEl", { static: false }) biblioEl: Dropdown;
  @ViewChild("grantEl", { static: false }) grantEl: Dropdown;

  constructor(
    protected oldEpc: OldEpcService,
    protected notification: ToastrService,
    protected codebook: CodebooksService,
    protected activatedRoute: ActivatedRoute,
    protected appCommunicationService: AppCommunicationService,
    protected authService: AuthenticationService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.checkOnlyPersonExportsView();

    this.generateCategoriesOptionsData();

    this.loadGrantCategories();

    this.loadBiblioCategories();

    this.loadFaculties();

    this.prefillForm();

    this.getInputSearchPlaceholder();

    this.onResize();

    this.checkLoggedUser();
  }

  checkLoggedUser() {
    this.isLoggedUser = this.authService.checkUser();

    if (!this.isLoggedUser) {
      this.searchOptionsData.splice(this.searchOptionsData.findIndex(d => d.value === OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Pracovisko), 1);
    }
  }

  checkOnlyPersonExportsView() {
    this.activatedRoute
      .queryParams
      .subscribe(v => {

        this.showOnlyPersonExports = false;

        if (v) {

          let text = "";

          if (v.surname) {
            text += v.surname;
          }

          if (v.name) {
            text += `, ${v.name}`;
          }

          if (v.percent && v.percent === "true") {
            this.searchQuery.showParticipationPercentage = true;
            this.showPercentAuthors = true;
          } else {
            this.searchQuery.showParticipationPercentage = false;
            this.showPercentAuthors = false;
          }

          if (v.citations && v.citations === "true") {
            this.showCitations = true;
            this.searchQuery.showCitations = true;
          } else {
            this.showCitations = false;
            this.searchQuery.showCitations = false;
          }

          if (text && text.length > 0) {
            this.showOnlyPersonExports = true;

            setTimeout(() => {
              this.searchQuery.searchPhrase = text;

              this.loadTableData();
            }, 20);
          } else {
            this.reset();

            this.showOnlyPersonExports = false;

            this.showSearching();
          }

        }
      });

    this.checkValidity();
  }

  onResize() {
    this.pTableColsToShow = getTableColumnsByPriorityAndResolution(this.pTableAllCols);
    this.notVisibleCols = this.pTableAllCols.filter(c => !this.pTableColsToShow.includes(c));
    this.pTable.colNumber = this.pTableColsToShow.length;
  }

  async loadFaculties() {
    const faculties = await this.codebook.get(CODEBOOK_ITEMS.faculties);
    if (faculties) {
      this.faculties = faculties.items;
      this.loadDepartments(null, this.faculties[0]);
    }
  }

  async loadBiblioCategories() {
    const categories: BiblioCategoryCodebookModel[] = await this.codebook.get(CODEBOOK_ITEMS.biblioCategories);

    if (categories) {
      this.biblioCategoriesData = [];

      categories.forEach(category => {
        this.biblioCategoriesData.push({
          title: category.description,
          value: category.code
        });
      });
    }
  }

  async loadGrantCategories() {
    const categories: GrantCategoryTypeCodebookModel[] = await this.codebook.get(CODEBOOK_ITEMS.grantCategoryTypes);
    if (categories) {
      this.grantCategoriesData = [];

      categories.forEach(category => {
        this.grantCategoriesData.push({
          title: category.description,
          value: category.value
        });
      });
    }
  }

  async loadDepartments(e, valueDirectly) {
    const departments = await this.codebook.getDepartments(valueDirectly ? valueDirectly.facultyId : e.value.facultyId).toPromise();
    this.selectedFacultyCode = valueDirectly ? valueDirectly.facultyCode : e.value.facultyCode;
    if (departments) {
      const all = {
        departmentId: 0,
        departmentName: WHOLE_FACULTY_OLD_EPC
      };
      // @ts-ignore
      this.departments = [all].concat(departments);
      this.selectedDepartmentId = this.departments[0].departmentId;
    }
  }

  prefillForm() {
    this.searchQuery.searchOption = OLD_EPC_SEARCH_OPTIONS_ENUM.Autor;
    this.searchQuery.categoryOption = this.categoriesOptionsData[0].value;

    this.exportBiblioQuery.exportType = this.exportsData[0].value;
  }

  generateCategoriesOptionsData() {
    this.categoriesOptionsData.push({
        title: this.i18n.getTranslation("Všetky kat. EPC"),
        value: OLD_EPC_CATEGORIES.ALL
      }, {
        title: this.i18n.getTranslation("Podľa kat. EPC"),
        value: OLD_EPC_CATEGORIES.BIBLIO
      },
      {
        title: this.i18n.getTranslation("Dotačné kat. MŠSR"),
        value: OLD_EPC_CATEGORIES.GRANT
      });

    this.exportsData = OLD_EPC_BIBLIO_EXPORT;
  }

  filterChanged() {
    this.getInputSearchPlaceholder();
  }

  searchSuggestions() {
    if (this.searchQuery.searchPhrase && this.searchQuery.searchPhrase.length > 2) {
      switch (this.searchQuery.searchOption) {
        case OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Autor:
          this.findAuthors();
          break;
        case OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Zdroj:
          this.findHostItems();
          break;
        case OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Nazov:
          this.findTitles();
          break;
      }
    }
  }

  onSelectSuggestedItem(val) {
    switch (this.searchQuery.searchOption) {
      case OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Autor:
        this.searchQuery.searchPhrase = val;
        break;
      case OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Nazov:
        this.searchQuery.searchPhrase = val;
        break;
      case OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Zdroj:
        this.searchQuery.searchPhrase = val;
        break;
    }
  }

  onSelectDepartment(e) {
    const department = e.value;
    this.selectedDepartmentId = department.departmentId;
  }

  reset() {
    const searchOption = this.searchQuery.searchOption;
    this.searchQuery = new OldEpcBiblioRequestModel();
    this.tableData = [];
    this.searchQuery.searchOption = searchOption;
    this.selectedFacultyCode = "";
    this.selectedDepartmentId = "";
    this.showPercentAuthors = false;
    this.searchQuery.reportingYearFrom = null;
    this.searchQuery.reportingYearTo = null;
    this.searchQuery.publishingYearFrom = null;
    this.searchQuery.publishingYearTo = null;
    this.showCitations = false;
    setTimeout(() => {
      this.categoryOptionEl.selectedOption = { label: this.categoriesOptionsData[0].title, value: this.categoriesOptionsData[0].value };
    }, 50);
    this.isAvailableForExport = false;
    this.checkValidity();
  }

  showSearching() {
    this.currentVisibleSection = this.availableSections.search;

    setTimeout(() => {
      this.fixDropdownValues();
    }, 50);
  }

  exportBiblio() {
    this.appCommunicationService.loadingOn(this.i18n.getTranslation('Exportujem.'));

    Object.assign(this.exportBiblioQuery, this.searchQuery);

    if (this.searchQuery.reportingYearFrom) {
      this.exportBiblioQuery.reportingYearFrom = new Date(this.searchQuery.reportingYearFrom).getFullYear();
    } else {
      delete this.exportBiblioQuery.reportingYearFrom;
    }

    if (this.searchQuery.reportingYearTo) {
      this.exportBiblioQuery.reportingYearTo = new Date(this.searchQuery.reportingYearTo).getFullYear();
    } else {
      delete this.exportBiblioQuery.reportingYearTo;
    }

    if (this.searchQuery.publishingYearFrom) {
      this.exportBiblioQuery.publishingYearFrom = new Date(this.searchQuery.publishingYearFrom).getFullYear();
    } else {
      delete this.exportBiblioQuery.publishingYearFrom;
    }

    if (this.searchQuery.publishingYearTo) {
      this.exportBiblioQuery.publishingYearTo = new Date(this.searchQuery.publishingYearTo).getFullYear();
    } else {
      delete this.exportBiblioQuery.publishingYearTo;
    }

    if (this.searchQuery.searchOption === OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Pracovisko) {
      if (this.selectedFacultyCode && this.selectedFacultyCode["facultyCode"]) {
        this.exportBiblioQuery.facultyCode = this.selectedFacultyCode["facultyCode"];
      } else {
        this.exportBiblioQuery.facultyCode = this.selectedFacultyCode;
      }
      if (!this.selectedDepartmentId) {
        this.exportBiblioQuery.departmentId = WHOLE_FACULTY_OLD_EPC;
      } else {
        this.exportBiblioQuery.departmentId = this.selectedDepartmentId;
      }
    }

    this.exportBiblioQuery.showCitations = this.showCitations;

    this.exportBiblioQuery.showParticipationPercentage = this.showPercentAuthors;

    this.oldEpc
      .biblioExportOldEpc(this.exportBiblioQuery, "export.rtf")
      .subscribe(() => {
        this.appCommunicationService.loadingOff();
      }, () => {
        this.appCommunicationService.loadingOff();
      });
  }

  changedShowCitations() {
    this.checkValidity();
  }

  checkValidity() {
    setTimeout(() => {

      this.dateRangeInvalid = false;

      if (this.searchQuery.searchOption === "Pracovisko") {

        if (this.showCitations) {

          this.dateRangeInvalid = true;

          console.log(this.dateRangeInvalid);

          if (this.searchQuery.reportingYearFrom && this.searchQuery.reportingYearTo) {

            const yearFrom = new Date(this.searchQuery.reportingYearFrom).getFullYear();
            const yearTo = new Date(this.searchQuery.reportingYearTo).getFullYear();
            console.log(yearTo - yearFrom);
            if (yearTo - yearFrom <= this.MAX_YEAR_RANGE_WORKPLACE) {
              this.dateRangeInvalid = false;
            }

          } else if (this.searchQuery.publishingYearFrom && this.searchQuery.publishingYearTo) {

            const yearFrom = new Date(this.searchQuery.publishingYearFrom).getFullYear();
            const yearTo = new Date(this.searchQuery.publishingYearTo).getFullYear();
            console.log(yearTo - yearFrom);
            if (yearTo - yearFrom <= this.MAX_YEAR_RANGE_WORKPLACE) {
              this.dateRangeInvalid = false;
            }

          }

        }

      }

      this.isValidForm = !this.dateRangeInvalid;

    }, 100);
    return this.isValidForm;

  }

  loadTableData() {
    this.appCommunicationService.loadingOn(this.i18n.getTranslation('Načítavam dáta.'));

    const query = new OldEpcBiblioRequestModel();

    Object.assign(query, this.searchQuery);

    if (this.searchQuery.reportingYearFrom) {
      query.reportingYearFrom = new Date(this.searchQuery.reportingYearFrom).getFullYear();
    }

    if (this.searchQuery.reportingYearTo) {
      query.reportingYearTo = new Date(this.searchQuery.reportingYearTo).getFullYear();
    }

    if (this.searchQuery.publishingYearFrom) {
      query.publishingYearFrom = new Date(this.searchQuery.publishingYearFrom).getFullYear();
    }

    if (this.searchQuery.publishingYearTo) {
      query.publishingYearTo = new Date(this.searchQuery.publishingYearTo).getFullYear();
    }

    query.showCitations = this.showCitations;

    query.showParticipationPercentage = this.showPercentAuthors;

    this.isAvailableForExport = false;

    this.tableLoading = true;

    if (query.searchOption === OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Pracovisko) {
      if (this.selectedFacultyCode && this.selectedFacultyCode["facultyCode"]) {
        query.facultyCode = this.selectedFacultyCode["facultyCode"];
      } else {
        query.facultyCode = this.selectedFacultyCode;
      }
      if (!this.selectedDepartmentId) {
        query.departmentId = WHOLE_FACULTY_OLD_EPC;
      } else {
        query.departmentId = this.selectedDepartmentId;
      }
    }

    Object.keys(query)
      .forEach(key => {
        if (query[key] === null) {
          delete query[key];
        }
      });

    this.oldEpc
      .getAllBiblioOldEpc(query)
      .subscribe(val => {
        this.tableData = val;
        this.isAvailableForExport = true;
        this.currentVisibleSection = this.availableSections.export;
        if (!this.showOnlyPersonExports) {
          this.notification.success(this.i18n.getTranslation('Dáta boli úspešne načítané.'));
        }
        this.tableLoading = false;
        this.appCommunicationService.loadingOff();
        this.wasSearchedWithCitations = this.showCitations;
        this.wasSearchedWithPercent = this.showPercentAuthors;
      }, e => {
        console.log(e);
        if (e && e.error) {
          const errors = e.error["Errors"] as number[];
          if (errors && errors.length > 0) {
            const isTooManyReqsts = (errors.indexOf(429) !== -1);

            if (isTooManyReqsts) {
              this.notification.error(this.i18n.getTranslation('Príliš veľa volaní. Skúste to neskôr prosím.'));
              this.tableLoading = false;
              this.appCommunicationService.loadingOff();
              return;
            }
          }
        }
        this.tableLoading = false;
        this.notification.error(this.i18n.getTranslation("Nastala chyba."));
        this.appCommunicationService.loadingOff();
      });
  }

  findTitles() {
    const searchReq: SearchQueryRequestModel = new SearchQueryRequestModel();

    searchReq.phrase = this.searchQuery.searchPhrase;

    this.oldEpc
      .findBiblioTitleOldEpc(searchReq)
      .subscribe(val => {
        this.searchedDataSuggestions = val.slice(0, 100);
      });
  }

  findHostItems() {
    const searchReq: SearchQueryRequestModel = new SearchQueryRequestModel();

    searchReq.phrase = this.searchQuery.searchPhrase;

    this.oldEpc
      .findBiblioHostItemOldEpc(searchReq)
      .subscribe(val => {
        this.searchedDataSuggestions = val.slice(0, 100);
      });
  }

  findAuthors() {
    const searchReq: SearchQueryRequestModel = new SearchQueryRequestModel();

    searchReq.phrase = this.searchQuery.searchPhrase;

    this.oldEpc
      .findAuthorOldEpc(searchReq)
      .subscribe(val => {
        this.searchedDataSuggestions = val.slice(0, 100);
      });
  }

  searchOptionChange(e) {
    this.searchQuery.searchOption = e.value.value;

    this.tableData = [];

    this.searchQuery.searchPhrase = "";

    this.getInputSearchPlaceholder();

    this.checkValidity();
  }

  categoryOptionChanged(e) {
    this.searchQuery.categoryOption = e.value.value;
    if (this.searchQuery.categoryOption === this.oldEpcCategoriesEnum.GRANT) {
      this.searchQuery.grantCategory = this.grantCategoriesData[0].value;
    } else if (this.searchQuery.categoryOption === this.oldEpcCategoriesEnum.BIBLIO) {
      this.searchQuery.biblioCategoryCode = this.biblioCategoriesData[0].value;
    } else {
      this.searchQuery.grantCategory = null;
      this.searchQuery.biblioCategoryCode = null;
    }
  }

  exportOptionChanged(e) {
    Object.assign(this.exportBiblioQuery, this.searchQuery);
    this.exportBiblioQuery.exportType = e.value.value;
  }

  grantCategoryChanged(e) {
    this.searchQuery.grantCategory = e.value.value;
  }

  biblioCategoryChanged(e) {
    this.searchQuery.biblioCategoryCode = e.value.value;
  }

  getInputSearchPlaceholder() {
    switch (this.searchQuery.searchOption) {
      case OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Nazov:
        this.inputSearchPlaceholder = this.i18n.getTranslation("Zadajte časť názvu dokumentu");
        break;
      case OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Autor:
        this.inputSearchPlaceholder = this.i18n.getTranslation("Zadajte časť priezviska a následne vyberte autora zo zoznamu");
        break;
      case OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Pracovisko:
        this.inputSearchPlaceholder = null;
        break;
      case OLD_EPC_SEARCH_OPTIONS_ENUM_VALUES.Zdroj:
        this.inputSearchPlaceholder = this.i18n.getTranslation("Zadajte časť názvu zdrojového dokumentu");
        break;
    }
  }

  fixDropdownValues() {
    const searchOption = this.searchOptionsData.find(c => c.value === this.searchQuery.searchOption);
    if (searchOption) {
      setTimeout(() => {
        this.searchOptionsEl.selectedOption = { label: searchOption.title, value: searchOption.value };
      }, 50);
    }

    const category = this.categoriesOptionsData.find(c => c.value === this.searchQuery.categoryOption);
    if (category) {
      setTimeout(() => {
        this.categoryOptionEl.selectedOption = { label: category.title, value: category.value };
      }, 50);
    }

    if (this.searchQuery.categoryOption === this.oldEpcCategoriesEnum.BIBLIO) {
      const biblio = this.biblioCategoriesData.find(b => b.value === this.searchQuery.biblioCategoryCode);

      if (biblio) {
        this.biblioEl.selectedOption = { label: biblio.title, value: biblio.value };
      }
    }

    if (this.searchQuery.categoryOption === this.oldEpcCategoriesEnum.GRANT) {
      const grant = this.grantCategoriesData.find(b => b.value === this.searchQuery.grantCategory);

      if (grant) {
        this.grantEl.selectedOption = { label: grant.title, value: grant.value };
      }
    }

  }

  clickedLogIn(e: Event) {
    e.preventDefault();
    if (PRODUCTION_BUILD) {
      window.open("https://portal.tuke.sk/#/login");
    } else {
      window.open("https://portal.dev.msweb.tuke.sk/#/login");
    }
  }

}
