import { NgModule } from "@angular/core";
import { FilesComponent } from "./files.component";
import { CommonModule } from "@angular/common";
import { I18nModule } from "../../../../../core-frontend-common/template/shared/i18n/i18n.module";
import { TooltipModule } from "ngx-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    TooltipModule
  ],
  exports: [
    FilesComponent
  ],
  declarations: [
    FilesComponent
  ]
})

export class FilesModule {}
