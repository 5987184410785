import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smart-projects-import-info-modal',
  templateUrl: './projects-import-info-modal.component.html',
  styleUrls: ['./projects-import-info-modal.component.scss']
})
export class ProjectsImportInfoModalComponent implements OnInit {

  displayModal: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  openModal() {
    this.displayModal = true;
  }

  closeModal() {
    this.displayModal = false;
  }

}
