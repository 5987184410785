import { queryFilterTypes, queryOperators } from "../../../../core-frontend-common/misc/query-designer/query-designer";
import { createSubQuery } from "../../../../core-frontend-common/misc/query-designer/query-designer-functions";

export class FilterItem {
  queryParam: string;
  queryParamValue: string;
  queryParamData: any[];
  filterType: queryFilterTypes;
  itemParameter?: string;
}

export class SearchItem {
  searchParams: string[];
  searchValue: string;
}

export class OrderByItem {
  orderByParam: string;
  orderByType: "DESC" | "ASC";
}

export function getEmptyQueryDesignerFilter() {
  // @ts-ignore
  return {
    search: [],
    where: {
      operatorType: "AND",
      operands: []
    },
    orderBy: []
  };
}

export function getQueryDesignerFilter(
  filter: FilterItem[] = null,
  search: SearchItem = null,
  page: number = 1,
  pageSize: number = 10,
  orderBy: OrderByItem[] = null
) {
  const query = {
    search: []
  };

  if (filter) {
    filter.forEach(f => {
      query[f.queryParam] = {};
    });
  }

  // @ts-ignore
  const queryDesigner = {
    search: [],
    where: {
      operatorType: "AND",
      operands: []
    },
    page,
    pageSize,
    orderBy: []
  };

  if (orderBy) {
    orderBy.forEach(order => {
      queryDesigner.orderBy.push({
        field: order.orderByParam,
        order: order.orderByType
      });
    });
  } else {
    queryDesigner.orderBy.push({
      field: "Id",
      order: "DESC"
    });
  }

  if (search) {
    const stringArray = search.searchValue.split(/(\s+)/).filter(function(e) {
      return e.trim().length > 0;
    });
    for (let i = 0; i < stringArray.length; i++) {
      queryDesigner.search.push({
        fields: [],
        value: ""
      });
      queryDesigner.search[i].fields = search.searchParams;
      queryDesigner.search[i].value = stringArray[i];
    }
  }

  if (filter) {

    filter.forEach(filterItem => {
      query[filterItem.queryParam] = createSubQuery(queryOperators.OR, filterItem.queryParamData, filterItem.queryParamValue, filterItem.filterType, filterItem.itemParameter);
    });

    Object.keys(query)
      .forEach(queryItem => {
        if (Object.keys(query[queryItem]).length > 0 && queryItem !== "search") {
          queryDesigner.where.operands.push(query[queryItem]);
        }
      });

    return queryDesigner;
  }
  return queryDesigner;
}
