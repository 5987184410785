import { BaseDropdownModel } from "../../models/base-dropdown.model";

export const feeSources: BaseDropdownModel[] = [
  {
    item_text: 'feeSourceExactPrice',
    item_id: "exactPrice"
  },
  {
    item_text: 'feeSourceCalculatedPrice',
    item_id: "calculatedPrice"
  },
  {
    item_text: 'feeSourceEstimatedPrice',
    item_id: "estimatedPrice"
  }
];
