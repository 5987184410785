import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from './core-frontend-common/template/shared/i18n';
import { ToastrService } from 'ngx-toastr';
import { SystemMaintanceModel } from './+app-custom/models/system-maintance.model';
import { AppThemeService } from "./+app-custom/theme/app-theme.service";

@Component({
  selector: 'app-root',
  template: `
      <div class="alert alert-danger system-maintance-alert" *ngIf="isMaintanced">
          {{ systemMaintance.description }}
      </div>
      <router-outlet></router-outlet>
      <p-confirmDialog header="Confirmation" baseZIndex="2000"></p-confirmDialog>
      <overlay-dialog-root></overlay-dialog-root>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  systemMaintance: SystemMaintanceModel = new SystemMaintanceModel();
  isMaintanced = false;

  constructor(
    protected router: Router,
    protected i18n: I18nService,
    protected notification: ToastrService,
    protected appThemeService: AppThemeService,
  ) {
  }

  ngOnInit() {

    this.appThemeService.initTheme();

  }

  ngOnDestroy() {
  }

  // checkSystemMaintance() {
  //     this._generalService
  //         .checkSystemMaintance()
  //         .subscribe(value => {
  //             this.isMaintanced = true;
  //         }, () => {
  //             this.isMaintanced = false;
  //         })
  // }
}
