import { NgModule } from "@angular/core";
import { MyPublicationsComponent } from "./my-publications.component";
import { InputTextModule, TableModule } from "primeng";
import { I18nModule } from "../../../core-frontend-common/template/shared/i18n/i18n.module";
import { FormsModule } from "@angular/forms";
import { BtnGroupFilterModule } from "../../components/btn-group-filter/btn-group-filter.module";
import { CustomDatepickerModule } from "../../components/custom-datepicker/custom-datepicker.module";
import { TooltipModule } from "ngx-bootstrap";
import { CustomPipesModule } from "../../pipes/customPipes.module";
import { CommonModule } from "@angular/common";
import { TicketPriorityApprovementModalModule } from "../ticket-catalog/ticket-priority-approvement-modal/ticket-priority-approvement-modal.module";
import { ItemTableRowModule } from "../ticket-catalog/components/item-table-row/item-table-row.module";
import { TicketRowexpansionModule } from "../ticket-catalog/components/ticket-rowexpansion/ticket-rowexpansion.module";

@NgModule({
  imports: [
    InputTextModule,
    FormsModule,
    BtnGroupFilterModule,
    CustomDatepickerModule,
    TooltipModule,
    TableModule,
    CustomPipesModule,
    CommonModule,
    TicketPriorityApprovementModalModule,
    ItemTableRowModule,
    TicketRowexpansionModule,
    I18nModule.forChild({ extend: true })
  ],
  declarations: [
    MyPublicationsComponent
  ]
})

export class MyPublicationsModule {}
