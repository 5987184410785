import { BiblioProjectModel } from "../biblio/biblio-project.model";
import { ProjectParticipantModel } from "./project-participant.model";
import { GrantPriceModel } from "../../views/projets-management-view/project-form-page/grant-prices-subform/grant-prices-subform.component";
import { ResearchAndTechCodebookModel } from "../codebook/research-and-tech-codebook.model";
import { ProjectResearchAreaCodebookModel } from "../codebook/project-research-area-codebook.model";

export class ProjectModel {
  id?: number;
  name: string;
  nameEn: string;
  agencyCode: string;
  grantNumber: string;
  agencyName: string;
  recordLastUpdateDatetime?: Date;
  isLocked: boolean;
  programName: string;
  startYear?: number;
  endYear?: number;
  institutionName: string;
  institutionCode: string;
  departmentName: string;
  departmentCode: string;
  departmentIdAsString: string;
  grantPrice?: number;
  grantPrices: GrantPriceModel[] = [];
  keywordsAsString: string;
  acronym: string;
  annotation: string;
  annotationEn: string;
  participants: ProjectParticipantModel[];
  biblios: BiblioProjectModel[];

  isCommercialContract: boolean;
  isDomestic: boolean;
  isResearch: boolean;
  institutionIdNumber: string;
  grantSignatureDate: Date;
  achievementDescription: string;
  publishingUrlLink: string;
  contractRegisterUrlLink: string;
  participationType: 'coordinator' | 'partner';
  researchAndTechId?: number;
  researchAndTech: ResearchAndTechCodebookModel;
  projectResearchAreaId?: number;
  projectResearchArea: ProjectResearchAreaCodebookModel;

  isCurrentUserSupervisor: boolean;
}
