import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  getAllPersonsByFilterUrl, getAllTukeAuthorsByFilterUrl,
  getCheckOrcIdUrl,
  getCreateOrUpdatePersonUrl,
  getIsValidForCurrentUserUrl,
  getPersonById
} from "../app.urls";
import { map } from 'rxjs/operators';
import { BiblioPersonModel } from '../models/biblio/biblio-person.model';
import { PersonValidityModel } from "../models/person-validity.model";
import { PersonCreateUpdateRequestModel } from "../models/person-create-update.request.model";
import { TukeAuthorRequestModel } from "../models/tuke-author-request.model";

@Injectable({
  providedIn: 'root',
})
export class PersonsService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getAllByFilter(filter: any): Observable<{ totalItems: number, items: BiblioPersonModel[] }> {
    return this.http
      .post(getAllPersonsByFilterUrl(), filter)
      .pipe(map((res: any) => {
        return {
          totalItems: res.result.totalItems,
          items: res.result.items as BiblioPersonModel[],
        };
      }));
  }

  getAllTukeAuthorsByFilter(filter: any): Observable<TukeAuthorRequestModel[]> {
    return this.http
      .post(getAllTukeAuthorsByFilterUrl(), filter)
      .pipe(map((res: any) => {
        return res.items as TukeAuthorRequestModel[];
      }));
  }

  getById(id: number): Observable<BiblioPersonModel> {
    return this.http
      .get(getPersonById(id))
      .pipe(map((res: any) => {
        return res as BiblioPersonModel;
      }));
  }

  isValidForCurrentUser(): Observable<PersonValidityModel> {
    return this.http
      .get(getIsValidForCurrentUserUrl())
      .pipe(map((res: any) => {
        return res.result as PersonValidityModel;
      }));
  }

  createOrUpdatePerson(person: PersonCreateUpdateRequestModel): Observable<PersonValidityModel> {
    return this.http
      .post(getCreateOrUpdatePersonUrl(), person)
      .pipe(map((res: any) => {
        return res as PersonValidityModel;
      }));
  }

  checkOrcId(orcid: string): Observable<any> {
    return this.http
      .get(getCheckOrcIdUrl(orcid))
      .pipe(map((res: any) => {
        return res;
      }));
  }
}
