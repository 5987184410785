export class OpenAccessHostItemRequestModel {
  id?: number;
  policy: string;
  startYear?: number | Date;
  endYear?: number | Date;
  acpFees?: number;
  otherFees?: number;
  feeSourceInfo?: string;

  isValid?: boolean;
}
