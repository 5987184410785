import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "smart-youtube-player",
  templateUrl: "./youtube-player.component.html",
  styleUrls: ["./youtube-player.component.scss"]
})
export class YoutubePlayerComponent implements OnInit {

  @Input() url: string;

  @Input() title = 'Tutorial';

  @Input() height: 'auto' | '100%' = 'auto';

  constructor() {
  }

  ngOnInit() {
  }

}
