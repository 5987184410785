import { Component, Input, OnInit } from "@angular/core";
import { FormElements } from "../../../ticket-catalog/misc/form-elements";
import { CrossrefMetadataModel } from "../../../../models/crossref/crossref-metadata.model";
import { BiblioIdentifiersModel } from "./biblio-identifiers.model";

@Component({
  selector: "smart-biblio-identifiers",
  templateUrl: "./biblio-identifiers.component.html",
  styleUrls: ["./biblio-identifiers.component.scss"]
})
export class BiblioIdentifiersComponent implements OnInit {
  @Input() identifiers: BiblioIdentifiersModel = new BiblioIdentifiersModel();

  @Input() formElements: FormElements;

  @Input() crossRefBiblio: CrossrefMetadataModel;

  @Input() selectedDoiOption: "doi" | "no-doi";

  @Input() doiSuccessfullyLoaded: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  changeCrossRefValue(targetEl: string, value: string) {
    if (targetEl && value) {
      this.identifiers[targetEl] = value;
    }
  }

}
