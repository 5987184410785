export enum TicketActionsEnum {
  up,
  down,
  comment,
  left,
  edit,
  info,
  contact,
  check,
  delete,
  goTo,
  contactUser,
  quote,
  importDw,
  biblio,
  downloadDispatchNote
}

export class TicketActions {
  up = false;
  down = false;
  comment = false;
  left = false;
  edit = false;
  info = false;
  contact = false;
  check = false;
  delete = false;
  goTo = false;
  contactUser = false;
  quote = false;
  importDw = false;
  biblio = false;
  downloadDispatchNote = false;
}

export function generateTicketActions(
  info = false, up = false, down = false,
  comment = false, left = false, edit = false,
  contact = false, check = false, del = false, goTo = false,
  contactUser = false, quote = false, importDw = false,
  biblio = false, downloadDispatchNote = false
): TicketActions {
  const c = new TicketActions();
  c.info = info;
  c.up = up;
  c.down = down;
  c.comment = comment;
  c.left = left;
  c.edit = edit;
  c.contact = contact;
  c.check = check;
  c.delete = del;
  c.goTo = goTo;
  c.contactUser = contactUser;
  c.quote = quote;
  c.importDw = importDw;
  c.biblio = biblio;
  c.downloadDispatchNote = downloadDispatchNote;
  return c;
}
