import { Component, Input, OnInit } from "@angular/core";
import { CitationModel } from "../../../models/biblio/citation.model";

@Component({
  selector: 'smart-citations-list',
  templateUrl: './citations-list.component.html',
  styleUrls: ['./citations-list.component.scss']
})
export class CitationsListComponent implements OnInit {

  @Input() citations: CitationModel[] = [];

  constructor() { }

  ngOnInit() {
  }

  trackByFn(index: number, item: CitationModel) {
    return item.id;
  }

}
