export class DwDatabaseItem {
  name: DwDatabasesEnum | string;
  value: null | DwDatabasesEnum;
}

export enum DwDatabasesEnum {
  SCOPUS = "SCOPUS",
  DOI = "DOI",
  CCC = "Current Content Connect",
  WOS = "Web of Science Core Collection"
}

export enum DwDatabasesAcronymsEnum {
  SCOPUS = 'SCOPUS',
  DOI = 'DOI',
  WOS = 'WOS',
  CCC = 'CCC'
}

export const DwDatabasesData: DwDatabaseItem[] = [
  {
    name: "Vyberte databázu",
    value: null
  },
  {
    name: DwDatabasesEnum.SCOPUS,
    value: DwDatabasesEnum.SCOPUS
  },
  {
    name: DwDatabasesEnum.DOI,
    value: DwDatabasesEnum.DOI
  },
  {
    name: DwDatabasesEnum.CCC,
    value: DwDatabasesEnum.CCC
  },
  {
    name: DwDatabasesEnum.WOS,
    value: DwDatabasesEnum.WOS
  }
];


