import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { TicketSimpleModel } from "../../../models/ticket/ticket-simple.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap";
import { TicketService } from "../../../services/ticket.service";
import { TicketContactEmailRequestModel } from "../../../models/requests/ticket-contact-email-request.model";
import { ToastrService } from "ngx-toastr";
import { UserModel } from "../../../models/user.model";
import { AppCommunicationService } from "../../../services/app-communication.service";
import { ContactEmailModel } from "./contact-email.model";
import { EmailTemplates } from "./email-templates";
import { TicketContactEmailBulkRequestModel } from "../../../models/requests/ticket-contact-email-bulk-request.model";
import { EmailBaseModel } from "../../../models/requests/email-base.model";
import { TicketGotoRequestModel } from "../../../models/requests/ticket-goto-request.model";
import { TicketStatuses } from "../../../constants";
import { I18nService } from "../../../../core-frontend-common/template/shared/i18n";

@Component({
  selector: "smart-ticket-catalog-contact-email-modal",
  templateUrl: "./ticket-contact-email-modal.component.html",
  styleUrls: ["./ticket-contact-email-modal.component.scss"]
})
export class TicketContactEmailModalComponent implements OnInit {
  ticketContactForm: FormGroup;

  ticket: TicketSimpleModel;

  tickets: TicketSimpleModel[] = [];

  templatesData: ContactEmailModel[] = EmailTemplates;

  user: UserModel;

  selectedTemplate = {};

  isMultiMail = false;

  showToRework = false;

  toRework = false;

  sendToResponsible = true;

  sendToWorkflowAdmin = true;

  @ViewChild("staticModal", { static: false }) modal: ModalDirective;

  @Output() onFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    protected ticketService: TicketService,
    protected toastrService: ToastrService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.ticketContactForm = this.formBuilder.group({
      subject: ["", [Validators.required]],
      body: ["", [Validators.required]],
      recipientAddresses: ["", [Validators.required, Validators.email]]
    });
  }

  async show(ticket: TicketSimpleModel[], multi = false, showToRework = false) {
    this.ticket = ticket[0];
    if (multi) {
      this.tickets = ticket;
    }
    this.showToRework = showToRework;
    this.isMultiMail = multi;
    this.user = AppCommunicationService.getCurrentUser().user;
    this.prefillInputs();
    this.modal.show();
  }

  prefillInputs() {
    if (this.ticket && this.ticket.createdBy && this.ticket.createdBy.email) {
      this.ticketContactForm.get("recipientAddresses").patchValue(this.ticket.createdBy.email);
    }

    if (this.ticket && this.ticket.archiveId) {
      this.ticketContactForm.get("subject").patchValue(this.ticket.archiveId);
    } else {
      this.ticketContactForm.get("subject").patchValue(this.templatesData[0].value.subject);
    }

    this.prefillBody(this.templatesData[0].value.body);
  }

  changedTemplate(val: { title: string, value: any }) {
    if (this.ticket && this.ticket.archiveId) {
      this.ticketContactForm.get("subject").patchValue(this.ticket.archiveId);
    } else {
      this.ticketContactForm.get("subject").patchValue(val.value.subject);
    }

    this.prefillBody(val.value.body);
  }

  prefillBody(body: string) {
    let b = body;
    b += "\n";
    b += `${this.user.title ? this.user.title : ""} ${this.user.name} ${this.user.surname} ${this.user.titleAfter ? this.user.titleAfter : ""}`;
    this.ticketContactForm.get("body").patchValue(b);
  }

  close() {
    this.clear();
    this.modal.hide();
  }

  clear() {
    this.ticketContactForm.reset();
  }

  async submit() {
    if (this.ticketContactForm.valid) {

      const email = new TicketContactEmailRequestModel();
      const form = this.ticketContactForm.value;
      email.body = form.body;
      email.subject = form.subject;
      email.ticketId = this.ticket.id;
      email.recipientAddresses = [form.recipientAddresses];
      email.sendToResponsible = this.sendToResponsible;
      email.sendToWorkflowAdmin = this.sendToWorkflowAdmin;

      let res;

      if (this.isMultiMail) {
        const emails: TicketContactEmailBulkRequestModel = new TicketContactEmailBulkRequestModel();
        const emailBases: EmailBaseModel[] = [];

        this.tickets.forEach(ticket => {
          const t = new EmailBaseModel();
          t.recipientAddresses = email.recipientAddresses;
          t.recipients = email.recipients;
          t.ticketId = ticket.id;
          t.sendToResponsible = this.sendToResponsible;
          t.sendToWorkflowAdmin = this.sendToWorkflowAdmin;
          emailBases.push(t);
        });

        emails.body = email.body;
        emails.subject = email.subject;
        emails.tickets = emailBases;

        res = await this.ticketService
          .contactEmailBulk(emails)
          .toPromise();
      } else {
        res = await this.ticketService
          .contactEmail(email)
          .toPromise();
      }

      if (res) {

        if (this.showToRework && this.toRework) {

          const gotoReq = new TicketGotoRequestModel();
          gotoReq.ticketId = this.ticket.id;
          gotoReq.comment = this.ticket.comment;
          gotoReq.responsibleId = 0;
          gotoReq.status = TicketStatuses.to_rework;
          gotoReq.attachmentType = this.ticket.attachmentType;
          gotoReq.handOverType = this.ticket.handOverType;

          const gotoRes = await this.ticketService
            .getGoto(gotoReq)
            .toPromise();

          if (gotoRes) {
            this.toastrService.success(this.i18n.getTranslation("Tiket odoslaný na prepracovanie."));
            this.successResult();
          } else {
            this.toastrService.error(this.i18n.getTranslation("Tiket sa na prepracovanie nepodarilo odoslať."));
          }
          return;

        }

        this.successResult();
        return;
      }

      this.emailNotSentNotification();
    }
  }

  successResult() {
    this.emailSentNotification();
    this.onFinished.emit(true);
    this.close();
  }

  emailSentNotification() {
    this.toastrService.success(this.i18n.getTranslation("E-mail bol úspešne odoslaný."));
  }

  emailNotSentNotification() {
    this.toastrService.error(this.i18n.getTranslation("E-mail sa nepodarilo odoslať."));
  }

}
