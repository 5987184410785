import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { TicketSimpleModel } from "../../../models/ticket/ticket-simple.model";
import { CODEBOOK_ITEMS, CodebooksService } from "../../../services/codebooks.service";
import { Dropdown } from "primeng";
import { BiblioCategoryCodebookWithFormGroupModel } from "../../../models/codebook/biblio-category-codebook-with-formgroup.model";
import { TicketFullModel } from "../../../models/ticket/ticket-full.model";
import { TicketService } from "../../../services/ticket.service";
import { FormBiblioModel } from "../../../models/registration-form/form-biblio.model";

@Component({
  selector: "smart-ticket-import-from-dw-modal",
  templateUrl: "./ticket-import-from-dw-modal.component.html",
  styleUrls: ["./ticket-import-from-dw-modal.component.scss"]
})
export class TicketImportFromDwModalComponent implements OnInit {
  formBiblio: FormBiblioModel;

  currentFormGroup;

  currentBiblioCategory;

  allBiblioCategories: BiblioCategoryCodebookWithFormGroupModel[] = [];
  filteredBiblioCategories: BiblioCategoryCodebookWithFormGroupModel[] = [];

  ticket: TicketSimpleModel;

  categoryWasChanged = false;

  afterCloseFn: () => void;

  @ViewChild("staticModal", { static: false }) modal: ModalDirective;

  @ViewChild("biblioCategoryEl", { static: false }) biblioCategoryEl: Dropdown;

  constructor(
    private codebooksService: CodebooksService,
    private ticketService: TicketService
  ) {
  }

  ngOnInit() {
  }

  clear() {
  }

  async show(ticket: TicketSimpleModel, afterCloseFn: () => void = null) {
    this.ticket = ticket;
    this.afterCloseFn = afterCloseFn;

    await this.initCodebooks();

    const res = await this.loadFormBiblio();

    if (res && res.formBiblio) {
      this.formBiblio = res.formBiblio;
      this.currentFormGroup = res.formBiblio.group;
      this.prefilterBiblioCategories();
    }

    await this.prefillData();

    this.modal.show();
  }

  submit() {
  }

  close(closeWithoutFn = false) {
    this.modal.hide();
    if (this.afterCloseFn && !closeWithoutFn) {
      this.afterCloseFn();
    }
    this.clear();
  }

  async initCodebooks() {
    this.codebooksService.get(CODEBOOK_ITEMS.biblioCategoriesWithFormGroups)
      .then((categories: BiblioCategoryCodebookWithFormGroupModel[]) => {
        this.allBiblioCategories = categories.filter(c => c.isValid)
          .sort((a, b) => {
            return b.priority - a.priority;
          });
      });
  }

  async loadFormBiblio(): Promise<TicketFullModel> {
    return this.ticketService.getTicketById(this.ticket.id).toPromise();
  }

  async prefillData() {
    await this.prefillBiblioCategory();
  }

  async prefillBiblioCategory() {
    setTimeout(() => {
      this.currentBiblioCategory = this.formBiblio.biblioCategory;
      this.fixDropdownValues("biblioCategory");
    }, 50);
  }

  prefilterBiblioCategories() {
    console.log(this.allBiblioCategories)
    console.log(this.currentFormGroup);
    console.log(this.formBiblio)
    this.filteredBiblioCategories = this.allBiblioCategories.filter(c =>
      c.formGroups.find(s => s.code === this.currentFormGroup)
    );
  }

  onBiblioCategoryChange(e) {
    if (!e || !e.value || !e.value.code) {
      return;
    }
    const biblioCategoryCode = e.value.code;
    const biblioCategory = this.filteredBiblioCategories.find(v => v.code === biblioCategoryCode);

    if (biblioCategory) {
      this.currentBiblioCategory = biblioCategory;
    }

    this.categoryWasChanged = true;
  }

  fixDropdownValues(type: "biblioCategory") {
    if (type === "biblioCategory") {
      this.biblioCategoryEl.selectedOption = { label: this.currentBiblioCategory.code, value: this.currentBiblioCategory };
    }
  }
}
