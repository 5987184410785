import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TicketSimpleModel } from "../../../../models/ticket/ticket-simple.model";
import { TicketService } from "../../../../services/ticket.service";
import { TicketFullModel } from "../../../../models/ticket/ticket-full.model";
import { FileService } from "../../../../services/file.service";
import { FileMetadataModel } from "../../../../models/biblio/file-metadata.model";
import { TableColumn } from "../../../../misc/table-responsive";
import { BiblioService } from "../../../../services/biblio.service";
import { BiblioDetailComponent } from "../../../../components/biblio-detail/biblio-detail.component";
import { AuthorTukeEmploymentDialogComponent } from "../author-tuke-employment-dialog/author-tuke-employment-dialog.component";
import { TicketSection } from "../../misc/ticket-sections";

@Component({
  selector: "smart-ticket-catalog-rowexpansion",
  templateUrl: "./ticket-rowexpansion.component.html",
  styleUrls: ["./ticket-rowexpansion.component.scss"]
})
export class TicketRowexpansionComponent implements OnInit {

  fullTicket: TicketFullModel;

  file: FileMetadataModel;

  @Input() ticket: TicketSimpleModel;

  @Input() viewType: "admin" | "pedagogue" = "admin";

  @Input() notVisibleCols: TableColumn[] = [];

  @Input() activeTab: TicketSection;

  @Output() refreshData: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() showAllCitationsForBiblioIdEvent: EventEmitter<number> = new EventEmitter();

  @ViewChild(BiblioDetailComponent, {static: false}) biblioDetail: BiblioDetailComponent;

  @ViewChild(AuthorTukeEmploymentDialogComponent, {static: false}) authorTukeEmploymentDialog: AuthorTukeEmploymentDialogComponent;

  constructor(
    protected ticketService: TicketService,
    protected fileService: FileService,
    protected biblioService: BiblioService
  ) {
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {

    let res;

    if (this.viewType === "admin") {
      res = await this.ticketService.getTicketById(this.ticket.id).toPromise();
    } else {
      res = await this.ticketService.getTicketByIdForOwner(this.ticket.id).toPromise();
    }

    if (res) {
      this.fullTicket = res;

      if (this.fullTicket.fileMetadataId) {
        this.fileService.getInfo(this.fullTicket.fileMetadataId).subscribe(f => this.file = f);
      }
    }

  }

  openAuthorTukeEmploymentsDialog(login: string) {
    this.authorTukeEmploymentDialog.openModal(login, this.fullTicket, () => this.refreshData.emit(true));
  }

  showAllCitationsForBiblioId(biblioId: number) {
    this.showAllCitationsForBiblioIdEvent.emit(biblioId);
  }

}
