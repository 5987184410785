import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BaseConstantModel } from "../../../../models/base-constant.model";
import { first } from "rxjs/operators";
import { TicketService } from "../../../../services/ticket.service";
import { BaseDropdownModel } from "../../../../models/base-dropdown.model";
import { feeSources } from "../../open-access-fee-sources.data";
import { OpenAccessHostItemModel } from "../../../../models/open-access/open-access-host-item.model";
import { OpenAccessHostItemRequestModel } from "../../../../models/open-access/open-access-host-item-request.model";
import * as moment from "moment";
import { Dropdown } from "primeng";

@Component({
  selector: "smart-open-access-source-document",
  templateUrl: "./open-access-source-document.component.html",
  styleUrls: ["./open-access-source-document.component.scss"]
})
export class OpenAccessSourceDocumentComponent implements OnInit {

  openAccessSource: OpenAccessHostItemModel = new OpenAccessHostItemModel();

  policies: BaseConstantModel[] = [];

  feeSources: BaseDropdownModel[] = feeSources;

  @ViewChild("feeSourcesEl", { static: false }) feeSourcesEl: Dropdown;
  @ViewChild("policyEl", { static: false }) policyEl: Dropdown;

  @Output() dataChanged: EventEmitter<OpenAccessHostItemRequestModel> = new EventEmitter<OpenAccessHostItemRequestModel>();

  @Input() set openAccessSourceDocument(source: OpenAccessHostItemRequestModel | null) {
    if (source) {
      Object.keys(source).forEach(key => {
        if (source[key] && key.toLowerCase().indexOf("year") === -1) {
          this.openAccessSource[key] = source[key];
        }
      });

      if (source.startYear) {
        // @ts-ignore
        this.openAccessSource.startYear = moment(source.startYear, "YYYY").toDate();
      }

      if (source.endYear) {
        // @ts-ignore
        this.openAccessSource.endYear = moment(source.endYear, "YYYY").toDate();
      }

      setTimeout(() => {
        this.emitData();
      }, 150);
    }
  }

  constructor(
    private ticketService: TicketService
  ) {
  }

  ngOnInit() {

    this.getOpenAccessTypes();

  }

  getOpenAccessTypes() {
    this.ticketService
      .getOpenAccessTypes()
      .pipe(first())
      .subscribe({
        next: value => {
          this.policies = value;

          this.fulfillValues();
        }
      });
  }

  fulfillValues() {
    setTimeout(() => {

      if (this.openAccessSource.feeSourceInfo && this.feeSourcesEl) {
        this.feeSourcesEl.selectedOption = { label: this.openAccessSource.feeSourceInfo, value: {item_text: this.openAccessSource.feeSourceInfo, item_id: this.openAccessSource.feeSourceInfo} };
      }

      if (this.openAccessSource.policy && this.policyEl) {
        const pol = this.policies.find(p => p.value === this.openAccessSource.policy);
        if (pol) {
          this.policyEl.selectedOption = { label: pol.description, value: pol };
        }
      }

    }, 50);
  }

  policyChange(e) {
    this.openAccessSource.policy = e.value.value;
    this.emitData();
  }

  feeSurceChange(e) {
    this.openAccessSource.feeSourceInfo = e.value.item_text;
    this.emitData();
  }

  isSectionValid(): boolean {
    if (this.openAccessSource.policy.trim().length > 0) {
      return true;
    }
    return false;
  }

  getOpenAccessSectionData(): OpenAccessHostItemRequestModel {
    let openAccessHostItem: OpenAccessHostItemRequestModel;
    openAccessHostItem = { ...this.openAccessSource };
    if (openAccessHostItem.startYear) {
      openAccessHostItem.startYear = Number.parseInt(moment(openAccessHostItem.startYear).format("YYYY"), 0);
    }
    if (openAccessHostItem.endYear) {
      openAccessHostItem.endYear = Number.parseInt(moment(openAccessHostItem.endYear).format("YYYY"), 0);
    }
    return openAccessHostItem;
  }

  emitData() {
    const i = this.getOpenAccessSectionData();
    i.isValid = this.isSectionValid();
    this.dataChanged.emit(i);
  }

  changedData(value, type: 'start' | 'end') {
    if (type === "start") {
      this.openAccessSource.startYear = value;
    } else {
      this.openAccessSource.endYear = value;
    }

    this.emitData();
  }

}
