import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[mouseHoverStyle]'
})
export class MouseHoverStyleDirective {

  constructor(
    private el: ElementRef
  ) {
  }

  @HostListener(':mousemove', ['$event'])
  onMouseMove(event) {
    // const element = this.el.nativeElement as HTMLLinkElement;
    // if (!element) return;
    // const { left, top } = element.getBoundingClientRect();
    // this.el.nativeElement.style.setProperty('--x', element.clientLeft - left);
    // this.el.nativeElement.style.setProperty('--y', element.clientTop - top);
  }

}
