import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BiblioModel } from "../../../../models/biblio/biblio.model";
import { BiblioService } from "../../../../services/biblio.service";
import { BiblioPersonModel } from "../../../../models/biblio/biblio-person.model";
import { FilterService, FilterToShow } from "../filter/filter.service";
import { CitationComponent } from "../citation/citation.component";
import { map } from "rxjs/operators";
import { BreadcrumbsService } from "../../../../components/breadcrumbs/breadcrumbs.service";
import { createSubQuery } from "../../../../../core-frontend-common/misc/query-designer/query-designer-functions";
import { BiblioDetailComponent } from "../../../../components/biblio-detail/biblio-detail.component";
import { queryFilterTypes, queryOperators } from "../../../../../core-frontend-common/misc/query-designer/query-designer";

@Component({
  selector: "smart-biblio-table",
  templateUrl: "./biblio-table.component.html",
  styleUrls: ["./biblio-table.component.scss"]
})
export class BiblioTableComponent implements OnInit, OnDestroy {
  data: BiblioModel[] = [];

  collapsed = false;

  biblioFilter: any = {};

  selectedBibliosCount = 0;

  datatableSettings = {
    loading: false,
    paginator: true,
    pageSize: 10,
    page: 1,
    totalRecords: 0,
    lazy: true,
    colNumber: 5
  };

  @Input() isActive = false;

  @ViewChild(CitationComponent, { static: false }) citationModal: CitationComponent;

  @ViewChild(BiblioDetailComponent, { static: false }) biblioDetail: BiblioDetailComponent;

  constructor(
    protected biblioService: BiblioService,
    protected filterService: FilterService,
    protected breadcrumbsService: BreadcrumbsService
  ) {
  }

  ngOnInit() {
    this.onResize(window.innerWidth, true);

    setTimeout(() => {
      this.filterService
        .filterToShow
        .next(new FilterToShow(true, true, true, true, true));
    }, 200);
  }

  ngOnDestroy() {
  }

  onResize(event, onLoad = false) {
    if (onLoad) {
      this.getWindowWidth(event);
    } else {
      this.getWindowWidth(event.target.innerWidth);
    }
  }

  getWindowWidth(width) {
    if (width >= 1120) {
      this.datatableSettings.colNumber = 7;
    } else if (width >= 960) {
      this.datatableSettings.colNumber = 7;
    } else if (width >= 800) {
      this.datatableSettings.colNumber = 6;
    } else if (width >= 640) {
      this.datatableSettings.colNumber = 6;
    } else if (width >= 480) {
      this.datatableSettings.colNumber = 3;
    } else if (width > 320) {
      this.datatableSettings.colNumber = 3;
    } else if (width <= 320) {
      this.datatableSettings.colNumber = 3;
    }
  }

  getBiblio(dataFilter = null) {
    this.datatableSettings.loading = true;

    let filter;

    if (dataFilter) {
      filter = this.fulfillFilter(dataFilter);
      this.biblioFilter = dataFilter;
      this.datatableSettings.pageSize = dataFilter.pageSize.value;
    } else {
      this.datatableSettings.loading = false;
      return;
    }

    this.biblioService
      .getAllByFilter(filter)
      .pipe(map(res => {
        const totalItems = res.totalItems;
        const items = res.items;
        items.forEach(i => i.selected = false);
        return {
          totalItems,
          items
        };
      }))
      .subscribe(val => {
        this.datatableSettings.totalRecords = val.totalItems as number;
        this.data = val.items;
        setTimeout(() => {
          this.datatableSettings.loading = false;
        }, 200);
      }, err => {
        setTimeout(() => {
          this.datatableSettings.loading = false;
        }, 200);
      });
  }

  fulfillFilter(filter: any) {
    const query = {
      search: [],
      year: {},
      categoryCode: {}
    };
    // @ts-ignore
    const queryDesigner = {
      search: [],
      where: {
        operatorType: "AND",
        operands: []
      },
      page: this.datatableSettings.page,
      pageSize: filter.pageSize ? filter.pageSize.value : this.datatableSettings.pageSize,
      orderBy: [
        {
          field: "CreatedDatetime",
          order: "DESC"
        },
        {
          field: "Id",
          order: "DESC"
        }
      ]
    };

    if (filter) {
      if (filter && filter.text && filter.text.length > 0) {
        const stringArray = filter.text.split(/(\s+)/).filter(function(e) {
          return e.trim().length > 0;
        });
        for (let i = 0; i < stringArray.length; i++) {
          queryDesigner.search.push({
            fields: [],
            value: ""
          });
          queryDesigner.search[i].fields = ["title"];
          queryDesigner.search[i].value = stringArray[i];
        }
      }

      if (filter && filter.biblioCategories && filter.biblioCategories.length > 0) {
        query.categoryCode = createSubQuery(queryOperators.OR, filter.biblioCategories, "biblioCategory.code", queryFilterTypes.EQUAL, "code");
      }

      if (filter && filter.years && filter.years.length > 0) {
        const years = filter.years.map(Number);
        query.year = createSubQuery(queryOperators.OR, years, "yearOfPublishing", queryFilterTypes.EQUAL);
      }

      Object.keys(query)
        .forEach(queryItem => {
          if (Object.keys(query[queryItem]).length > 0 && queryItem !== "search") {
            queryDesigner.where.operands.push(query[queryItem]);
          }
        });

      return queryDesigner;
    }
    return queryDesigner;
  }

  setPage(pageInfo) {
    if (this.biblioFilter && this.datatableSettings.pageSize) {
      this.datatableSettings.page = (pageInfo.first + pageInfo.rows) / this.datatableSettings.pageSize;
      this.getBiblio(this.biblioFilter);
      this.scrollToTop();
    }
  }

  scrollToTop() {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  }

  collapseAll() {
    this.collapsed = !this.collapsed;
  }

  selectAllClick() {
    const isSomeUnselected = this.data.filter(v => !v.selected);
    if (isSomeUnselected && isSomeUnselected.length > 0) {
      this.data.forEach(i => i.selected = true);
      this.selectedBibliosCount = this.data.length;
    } else {
      this.data.forEach(i => i.selected = false);
      this.selectedBibliosCount = 0;
    }
  }

  personClick(person: BiblioPersonModel) {
    this.breadcrumbsService
      .push({
        breadcrumb: {
          title: person.fullName,
          data: person,
          filter: Object.assign(this.filterService.getCurrentFilter())
        }
      }, true);
  }

  clickedCitation(biblio: BiblioModel, closeFn: () => void = null) {
    this.citationModal.show(biblio, closeFn);
  }

  biblioSelected(biblio: BiblioModel) {
    console.log(biblio);
    // biblio.selected = !biblio.selected;
    setTimeout(() => {
      this.selectedBibliosCount = this.data.filter(i => i.selected === true).length;
    }, 200);
  }

  detailClick(biblio: BiblioModel) {
    this.biblioDetail.show(null, biblio.id, biblio);
  }

  downloadClick() {
    alert("download");
  }

  exportClick() {
    alert("Exportujem:..." + this.data);
  }
}
